/*
 * @Author: liuzhihao
 * @Date: 2020-08-12 10:33:56
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-10-18 23:44:53
 * @FilePath: \static\theme\Theme2\index.js
 * @Description: 头部注释
 */
// // 主题样式
// import './index.css'
// // icon样式
// import './iconCss/index.css'
// // 导入全局样式
// import './app/index.css'
var themeColor = "#1BC1B3"
export default {
  themeId: 2,
  iconType: 2,
  themeColor: themeColor,
  // 绿色1"#1BC1B3|#1BC1B3" 
  // themeMixin: themeColor + "|" + "#21ACA0",
  statisticsColor: {
    // 公假
    sabbaticals: themeColor,
    // 病假
    sickLeave: "#EA5008",
    // 事假
    casualLeave: "#F5C95E",
    // 打卡
    clockIn: themeColor,
    // 需打卡
    needClockIn: "#F5C95E",
  }
}