<!--
 * @Author: longtuxin
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-11-07 17:57:37
 * @FilePath: \web\src\pages\Home.vue
 * @Description: 简介
-->
<template>
  <div v-loading.fullscreen="loading">
    <SetSubjectDialog
      :visible.sync="setSubjectVisible"
      :noSubjectClassList="noSubjectClassList"
      @success="execRedirect(curJumpMenu)"
    ></SetSubjectDialog>
  </div>
</template>
<script>
import MenuMixin from "@/mixins/MenuMixin";
import SetSubjectDialog from "@/components/base/SetSubjectDialog.vue";
import { spacePfvs, saasSpacePfvs } from "../../config/pfv";
export default {
  components: {
    SetSubjectDialog,
  },
  computed: {
    homeMenuUrl() {
      return this.$store.state.layout.homeMenuUrl;
    },
    homeMenu() {
      return this.$store.state.layout.homeMenu;
    },
    domainConfig() {
      return this.$store.state.app.domainConfig;
    },
    isSpace() {
      if (!this.domainConfig) return false;
      return (
        spacePfvs.includes(this.domainConfig.pfv) ||
        saasSpacePfvs.includes(this.domainConfig.pfv)
      );
    },
  },
  data(){
    return {
      loading: true
    }
  },
  watch: {
    homeMenu(url) {
      this.init();
    },
  },
  mixins: [MenuMixin],
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let fullPath = this.$route.fullPath;
      console.log("this.homeMenu", this.homeMenu);
      this.handleMenuRedirect(this.homeMenu);
      this.loading = false;
      return;
    },
  },
};
</script>

