/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-02 14:17:03
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-03-28 16:58:55
 * @Description: 集团校首页布局
 */

<template>
  <div class='wrap'>
    <div class="header">
      <!-- 集团名称 -->
      <school-info></school-info>
      <!-- 中间展示部分 -->
      <ul class="header_center">
        <li>共融</li>
        <li>共享</li>
        <li>共生</li>
        <li>共荣</li>
      </ul>
      <!-- 账户信息、角色 -->
      <change-role-and-user-manage></change-role-and-user-manage>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SchoolInfo from './components/SchoolInfo'
import ChangeRoleAndUserManage from './components/ChangeRoleAndUserManage'
export default {
  name:'',
  props: {},
  components: {
    SchoolInfo,
    ChangeRoleAndUserManage
  },
  data() {
    return {

    };
  },
  computed: {},
  created() {},
  methods: {}
}
</script>
<style scoped>
.wrap {
  width: 100%;
  height: 100%;
}
.header {
  height: 72px;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1A58E8;
  box-shadow: 0px 2px 8px rgba(29, 35, 41, 0.05);
  color: #fff;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}
.header_center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
}

.header_center li {
  font-size: 28px;
  letter-spacing: 0.1em;
}

.header_center li ~ li {
  margin-left: 16px;
}
.main {
  width: 100%;
  height: calc(100vh - 72px); 
  background-color: #f6f7f9;
}
</style>