const { removeAuthToken } = require("../utils/auth");

/*
 * @Author: liuzhihao
 * @Date: 2021-08-28 14:10:32
 * @LastEditors: chenhuiling
 * @LastEditTime: 2023-09-27 18:29:17
 * @FilePath: \web\src\services\LoginService.js
 * @Description: 头部注释
 */
exports.logout = function (cb) {
    localStorage.clear()
    sessionStorage.clear()
    removeAuthToken()
    let ctx = "/";
    if (location.href.indexOf("ssportal") !== -1) {
        ctx = "/ssportal/";
    }
    let timestamp = new Date().getTime();

    cb && cb()
    location.href = ctx + "login/logoutPage?timestamp=" + timestamp;
        
}