<template>
	<div class="no-data-tip"
	     :style="`${imgUrl ? `background-image: url(${imgUrl}); background-size: 100%;` : ''}`">{{ resmsg }}</div>
</template>

<script type="text/javascript">
export default {
  props: {
    resmsg: {
      type: String,
      default: '暂无数据'
    },
    imgUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
<style type="text/css" scoped >
.no-data-tip {
  margin-bottom: 30px;
  text-align: center;
  padding-top: 150px;
  font-size: 16px;
  color: #999;
  background-image: url('../../assets/images/no-data-img.png');
  background-repeat: no-repeat;
  background-position: center top;
}
</style>