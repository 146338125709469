import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/Layout'
import Home from '@/pages/Home'
import BlackMiddle from '@/pages/BlackMiddle'
// import AppHome from '@/pages/AppHome'
// import AppHomeYd from '@/pages/AppHomeYd'
// import AppHomeImg from '@/pages/AppHomeImg'
// import AppHomeData from '@/pages/AppHomeData'
// import AppHomeSchool from '@/pages/AppHomeSchool'
import NotFoundPage from '@/pages/NotFoundPage'
import AuthLogin from '@/pages/AuthLogin'
import WebIframe from '@/pages/WebIframe'
import Building from '@/pages/Building'
import PersonPortrayal from '@/pages/PersonPortrayal'
import { sensorsInit } from './autotrack'
import componentsDesc from '@/pages/componentsDesc'

Vue.use(Router)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

/*
路由引入
需import路由文件，然后添加进routes数组中加载
add by 2017-04-25
*/
/*管理员*/
import handle from '../utils/handle.js'
import store from '../store'
import layout from '../store/modules/layout.js'
import { postByUrl } from '@/api/common'

const sensifuncValidate = (r) =>
  require.ensure(
    [],
    () => r(require('edumgr/lib/SensifuncValidate')),
    'SensifuncValidate'
  )
const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/login',
    beforeEnter (to, from, next) {
      location.href = '../#/loginPage'
    },
  },
  {
    path: '/prod_login',
    beforeEnter() {
      location.href = '../ssportal/loginPage'
    }
  },
  {
    path: '/sxLogin',
    beforeEnter(to, from, next) {
      localStorage.removeItem('storage_notice');
      location.href = '/'
    },
  },
  // {
  //   path: '/app_home',
  //   component: AppHome,
  // },
  // ,
  // {
  //   path: '/app_home_yd',
  //   component: AppHomeYd,
  // },
  // {
  //   path: '/app_home_img',
  //   component: AppHomeImg,
  // },
  // {
  //   path: '/app_home_data',
  //   component: AppHomeData,
  // },
  // {
  //   path: '/app_home_school',
  //   component: AppHomeSchool,
  // },
  {
    path: '/index',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
      },
      {
        path: 'black',
        name: 'black_middle_page',
        component: BlackMiddle
      },
      {
        path: 'sensifuncValidate',
        name: 'sensifuncValidate',
        component: sensifuncValidate,
        props: true,
      },
    ],
  },
  {
    path: '/personPortrayal',
    component: PersonPortrayal,
  },
  {
    path: '/authLogin',
    component: AuthLogin,
  },
  {
    path: '/web',
    component: Layout,
    children: [
      {
        path: '*',
        component: WebIframe,
      },
    ],
  },
  {
    path: '/building',
    component: Layout,
    children: [
      {
        path: '*',
        component: Building,
      },
    ],
  },
]

if (process.env.NODE_ENV === "development") {
  routes.push({
    path: '/components',
    name: 'global_components',
    component: componentsDesc
  })
}

var errerRouteArr = [
  {
    path: '/error',
    component: Layout,
    children: [
      {
        path: '',
        component: NotFoundPage,
      },
    ],
  },
  {
    path: '*',
    component: NotFoundPage,
    async beforeEnter (to, from, next) {
      //业务线拦截
      handle(to, from, next).then((result) => {
        console.log('next------------', to)
        if (result == false) {
          next('/error')
          return
        }
        next(to)
      })
      // let isService = await handle(to, from, next);
      // // console.log("isService:",isService);
      // if(!isService) {
      //   // 非业务线页面，为不存在的路由,或者加载出错
      //   next('/error');
      // }else if(isService>30){
      //   /*
      // 加载重试次数，当加载次数大于20时，跟路由index.js中setTimeout延迟的时间匹配 超过次数*时长 会白屏等待
      //   引发另一问题，当加载的文件不存在时，首次会出现白屏，再次点击会进入error
      //   add by huzhen 2019-04-14
      //   */
      //   next();
      // }else{
      //   setTimeout(()=>{
      //     next(to);
      //   },20);
      // }
    },
  },
]
let getCurMenuList = async (to, next) => {
  let filterUrl = ['/error', '/index', '/login']
  let path = to.path
  let { userMenuList } = layout.state
  if (filterUrl.includes(path)) {
    next()
    return
  }
  if (!userMenuList) {
    const json = await postByUrl('/login/queryMenuList', { typeCode: 'web' })
    store.dispatch('setMenuInfo', json)
    userMenuList = json.data.menuDtos
  }

  let findMenu = (menu) => {
    if (!menu) {
      return
    }
    let hasMenuPath = menu.find((x) => {
      if (x.menuUrl && path.includes(x.menuUrl)) {
        return x.menuUrl
      } else {
        return findMenu(x.menuDtos)
      }
    })
    return hasMenuPath
  }
  let hasMenu = findMenu(userMenuList)
  if (hasMenu) {
    next()
  } else {
    next('/error')
  }
}
routes.push(...errerRouteArr)
const router = new Router({
  routes,
  linkActiveClass: 'cur',
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, resolve, reject) {
  if (resolve || reject)
    return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location)
}
/**
 * @des sensorsInit 埋点
 */
router.beforeEach((to, from, next) => {
  sensorsInit(to)
  next()
})

export default router
