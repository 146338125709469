/*
 * @Author: liuzhihao
 * @Date: 2020-12-10 10:37:18
 * @LastEditors: xiejh 1415413020@qq.com
 * @LastEditTime: 2024-06-27 11:00:30
 * @FilePath: \web\src\utils\loadsub.js
 * @Description: 头部注释
 */
import Vue from 'vue'
import Vuex from 'vuex'
import globalStore from '../store/index'
import globalRouter from '../router'
import fetch from './fetch'
import {
  postByCode,
  postByUrl,
  getByUrl,
  postByCodeExtraConfig,
  postByPortal,
} from '@/api/common'
import LoginService from '@/services/LoginService'
import Layout from '@/layout/Layout.vue'
import { proxy, unProxy } from 'ajax-hook'
import vuxStore from '@/store/index'
import { Message, MessageBox } from 'element-ui'
import { registerQiankun } from './qiankun'
import { uploadFile } from '@/utils/cosUploadfile' //新文件服务器
import { verifyRbac } from '../plugins/GlobalPlugin'

// 挂载业务线数据
const registerApp = (appName, { store, router }) => {
  if (router) {
    let root = [
      {
        path: '/wgw',
        component: Layout,
        children: router,
      },
    ]
    globalRouter.addRoutes(root)
  }
  if (store) {
    globalStore.registerModule(
      appName,
      Object.assign(store, {
        namespaced: true,
      })
    )
  }
}

const registerCommon = (name) => {
  return { ...Vue.prototype.$COMMON, ...name }
}

const disposeParams = (object) => {
  let { url, params, callback } = object
  let { host } = window.location
  let [path, prefix] = url
  if (!prefix) {
    prefix = 'api'
  }
  return [`//${host}/${prefix}${path}`, params, callback]
}

const postUrl = async (object) => {
  let [path, params, callback] = disposeParams(object)
  let res = await postByUrl(path, params)
  if (!res.success) {
    Message.error(res.resmsg)
  }
  if (callback.prototype) {
    callback({ data: res })
  } else {
    callback(res)
  }
}
const getUrl = async (object) => {
  let [path, params, callback] = disposeParams(object)
  let res = await getByUrl(path, params)
  if (callback) {
    callback({ data: res })
  } else {
    callback(res)
  }
}

// 注册qiankun子应用
registerQiankun()

proxy({
  // 请求发起前进入
  onRequest: async (config, handler) => {
    // 处理文件服务器重新请求地址
    if (
      typeof config.url == 'string' &&
      config.url.indexOf('/file-node/file') >= 0
    ) {
      await vuxStore.dispatch('getUploadUrl')
      let { token, uploadUrl } = vuxStore.state.user
      if (!uploadUrl) {
        await handler.next(config)
      }
      let newUploadUrl = uploadUrl + `&token=${token}`
      config.url = newUploadUrl
    }
    await handler.next(config)
  },
  // 请求发生错误时进入，比如超时；注意，不包括http状态码错误，如404仍然会认为请求成功
  onError: (err, handler) => {
    console.log(err.type)
    handler.next(err)
  },
  // 请求成功后进入
  onResponse: (response, handler) => {
    handler.next(response)
  },
})

window.bapp = Object.assign(window.bapp || {}, {
  Vue,
  Vuex,
  router: globalRouter,
  store: globalStore,
  util: {
    registerApp,
    fetch,
    postByCode,
    postByUrl,
    getByUrl,
    postByCodeExtraConfig,
    registerCommon,
    postUrl,
    getUrl,
    postByPortal,
    logout: LoginService.logout,
    uploadFile,
    verifyRbac: verifyRbac.bind({$store: globalStore})
  },
})
