/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-01-18 17:35:29
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-02-01 11:35:16
 * @Description: Description
 */

const requireComponent = require.context(
  // 其组件目录的相对路径
  '../common',
  // 是否查询其子目录
  false,
  // 匹配基础组件文件名的正则表达式
  /\w+\.js$/
)

// 对象存放所有子组件
const components = {}
requireComponent.keys().forEach(filePath => {
  const name = filePath.split('/').pop().replace(/\.\w+$/, '')
  if (name !== 'index') {
    components[name] = requireComponent(filePath).default
  }
})
export default components
