/*
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2022-09-28 18:12:24
 * @FilePath: /space-portal/src/utils/clipboard.js
 * @Description: 复制粘贴文本
 */
import Clipboard from 'clipboard'

function clipboardSuccess() {
  window && window.bapp && window.bapp.Vue.prototype.$message({
    message: '复制成功',
    type: 'success',
    duration: 1500
  })
}

function clipboardError() {
  window && window.bapp && window.bapp.Vue.prototype.$message({
    message: '复制出错',
    type: 'error'
  })
}

export default (text, event) => {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
