/*
 * @Author: liuzhihao
 * @Date: 2020-08-13 19:50:38
 * @LastEditors: chenhuiling
 * @LastEditTime: 2023-12-07 16:00:31
 * @FilePath: \edumgrd:\yjt-projects\web\src\theme\index.js
 * @Description: 头部注释
 */
import { getByUrl } from '@/api/common'
import { changeTheme } from './themeUtils'
// import themes from './Theme2/index.js' //主题1-初始
// import themes from './theme2/index.js' //主题2-绿色
// import themes from './theme3/index.js' //主题3-蓝色
// const importTheme = () => import('./theme2/index.js')

export default {
  install:  (Vue, options)=> {
    let { themeId } = options;
    if (!themeId) {
      themeId = 'Theme3'
    }
    let themes = require(`@static/theme/${themeId}/index.js`).default;


    require(`@static/theme/${themeId}/iconCss/index.css`)
    addLink(`${ location.origin }/statics/static/theme/${themeId}/index.css`)
    addLink(`${ location.origin }/statics/static/theme/${themeId}/app/index.css`)

    // 设置全局保存主题
    Vue.prototype.themes = themes;
    const _themes = JSON.stringify(themes)
    // 保存到缓存给予分项使用
    localStorage.setItem('themes', _themes)
    changeTheme(themes.themeColor)
  }
}

function addLink(url) {
  if(!url) return
  let el = document.createElement('link');

  el.setAttribute('rel', 'stylesheet');

  el.setAttribute('href', url);

  document.head.appendChild(el);
}