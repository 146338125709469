<template>
  <!-- 切换角色 -->
  <div v-if="user" class="role">
    <img
      v-if="user && user.avatarUrl"
      :src="user.avatarUrl"
      alt=""
      class="role_headerImg"
    />

    <span
      v-else
      class="userName"
      :style="{
        backgroundColor: textColor === '#fff' ? '#fff' : themeColor,
        color: textColor === '#fff' ? themeColor : '#fff',
      }">
      {{ user && user.userName.slice(0, 1) }}
    </span>

    <el-dropdown trigger="click" @command="changeUserRole">
      <div class="el-dropdown-link" :style="{color: _textColor}">
        <span v-if="userType === 2 && child" class="role_title">
          {{ child.childName }}
          <small
            >（{{ child.className }}）{{
              child.familyRole | clsRole(child.isJwh)
            }}</small
          >
        </span>

        <span v-else-if="userType === 1 && stuClass" class="role_title">
          {{ user.userName }}
          <small>（{{ stuClass.className }}）</small>
        </span>

        <span v-else class="role_title">
          <span>{{ user.userName }}</span>
          <small>{{ userType | userType(school) }}</small>
        </span>

        <svg-icon
            style="font-size: 10px"
            :style="{color: _textColor}"
            icon-class="arrow-down" />
      </div>

      <el-dropdown-menu class="switch-dropdown" slot="dropdown">
        <el-dropdown-item
          v-for="(role, index) in roleTypeInfos"
          :key="'role_' + index"
          :divided="index > 0"
          :command="{ school, role }"
        >
          <a href="javascript:;">
            <span v-if="role.userType !== 2 && role.userType !== 1">
              {{ role.userType | userType(school) }}</span
            >

            <span v-else-if="role.userType === 2">
              {{ role.childName }}
              <small
                >（{{ role.className }}）&nbsp;{{
                  role.familyRole | clsRole(role.isJwh)
                }}</small
              >
            </span>

            <span v-else-if="role.userType === 1">
              <small>{{ role.className }}</small>
            </span>
          </a>
        </el-dropdown-item>
        <!-- <el-dropdown-item divided
                          :command="{ op:'setting'}">
          <div>个人设置</div>
        </el-dropdown-item> -->
        <el-dropdown-item divided :command="{ op: 'exit' }">
          <div>退出</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from "@/mixins/SwitchSchoolAndRoleMixin"
import CONST_USER from "@/const/user"
import { mapState } from 'vuex'

export default {
  components: {},
  mixins: [SwitchSchoolAndRoleMixin],
  props: {
    textColor: String
  },
  computed: {
    ...mapState({ theme: state => state.theme.theme || {} }),
    _textColor() {
      return this.textColor || this.theme.menuTextColor || '#252526'
    },
    themeColor() {
      // 获取原型上的theme
      return this.theme.themeColor
    },
  },
  filters: {
    userType: function (ut, school) {
      if (ut == 4 && school && school.stype === 0) {
        return "职员"
      }
      return CONST_USER.userType[ut] || "管理员"
    },
    clsRole(familyRole, isJwh) {
      if (isJwh == 1) {
        return "家委"
      } else {
        return "家长"
      }
    },
  },
  methods: {
    changeUserRole(params) {
      // 修改角色成功后，触发change事件
      this.changeRole({...params, callback: () => {
        this.$emit('change')
      }})
    }
  }
}
</script>

<style scoped>
.role {
  min-width: 160px;
  display: flex;
  align-items: center;
}
.role_headerImg {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-right: 10px;
}

.userName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #165DFF;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  margin-right: 8px;
  flex-shrink: 0;
}

.role_title small {
  margin: 0 8px;
  font-size: 14px;
}
.switch-dropdown a {
  color: #595959;
}
.el-dropdown-link {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
</style>
