import { postUrl } from '../api/common'
import axios from 'axios'
import TcVod from 'vod-js-sdk-v6'
// 集成移动云EOS上传代码（移动云用的亚马逊产品）
// import AwsSdk from 'aws-sdk'

/**
 * @description base64 转 file 格式
 */
export function dataURItoBlob(base64) {
  let fileName = new Date().getTime() + '_' + parseInt(Math.random() * 100)
  // 将base64按照 , 进行分割 将前缀  与后续内容分隔开
  let data = base64.split(',')
  // 利用正则表达式 从前缀中获取图片的类型信息（image/png、image/jpeg、image/webp等）
  let type = data[0].match(/:(.*?);/)[1]
  // 从图片的类型信息中 获取具体的文件格式后缀（png、jpeg、webp）
  let suffix = type.split('/')[1]
  // 使用atob()对base64数据进行解码  结果是一个文件数据流 以字符串的格式输出
  const bstr = window.atob(data[1])
  // 获取解码结果字符串的长度
  let n = bstr.length
  // 根据解码结果字符串的长度创建一个等长的整形数字数组
  // 但在创建时 所有元素初始值都为 0
  const u8arr = new Uint8Array(n)
  // 将整形数组的每个元素填充为解码结果字符串对应位置字符的UTF-16 编码单元
  while (n--) {
    // charCodeAt()：获取给定索引处字符对应的 UTF-16 代码单元
    u8arr[n] = bstr.charCodeAt(n)
  }
  // 利用构造函数创建File文件对象
  // new File(bits, name, options)
  const file = new File([u8arr], `${fileName}.${suffix}`, {
    type: type,
  })
  // 将File文件对象返回给方法的调用者
  return file
}

/**
 * 文件上传
 * @param {*} file 待上传文件
 * @param {*} options 上传配置
 * @param {*} onFileFinish 上传完成回调
 * @param {*} onProgress 进度回调 code: 100上传, 101转码
 * @param {*} onError 错误回调
 */
export function uploadFile({
  file,
  options,
  onFileFinish,
  onProgress,
  onError,
}) {
  let { extension, extensions, bizFunctionCode, bizModuleCode } = options || {}
  extensions = extensions || []
  if (!extension) {
    if (!!isVod(file.name)) {
      extension = 'vod'
    }
  }

  let url = '/file/getUploadRequest'
  let params = {
    // 所属功能
    bizFunctionCode: bizFunctionCode || 'resources',
    // 所属模块
    bizModuleCode: bizModuleCode || 'saas',
    // 是否允许分块
    chunking: true,
    // 所属能力
    extension: extension || 'cos',
    // 	二次扩展
    extensions: [],
    // 文件名称
    fileName: file.name,
    // 文件尺寸
    fileSize: file.size,
  }

  if (options) {
    delete options.extension
    params = Object.assign({}, params, options)
    params.extensions = []
  }
  if (params.extension == 'cos-pub') {
    params.bizFunctionCode = 'public'
  }
  if (extension === 'vod') {
    // procedure代表腾讯云点播中的任务流设置，ResPreset是目前使用的任务流详情，可以根据实际情况修改
    params.arguments = { procedure: 'ResPreset' }
  }

  postUrl(url, params).then((res) => {
    let { code, data } = res || {}
    if (code == 200) {
      switch (data.serviceType) {
        // 针对腾讯云云点播主机进行特殊处理
        case 'TENCENT-VOD':
          vodUpload(file, data, onFileFinish, onError, onProgress)
          break
        // 针对移动云COS主机进行特殊处理
        case 'CHINAMOBILE-EOS':
          eosUpload(file, data, onFileFinish, onError, onProgress)
          break
        // 其他采用通用处理方式（适用于内部存储、腾讯云对象存储）
        default:
          executeUpload(
            file,
            data,
            extensions[0],
            onFileFinish,
            onError,
            onProgress
          )
          break
      }
    } else {
      if (onError) onError(res)
    }
  })
}

/**
 * 是否是视频
 * @param {*} name 文件名
 * @returns
 */
function isVod(name) {
  if (typeof name !== 'string') return
  let is = false
  let typeList = [
    'mp4',
    'webm',
    'avi',
    'rmvb',
    '3gp',
    'flv',
    'PM4',
    'WEBM',
    'AVI',
    'RMVB',
    '3PG',
    'FLV',
  ]
  typeList.forEach((item) => {
    if (!is) {
      is = name.search(`${item}$`) != -1
    }
  })
  return is
}

/**
 * 云点播-视频上传
 * @param {*} file
 * @param {*} uploadToken
 * @param {*} onFileFinish
 */
function vodUpload(file, uploadToken, onFileFinish, onError, onProgress) {
  const tcVod = new TcVod({
    getSignature: () => {
      return uploadToken.requestForm.signature
    }, // 前文中所述的获取上传签名的函数
  })
  const uploader = tcVod.upload({
    mediaFile: file, // 媒体文件（视频或音频或图片），类型为 File
  })
  uploader.on('media_progress', function (info) {
    if (onProgress)
      onProgress({
        code: 100,
        percent: info.percent,
      })
  })
  uploader
    .done()
    .then(async (doneResult) => {
      let result = await uploadResponse({
        uploadToken,
        uploadResult: {},
        vodUrl: doneResult.video.url,
      })
      if (result.code == 200) {
        // 每隔两秒轮询取结果，循环若干次
        for (var i = 0; i < 1000; i++) {
          var traceResult = await fileTaskTrace(uploadToken.taskCode)
          console.log('traceResult', traceResult)
          // 如果 status 为 1，则上传并转码完成
          if (traceResult && traceResult.data.status === 1) {
            if (onFileFinish) onFileFinish(traceResult)
            return
          }
          // 隔1000获取一次上传结果
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      } else if (onError) onError(result)
    })
    .catch(function (err) {
      if (onError) onError(err)
    })
}

/**
 * 移动云COS上传
 * @param {*} file
 * @param {*} uploadToken
 * @param {*} onFileFinish
 */
function eosUpload(file, uploadToken, onFileFinish, onError, onProgress) {
  console.log('file', file)
  // SDK 参数初始化（该签名仅支持上传）
  let s3 = new AwsSdk.S3({
    accessKeyId: uploadToken.requestForm['accessKeyId'],
    secretAccessKey: uploadToken.requestForm['secretAccessKey'],
    sessionToken: uploadToken.requestForm['sessionToken'],
    endpoint: uploadToken.requestForm['endpoint'],
  })
  // 构建上传参数
  let params = {
    Body: file,
    Bucket: uploadToken.requestForm['bucketName'],
    Key: uploadToken.requestForm['cloudKey'],
  }
  let request = s3.putObject(params, async (err, data) => {
    if (err) {
      if (onError) onError(err)
      return
    }
    let result = await uploadResponse({ uploadToken, uploadResult: {} })
    if (result.code == 200) {
      // 每隔两秒轮询取结果，循环若干次
      for (var i = 0; i < 1000; i++) {
        var traceResult = await fileTaskTrace(uploadToken.taskCode)
        // 如果 status 为 1，则上传并转码完成
        if (traceResult && traceResult.data.status === 1) {
          if (onFileFinish) onFileFinish(traceResult)
          return
        }
        // 隔1000获取一次上传结果
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    } else if (onError) onError(result)
  })
  request.on('httpUploadProgress', function (progress) {
    // 进度回调
    onProgress({
      code: 100,
      percent: !!progress.total ? (progress.loaded || 0) / progress.total : 0,
      text: `${file.name}（正在上传 ${Math.round(
        ((progress.loaded || 0) / progress.total) * 100
      )}% ）`,
    })
  })
}

/**
 * 执行单个文件上传
 * @param file
 * @param options
 * @param uploadToken
 */
async function executeUpload(
  file,
  uploadToken,
  extension,
  onFileFinish,
  onError,
  onProgress
) {
  let res
  if (uploadToken.chunkCount == 0) {
    res = await executeRequestUpload({ uploadToken, file, onProgress })
  } else {
    for (let i = 0; i < uploadToken.chunkCount; i++) {
      res = await executeRequestUpload({
        uploadToken,
        file,
        chunkIndex: i,
        onProgress,
      })
    }
  }
  // 合并未完成，文件中心转异步，需跟踪
  if(!res.data.fileUrl && res.data.complete === false){
    // 每隔一秒轮询取结果，循环若干次
    for (var i = 0; i < 1000 && !res.data.fileUrl; i++) {
      res = await fileTaskTrace(uploadToken.taskCode);
      console.log('traceResult', res);
      // 隔1000获取一次上传结果
      await new Promise(resolve => setTimeout(resolve,1000));
    }
  }

  if (res.code == 200 && res.data.fileUrl) {
    if (extension) {
      await executeRequestExtension(
        res,
        extension,
        onFileFinish,
        onError,
        onProgress
      )
    } else if (onFileFinish) {
      onFileFinish(res)
    }
  } else {
    if (onError) onError(res)
  }
}

/**
 * 发起扩展
 * @param {*} res
 * @returns
 */
async function executeRequestExtension(
  res,
  extension,
  onFileFinish,
  onError,
  onProgress
) {
  let params = {
    extension: extension,
    fileSrc: res.data.fileUrl,
  }
  // 发起扩展
  let result = await postUrl('/file/getExtension', params)
  // 最大超时时间 1分钟
  let maxTime = 1000 * 60 * 1
  // 当前时间
  let curTime = 0
  // 轮询时间间隔
  let time = 2000
  // 定时id
  let intervalId = null
  // 转换后后缀名
  // let suffix = ['.pdf']

  if (result.code == 200) {
    if (onProgress)
      onProgress({
        code: 101,
        text: `文件转码中`,
      })

    // 轮询查结果
    intervalId = setInterval(async () => {
      let taskRes = await fileTaskGet(result.data.taskCode)
      // 计时
      curTime += time
      if (
        taskRes.code == 200 &&
        taskRes.data.fileUrl &&
        taskRes.data.status == '3'
      ) {
        if (onError)
          onError({
            code: 40002,
            text: '转换失败',
          })
        clearInterval(intervalId)
      } else if (taskRes.code == 200 && taskRes.data.fileUrl) {
        // 扩展文件url
        res.data.extensionFileUrl = taskRes.data.fileUrl
        if (onFileFinish) onFileFinish(res)
        clearInterval(intervalId)
      } else if (curTime >= maxTime) {
        if (onError)
          onError({
            code: 40001,
            text: '转换超时',
          })
        clearInterval(intervalId)
      }
    }, time)
  } else {
    if (onError) onError(result)
  }
}
// 【旧】的任务追踪
async function fileTaskGet(taskCode) {
  let params = {
    code: taskCode,
  }
  let result = await postUrl('/file/fileTaskGet', params)
  return result
}

// 【新】的任务追踪
async function fileTaskTrace(taskCode) {
  let params = {
    fileSrc: taskCode,
  }
  let result = await postUrl('/file/fileTaskTrace', params)
  return result
}

/**
 * 执行单次文件上传（即整个文件上传请求或一次分块请求）
 * @param uploadToken
 * @param file
 * @param chunkIndex
 */
async function executeRequestUpload({
  uploadToken,
  file,
  chunkIndex,
  onProgress,
}) {
  let uploadResult = await axios.request(
    createUploadRequestConfig(uploadToken, file, chunkIndex, onProgress)
  )
  if (!uploadResult) {
    return {}
  }
  delete uploadResult.request
  return await uploadResponse({ uploadToken, uploadResult, chunkIndex })
}
// 文件中心同步回调
async function uploadResponse({
  uploadToken,
  uploadResult,
  chunkIndex,
  vodUrl,
}) {
  let { headers } = uploadResult || {}
  if (vodUrl) {
    headers = {
      url: vodUrl,
    }
  }
  let params = {
    data: {
      taskCode: uploadToken.taskCode,
      headers: headers,
      body: JSON.stringify(uploadResult),
      chunkNumber: chunkIndex !== undefined ? chunkIndex + 1 : undefined,
    },
  }
  let result = await postUrl('/file/uploadResponse', params)
  return result
}

/**
 * 创建上传请求参数
 * @param uploadToken
 * @param file
 * @param chunkIndex
 */
function createUploadRequestConfig(uploadToken, file, chunkIndex, onProgress) {
  let method
  let url
  let data
  var onUploadProgress = () => {}
  let blob
  if (chunkIndex === undefined) {
    url = uploadToken.uploadUrl
    // 进度回调
    if (onProgress)
      onUploadProgress = (e) =>
        onProgress({
          code: 100,
          percent: (e.loaded || 0) / file.size,
          text: `${file.name}（正在上传 ${Math.round(
            ((e.loaded || 0) / file.size) * 100
          )}% ）`,
        })

    blob = file
  } else {
    url = uploadToken.uploadUrl.replace('{CHUNK_NUMBER}', `${chunkIndex + 1}`)
    // 进度回调
    if (onProgress)
      onUploadProgress = (e) =>
        onProgress({
          code: 100,
          percent: Math.round(
            (chunkIndex * uploadToken.chunkSize + (e.loaded || 0)) / file.size
          ),
          text: `${file.name}（正在上传 ${Math.round(
            ((chunkIndex * uploadToken.chunkSize + (e.loaded || 0)) /
              file.size) *
              100
          )}% ）`,
        })

    if (chunkIndex == uploadToken.chunkCount - 1) {
      blob = file.slice(chunkIndex * uploadToken.chunkSize)
    } else {
      blob = file.slice(
        chunkIndex * uploadToken.chunkSize,
        (chunkIndex + 1) * uploadToken.chunkSize
      )
    }
  }

  switch (uploadToken.uploadMethod) {
    case 'POST_FILE': {
      method = 'post'
      data = new FormData()
      Object.keys(uploadToken.requestForm).forEach((key) =>
        data.append(key, uploadToken.requestForm[key])
      )
      data.append('file', blob)
      break
    }
    case 'PUT_STREAM': {
      method = 'put'
      data = blob
      break
    }
    default:
      return {}
  }
  return {
    method: method,
    url: url,
    data: data,
    headers: uploadToken.requestHeader,
    onUploadProgress,
  }
}
