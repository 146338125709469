<template>
  <div class="top-menu" :style="{ 'background-color': color1 }">
    <div class="school-info" v-if="school" :title="schoolName" @click="toHome">
      <school-logo class="school-logo" :slogo="school.slogo"></school-logo>
      <div class="school-name">{{ schoolName }}</div>
    </div>
    <ul class="web-menu-list">
      <template v-for="(item, index) in userMenuList">
        <li
          class="item"
          :class="{ cur: isCurMenu(item) }"
          @mouseenter="handleMenuMouseenter(item.menuId)"
          @mouseleave="handleMenuMouseleave(item.menuId)"
          :style="{
            'background-color': isCurMenu(item) ? color2 : '',
            'border-color': color2,
          }"
          :key="index"
          v-if="index < 16"
          @click="handleMenuRedirect(item)"
        >
          <TopMenuIcon class="img-box" :iconClass="item.icon"> </TopMenuIcon>
          <div class="menu-name">{{ getTranslateMenuName(item) }}</div>
        </li>
      </template>
    </ul>
    <SetSubjectDialog
      :visible.sync="setSubjectVisible"
      :noSubjectClassList="noSubjectClassList"
      @success="execRedirect(curJumpMenu)"
    ></SetSubjectDialog>
  </div>
</template>
<script>
import TopMenuIcon from '@/components/skin01/sub/TopMenuIcon';
import SchoolLogo from '@/components/SchoolLogo';
import MenuMixin from '@/mixins/MenuMixin';
import ThemeMixin from '@/mixins/ThemeMixin';
import MenuService from '@/services/MenuService';
import SetSubjectDialog from '@/components/base/SetSubjectDialog.vue';

export default {
  components: {
    TopMenuIcon,
    SchoolLogo,
    SetSubjectDialog,
  },
  mixins: [MenuMixin, ThemeMixin],
  computed: {
    domainConfig() {
      let domainConfig = this.$store.state.app.domainConfig;
      return domainConfig;
    },
    schoolName() {
      if (this.school) {
        //加州生产，华南师范大学附属中学学校，针对sso_hsfz菜单下的这个学校写死展示名称
        if (
          this.school.schoolId == '50656' &&
          this.domainConfig &&
          this.domainConfig.pfv == 'sso_hsfz'
        ) {
          return '华南师大附中校务';
        } else {
          return this.school ? this.school.schoolName : '';
        }
      } else {
        return '';
      }
    },
    userMenuList() {
      let userMenuList = this.$store.state.layout.userMenuList;
      let data = MenuService.getMenuDataList(userMenuList);
      return this.iconCompensation(data);
    },
    curMenuList() {
      return this.$store.state.layout.curMenuList;
    },
    curMenu() {
      let curMenuList = this.curMenuList;
      if (curMenuList && curMenuList.length) {
        return curMenuList[0];
      }
    },
    school() {
      return this.$store.state.user.school;
    },
    homeMenuUrl() {
      return this.$store.state.layout.homeMenuUrl;
    },
    homeMenu() {
      return this.$store.state.layout.homeMenu;
    },
  },
  methods: {
    iconCompensation(data) {
      // 循环查找空的icon，补充
      let dictionaries = {
        家校互动: 'menu-icon-jxgt',
        托管学堂: 'menu-icon-train',
        班级教务: 'menu-icon-jwgl',
        智慧操场: 'menu-icon-playground',
        健康中心: 'menu-icon-health',
        校园办公: 'menu-icon-oa',
        校园安全: 'menu-icon-safety',
        云课堂: 'menu-icon-res',
        德育评价: 'menu-icon-zhpj',
        AI学堂: 'menu-icon-pcxx',
        工作台: 'menu-icon-yyzx',
        宿舍管理: 'menu-icon-ssgl',
        校长驾驶舱: 'menu-icon-xzjsc',
      };
      data.forEach((item, index) => {
        if (item.icon == '') {
          item.icon = dictionaries[item.menuName];
        }
      });
      return data;
    },
    toHome() {
      this.$router.push({ path: this.homeMenuUrl });
    },
    isCurMenu(item) {
      let curMenu = this.curMenu;
      return curMenu && curMenu.menuId == item.menuId;
    },
    handleMenuMouseenter(id) {
      // if(this.curMenu){
      // 	this.curMenu.menuId = id;
      // }
      // event.target.style.backgroundColor=this.color2;
    },
    handleMenuMouseleave() {
      // this.curMenu.menuId = null;
      // event.target.style.backgroundColor="";
    },
  },
};
</script>

<style type="text/css" scoped>
.top-menu {
  width: 100%;
  height: 78px;
  position: relative;
  display: flex;
}
.school-info {
  color: #fff;
  /* float: left; */
  /* margin:15px 0 0 15px; */
  margin-left: 15px;
  font-weight: bold;
  font-size: 16px;
  max-width: 290px;
  min-width: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* position: absolute; */
}
.school-logo {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  margin-right: 10px;
  flex-shrink: 0;
}
.school-name {
  flex-shrink: 10;
  max-height: 48px;
  overflow: hidden;
}
/* 菜单 */
.web-menu-list {
  flex-grow: 1;
  overflow: hidden;
  margin-left: 40px;
  height: 78px;
  display: flex;
}
.web-menu-list li {
  padding: 8px 10px 8px;
  color: #fff;
  flex-grow: 1;
  width: 106px;
  min-width: 82px;
  max-width: 128px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  /* border-right:1px solid; */
  transition: all 0.3s;
}
.web-menu-list li:hover {
  transform: scale(1.1);
  font-weight: bold;
}
.web-menu-list li.cur:hover {
  transform: none;
  font-weight: normal;
}
.web-menu-list li:last-child {
  border-right: none;
}
.web-menu-list li .img-box {
  height: 38px;
  width: 38px;
  margin: 0 auto;
}
.web-menu-list li .menu-name {
  margin-top: 3px;
}

/* 主题 */
/* .theme-blue{
		background: #6696e8;
	}
	.theme-blue .web-menu-list li:not(:last-child){
		border-right:1px solid #558AE3;
	}
	.theme-blue .web-menu-list li:hover,.web-menu-list li.cur{
		background:#558AE3;
	} */
</style>
