/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-02 16:08:18
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-03-29 11:35:27
 * @Description: 用户姓名、角色切换
 */

<template>
  <div class="role_box">
    <img :src="user.avatarUrl" class="role_box_avatarUrl" v-if="user.avatarUrl" />
    <span v-else class="role_box_avatarName">{{ user.userName && user.userName.slice(0, 1) }}</span>
    <el-dropdown v-if="user" class="role-container" placement="top-start" @command="changeRole">
      <!-- 切换角色 -->
      <div class="el-dropdown-link role-name sel-block">
        <span class="role-title f-toe" v-if="userType == 2 && child"
          >{{ child.childName }}
          <span
            >（{{ child.className }}）{{
              clsRoleFormat(child.familyRole, child.isJwh)
            }}</span
          >
        </span>
        <span class="role-title f-toe" v-else-if="userType == 1 && stuClass"
          >{{ user.userName }}
          <span class="extra">（{{ stuClass.className }}）</span>
        </span>
        <span class="role-title f-toe" v-else>
          <span v-if="!showWxName">{{ user.userName }}</span>
          <span v-else>
            <ww-open-data
              :type="`userName`"
              :openid="openid"
              id="ww_open_data"
            />
          </span>
          <span>{{ userTypeFormat(userType, school) }}</span>
        </span>
        <i class="el-icon-caret-bottom"></i>
      </div>
      <el-dropdown-menu class="switch-dropdown" slot="dropdown">
        <el-dropdown-item
          :command="{ school: school, role: role }"
          v-for="(role, index) in roleTypeInfos"
          :key="'role_' + index"
        >
          <a href="javascript:;" class="f-toe">
            <span v-if="role.userType != 2 && role.userType != 1">{{
              userTypeFormat(role.userType, school)
            }}</span>
            <span v-else-if="role.userType == 2"
              >{{ role.childName }}
              <span
                >（{{ role.className }}）&nbsp;{{
                  clsRoleFormat(role.familyRole, role.isJwh)
                }}</span
              >
            </span>
            <span v-else-if="role.userType == 1">
              <span>{{ role.className }}</span>
            </span>
          </a>
        </el-dropdown-item>
        <el-dropdown-item divided :command="{ op: 'exit' }">
          <div>{{ $t("topHeader.logout") }}</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin'
export default {
  components: {},
  mixins: [SwitchSchoolAndRoleMixin],
  data() {
    return {
      showWxName: false,
    };
  },
  computed: {
    // 用户信息
    // user() {
    //   return this.$store.state.user.user
    // },
    school() {
      if (
        !!this.$store.state.user.school &&
        this.$store.state.user.school.tdVer == 2
      ) {
        this.initQW()
      }
      return this.$store.state.user.school
    },
  },
  created() {},
  methods: {
    loadJs(url, callback) {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      if (typeof callback === 'function') {
        // ie
        if (script.readyState) {
          script.onreadystatechange = function() {
            if (
              script.readyState == 'loaded' ||
              script.readyState == 'complete'
            ) {
              script.onreadystatechange = null
              callback()
            }
          }
        } else {
          script.onload = function() {
            callback()
          }
        }
      }
      script.src = url
      document.body.appendChild(script)
    },
    initQW() {
      this.loadJs('https://res.wx.qq.com/open/js/jweixin-1.2.0.js', () => {
        this.loadJs(
          'https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js',
          () => {
            let url = window.location.href
            if (url.indexOf('#') > -1) {
              url = url.split('#')[0]
            }
            // url = window.location.host;

            wxSign({
              accType: 'wxwork',
              type: 'agent_config',
              url: url,
              schoolId: this.school.schoolId
            }).then(res => {
              let { data, resmsg, status } = res
              let _this = this
              if (!status) return this.$message.error(resmsg)
              wx.agentConfig({
                corpid: data.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: data.agentid, // 必填，企业微信的应用id （e.g. 1000247）
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature, // 必填，签名，见附录1
                jsApiList: ['selectExternalContact'], //必填
                success: function(res) {
                  _this.showWxName = true
                  WWOpenData.bind(document.getElementById('ww_open_data'))
                  _this.openid = _this.user.account
                },
                fail: function(res) {
                  // if(res.errMsg.indexOf('function not exist') > -1){
                  //     alert('版本过低请升级')
                  // }
                }
              })
            })
          }
        )
      })
    },
  },
};
</script>
<style scoped lang="less">
.role-container {
  color: #fff;
}
.role-title {
  font-size: 14px;
  font-weight: bold;
}
.el-icon-caret-bottom {
  font-size: 12px;
}
.role_box {
  display: flex;
  align-items: center;
  &_avatarUrl {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  &_avatarName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #165DFF;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    margin-right: 8px;
  }
}
</style>