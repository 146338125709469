<template>
  <div class="navMenu" :style="headerStyle" ref="menu">
    <div class="mask" v-if="isSpaceSetting"></div>
    <SchoolList class="schoolList" :textColor="!isHomePage ? '#fff' : ''"></SchoolList>
    
    <div ref="navMenu_menu_div" v-resize="handleGetWidth" :style="{width: `calc(100% - 200px - ${rightContWidth}px)`}">
      <el-menu
        v-if="menuList.length"
        class="navMenu_menu"
        :default-active="activeIndex"
        mode="horizontal"
        :text-color="textColor"
        :active-text-color="isHomePage ? themeColor : '#fff'"
        background-color="#fff"
        collapse
        @select="handleSelect"
      >
        <span v-for="(item, index) of menuList.slice(0, showMenuNum)" :key="item.menuId">
          <el-menu-item
            v-if="!item.menuDtos"
            class="navMenu_menu_item"
            :index="index + ''"
            :route="{ path: '/web/view' }"
          >
            <!-- <MenuIcon class="menu-icon" :iconClass="item.icon" /> -->
            {{ item.menuName }}
          </el-menu-item>
  
          <el-submenu v-else class="menu_submenu" :popper-class="`layout-space_submenu ${!isHomePage ? 'no_index' : ''}`" :index="index + ''">
            <template slot="title">
              <!-- <MenuIcon class="menu-icon" :iconClass="item.icon" /> -->
              {{ item.menuName }}
            </template>
            <el-menu-item
              v-for="(subMenu, i) of item.menuDtos"
              :key="subMenu.menuId"
              :index="`${index}-${i}`"
              >{{ subMenu.menuName }}</el-menu-item
            >
          </el-submenu>
        </span>
  
        <!-- 超出showMenuNum个菜单用 - 更多 - 显示 -->
        <el-submenu
          v-if="menuList.length > showMenuNum"
          class="menu_submenu"
          :popper-class="`layout-space_submenu ${!isHomePage ? 'no_index' : ''}`"
          index="collapse">
          <template slot="title">更多</template>
          <span
            v-for="(item, idx1) in menuList.slice(showMenuNum)"
            :key="item.menuId">
            <el-menu-item
              v-if="!item.menuDtos"
              class="navMenu_menu_item"
              :index="`${idx1 + showMenuNum}`"
              :route="{ path: '/web/view' }"
            >
              {{ item.menuName }}
            </el-menu-item>
            <el-submenu
              v-else
              class="menu_submenu"
              :popper-class="`layout-space_submenu ${!isHomePage ? 'no_index' : ''}`"
              :index="`${idx1 + showMenuNum}`">
              <template slot="title">
                {{ item.menuName }}
              </template>
              <el-menu-item
                v-for="(subMenu, idx2) of item.menuDtos"
                :key="subMenu.menuId"
                :index="`${idx1 + showMenuNum}-${idx2}`"
                >{{ subMenu.menuName }}</el-menu-item
              >
            </el-submenu>
          </span>
        </el-submenu>
      </el-menu>
      <div style="line-height:64px" v-else>菜单为空</div>
    </div>

    <div ref="rightCont">
      <div class="navMenu_right">
        <!-- 换肤 - 消息中心 -->
        <msg-and-bg :count="msgCount" :textColor="!isHomePage ? '#fff' : ''" />
        
        <!-- 切换角色 -->
        <RoleChange @change="getMsgCount" :textColor="!isHomePage ? '#fff' : ''" />
      </div>
    </div>
  </div>
</template>

<script>
import RoleChange from './RoleChange';
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin';
import SpaceMenuMixin from '@/mixins/SpaceMenuMixin';
import MenuIcon from '../MenuIcon';
import MsgAndBg from './MsgAndBg'
import { mapState } from 'vuex';
import SchoolList from './SchoolList'

export default {
  components: { RoleChange, MenuIcon, MsgAndBg, SchoolList },
  mixins: [SwitchSchoolAndRoleMixin, SpaceMenuMixin],
  props: {
    activeIndex: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      rightContWidth: 0, // 右边换肤、切换角色模块宽度
      showMenuNum: 5,
      msgCount: 0 // 消息通知数量
    }
  },
  directives: {
    resize: {
      // 指令的名称resize
      bind(el, binding) {
        // el为绑定的元素，binding为绑定给指令的对象
        // console.log(el, '绑定', binding)
        let width = ''
        let height = ''

        function isResize() {
          const style = document.defaultView.getComputedStyle(el)
          if (width !== style.width || height !== style.height) {
            binding.value({
              width: parseInt(style.width),
              height: parseInt(style.height)
            }) // 关键(这传入的是函数,所以执行此函数)
          }
          width = style.width
          height = style.height
        }
        el.__vueSetInterval__ = setInterval(isResize, 300)
      },
      unbind(el) {
        // console.log(el, '解绑')
        clearInterval(el.__vueSetInterval__)
      }
    }
  },
  computed: {
    ...mapState({ 
      theme: state => state.theme.theme || {}, 
      isSpaceSetting: state => state.isSpaceSetting
    }),
    // 是否是大平台首页
    isHomePage() {
      return this.$route.name === 'space_mHome'
    },
    headerStyle() {
      if(this.theme.menuBgUrl && this.isHomePage) {
        return `background-image: url(${this.theme.menuBgUrl})`
      }
      return 'background-color: ' + this.themeColor
    },
    textColor() {
      if (!this.isHomePage) return '#fff'
      return this.theme.menuTextColor || '#fff'
    },
    themeColor() {
      return this.theme.themeColor
    },
    isRefreshMsgCount() {
      return this.$store.state.isRefreshMsgCount
    },
    school(){
       return this.$store.state.user.school || {}
    },
    edbType() {
      //学校或机构类型
      return this.school.edbType;
    },
  },
  watch: {
    isRefreshMsgCount(n) {
      if (n) {
        this.getMsgCount();
      }
    }
  },
  mounted() {
    this.$store.commit('CHANGE_THEME')
    this.getMsgCount();
    this.$nextTick(() => {
      this.rightContWidth = this.$refs.rightCont.offsetWidth
    })
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.$nextTick(() => {
          this.handleGetWidth({
            width: this.$refs.navMenu_menu_div.offsetWidth
          })
        })
      }
    },
    menuList: {
      handler(val) {
        if (!val.length) return
        this.$nextTick(() => {
          this.handleGetWidth({
            width: this.$refs.navMenu_menu_div.offsetWidth
          })
        })
      },
      immediate: true
    }
  },
  methods: {
    handleSelect(key) {
      const indexArr = key.split('-').map((it) => {
        return parseInt(it);
      });

      const [menuIndex, subMenuIndex] = indexArr;

      if (this.menuList[menuIndex].menuUrl) {
        this.redirectUrl(this.menuList[menuIndex]);
        return;
      }

      const subMenuList = this.menuList[menuIndex].menuDtos;
      const menu =
        subMenuList && this.getDefaultMenuUrl(subMenuList[subMenuIndex]);
      this.redirectUrl(menu);
    },
    // 获取未读消息数
    async getMsgCount() {
      let params = {
        schoolId: 'W_SCHOOLID',
        userId: 'W_USERID',
        roleType: 'W_USERTYPE'
      }
      params.infoType = this.edbType == 0? 1:2
      const ret = await this.postByCode("I_SPACE_SPACE_NEWS_GETCOUNT", params);
      this.$store.commit('SET_REFRESH_MSG_COUNT', false);
      if (ret.status && ret.data) {
        this.$store.commit('SET_MSG_COUNT_DATA', ret.data);
        this.msgCount = ret.data.notiUnread + ret.data.mailUnread + ret.data.procUnread;
      } else {
        this.$store.commit('SET_MSG_COUNT_DATA', {});
        this.msgCount = 0;
      }
    },

    // 获取菜单的宽度
    handleGetWidth(data) {
      // console.log('menulistwidth', this.$refs.navMenu_menu_div.offsetWidth);
      let width = data.width
      let count = 0
      for (let menu of this.menuList) {
        // 40是每个菜单的左右内边距和、菜单名每个字20px
        width = width - 40 - (menu.menuName ? menu.menuName.length * 20 : 0)
        // 80是更多项的宽度（包含左右内边距），如果是最后一个则判断宽度大于0既是不用放入更多项里边
        if (width > 80 || (width > 0 && this.menuList.length === count + 1)) {
          count++
        } else {
          break
        }
      }
      this.showMenuNum = count
    }
  },
};
</script>

<style lang="less" scoped>
@import url('../index.css');
@nav-height: 64px;
@nav-text-size: 20px;
.navMenu {
  position: relative;
  padding: 0 20px 0 0;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 10;
  }
}
.navMenu_menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .menu-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}

.navMenu_right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: @nav-height;
}

.schoolList {
  max-width: 280px;
  min-width: 200px;
}

/deep/ .navMenu_menu {
  &.el-menu {
    // 菜单背景色改为透明
    background-color: transparent !important;
    font-size: @nav-text-size !important;
  }
  .el-menu-item {
    // 菜单背景色改为透明
    background-color: transparent !important;
    height: @nav-height;
    line-height: @nav-height;
    font-size: @nav-text-size;
    &.navMenu_menu_item.is-active {
      // 菜单背景色改为透明
      background-color: transparent !important;
      font-size: @nav-text-size;
      height: @nav-height;
    }
  }
  .el-submenu .el-submenu__title {
    font-size: @nav-text-size !important;
    // 菜单元素背景色改为透明
    background-color: transparent !important;
  }
}
/deep/.el-submenu__title {
  height: @nav-height;
  line-height: @nav-height;
  padding: 0 20px;
}
// 悬停时菜单样式
/deep/.el-submenu.is-opened .el-submenu__title {
  background: rgba(0,0,0,0.08) !important;
}
/deep/.el-menu--horizontal .el-icon-arrow-down {
  display: none;
}

</style>

<style>
.el-main {
  padding: 0 !important;
}

.layout-space_submenu.el-menu--horizontal.no_index .el-menu-item, 
.layout-space_submenu.el-menu--horizontal.no_index .el-submenu__title {
  color: #252526 !important;
  font-size: 18px;
}

.layout-space_submenu.el-menu--horizontal .el-menu-item.is-active {
  background-color: #f5f6f7 !important;
}
.layout-space_submenu .el-menu--popup-bottom-start {
  /* 下拉菜单距离顶部距离 */
  margin-top: 16px !important;
}
.layout-space_submenu .el-menu {
  border-radius: 8px;
}
.layout-space_submenu .el-menu-item,
.layout-space_submenu .el-submenu__title {
  font-size: 18px;
  height: 44px !important;
  line-height: 44px !important;
  border-radius: 4px;
}
.layout-space_submenu .el-menu-item:not(.is-disabled):hover {
  background: #F3F4F5 !important;
}
</style>
