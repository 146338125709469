<!--
 * @Author: chenhanxiong
 * @Date: 2021-03-26 11:38:12
 * @LastEditors: chenhanxiong
 * @LastEditTime: 2021-04-22 14:08:13
 * @FilePath: \deome:\wesass\ws-production\web\src\pages\AuthLogin.vue
 * @Description: 头部注释
-->
<template>
  <div
    class="app-container"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
  </div>
</template>
<script>
import MenuMixin from "@/mixins/MenuMixin";
// import LayoutMixin from "@/mixins/LayoutMixin";
import SwitchSchoolAndRoleMixin from "@/mixins/SwitchSchoolAndRoleMixin";
export default {
  data() {
    return {
      loading: true,
    };
  },
  filters: {},
  components: {},
  computed: {
    domainConfig() {
      return this.$store.state.app.domainConfig;
    },
  },
  mixins: [MenuMixin, SwitchSchoolAndRoleMixin],
  mounted() {
    this.handleRedirect();
  },
  methods: {
    handleRedirect() {
      let { schoolId, userType, path } = this.$route.query;
      this.$store
        .dispatch("getLoginData", {
          route: this.$route,
          router: this.$router,
          schoolId,
          role: {
            userType,
          },
          domainConfig: this.domainConfig,
        })
        .then(() => {
          this.$router.push(path);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.app-container {
  width: 100%;
  height: 100%;
}
</style>

