<template>
	<div>
		 <div class="menu" @click="toggleClick"  :class="{'is-active':isActive}">
		    <div class="bar"></div>
		    <div class="bar"></div>
		    <div class="bar"></div>
	  	</div> 
	</div>
</template>

<script>
export default {
  name: 'hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    toggleClick: {
      type: Function,
      default: null
    }
  }
}
</script>

<style scoped>
.hamburger {
	display: inline-block;
	cursor: pointer;
	width: 20px;
	height: 20px;
	transform: rotate(0deg);
	transition: .38s;
	transform-origin: 50% 50%;
}
.hamburger.is-active {
	transform: rotate(90deg);
}

.menu.is-active {
	transform: rotate(90deg);
}
.menu {
  /*height: 40px;
  width: 40px;*/
  position: relative;
  margin: auto;
  margin-top: 10px;
  border: 5px solid transparent;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.bar {
  height: 2px;
  width: 16px;
  display: block;
  margin: 4px auto;
  position: relative;
  background-color: #fff;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}       

</style>
