/*
 * @Author: longtuxin
 * @Date: 2023-10-13 10:05:25
 * @LastEditors: chenhuiling
 * @LastEditTime: 2023-12-23 00:11:48
 * @FilePath: \edumgrd:\yjt-projects\web\src\theme\themeUtils.js
 * @Description: 主题色相关方法，TODO: 考虑提取到公共库
 */
const packageConfig = require('../../package.json')

/** 修改全局主题 */
export function changeTheme(themeColor) {
  let _themeColor = themeColor || "#2D55EB";
  // 获取子仓库根元素
  // const root = document.getElementById(packageConfig.name);
  const root = document.querySelector('body');
  // 修改:root CSS变量的值
  root.style.setProperty("--theme-color", _themeColor);
  root.style.setProperty("--theme-color-light-8", hexToRgba(_themeColor, 0.1));
  root.style.setProperty("--theme-color-light-9", hexToRgba(_themeColor, 0.05));
  root.style.setProperty("--color-border", "#e1e2e6");
  root.style.setProperty("--color-text-1", "#252526");
  root.style.setProperty("--color-text-2", "#575859");
  root.style.setProperty("--color-text-3", "#898A8C");
}

/** 获取主题色 */
export function getThemeColor(prop = "--theme-color") {
  const root = document.getElementById(packageConfig.name);
  return root.style.getPropertyValue(prop);
}

/** 16进制颜色转 rgba，即 #000000 => rgba(0,0,0,0.5) */
export function hexToRgba(hexColor = "", alpha = 0.5) {
  if (!hexColor.startsWith("#")) return hexColor;

  // 将颜色值拆分成 R、G、B 三个部分
  var r = parseInt(hexColor.slice(1, 3), 16);
  var g = parseInt(hexColor.slice(3, 5), 16);
  var b = parseInt(hexColor.slice(5, 7), 16);

  // 转换为 RGBA 格式并添加透明度
  var rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgba;
}

/** 16进制颜色加深 */
export function darkenHexColor(hexColor, percent) {
  if (!hexColor.startsWith("#")) return hexColor;

  // 将颜色值拆分成 R、G、B 三个部分
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // 计算减暗后的RGB值
  const darkerR = Math.floor(r * (1 - percent));
  const darkerG = Math.floor(g * (1 - percent));
  const darkerB = Math.floor(b * (1 - percent));

  // 将新的RGB值转换回十六进制颜色值
  const darkerHex =
    "#" +
    darkerR.toString(16).padStart(2, "0") +
    darkerG.toString(16).padStart(2, "0") +
    darkerB.toString(16).padStart(2, "0");

  return darkerHex;
}
