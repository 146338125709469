<template>
    <el-menu class="web-left-menu" v-if="authMenus"
        mode="vertical"
        style="width:180px"
        :active-text-color="color2"
        :default-active="defaultActiveMenu"
        :default-openeds="getOpensMenu()"
    >
        <!-- :collapse="sidebar.opened==false" -->
    <!-- 功能类型：1菜单，2按钮，3虚拟节点，4面板 -->
        <template   v-for="(item) in authMenus">
            <template v-if="item.funcType==1">
                <!-- 左侧菜单带二级节点 -->
                <el-submenu :style="{'border-left-color':color2,'color':color2}" class="web-left-menu-submenu" 
                    :class="{'cur':parentCurMenu&&parentCurMenu.menuId==item.menuId}" 
                    v-if="hasChildMenu(item)" 
                    :data-key="item.menuIndexKey" 
                    :key="item.menuIndexKey" 
                    :index="item.menuIndexKey">
                    <template slot="title">
                        <i class="web-left-menu-icon" :class="getIcon(item.icon,'folder')"></i>
                        <span>{{item.menuName}}</span>
                    </template>
                    <el-menu-item class="web-left-menu-item" v-for="sub in getMenuList(item.menuDtos)" :data-key="sub.menuIndexKey" :key="sub.menuIndexKey" :index="sub.menuIndexKey" @click="handleMenuRedirect(sub)">
                        <span  slot="title">{{sub.menuName}}</span>
                    </el-menu-item>
                </el-submenu>
                <!-- 左侧只展示一级菜单 -->
                <el-menu-item class="web-left-menu-single-item" 
                    v-else 
                    :class="{'cur':curMenu.menuId==item.menuId}"
                    :data-key="item.menuIndexKey" 
                    :key="item.menuIndexKey" 
                    :index="item.menuIndexKey" @click="handleMenuRedirect(item)">
                    <i class="web-left-menu-icon" :class="getIcon(item.icon)"></i>
                    <span  slot="title">{{item.menuName}}</span>
                </el-menu-item>
            </template>
        </template>
    </el-menu>
</template>
<script>
import MenuMixin from '@/mixins/MenuMixin';
import ThemeMixin from '@/mixins/ThemeMixin';
import MenuService from '@/services/MenuService';
export default {
    mixins:[MenuMixin,ThemeMixin],
    computed:{
        parentCurMenu(){
            let curMenuList=this.curMenuList;
			if(curMenuList&&curMenuList.length>=2){
                let len=curMenuList.length;
				return curMenuList[len-2];
			}
        },
        curMenu(){
			let curMenuList=this.curMenuList;
			if(curMenuList&&curMenuList.length){
                let len=curMenuList.length;
				return curMenuList[len-1];
			}
        },
        topMenu(){
			let curMenuList=this.curMenuList;
			if(curMenuList&&curMenuList.length){
				return curMenuList[0];
			}
        },
        authMenus(){
            let topMenu=this.topMenu;
            if(topMenu){
                return topMenu.menuDtos;
            }
        },
        domainConfig(){
            return this.$store.state.app.domainConfig
        },
        curMenuList(){
            return this.$store.state.layout.curMenuList;
        },
        menuIcon(){
            let domainConfig=this.domainConfig;
            let iconComponent="MenuIconSass";
            if(domainConfig&&domainConfig.idxTemp.code=="middle"){
                iconComponent="MenuIconMiddle"
            }
            return iconComponent;
        },
        defaultActiveMenu(){
            let curMenuList=this.curMenuList;
            if(!curMenuList){
                return null;
            }
            let len=curMenuList.length;
            if(len){
                return curMenuList[len-1].menuIndexKey;
            }
            return null;
        },
        color2(){
            return "#A42B33"
        }
    },
    methods:{
        getMenuList(menuDataList){
            return MenuService.getMenuDataList(menuDataList);
        },
        hasChildMenu(menu){
            //是否存在下级菜单，去除其他类型的数据
            let menuList=this.getMenuList(menu.menuDtos);
            if(menuList&&menuList.length>0){
                return true;
            }
            return false;
        },
        getIcon(icon,type){
            if(!icon){
                if(type=="folder"){
                    return "fa fa-folder";
                }
                return "fa fa-file-text-o";
            }
            return icon;
        },
        getOpensMenu(){
            let authMenus=this.authMenus;
            let url=this.$route.fullPath;
            let curMenuList=this.curMenuList;

            if(!curMenuList||!curMenuList.length){
                return [];
            }

            let curMenuKeyIndexs= curMenuList.map((menu)=>{
                return menu.menuIndexKey;
            });

            return curMenuKeyIndexs;
        },
    }
}
</script>
<style scoped>
    .web-left-menu{
        height: 100%;
    }
    .web-left-menu-submenu /deep/ .el-submenu__title,
    .web-left-menu-single-item {
        border-left: 3px solid #fff;
        line-height: 45px;
        height: 45px;
    }
   
    .web-left-menu-submenu.cur /deep/ .el-submenu__title,
    .web-left-menu-single-item.cur{
        background:#f8f8f8;
        border-left: 3px solid;
        border-left-color: inherit;
    }
    .web-left-menu-submenu.cur /deep/ .el-menu{
        background: #f8f8f8;
    }
    .web-left-menu-item.el-menu-item{
        padding-left: 50px!important;
    }
    .web-left-menu-item.el-menu-item.is-active{
        background:#FCF5F5;
        box-sizing: border-box;
        min-width: auto!important;
    }
    .web-left-menu-icon{
        margin-right: 8px;
        width: 14px;
        font-size: 16px;
        display: inline-block;
    }

</style>

