<template>
  <div class="top-header">
    <div class="top-header-content clearfix">
      <!-- <div class="top-header-tip">欢迎进入 {{$store.state.app.title}}！</div> -->
      <div class="role-infos">
        <div class="full"
             @click="changeLangEvent()">
          <span class="lan">{{ language }}</span>
        </div>
        <div class="school-container"
             v-if="school">
          <div class="school-name"
               v-if="schoolList && schoolList.length == 1">
            <PortalSchoolLogo class="school-logo"
                              :slogo="school.slogo"></PortalSchoolLogo>
            <span class="school-title f-toe">{{ school.schoolName }}</span>
          </div>
          <el-dropdown v-else
                       placement="top-start"
                       @command="changeSchool">
            <!-- 切换学校 -->
            <div class="school-name"
                 :class="{ 'sel-block': schoolList && schoolList.length > 1 }">
              <PortalSchoolLogo class="school-logo"
                                :slogo="school.slogo"></PortalSchoolLogo>
              <span class="school-title f-toe">{{ school.schoolName }}</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu class="switch-dropdown"
                              slot="dropdown">
              <template v-for="(sch, index) in schoolList">
                <el-dropdown-item :divided="index > 0 ? true : false"
                                  :command="{ school: sch }"
                                  :class="{ 'is-active': sch.schoolId == school.schoolId }"
                                  v-if="index < 6"
                                  :key="'schoolsel_' + sch.schoolId"
                                  :title="sch.schoolName">
                  <div class="f-toe school-info"
                       href="javascript:;">
                    <school-logo class="slogo"
                                 :slogo="sch.slogo"></school-logo>{{ sch.schoolName }}
                  </div>
                </el-dropdown-item>
              </template>
              <el-dropdown-item divided
                                :command="{ more: 1 }"
                                v-if="schoolList && schoolList.length > 6">
                <div href="javascript:;"
                     class="more-school">更多学校...</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="role-container"
             v-if="user">
          <el-dropdown placement="top-start"
                       @command="changeRole">
            <!-- 切换角色 -->
            <div class="el-dropdown-link role-name sel-block">
              <img class="user-avatar"
                   :src="user.avatarUrl" />
              <span class="role-title f-toe"
                    v-if="userType == 2 && child">{{ child.childName }}
                <small>（{{ child.className }}）{{ clsRoleFormat(child.familyRole, child.isJwh) }}</small>
              </span>
              <span class="role-title f-toe"
                    v-else-if="userType == 1 && stuClass">{{ user.userName }}
                <small class="extra">（{{ stuClass.className }}）</small>
              </span>
              <span class="role-title f-toe"
                    v-else>{{ user.userName }}
                <small>{{ userTypeFormat(userType, school) }}</small>
              </span>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu class="switch-dropdown"
                              slot="dropdown">
              <el-dropdown-item :command="{ school: school, role: role }"
                                v-for="(role, index) in roleTypeInfos"
                                :key="'role_' + index">
                <a href="javascript:;"
                   class="f-toe">
                  <span v-if="role.userType != 2 && role.userType != 1">{{ userTypeFormat(role.userType, school) }}</span>
                  <span v-else-if="role.userType == 2">{{ role.childName }}
                    <small>（{{ role.className }}）&nbsp;{{ clsRoleFormat(role.familyRole, role.isJwh) }}</small>
                  </span>
                  <span v-else-if="role.userType == 1">
                    <small>{{ role.className }}</small>
                  </span>
                </a>
              </el-dropdown-item>
              <el-dropdown-item divided
                                :command="{ op: 'setting' }">
                <div href="javascript:;"
                     class="">{{ $t('topHeader.setting') }}</div>
              </el-dropdown-item>
              <el-dropdown-item divided
                                :command="{ op: 'exit' }">
                <div href="javascript:;"
                     class="">{{ $t('topHeader.logout') }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right-shortcut">
          <ul>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="选择要切换的学校"
               width="829px"
               :visible.sync="dialogVisiable">
      <el-input class="search-input"
                size="mini"
                placeholder="搜索学校"
                v-model.trim="searchSchoolName" />
      <ul class="switch-school-list">
        <li v-for="sch in searchSchoolList"
            :key="'schoolsel_' + sch.schoolId">
          <a href="javascript:;"
             :title="sch.schoolName"
             class="f-toe"
             @click="changeSchoolAndRole(sch.schoolId)">
            <school-logo class="slogo"
                         :slogo="sch.slogo"></school-logo>
            {{ sch.schoolName }}
          </a>
        </li>
      </ul>
      <div slot="footer"></div>
    </el-dialog>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin'
import PortalSchoolLogo from './sub/PortalSchoolLogo'
import SchoolLogo from '../SchoolLogo'
export default {
  mixins: [SwitchSchoolAndRoleMixin],
  components: {
    SchoolLogo,
    PortalSchoolLogo
  },
  data() {
    return {
      searchSchoolName: null,
      language: localStorage.getItem('lang') || 'zh'
    }
  },
  computed: {
    searchSchoolList() {
      let schoolList = this.schoolList
      if (!schoolList || !this.searchSchoolName) {
        return schoolList
      }
      return schoolList.filter(sch => {
        return sch.schoolName.indexOf(this.searchSchoolName) !== -1
      })
    },
    changeLangEvent() {
      if (this.language == 'zh') {
        localStorage.setItem('locale', 'en')
        this.$i18n.locale = localStorage.getItem('locale')
        localStorage.setItem('lang', 'en')
        this.language = 'en'
        location.reload()
      } else if (this.language == 'en') {
        localStorage.setItem('locale', 'zh')
        this.$i18n.locale = localStorage.getItem('locale')
        localStorage.setItem('lang', 'zh')
        this.language = 'zh'
        location.reload()
      }
    }
  }
}
</script>

<style type="text/css" scoped >
/**.extra
 */
/* .school-info{
		color:#fff;
		float: left;
		margin:15px 0 0 15px;
		font-weight: bold;
		font-size: 16px;
		max-width: 250px;
		cursor: pointer;
		position: absolute;
	} */
.school-logo {
  max-width: 150px;
  height: 50px;
  vertical-align: middle;
  margin-right: 10px;
}

.top-header {
  /* height: 38px; */
  background: -webkit-linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(237, 237, 237, 1) 91%
  );
}
.top-header-content {
  max-width: 1276px;
  margin: 0 auto;
  padding: 5px 0;
}
.top-header-tip {
  float: left;
  line-height: 38px;
  padding-left: 20px;
  font-size: 12px;
}
.role-infos {
  /* float: right; */
  padding-right: 15px;
}
.school-container {
  font-size: 0;
}
.school-name {
  font-size: 0;
}
.school-name .school-title {
  max-width: 150px;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  font-weight: bold;
}
.school-name i {
  font-size: 14px;
  vertical-align: middle;
  margin-left: 5px;
}
.role-infos .school-container {
  float: left;
  margin-left: 15px;
  /* min-width: 100px; */
  line-height: 50px;
}
.role-infos .full {
  float: left;
  /* min-width: 100px; */
  line-height: 50px;
}
.role-infos .role-container {
  float: left;
  margin-left: 30px;
  line-height: 50px;
}
.role-title {
  font-size: 16px;
  vertical-align: middle;
}
.role-title .extra {
  font-size: 14px;
  margin-left: 5px;
}
.role-title > small {
  font-size: 14px;
  margin-left: 5px;
}
.user-avatar {
  width: 30px;
  border-radius: 100%;
  margin-right: 5px;
}
/* 学校信息 */
.switch-dropdown {
}
.switch-dropdown .school-info {
  max-width: 170px;
}
.slogo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  float: left;
  /* vertical-align: middle; */
}
.switch-dropdown .more-school {
  font-style: italic;
}

/*更多学校 切换*/
.more-school {
  color: #fa5555;
  font-style: italic;
}
.switch-school-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}
.switch-school-list li {
  width: 33.3%;
  float: left;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  font-size: 0;
}
.switch-school-list li a {
  border: 1px solid #ececec;
  padding: 7px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  width: 100%;
  line-height: 28px;
  color: #333;
  font-size: 14px;
}
.switch-school-list li a:hover {
  background: #ecf5ff;
  color: #409eff;
}
.search-input {
  width: 200px;
  margin-bottom: 10px;
  position: absolute;
  margin-top: -35px;
  right: 30px;
}
.lan {
  display: inline-block;
  cursor: pointer;
  width: 22px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  color: #24292e;
}
</style>