<!--
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-06-26 19:58:29
 * @FilePath: /web/src/layout/LayoutSpace/components/HeaderSubMenu.vue
 * @Description: 简介
-->
<template>
  <div class="navMenu"
       :style="headerStyle">
    <el-dropdown class="navMenu_left"
                 placement="bottom-start"
                 @command="commandHomeMenu">
      <div class="el-dropdown-link"
           :style="{color: textColor}"
           @click="$router.push('/space')">
        <img style="margin-right:8px" src="@/assets/images/layout/ico_home.png"/>
        <span class="els">首页{{ menuName }}</span>
      </div>
      <el-dropdown-menu slot="dropdown" class="headerSubMenu">
        <el-dropdown-item v-for="menu of menuList"
                          :key="menu.menuId"
                          :command="menu"
                          class="flex-middle">
          <MenuIcon :iconClass="menu.icon" class="menuIcon" style="color:#575859" />
          <span class="menuName">{{ menu.menuName }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-menu v-if="subMenuList.length"
             :style="{ width: `calc(100% - ${+otherWidth})` }"
             class="navMenu_menu"
             :default-active="activeIndex"
             mode="horizontal"
             active-text-color="#fff"
             background-color="#fff"
             v-resize="handleGetWidth"
             @select="handleSelect">
      <el-menu-item v-for="(item, index) of subMenuList.slice(0, showCount)"
                    :key="item.menuId"
                    :index="index + ''">{{ item.menuName }}</el-menu-item>
      <el-dropdown v-if="subMenuList.length > showCount" @command="handleSelect">
        <span class="navMenu_menu_more">更多</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item, i of subMenuList.slice(showCount)"
            :key="i"
            :command="showCount + i"
          >{{ item.menuName }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-menu>

    <!-- 下拉学校列表 -->
    <SchoolList class="schoolList" type="2" textColor="#fff" textSize="16px"></SchoolList>

    <!-- 切换角色 -->
    <RoleChange ref="roleChange" textColor="#fff" />
  </div>
</template>

<script>
import RoleChange from './RoleChange'
import SpaceMenuMixin from '@/mixins/SpaceMenuMixin'
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin';
import MenuIcon from '../MenuIcon'
import { mapState } from 'vuex'
import SchoolList from './SchoolList'

export default {
  components: { RoleChange, MenuIcon, SchoolList },
  mixins: [SpaceMenuMixin,SwitchSchoolAndRoleMixin],
  props: {
    menuName: {
      type: String,
      require: true
    },
    activeIndex: {
      type: String,
      require: true
    },
    subMenuList: {
      type: Array,
      require: true
    }
  },
  directives: {
    resize: {
      // 指令的名称resize
      bind(el, binding) {
        // el为绑定的元素，binding为绑定给指令的对象
        // console.log(el, '绑定', binding)
        let width = ''
        let height = ''

        function isResize() {
          const style = document.defaultView.getComputedStyle(el)
          if (width !== style.width || height !== style.height) {
            binding.value({
              width: parseInt(style.width),
              height: parseInt(style.height)
            }) // 关键(这传入的是函数,所以执行此函数)
          }
          width = style.width
          height = style.height
        }
        el.__vueSetInterval__ = setInterval(isResize, 300)
      },
      unbind(el) {
        // console.log(el, '解绑')
        clearInterval(el.__vueSetInterval__)
      }
    }
  },
  data() {
    return {
      // 角色切换组件的宽度
      roleChangeWidth: 0,
      showCount: 5 // 显示的菜单数量
    }
  },
  computed: {
    ...mapState({ theme: state => state.theme.theme || {} }),
    // 是否是大平台首页
    isHomePage() {
      return this.$route.name === 'space_mHome'
    },
    headerStyle() {
      if(this.theme.menuBgUrl && this.isHomePage) {
        return `background-image: url(${this.theme.menuBgUrl})`
      }
      return 'background-color: ' + this.themeColor
    },
    textColor() {
      // return this.theme.menuTextColor || '#252526'
      return '#fff'
    },
    themeColor() {
      // 获取原型上的theme
      return this.theme.themeColor
    },
    // 除中间菜单之外其他元素的宽度和
    otherWidth() {
      // roleChange的宽度 + 学校列表的宽度 + 导航指引的宽度
      return this.roleChangeWidth + 400
    }
  },
  async mounted() {
    await this.$nextTick()
    this.roleChangeWidth = this.$refs.roleChange.$el.offsetWidth
    // console.log('this.$refs.roleChange', this.$refs.roleChange.$el.offsetWidth);
  },
  methods: {
    handleSelect(menuIndex) {
      const menuUrl = this.getDefaultMenuUrl(this.subMenuList[menuIndex])
      if (menuUrl) this.redirectUrl(menuUrl)
    },
    commandHomeMenu(menu) {
      // if (menu.menuUrl) {
      //   this.$router.push(menu.menuUrl)
      //   return
      // }
      const menuUrl = this.getDefaultMenuUrl(menu)
      if (menuUrl) this.redirectUrl(menuUrl)
    },

    // 获取菜单的宽度
    handleGetWidth(data) {
      let width = data.width
      let count = 0
      for (let menu of this.subMenuList) {
        // 40是每个菜单的左右内边距和、菜单名每个字20px
        width = width - 40 - (menu.menuName ? menu.menuName.length * 20 : 0)
        // 80是更多项的宽度（包含左右内边距），如果是最后一个则判断宽度大于0既是不用放入更多项里边
        if (width > 80 || (width > 0 && this.subMenuList.length === count + 1)) {
          count++
        } else {
          break
        }
      }
      this.showCount = count
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../index.css');
@nav-height: 64px;
@nav-text-size: 16px;
.navMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
}

.navMenu_left {
  width: 200px;
  min-width: 200px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 24px;
  .el-dropdown-link {
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #252526;
  }
  &:hover {
    background: #1842D3;
    box-shadow: inset 0px -1px 0px #F0F0F0;
  }
}

.menuIcon {
  margin-right: 12px;
  /deep/ .top-menu-icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
  }
  /deep/ img {
    width: 18px;
    height: 18px;
  }
}

.els {
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-dropdown-menu.headerSubMenu {
  background: #F6F7F9;
  color: #252526;
  .el-dropdown-menu__item {
    height: 54px;
    color: #252526;
    .menuName {
      line-height: 22px;
      font-size: 14px;
    }
    &:hover {
      background: #EAEEFE;
      color: #2D55EB;
      font-weight: bold;
    }
  }
}

.schoolList {
  max-width: 260px;
  box-sizing: border-box;
}

/deep/ .navMenu_menu {
  // max-width: 500px;
  // overflow-x: auto;
  overflow: hidden;
  .el-menu-item {
    color: #fff;
  }
  &.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    font-weight: bold;
    color: #fff;
  }
  &_more {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  &.el-menu {
    // 菜单背景色改为透明
    background-color: transparent !important;
    font-size: @nav-text-size !important;
  }
  .el-menu-item {
    // 菜单背景色改为透明
    background-color: transparent !important;
    height: @nav-height;
    line-height: @nav-height;
    font-size: @nav-text-size;
    &.is-active {
      font-weight: bold;
    }
    &.navMenu_menu_item.is-active {
      // 菜单背景色改为透明
      background-color: transparent !important;
      font-size: @nav-text-size;
      height: @nav-height;
    }
  }
}
</style>