<template>
	<div>
		<edu-dialog v-model="dialogVisible" title="选择班级">
			<div class="desc">目前已开通云盘的班级</div>
			<div class="xycloud-list">
				<a target="_blank" @click="close" :key="index" v-for="(item,index) in xycloudList" :href="toXycloudUrl+'?classId='+item.classId" class="item" >{{item.className}}</a>
			</div>
			<div slot="footer"></div>
		</edu-dialog>
	</div>
</template>
<script type="text/javascript">
	import {EduDialog} from 'edu-ui';
	/*import api from '@/api';*/
	import {postByUrl} from '@/api/common';
	export default{
		props:{
			value:{}
		},
		data:function(){
			return {
				dialogVisible:false,
				loading:false,
				xycloudList:null,
				toXycloudUrl:ctx+'/xycloud/toXycloud'
			}
		},
		components:{
			EduDialog
		},
		watch:{
			value(){
				this.queryXycloudClassList();
			}
		},
		methods:{
			close(cls){
				this.dialogVisible=false;
			},
			queryXycloudClassList(){
				if(this.loading===true){
					return;
				}
				this.loading=true;
				var newWindow = window.open("about:blank") ;
				postByUrl("/xycloud/queryXycloudClassList",{}).then((json)=>{
					/*json={"rescode":"10000","resmsg":"该用户在本校暂未加入班级","data":null,"status":true};*/
					this.loading=false;
					let data=json.data;
					if(json.status===false){
						this.$dialog.error(json.resmsg);
						return;
					}
					if(!newWindow){
						this.$dialog.error("您的浏览器拦截了新弹出的窗口，请在浏览器右上角设置允许弹出窗口");
						return;
					}
					if(!data||data.length===0){
						/*不存在云盘*/
						newWindow.close();
						this.$dialog.alert("您所在的班级暂时没有关联云盘，请联系管理员开通");
					}else if(data.length===1){
						/*一个云盘，直接跳转*/
						newWindow.location.href=this.toXycloudUrl+"?classId="+data[0].classId;
					}else if(data.length>1){
						/*多个云盘，弹框选择*/
						newWindow.close();
						this.dialogVisible=true;
						this.xycloudList=json.data;
					}
				});
			}
		}
	}
</script>
<style type="text/css" scoped >
	.desc{
		text-align: left;
	}
	.xycloud-list .item{
		display: block;
		padding: 7px 10px;
		border:1px solid #d2d2d2;
		margin: 10px 0;
		text-align: left;
	}
</style>