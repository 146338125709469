<template>
  <ul class="menu-list" ref="menu" v-resize="resize" :style="{ 'color': isHomePage ? '#2d55eb' : '#fff' }">
    <template v-for="(item, index) in userMenuList.slice(0, showMenuNum)">
      <li
        class="item"
        :class="{ cur: isCurMenu(item), index: isHomePage }"
        :key="index"
        @click="handleMenuRedirect(item)"
      >
        <!-- <TopMenuIcon class="img-box" :iconClass="item.icon" v-if="!isHomePage && item.icon"></TopMenuIcon> -->
        <div class="menu-name" :class="{ index: isHomePage || !item.icon }">{{ getTranslateMenuName(item) }}</div>
      </li>
    </template>
    <li v-if="singleList.length">
      <el-dropdown @command="handleZhxyCommand">
        <span class="flex">
          <!-- <i class="el-icon-menu more_icon" :class="{ index: isHomePage }"></i> -->
          <!-- <TopMenuIcon class="img-box" iconClass="@/assets/images/icon_school.png" v-if="!isHomePage"></TopMenuIcon> -->
          <!-- <img :src="schoolImgUrl" class="zhxy_img" v-if="!isHomePage" /> -->
          <span class="cursor more" :class="{ index: isHomePage }">智慧校园</span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item, i of singleList"
            :key="i"
            :command="item"
          >{{ item.schoolName }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </li>
    <li v-if="userMenuList && userMenuList.length > showMenuNum">
      <el-dropdown @command="handleCommand">
        <span>
          <!-- <i class="el-icon-menu more_icon" :class="{ index: isHomePage }"></i> -->
          <span class="cursor more" :class="{ index: isHomePage }">更多</span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item, i of userMenuList.slice(showMenuNum)"
            :key="i"
            :command="item"
          >{{ getTranslateMenuName(item) }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </li>
  </ul>
</template>

<script>
import TopMenuIcon from './TopMenuIcon';
import MenuMixin from '@/mixins/MenuMixin';
import MenuService from '@/services/MenuService';
import mconfig from '@mconfig/index'
export default {
  props: {
    isHomePage: Boolean
  },
  components: {
    TopMenuIcon
  },
  mixins: [MenuMixin],
  directives: {
    resize: {
      // 指令的名称resize
      bind(el, binding) {
        // el为绑定的元素，binding为绑定给指令的对象
        // console.log(el, '绑定', binding)
        let width = ''
        let height = ''

        function isResize() {
          const style = document.defaultView.getComputedStyle(el)
          if (width !== style.width || height !== style.height) {
            binding.value({
              width: parseInt(style.width),
              height: parseInt(style.height)
            }) // 关键(这传入的是函数,所以执行此函数)
          }
          width = style.width
          height = style.height
        }
        el.__vueSetInterval__ = setInterval(isResize, 300)
      },
      unbind(el) {
        // console.log(el, '解绑')
        clearInterval(el.__vueSetInterval__)
      }
    }
  },
  data() {
    return {
      showMenuNum: 4, // 菜单显示的个数
      hasZhxy: true, // 是否有子校
      // schoolImgUrl: require('@/assets/images/groupSchool/icon_school.png'),
      singleList: [] // 智慧校园 - 单校列表
    };
  },
  watch: {
    curMenuList: {
      handler(n) {
        if (n) {
          const lastMenu = n.slice(-1)
          this.$emit('change', lastMenu[0].menuIndexKey)
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    // 用户菜单列表
    userMenuList() {
      let userMenuList = this.$store.state.layout.userMenuList;
      let data = MenuService.getMenuDataList(userMenuList);
      return this.iconCompensation(data);
    },
    // 当前选中菜单列表（每个层级的第一个）
    curMenuList() {
      return this.$store.state.layout.curMenuList;
    },
    // 当前菜单
    curMenu() {
      let curMenuList = this.curMenuList;
      if (curMenuList && curMenuList.length) {
        return curMenuList[0];
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取学校列表
    getList() {
      this.postByCode('I_BDM_BDM_GROUP_SCHOOL_QUERYUSERBINDGROUPSCHOOLLIST', {
        groupSchoolId: 'W_SCHOOLID',
        userId: 'W_USERID',
        userType: 'W_USERTYPE'
      }).then(res => {
        if (!res.status) return this.$message.error(res.resmsg)
        this.singleList = res.data.list || []
        this.hasZhxy = !!this.singleList.length
        // 获取学校列表后，再次执行一遍获取显示菜单个数函数（可能没有子校则智慧校园项不显示），70为菜单左右内边距之和
        this.resize({
          width: this.$refs.menu.offsetWidth - 70
        })
      })
    },

    // 智慧校园下拉菜单command事件，单点登录跳转单校
    handleZhxyCommand(val) {
      const dest = ['WUXI_PRODUCT', 'YUNFU_PRODUCT', 'YJT_PRODUCT']
      const jumpDomain = (dest.includes(mconfig.dest) ? 'https://bp-web.etonedu.cn' : 'https://bp-test.etonedu.cn')
      let url = jumpDomain + '/ssportal/groupSchool/groupSchoolGetToken'
      this.getByUrl('groupSchool/groupSchoolSetToken', {
        url
      }).then(res => {
        if (!res.status) return this.$message.error(res.resmsg)
        let token = res.data.token
        window.open(`${url}?token=${token}&schoolId=${val.schoolId}`, '_blank')
      })
    },

    iconCompensation(data) {
      // 循环查找空的icon，补充
      let dictionaries = {
        家校互动: 'menu-icon-jxgt',
        托管学堂: 'menu-icon-train',
        班级教务: 'menu-icon-jwgl',
        智慧操场: 'menu-icon-playground',
        健康中心: 'menu-icon-health',
        校园办公: 'menu-icon-oa',
        校园安全: 'menu-icon-safety',
        云课堂: 'menu-icon-res',
        德育评价: 'menu-icon-zhpj',
        AI学堂: 'menu-icon-pcxx',
        工作台: 'menu-icon-yyzx',
        宿舍管理: 'menu-icon-ssgl',
        校长驾驶舱: 'menu-icon-xzjsc',
      };
      data.forEach((item, index) => {
        if (item.icon == '') {
          item.icon = dictionaries[item.menuName];
        }
      });
      return data;
    },
    // 是否是当前选中菜单
    isCurMenu(item) {
      let curMenu = this.curMenu;
      return curMenu && curMenu.menuId == item.menuId;
    },

    // 顶部菜单栏宽高变化时触发
    resize(data) {
      // 70菜单左右的内边距之和
      let width = data.width
      let count = 0
      // 判断是否存在子校（存在子校 - 智慧校园栏的宽度为128）
      if (this.hasZhxy) {
        width -= 128
      }
      for (let menu of this.userMenuList) {
        // 48是每个菜单的左右内边距、加左外边距之和、菜单名每个字20px
        width = width - 48 - (menu.menuName ? menu.menuName.length * 20 : 0)
        // 88是更多项的宽度（包含左右内边距），如果是最后一个则判断宽度大于0既是不用放入更多项里边
        if (width > 88 || (width > 0 && this.userMenuList.length === count + 1)) {
          count++
        } else {
          break
        }
      }
      this.showMenuNum = count
    },

    // 更多的下拉菜单的command事件
    handleCommand(val) {
      this.handleMenuRedirect(val)
    }
  }
}
</script>
<style lang='less' scoped>
.more {
  font-size: 20px;
  color: #fff;
  // margin-left: 12px;
  // white-space: nowrap;
  &.index {
    color: #2D55EB;
    margin-left: 0;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.more_icon {
  font-style: 20px;
  color: #fff;
  &.index {
    color: #2D55EB;
  }
}

.zhxy_img {
  width: 20px;
  height: 20px;
}

.menu-list {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 10px 0 60px;
  height: 100%;
}

.menu-list > li {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  font-size: 20px;
}

.menu-list > li.cur {
  font-weight: bold;
}

.menu-list > li.cur:after {
  content: '';
  position: absolute;
  width: calc(100% - 32px);
  height: 2px;
  background: #fff;
  left: 16px;
  bottom: 2px;
}

.menu-list > li.cur.index:after {
  background: #2D55EB;
}

.menu-list > li ~ li {
  margin-left: 16px;
}

.menu-list > li > .menu-name {
  // margin-left: 12px;
  // white-space: nowrap;
  &.index {
    margin-left: 0;
  }
}

.img-box {
  width: 20px;
  height: 20px;
}

.menu-list li:hover {
  /* transform: scale(1.1); */
  font-weight: bold;
}
</style>