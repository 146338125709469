/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-06 11:16:10
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-03-27 11:20:32
 * @Description: 侧边菜单栏
 */

<template>
  <el-menu v-if="siderBarMenu && siderBarMenu.length > 0"
           class="el-menu-vertical"
           :default-active="activeSideMenu"
           :default-openeds="defaultOpeneds"
           text-color="#000"
           active-text-color="#2D55EB"
           unique-opened>
    <my-submenu :sider-bar-menu="siderBarMenu"></my-submenu>
  </el-menu>
</template>

<script>
import MySubmenu from './MySubmenu'
export default {
  components: {
    MySubmenu
  },
  props: {
    siderBarMenu: {
      type: Array,
      require: true
    },
    activeSideMenu: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      defaultOpeneds: []
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
  height: 100%;
  background: #fff;
}
.el-menu-vertical {
  /deep/ .el-menu-item.is-active {
    background-color: rgba(45, 85, 235, 0.1);
  }
  /deep/ .el-menu-item:hover {
    background: rgba(45, 85, 235, 0.1);
  }
}
/deep/ .el-menu {
  background: #fff;
}
</style>

<style lang="less">
.el-submenu .el-menu-item {
  min-width: auto !important;
}

</style>
