/*
 * @Author: longtuxin
 * @LastEditors: chenhanxiong
 * @LastEditTime: 2023-12-03 10:22:20
 * @FilePath: \static\theme\ThemeQhfz\index.js
 * @Description: 大平台主题
 */
// // 主题样式
// import './index.css'
// // icon样式
// import './iconCss/index.css'
// // 导入全局样式
// import './app/index.css'

var themeColor = '#7d438a' //导航颜色

export default {
  themeId: 'portalQhfz',
  iconType: 2,
  themeColor,
  menuBgUrl: null,
  menuTextColor: '#252526', //导航文字颜色
  statisticsColor: {
    // 公假
    sabbaticals: themeColor,
    // 病假
    sickLeave: '#EA5008',
    // 事假
    casualLeave: '#F5C95E',
    // 打卡
    clockIn: themeColor,
    // 需打卡
    needClockIn: '#16D2AE',
  },
}
