/*
 * @Author: chenhanxiong
 * @Date: 2022-12-06 17:03:55
 * @LastEditors: liuzhihao
 * @LastEditTime: 2023-05-12 21:55:21
 * @FilePath: \web\src\router\autotrack.js
 * @Description: 埋点数据
 */
import store from '@/store/index'
import mconfig from '../../../mconfig/static'

import { titles, defaultTitle, specialTitle } from './router-title'

/**
 * 1，传参的params
 * 2，通过？区分的
 */
const setTitle = (to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
    return;
  }
  let { hash } = window.location
  let rows = titles.find((x) => x.path == to.path)

  let hashUrl = hash.split('#')[1]

  if (rows && hashUrl.indexOf('?') > -1) {
    let { query } = to
    let str = JSON.stringify(query)
    rows = specialTitle.find((x) => str.indexOf(x.tag) > -1)
  }

  if (!rows) {
    let { params } = to
    if (Object.keys(params).length) {
      rows = titles.find((x) => to.path.indexOf(x.path) > -1)
    }
  }
  let title = rows ? rows.title : defaultTitle
  document.title = title
}
const getUser = () => {
  let user = store.state.user
  if (!user) {
    return {}
  }
  return user
}

const getUserId = () => {
  return getUser().userId
}

const getSchoolId = () => {
  let user = getUser()
  if (user.school) {
    return user.school.schoolId
  }
}

const getUserType = () => {
  return getUser().userType
}

/**
 *
 * @param app_env
 *  JZ环境:  ENV-JZ
 *  自营环境: ENV-ZY
 *  移动融合: ENV-YD
 *  AI学堂:  ENV-AI
 * @returns
 */
const getAppEnv = (dest) => {
  if (dest == 'JZ_PRODUCT') {
    return 'ENV-JZ'
  } else if (dest == 'FUSE_PRODUCT') {
    return 'ENV-YD'
  } else {
    return 'ENV-ZY'
  }
}
const getProject = (dest) => {
  if (dest == 'YJT_SX') {
    return 'PRJ_SHANX_AFTERSCHLSVC'
  } else {
    return 'PRJ_SAAS'
  }
}

export const sensorsInit = (to) => {
  const sensors = window['sensorsDataAnalytic201505']
  const userId = getUserId()
  const schoolId = getSchoolId()
  const userType = getUserType()
  const path = mconfig.sensorsDomain
  const app_env = getAppEnv(mconfig.dest)
  const project = getProject(mconfig.dest)
  setTitle(to)
  if (!sensors) {
    return
  }
  if (!path) {
    return
  }
  
  if (!userId) {
    return
  }
  
  
  const url = `${path}/usertrack/api/v1/sa?app_env=${app_env}&project=${project}&school_id=${schoolId}&user_cur_role=${userType}`

  
  sensors.init({
    show_log: false,
    batch_send: true,
    server_url: url,
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    send_type: 'beacon',

    heatmap: {
      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'default',
      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'default',
    },
  })

  sensors.quick('autoTrack')

  sensors.login(userId)
}
