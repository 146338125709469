export const defaultTitle = '智慧校园公共服务平台'
export const titles = [
  { path: '/basemodel/sch_mgr/home', title: 'SaaS首页' },
  { path: '/jxhd/noti_schmgr/noti/list', title: '通知列表' },
  { path: '/jxhd/noti_schmgr/noti/my', title: '我的发布' },
  { path: '/jxhd/noti_schmgr/noti/read', title: '通知阅读统计' },
  { path: '/jxhd/noti_schmgr/noti/demo/list', title: '通知模板' },
  { path: '/jxhd/noti_schmgr/noti/demo/list/add_edit', title: '自定义模板' },
  { path: '/jxhd/message/activity', title: '报名列表' },
  { path: '/jxhd/message/activity/activityadd', title: '发报名' },
  { path: '/jxhd/message/vote', title: '投票列表' },
  { path: '/jxhd/message/vote/voteadd', title: '发投票' },
  { path: '/jxhd/schoolmanager_album/album_list', title: '相册列表' },
  { path: '/jxhd/schoolmanager_album/album_list/add', title: '发布相册' },
  { path: '/jxhd/schoolmanager_album/album_list/myalbum', title: '我的相册' },
  { path: '/jxhd/schoolmanager_album/audit_list', title: '审核列表' },
  { path: '/jxhd/schoolmanager_album/album_control', title: '设置' },
  { path: '/album/grow/list', title: '成长相册列表' },
  { path: '/album/grow/albumlist', title: '成长相册学生列表' },
  { path: '/album/grow/photoGallery', title: '照片库' },
  { path: '/daily/sch_mgr/appraise/charts', title: '评价概况' },
  { path: '/daily/sch_mgr/appraise/list', title: '评价记录列表' },
  { path: '/daily/sch_mgr/appraise/stulist', title: '学生统计列表' },
  { path: '/daily/sch_mgr/appraise/tealist', title: '教师统计列表' },
  { path: '/daily/sch_mgr/appraise/itemlist', title: '评价项管理列表' },
  { path: '/daily/sch_mgr/appraise/setting', title: '权限设置' },
  { path: '/daily/sch_mgr/homework/list', title: '作业评价列表' },
  { path: '/wgw/baseschool', title: '官网基础信息设置' },
  { path: '/wgw/messageadd/addMessage', title: '发布信息' },
  { path: '/wgw/contentlist', title: '信息列表' },
  { path: '/wgw/termlist', title: '栏目设置' },
  { path: '/wgw/powerpoint', title: '图库' },
  { path: '/wgw/templatemgr', title: '模板选择' },
  { path: '/wgw/controls', title: '功能小控件' },
  { path: '/wgw/disclosures', title: '公示信息' },
  { path: '/penroll/enroll_manager/release/list', title: '招生信息列表' },
  { path: '/penroll/enroll_manager/release/add', title: '发布招生信息' },
  { path: '/penroll/enroll_manager/follow/pendingReview', title: '招生跟进' },
  { path: '/penroll/enroll_manager/enrollQuery/list', title: '招生查询列表' },
  {
    path: '/penroll/enroll_manager/setting/dataRegistration',
    title: '招生设置-资料登记模板'
  },
  {
    path: '/penroll/enroll_manager/setting/smsNotification',
    title: '招生设置-短信通知模板'
  },
  {
    path: '/penroll/enroll_manager/setting/interviewDocument',
    title: '招生设置-面谈证模板'
  },
  {
    path: '/penroll/enroll_manager/setting/interviewIndicator',
    title: '招生设置-面试指标设置'
  },
  {
    path: '/penroll/enroll_manager/setting/admissionNotice',
    title: '招生设置-录取通知书模板'
  },
  {
    path: '/penroll/enroll_manager/setting/enrollLink',
    title: '招生设置-招生环节设置'
  },
  {
    path: '/penroll/enroll_manager/setting/SettingPay',
    title: '招生设置-缴费设置'
  },
  { path: '/basemgr/chargingStandard/chargingList', title: '收费标准-列表' },
  {
    path: '/basemgr/chargingStandard/chargingList/addCharging',
    title: '收费标准-新增'
  },
  { path: '/health/fund/fundList_Admin', title: '资助列表' },
  { path: '/health/fund/fundList_Admin/fundAdd', title: '资助-新增' },
  { path: '/puniv/questionnaire/surveyList', title: '问卷列表' },
  { path: '/puniv/questionnaire/surveyList/surveyAdd', title: '新增问卷' },
  { path: '/puniv/questionnaire/analyseTable', title: '问卷分析-统计表' },
  {
    path: '/puniv/questionnaire/analyseTable/answerList',
    title: '问卷分析-答卷列表'
  },
  { path: '/jxhd/growing_process/review_group', title: '测评组列表' },
  { path: '/jxhd/growing_process/evaluation_manage', title: '测评管理' },
  { path: '/jxhd/growing_process/template_settings', title: '模板设置' },
  { path: '/poverallevaluation/manager_evaluation/list', title: '评价列表' },
  { path: '/poverallevaluation/manager_record/list', title: '评价记录列表' },
  { path: '/poverallevaluation/manager_statistics/over', title: '评价统计' },
  {
    path: '/poverallevaluation/manager_library/list/template',
    title: '评价设置-评价模板库'
  },
  {
    path: '/poverallevaluation/manager_library/list/indicators',
    title: '评价设置-评价指标库'
  },
  {
    path: '/poverallevaluation/manager_library/list/types',
    title: '评价设置-评价类型'
  },
  { path: '/jxhd/slogan_class/slogan', title: '班级口号管理列表' },
  { path: '/jxhd/schmgr_homework/homework/record', title: '作业记录' },
  { path: '/jxhd/schmgr_homework/homework/setting', title: '发布设置' },
  { path: '/jxhd/schmgr_homework/homework/statistics', title: '作业统计' },
  {
    path: '/jxhd/schmgr_homework_login/schoolmanager_elective/registryStatistics',
    title: '登记统计'
  },
  {
    path: '/jxhd/schmgr_homework_login/schoolmanager_elective/subjectStatistics',
    title: '科目统计'
  },
  {
    path: '/jxhd/schmgr_homework_login/schoolmanager_elective/studentStatistics',
    title: '学生统计'
  },
  { path: '/jxhd/school_manager_scores/list', title: '成绩列表' },
  { path: '/jxhd/school_manager_scores/list/add/import', title: '发布成绩' },
  {
    path: '/jxhd/school_manager_scores/list/grade_add_import',
    title: '发考试'
  },
  {
    path: '/jxhd/school_manager_scores/list/exam_name_list',
    title: '考试类型设置'
  },
  { path: '/jxhd/curricula/list', title: '高考选课' },
  { path: '/jxhd/curricula/list/add', title: '发布选课' },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/electivelist',
    title: '兴趣选课-选课列表'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/electivestastics',
    title: '兴趣选课-选课统计'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/electiveroster',
    title: '兴趣选课-选课名单'
  },
  {
    path: '/courseatten/schedule_manage/curriculum/totalCurriculum',
    title: '排课课表-总课表'
  },
  {
    path: '/courseatten/schedule_manage/curriculum/class',
    title: '排课课表-班级课表'
  },
  {
    path: '/courseatten/schedule_manage/curriculum/classRoom',
    title: '排课课表-教室课表'
  },
  {
    path: '/courseatten/schedule_manage/curriculum/teacher',
    title: '排课课表-老师课表'
  },
  {
    path: '/courseatten/schedule_manage/curriculum/student',
    title: '排课课表-学生课表'
  },
  {
    path: '/courseatten/schedule_manage/curriculum/classSearch',
    title: '排课课表-班级名单查询'
  },
  {
    path: '/courseatten/schedule_manage/courseSchedule/setCourse',
    title: '修改排课-录入课表'
  },
  {
    path: '/courseatten/schedule_manage/timeConfig',
    title: '排课作息时间设置'
  },
  {
    path: '/courseatten/schedule_manage/course_manage/courseInfo',
    title: '课程管理列表'
  },
  {
    path: '/courseatten/schedule_manage/course_manage/addCourse',
    title: '添加课程'
  },
  { path: '/courseatten/template_adjust_course/static/index', title: '调课' },
  { path: '/jxhd/manager_mission/subject_setting', title: '科目设置' },
  { path: '/courseatten/substitute_course/index', title: '代课' },
  {
    path: '/courseatten/course_adjustment/static_page/index',
    title: '节假日调课'
  },
  {
    path: '/courseatten/course_adjustment/static_page/new_adjustment',
    title: '新增调课'
  },
  { path: '/jxhd/manager_mission/schoolmanage_course', title: '教学课表' },
  { path: '/jxhd/manager_mission/schedule_setting', title: '作息时间' },
  { path: '/pevaluate2/record_admin/list', title: '评价记录列表' },
  { path: '/pevaluate2/statistics_admin/exhibition', title: '评价统计列表' },
  { path: '/pevaluate2/setting_admin/list', title: '德育评价表设置' },
  { path: '/pevaluate2/setting_admin/list/type', title: '德育评价类型' },
  { path: '/basemgr/stumgr/high_student_school_roll/index', title: '学生学籍' },
  {
    path: '/basemgr/stumgr/high_student_school_roll/bulk_record',
    title: '学生学籍-操作日志'
  },
  { path: '/basemgr/stumgr/roll_file/index', title: '学籍归档' },
  { path: '/basemgr/stumgr/roll_file/add', title: '新增学籍归档' },
  { path: '/basemgr/stumgr/roll_setting/index', title: '学籍设置' },
  {
    path: '/basemgr/stumgr/roll_setting/addModTemplate/0',
    title: '学籍设置-添加学籍模板'
  },
  { path: '/health/prove/prove_issue', title: '证明开具' },
  { path: '/health/prove/prove_query', title: '证明查询' },
  { path: '/health/prove/prove_template', title: '证明模板' },
  {
    path: '/health/prove/prove_template/template_Setting',
    title: '证明模板-成绩表格模板'
  },
  {
    path: '/health/prove/prove_template/add_template',
    title: '证明模板-新增模板'
  },
  { path: '/basemgr/stu_medal/list', title: '奖章列表' },
  { path: '/basemgr/stu_medal/set/index', title: '奖章设置' },
  { path: '/attendance/attendanceconfirm', title: '学生出勤确认汇总' },
  { path: '/bancard/record/leave_school', title: '家长接送记录' },
  {
    path: '/attendance/studentAttendance/attendancestatics/attenschinout',
    title: '学生进出校考勤统计'
  },
  {
    path: '/attendance/studentAttendance/attendancestatics/attenschbus',
    title: '学生校车打卡统计'
  },
  { path: '/attendance/attendancesetting/setting', title: '学生考勤设置' },
  { path: '/courseatten/class_atten/statistics', title: '课堂考勤' },
  {
    path: '/attendance/dormAttendance/schmgr_statistics/grade_list',
    title: '宿舍考勤统计（年级统计）'
  },
  {
    path: '/attendance/dormAttendance/schmgr_statistics/dorm_list',
    title: '宿舍考勤统计（宿舍统计）'
  },
  {
    path: '/attendance/dormAttendance/schmgr_statistics/absence/list',
    title: '宿舍考勤缺勤名单列表'
  },
  { path: '/attendance/attendanceStatica/statica', title: '职工考勤统计' },
  { path: '/attendance/attendanceRules/rules', title: '职工考勤规则列表' },
  {
    path: '/attendance/attendanceRules/rules/attendanceAdd',
    title: '新增职工考勤规则'
  },
  { path: '/attendance/vacation_balance/list', title: '假期余额管理' },
  { path: '/health/askleave_record/list', title: '请假记录' },
  { path: '/health/askleave/askleavesetting', title: '请假设置' },
  { path: '/bancard/clscardmgr/classcard_list', title: '班牌列表' },
  { path: '/bancard/clscardmgr/classcard_configure', title: '班牌配置' },
  { path: '/bancard/clscardmgr/classcard_module', title: '模板库' },
  { path: '/bancard/clscardmgr/classcard_rules', title: '班牌规则设置' },
  { path: '/bancard/clscardmgr/classcard_medium', title: '多媒体库' },
  {
    path: '/bancard/clscardmgr/classcard_custom_template',
    title: '自定义模板库'
  },
  { path: '/attendance/safety_qr_code/visitors_qr_code', title: '访客二维码' },
  {
    path: '/attendance/safety_recording/visitors_recording',
    title: '访客记录'
  },
  {
    path: '/attendance/safety_inout_make/visitors_inout_make',
    title: '进出记录'
  },
  { path: '/business/car/qrcode', title: '车辆进校申请二维码' },
  { path: '/business/car/record', title: '车辆进出记录' },
  { path: '/business/car/temporaryrecord', title: '临时车辆申请记录' },
  { path: '/business/car/whitelist', title: '白名单' },
  { path: '/business/car/earlywarning', title: '预警通知名单' },
  { path: '/basemodel/sch_mgr/equipment_mgr', title: '设备管理' },
  { path: '/basemodel/sch_mgr/electronic', title: '电子围栏' },
  { path: '/basemodel/sch_mgr/undisturbed_setting', title: '免打扰设置' },
  { path: '/bancard/dashboard/billboard', title: '智慧校园看板' },
  {
    path: '/attendance/schoolmonitor/record/serial',
    title: '监控记录-监控流水'
  },
  {
    path: '/attendance/schoolmonitor/record/discipline',
    title: '监控记录-违规列表'
  },
  { path: '/attendance/schoolmonitor/list', title: '监控列表' },
  { path: '/attendance/school_bus/record_list', title: '打卡记录' },
  { path: '/attendance/school_bus/path_list', title: '路线管理' },
  { path: '/attendance/school_bus/busInformation/list', title: '车辆信息' },
  { path: '/basemgr/order/session', title: '场地预约名单' },
  { path: '/health/self_study/manage/apply_list', title: '申请记录' },
  { path: '/health/self_study/manage/rule_setting', title: '规则设置' },
  { path: '/health/self_study/manage/reason_setting', title: '申请理由设置' },
  { path: '/oa/safetyreport/datatable', title: '数据表管理' },
  { path: '/oa/safetyreport/reportRecord', title: '上报记录' },
  {
    path: '/oa/oa_manage/safety_report_fill/approval_list',
    title: '安全上报-审批设置'
  },
  { path: '/oa/oa_manage/approval_list', title: '申请查询' },
  { path: '/oa/oa_manage/form_list/staff', title: '表单管理-职工表单' },
  { path: '/oa/oa_manage/form_list/student', title: '表单管理-学生表单' },
  { path: '/oa/oa_manage/askleave_manage/applylist', title: '请假-申请记录' },
  {
    path: '/oa/oa_manage/askleave_manage/applytypelist',
    title: '请假-请假类型'
  },
  {
    path: '/oa/oa_manage/askleave_manage/askleave_rule',
    title: '请假-请假规则'
  },
  {
    path: '/oa/oa_manage/askleave_manage/approval_list/8',
    title: '请假-审批设置'
  },
  { path: '/oa/oa_manage/overtime_manage/applylist', title: '加班-申请记录' },
  {
    path: '/oa/oa_manage/overtime_manage/approval_list/28',
    title: '加班-审批设置'
  },
  {
    path: '/oa/oa_manage/overtime_manage/rule_setting',
    title: '加班-加班规则'
  },
  { path: '/oa/oa_manage/wipeout_manage/applylist', title: '报销-申请记录' },
  {
    path: '/oa/oa_manage/wipeout_manage/applytypelist',
    title: '报销-报销类型'
  },
  {
    path: '/oa/oa_manage/wipeout_manage/approval_list/18',
    title: '报销-审批设置'
  },
  { path: '/oa/oa_manage/repair_manager/list', title: '校园报修-申请记录' },
  {
    path: '/oa/oa_manage/repair_manager/setting',
    title: '校园报修-保修接单设置'
  },
  { path: '/oa/oa_manage/repair_manager/audit/30', title: '校园报修-审批设置' },
  { path: '/oa/oa_manage/car_manage/carapplylist', title: '用车-车辆申请记录' },
  { path: '/oa/oa_manage/car_manage/carlist', title: '用车-车辆信息' },
  { path: '/oa/oa_manage/car_manage/driverlist', title: '用车-司机信息' },
  { path: '/oa/oa_manage/car_manage/approval_list/11', title: '用车-审批设置' },
  {
    path: '/oa/oa_manage/chapter_manage/chapterapplylist',
    title: '用章-申请记录'
  },
  { path: '/oa/oa_manage/chapter_manage/chapterlist', title: '用章-印章设置' },
  {
    path: '/oa/oa_manage/chapter_manage/approval_list/12',
    title: '用章-审批设置'
  },
  { path: '/oa/oa_manage/boardroom/applylist', title: '功能室-功能室申请记录' },
  {
    path: '/oa/oa_manage/boardroom/boardroom_user_condition',
    title: '功能室-使用情况'
  },
  {
    path: '/oa/oa_manage/boardroom/boardroom_meeting_statistics',
    title: '功能室-会议统计'
  },
  { path: '/oa/oa_manage/boardroom/setting', title: '功能室-功能室列表' },
  { path: '/oa/oa_manage/boardroom/classifylist', title: '功能室-功能室类型' },
  { path: '/oa/oa_manage/boardroom/applytypelist', title: '功能室-使用类型' },
  {
    path: '/oa/oa_manage/boardroom/approval_list/13',
    title: '功能室-审批设置'
  },
  { path: '/oa/oa_manage/out_manage/outstatistics', title: '外出-外出统计' },
  { path: '/oa/oa_manage/out_manage/outapplylist', title: '外出-申请记录' },
  { path: '/oa/oa_manage/out_manage/approval_list/14', title: '外出-审批设置' },
  { path: '/oa/oa_manage/visitor_manage/applylist', title: '访客-申请记录' },
  {
    path: '/oa/oa_manage/visitor_manage/approval_list/51',
    title: '访客-审批设置'
  },
  {
    path: '/oa/oa_manage/internal_repair_manager/list',
    title: '内部设备报修-申请记录'
  },
  {
    path: '/oa/oa_manage/internal_repair_manager/setting',
    title: '内部设备报修-保修接单设置'
  },
  {
    path: '/oa/oa_manage/internal_repair_manager/audit/46',
    title: '内部设备报修-审批设置'
  },
  {
    path: '/oa/oa_manage/student_askleave/applylist',
    title: '学生请假-申请记录'
  },
  {
    path: '/oa/oa_manage/student_askleave/approval_list/34',
    title: '学生请假-审批设置'
  },
  {
    path: '/oa/oa_manage/supplement_card/applylist',
    title: '补卡申请-申请记录'
  },
  {
    path: '/oa/oa_manage/supplement_card/approval_list/50',
    title: '补卡申请-审批设置'
  },
  {
    path: '/oa/oa_manage/registration_grades/applylist',
    title: '成绩证明申请-申请记录'
  },
  {
    path: '/oa/oa_manage/registration_grades/approval_list/49',
    title: '成绩证明申请-审批设置'
  },
  {
    path: '/oa/oa_manage/registration_grades/authority/set',
    title: '成绩证明申请-权限设置'
  },
  { path: '/oa/oa_manage/stayindorm/list', title: '入住申请-申请记录' },
  {
    path: '/oa/oa_manage/stayindorm/approval_list/45',
    title: '入住申请-审批设置'
  },
  { path: '/oa/oa_manage/stayindorm/setting', title: '入住申请-权限设置' },
  { path: '/oa/oa_manage/staynight_manage/list', title: '留宿申请-申请记录' },
  {
    path: '/oa/oa_manage/staynight_manage/approval_list/39',
    title: '留宿申请-审批设置'
  },
  { path: '/oa/oa_manage/exitdorm/list', title: '退宿申请-申请记录' },
  {
    path: '/oa/oa_manage/exitdorm/approval_list/44',
    title: '退宿申请-审批设置'
  },
  { path: '/oa/oa_manage/exitdorm/setting', title: '退宿申请-规则设置' },
  { path: '/oa/oa_manage/stopdorm/list', title: '停宿申请-申请记录' },
  {
    path: '/oa/oa_manage/stopdorm/approval_list/43',
    title: '停宿申请-审批设置'
  },
  { path: '/oa/oa_manage/dorm_manage/list', title: '调换宿舍申请-申请记录' },
  {
    path: '/oa/oa_manage/dorm_manage/approval_list/36',
    title: '调换宿舍申请-审批设置'
  },
  { path: '/oa/oa_manage/dorm_manage/setting', title: '调换宿舍申请-规则设置' },
  { path: '/oa/oa_manage/dispatch_manage/applylist', title: '发文-申请记录' },
  {
    path: '/oa/oa_manage/dispatch_manage/approval_list/9',
    title: '发文-审批设置'
  },
  {
    path: '/oa/oa_manage/dispatch_manage/type_setting',
    title: '发文-类型设置'
  },
  { path: '/oa/oa_manage/receiving_manage/applylist', title: '收文-申请记录' },
  { path: '/oa/oa_manage/receiving_manage/applyfile', title: '收文-收文归档' },
  {
    path: '/oa/oa_manage/receiving_manage/permission_setting',
    title: '收文-权限设置'
  },
  {
    path: '/oa/oa_manage/receiving_manage/approval_list/10',
    title: '收文-审批设置'
  },
  {
    path: '/oa/oa_manage/receiving_manage/type_setting',
    title: '收文-类型设置'
  },
  { path: '/oa/work_statement_admin/work_report_list', title: '报告管理' },
  { path: '/oa/work_statement_admin/work_report_set', title: '报告设置' },
  { path: '/jxhd/notiCustomize/list/bulletin', title: '公告' },
  { path: '/jxhd/notiCustomize/list/add/sms', title: '发短信' },
  { path: '/jxhd/notiCustomize/list/sms', title: '短信列表' },
  { path: '/oa/oa_prize_mgr/list/1', title: '奖项列表-学生奖项' },
  { path: '/oa/oa_prize_mgr/list/2', title: '奖项列表-教师奖项' },
  { path: '/oa/oa_prize_mgr/list/3', title: '奖项列表-学校奖项' },
  { path: '/oa/oa_prize_mgr/approvallist', title: '审批查询' },
  { path: '/oa/oa_prize_mgr/approvalsetting/19', title: '审批设置' },
  { path: '/oa/asset_manage/stock_check', title: '库存查询' },
  { path: '/oa/asset_manage/warehousing', title: '库存记录' },
  { path: '/oa/asset_manage/warehouse_setting/1', title: '仓库设置' },
  { path: '/oa/asset_manage/audit_setting', title: '审批设置' },
  { path: '/oa/asset_manage/asset_discovery', title: '资产盘点' },
  { path: '/oa/shop_apply/shop_apply/shop_apply_foods', title: '食物采购' },
  { path: '/oa/shop_apply/shop_apply/shop_apply_goods', title: '物品采购' },
  { path: '/oa/shop_apply/shop_approval', title: '审批设置' },
  { path: '/oa/shop_apply/shop_type/pay', title: '类型设置-支付方式' },
  { path: '/oa/shop_apply/shop_type/fund', title: '类型设置-经费类型' },
  { path: '/oa/fund_managenment/project_list', title: '项目列表' },
  { path: '/oa/fund_managenment/project_application_list', title: '申请记录' },
  { path: '/oa/fund_managenment/project_review_settings', title: '审批设置' },
  {
    path: '/oa/fund_managenment/project_sensitive_list',
    title: '敏感信息设置'
  },
  {
    path: '/oa/fund_managenment/other_setting/fund_purpose_list',
    title: '其他设置'
  },
  { path: '/oa/wage_manage_mgr/wage_share', title: '工资条发放' },
  { path: '/mix/message/publicActivity', title: '公开活动' },
  { path: '/mix/message/drawActivity', title: '活动抽奖' },
  {
    path: '/oa/oa_manage/opencourse_manage/applylist',
    title: '公开课-申请记录'
  },
  {
    path: '/oa/oa_manage/opencourse_manage/approval_list/37',
    title: '公开课-审批设置'
  },
  {
    path: '/oa/oa_manage/opencourse_manage/opencourse_setting_list',
    title: '公开课-其他设置'
  },
  {
    path: '/oa/oa_manage/opencourse_manage/opencourse_room_setting_list',
    title: '公开课-场室设置'
  },
  {
    path: '/oa/oa_manage/opencourse_manage/opencourse_teaching_time_setting_list/list',
    title: '公开课-授课时间设置'
  },
  {
    path: '/oa/oa_manage/opencourse_manage/opencourse_print',
    title: '公开课-打印证明'
  },
  {
    path: '/oa/oa_manage/tengyin_manage/applylist',
    title: '誊印管理-申请记录'
  },
  {
    path: '/oa/oa_manage/tengyin_manage/tengin_static',
    title: '誊印管理-誊印统计'
  },
  {
    path: '/oa/oa_manage/tengyin_manage/approval_type_list',
    title: '誊印管理-誊印类型'
  },
  {
    path: '/oa/oa_manage/tengyin_manage/approval_list/35',
    title: '誊印管理-审批设置'
  },
  { path: '/oa/oa_manage/data_form_list/staff', title: '数据表管理-职工表单' },
  {
    path: '/oa/oa_manage/data_form_list/student',
    title: '数据表管理-学生表单'
  },
  { path: '/oa/principal_mailbox/list', title: '信件列表' },
  { path: '/oa/principal_mailbox/approval_list', title: '信箱配置' },
  { path: '/oa/principal_mailbox/type_list', title: '信件类型' },
  { path: '/oa/principal_mailbox/recycle_list', title: '已删除信件' },
  { path: '/finm/schoolmanager_finm/list', title: '缴费管理' },
  { path: '/finm/schoolmanager_finm/list/add', title: '发布缴费' },
  { path: '/finm/schoolmanager_finm/order_list', title: '订单流水查询' },
  { path: '/finm/schoolmanager_finm/qrcode', title: '缴费二维码' },
  {
    path: '/finm/schoolcard/schoolcard_list/campuscardracharge',
    title: '学生校园卡充值查询'
  },
  {
    path: '/finm/schoolcard/schoolcard_list/campuscardconsume',
    title: '学生校园消费查询'
  },
  { path: '/finm/schoolcard/zgs_list/zgracharge', title: '职工校园卡充值查询' },
  { path: '/finm/schoolcard/zgs_list/zgexpenses', title: '职工校园卡消费查询' },
  { path: '/res/schmgr_respublic/list', title: '公开资源' },
  { path: '/res/schmgr_resschool/list', title: '学校资源' },
  { path: '/res/schmgr_resmy/auditList', title: '审核资源' },
  { path: '/res/schmgr_resmy/auditset', title: '审核设置' },
  { path: '/res/schmgr_resmy/release', title: '上传资源' },
  { path: '/res/schmgr_resmy/releaseList', title: '我上传的资源' },
  { path: '/res/schmgr_resmy/collectList', title: '我收藏的资源' },
  { path: '/res/teaching_material', title: '教材管理' },
  { path: '/dorm/dormview/homePage', title: '学生宿舍视图' },
  { path: '/dorm/dormmgr/dormsetting/buildinglist', title: '宿舍楼栋管理' },
  { path: '/dorm/dormmgr/dormsetting/roomlist', title: '宿舍房间管理' },
  { path: '/dorm/dormmgr/dormsetting/managerlist', title: '宿舍管理员' },
  { path: '/dorm/dormmgr/dormsetting/chargelist', title: '住宿标准' },
  { path: '/dorm/dormmgr/stu_manage/staymgrlist', title: '学生住宿管理' },
  { path: '/dorm/dormmgr/stu_manage/checkInlist', title: '学生入驻管理' },
  { path: '/dorm/dormmgr/stu_manage/stopDormlist', title: '学生停宿管理' },
  { path: '/dorm/dormmgr/stu_manage/retirementlist', title: '学生退宿管理' },
  { path: '/dorm/dormmgr/stu_manage/accommodationlist', title: '学生调宿管理' },
  { path: '/dorm/dormmgr/stu_manage/dutylist', title: '学生值日记录' },
  {
    path: '/dorm/staffdormmgr/dormsetting/buildinglist',
    title: '职工宿舍楼栋管理'
  },
  {
    path: '/dorm/staffdormmgr/dormsetting/roomlist',
    title: '职工宿舍房间管理'
  },
  {
    path: '/dorm/staffdormmgr/staff_manage/staymgrlist',
    title: '教师住宿管理'
  },
  {
    path: '/dorm/staffdormmgr/staff_manage/retirementlist',
    title: '教师退宿管理'
  },
  {
    path: '/dorm/staffdormmgr/staff_manage/accommodationlist',
    title: '教师调宿管理'
  },
  {
    path: '/dorm/dormitoryIterate/adminStats/dormitory',
    title: '宿舍考勤统计'
  },
  {
    path: '/dorm/dormitoryIterate/adminStats/class',
    title: '宿舍考勤班级统计'
  },
  {
    path: '/dorm/dormitoryIterate/settingIndex/settingList',
    title: '宿舍考勤规则设置'
  },
  { path: '/dorm/checksleep/AdminStatistics', title: '查寝统计' },
  {
    path: '/dorm/checksleep/AdminSettingIndex/AdminSettingList',
    title: '查寝时段设置'
  },
  {
    path: '/dorm/checksleep/AdminSettingIndex/AdminSettingMessage',
    title: '查寝消息设置'
  },
  { path: '/dorm/dormitoryrating/ScoringRecord', title: '宿舍评分记录' },
  {
    path: '/dorm/dormitoryrating/ScoringRecord/ScoringRecordNewScore?calendarId=336',
    title: '新建宿舍评分'
  },
  { path: '/dorm/dormitoryrating/ScoringSettings', title: '宿舍评分设置' },

  { path: '/oa/oa_manage/mgrapplylist', title: '学生调宿申请' },
  { path: '/dorm/dormmgr/staynight_apply/list', title: '学生留宿申请记录' },
  { path: '/dorm/dormmgr/staynight_apply/staylist', title: '学生留宿名单' },
  { path: '/dorm/dormmgr/staynight_apply/setting', title: '学生留宿设置' },
  { path: '/dorm/dormreport/home', title: '学生宿舍报表' },
  { path: '/dorm/dormAsset/tabList/dormAssetList', title: '宿舍资产' },
  {
    path: '/dorm/dormAsset/tabList/individualAssetList',
    title: '宿舍个人资产'
  },
  {
    path: '/dorm/dormAsset/tabList/dormAssetList/assetDistributionAdd',
    title: '新增资产分配'
  },
  {
    path: '/dorm/dormmgr/dormRepairsTableList/dormRepairs',
    title: '宿舍保修申请记录'
  },
  {
    path: '/dorm/dormmgr/dormRepairsTableList/repairsSet',
    title: '宿舍申请保修设置'
  },
  { path: '/train/statis/sch_list', title: '课程数据分析首页' },
  { path: '/train/statis/sch_list/apply_details', title: '素质课程报名详情' },
  { path: '/train/statis/sch_list/fee_details', title: '素质课程收费详情' },
  { path: '/train/statis/sch_list/details_basic', title: '基本托管收费详情' },
  { path: '/train/stuname/sch_list', title: '学生报名统计' },
  { path: '/train/sch_mgr/set/course/list', title: '素质课程管理' },
  { path: '/train/sch_mgr/set/class/set', title: '素质课程分班设置' },
  { path: '/train/sch_mgr/set/enroll/list', title: '素质课程报名设置' },
  { path: '/train/sch_mgr/set/class/open', title: '素质课程开班设置' },
  { path: '/train/class_plan/index/list/2022-2023-1', title: '素质课课班管理' },
  { path: '/train/finm/school_order_flow', title: '课程缴费流水' },
  { path: '/train/finm/school_refund_flow', title: '课程退费流水' },
  { path: '/train/finm/school_refund_audit_list', title: '课程退费审核' },
  { path: '/train/sch_mgr/list', title: '课程批量退费' },
  {
    path: '/train/sch_mgr/setting?batchRefundSetFlag=true',
    title: '课程批量退费设置'
  },
  { path: '/train/appraise/sch_list', title: '素质课程服务评价' },
  {
    path: '/train/attendance_center/sch_attendance_list',
    title: '课班考勤管理'
  },
  {
    path: '/train/attendance_center/sch_class_attendance',
    title: '课班考勤流水'
  },
  {
    path: '/train/attendance_center/sch_stu_attendance',
    title: '学生课程考勤统计'
  },
  { path: '/train/sch_mgr/rest_reg/list', title: '托管管理' },
  { path: '/train/finm/sch_rest_reg_list', title: '基本托管缴费流水' },
  { path: '/train/sch_mgr/rest_reg/list/add/new', title: '发布托管信息' },
  {
    path: '/train/sch_mgr/trustee_refund_flow/list',
    title: '基本托管退费流水'
  },
  {
    path: '/train/sch_mgr/trustee_refund_audit/list',
    title: '基本托管退费审核'
  },
  {
    path: '/train/sch_mgr/trustee_refund_batch/list',
    title: '基本托管批量退费'
  },
  { path: '/train/sch_mgr/tour_class/record', title: '基本托管巡课记录' },
  { path: '/train/sch_mgr/order/list', title: '订餐管理' },
  { path: '/train/sch_mgr/order/list/add/new', title: '发布订餐信息' },
  { path: '/ptrainv3/meal/list', title: '批量停餐' },
  { path: '/ptrainv3/meal/list/launch', title: '发起停餐' },
  { path: '/train/finm/sch_meal_reg_list', title: '配餐缴费流水' },
  { path: '/train/meal_refund/ins_refund_flow', title: '配餐退费流水' },
  { path: '/train/meal_refund/audit_list', title: '配餐退款审核' },
  { path: '/train/sch_mgr/refund/list', title: '配餐批量退费' },
  { path: '/train/meal_refund/school_list', title: '配餐订餐评价' },
  { path: '/train/sch_mgr/menu/list', title: '配餐套餐管理' },
  { path: '/train/sch_mgr/supply/list', title: '配餐供应商管理' },
  { path: '/ptrainv3/leave/list', title: '托管请假列表' },
  { path: '/ptrainv3/leave/list/eraleave', title: '托管代请假' },
  { path: '/ptrainv3/leave/list/eraleave/launch', title: '发起代请假' },
  { path: '/train/statis/off_set', title: '请假设置' },
  { path: '/train/sch_mgr/noti/list', title: '托管消息中心' },
  { path: '/train/sch_t/gradeOrientationChart', title: '托管学生走向表' },
  { path: '/train/sch_mgr/setting', title: '托管设置' },
  {
    path: '/business/sch_mgr_running/studentRanking/school',
    title: '校级跑步排行榜'
  },
  {
    path: '/business/sch_mgr_running/studentRanking/grade',
    title: '年级跑步排行榜'
  },
  {
    path: '/business/sch_mgr_running/studentRanking/class',
    title: '班级跑步排行榜'
  },
  { path: '/business/sch_mgr_running/punchrecord', title: '打卡记录' },
  {
    path: '/business/sch_mgr_running/statistical/daily',
    title: '长跑日常统计'
  },
  {
    path: '/business/sch_mgr_running/statistical/week_task',
    title: '长跑周任务统计'
  },
  { path: '/business/sch_mgr_running/dismissedlist', title: '免跑名单' },
  {
    path: '/business/sch_mgr_running/punchsetting/rulesetting',
    title: '长跑打卡设置'
  },
  {
    path: '/business/sch_mgr_running/punchsetting/tasklist',
    title: '长跑任务设置'
  },
  {
    path: '/health/temperature/temperature_check_Index/temperature-bulletin',
    title: '学生体温每日简报'
  },
  {
    path: '/health/temperature/temperature_check_Index/temperature-statistics',
    title: '学生体温统计报表'
  },
  {
    path: '/health/temperature/temperature_check_Index/temperature-water',
    title: '学生体温流水查询'
  },
  { path: '/attendance/temperature/statistics', title: '职工体温' },
  { path: '/health/visitor/visitor_temperature', title: '访客体温' },
  {
    path: '/health/temperature/parentTemp/parent_temp_water',
    title: '家长体温'
  },
  {
    path: '/health/temperature/tempPushIndex/student_temp_setting',
    title: '学生体温推送设置'
  },
  {
    path: '/health/temperature/tempPushIndex/parent_temp_setting',
    title: '家长体温推送设置'
  },
  {
    path: '/health/temperature/tempPushIndex/attendance_temp_setting',
    title: '职工体温推送设置'
  },
  {
    path: '/health/temperature/tempPushIndex/visitor_temp_setting',
    title: '访客推送体温设置'
  },
  { path: '/health/home/checkIndex/bulletin', title: '健康登记每日简报' },
  { path: '/health/home/checkIndex/statistics', title: '健康登记统计报表' },
  { path: '/health/home/checkIndex/water', title: '健康登记流水查询' },
  { path: '/health/home/checkIndex/setting', title: '健康登记设置' },
  { path: '/health/askleave/sickLeave', title: '病假管理' },
  { path: '/health/home/healthIndex', title: '身高体重检查' },
  { path: '/health/schoolmanager_recipe/recipeListddit', title: '阳光食谱' },
  { path: '/business/sch_mgr/meal/list', title: '午餐午休登记' },
  { path: '/business/sch_mgr/meal/list/add', title: '新增午餐午休申请' },
  {
    path: '/courseatten/course_scheduling_system/divide_class/task_list',
    title: '分班任务列表'
  },
  {
    path: '/courseatten/course_scheduling_system/divide_class/edit_task',
    title: '创建任务'
  },
  {
    path: '/courseatten/course_arrangement/normalTask/schedule_course',
    title: '课表调整'
  },
  {
    path: '/courseatten/course_arrangement/normalTask/normal_class',
    title: '行政班排课'
  },
  {
    path: '/courseatten/course_arrangement/optionalTask/optional_class',
    title: '走班排课'
  },
  {
    path: '/courseatten/course_arrangement/optionalTask/new_course/1/new_arrange',
    title: '新建排课'
  },
  {
    path: '/courseatten/scheduling_course/course_center/classify',
    title: '课程类目'
  },
  {
    path: '/courseatten/scheduling_course/course_center/list',
    title: '课程中心'
  },
  {
    path: '/courseatten/scheduling_course/course_center/add',
    title: '添加课程'
  },
  { path: '/courseatten/scheduling_course/arrange/list', title: '排课管理' },
  { path: '/workbench/work/workList', title: '工作台第三方登录' },
  { path: '/basemgr/manager_mission/school_calendar', title: '校历设置' },
  { path: '/basemgr/manager_mission/school_calendar/cAdd', title: '新增校历' },
  { path: '/basemgr/orgs/join_setting', title: '用户加入设置' },
  { path: '/basemgr/orgs/join_help', title: '加入班级指引' },
  { path: '/basemgr/orgs/basic_info', title: '学校基础信息设置' },
  { path: '/basemodel/sch_mgr/phone_book/setting', title: '通讯录设置' },
  { path: '/basemgr/orgs/physical_position', title: '物理位置管理' },
  { path: '/basemgr/teaching/structure', title: '教学架构' },
  { path: '/basemgr/administrative/structure', title: '行政架构' },
  { path: '/basemgr/usermgr/usermgr_list', title: '职员管理' },
  { path: '/basemgr/usermgr/jobmgr_list', title: '职位管理' },
  { path: '/basemgr/usermgr/jobmgr_list/jobmgr_add', title: '添加职位' },
  { path: '/basemgr/usermgr/staff_grouping', title: '职员分组' },
  { path: '/basemgr/usermgr/departure_list', title: '离职档案' },
  { path: '/basemgr/classmgr/sass', title: '班级成员' },
  { path: '/basemgr/usermgr/student_grouping', title: '学生分组' },
  { path: '/basemgr/stumgr/setstudents', title: '学生账号' },
  { path: '/basemgr/classmgr/class_headmaster', title: '设置班主任' },
  { path: '/basemgr/usermgr/user_audit/waiting', title: '用户待审核' },
  { path: '/basemgr/usermgr/user_audit/reject', title: '用户已审核' },
  {
    path: '/basemgr/usermgr/user_audit_setting/setting',
    title: '教学组织审核设置'
  },
  {
    path: '/basemgr/usermgr/user_audit_setting/setting_man',
    title: '行政组织审核设置'
  },
  { path: '/basemgr/userimport/personnel/student', title: '学生家长导入' },
  { path: '/basemgr/userimport/personnel/teacher', title: '老师导入' },
  { path: '/basemgr/userimport/personnel/adviser', title: '班主任导入' },
  { path: '/basemgr/studentfile/studentFileList', title: '幼儿档案' },
  {
    path: '/basemgr/studentfile/studentFileList/structureFileLog',
    title: '幼儿档案操作日志'
  },
  { path: '/basemgr/authorization/platform', title: '权限管理' },
  {
    path: '/device/schoolcard/studentlist/schoolcardadd',
    title: '新增学生卡号'
  },
  {
    path: '/device/schoolcard/parentlist/schoolcardadd',
    title: '新增家长卡号'
  },
  {
    path: '/device/schoolcard/teacherlist/schoolcardadd',
    title: '新增职工卡号管理'
  },
  { path: '/device/schoolcard/studentlist', title: '学生卡号管理' },
  { path: '/device/schoolcard/teacherlist', title: '职工卡号管理' },
  {
    path: '/device/schoolcard/teacherlist/schoolcardadd/40/4',
    title: '新增职工卡号管理'
  },
  { path: '/device/schoolcard/parentlist', title: '家长卡号管理' },
  {
    path: '/device/schoolcard/parentlist/schoolcardadd/40/2',
    title: '新增家长卡号'
  },
  { path: '/device/schoolcard/temporarylist', title: '临时卡号管理' },
  {
    path: '/basemgr/statisticsmessage/stasticsanalysis/publishprofile',
    title: '消息发布概况'
  },
  {
    path: '/basemgr/statisticsmessage/stasticsanalysis/publishstastics',
    title: '消息发布统计'
  },
  { path: '/basemgr/statisticsmessage/messagelist', title: '消息列表' },
  { path: '/basemgr/usermgr/user_statistics', title: '用户统计' },
  { path: '/basemgr/statisticsmessage/integration', title: '积分统计' },
  { path: '/oa/schedule/school', title: '学校日程' },
  { path: '/oa/schedule/school/logs', title: '学校日程操作日志' },
  { path: '/device/qrcode/qrStu/stuGeneration/stuAuto', title: '学生二维码' },
  {
    path: '/device/qrcode/qrTea/qr_operation_log/3',
    title: '学生二维码操作日志'
  },
  {
    path: '/device/qrcode/qrTea/qr_operation_log/2',
    title: '教职工二维码操作日志'
  },
  {
    path: '/device/qrcode/qrTea/teaGeneration/teaAuto',
    title: '教职工二维码'
  },
  {
    path: '/device/qrcode/qrTea/teaGeneration/teaImport',
    title: '教职工二维码'
  },
  { path: '/device/qrcode/qrSet', title: '二维码设置' },
  { path: '/wgw/service_newsList', title: '政策列表' },
  { path: '/wgw/service_newsList/addEdit', title: '发布政策' },
  { path: '/wgw/service_newsTermTree', title: '栏目设置' },
  { path: '/wgw/service_JobList', title: '作业资讯列表' },
  { path: '/wgw/service_JobList/addEdit', title: '发布作业资讯' },
  { path: '/wgw/service_JobTermTree', title: '栏目设置' },
  { path: '/datacenter/headmaster/manage', title: '智慧管理' },
  { path: '/datacenter/headmaster/safety', title: '智慧安全' },
  { path: '/datacenter/headmaster/domitory', title: '智慧宿舍' },
  { path: '/pevaluating_v2/homePage', title: 'AI学堂首页' },
  { path: '/preadpaper/examManager?tabName=first', title: '我发布的考试' },
  {
    path: '/preadpaper/examManager?tabName=second',
    title: '指派给我的考试任务'
  },
  {
    path: '/preadpaper/examManager?tabName=third',
    title: '指派给我的阅卷任务'
  },
  { path: '/preadpaper/examManager/examRelease', title: '发布考试' },
  { path: '/preadpaper/editorCard', title: '编辑答题卡' },
  { path: '/preadpaper/answerCardManager', title: '答题卡管理' },
  { path: '/preadpaper/previewAnswerStructure', title: '预览答题卡' },
  { path: '/preadpaper/answerManageSet', title: '上传答案' },
  { path: '/preadpaper/editAnswerCard', title: '编辑答题卡' },
  { path: '/evaluating/mineExamReportSingle', title: '单科考试分析报告' },
  { path: '/evaluating/checkReportDetail', title: '查看考试报告设置' },
  {
    path: '/pevaluatingreport/schoolExamDetail/examResult',
    title: '考试报告-成绩概览'
  },
  {
    path: '/pevaluatingreport/schoolExamDetail/classAnalyse',
    title: '考试报告-班内分析'
  },
  {
    path: '/pevaluatingreport/schoolExamDetail/examAnalyse',
    title: '考试报告-试卷分析'
  },
  {
    path: '/pevaluatingreport/schoolExamDetail/knowledgeAnalyse',
    title: '考试报告-知识点分析'
  },
  {
    path: '/pevaluatingreport/schoolExamDetail/classExamRegardStudents',
    title: '考试报告-需留意学生'
  },
  {
    path: '/pevaluatingreport/schoolExamDetail/examSchoolReportList',
    title: '考试报告-成绩单'
  },
  { path: '/evaluating/mineExamReportAll', title: '综合考试分析报告' },
  { path: '/evaluating/achievementReport', title: '成绩率设置' },
  { path: '/evaluating/editExamReportParameters', title: '新增报告参数设置' },
  { path: '/evaluating/gardeSectionReport', title: '分数段设置' },
  { path: '/evaluating/studyGradeReport', title: '学业等级设置' },
  { path: '/evaluating/rankingReport', title: '名次段设置' },
  { path: '/evaluating/deviceList', title: '学习设备列表' },
  { path: '/evaluating/mineStudyDevice', title: '个人学习设备设置' },
  { path: '/evaluating/addAndEditDevice', title: '添加个人学习设备' },
  { path: '/pinternetClass/clientDownloadIndex', title: '互联课堂下载页' },
  {
    path: '/prepareLessons/prepare_lesson/my_prepare/index/my_lesson_package',
    title: '我的备课列表'
  },
  {
    path: '/prepareLessons/prepare_lesson/my_prepare/edit_lesson',
    title: '创建备课'
  },
  {
    path: '/prepareLessons/prepare_lesson/group_prepare/index',
    title: '小组备课'
  },
  {
    path: '/prepareLessons/prepare_lesson/group_prepare/edit_lesson',
    title: '小组编辑备课'
  },
  {
    path: '/prepareLessons/prepare_lesson/group_management/table',
    title: '备课组列表'
  },
  {
    path: '/prepareLessons/prepare_lesson/group_management/edit',
    title: '编辑备课组'
  },
  { path: '/evaluating/questionBank', title: '试题库查询' },
  { path: '/evaluating/questionBankAudit', title: '审核试题' },
  { path: '/evaluating/importQuestion', title: '批量导入试题' },
  { path: '/evaluating/addQuestionBank', title: '创建试题' },
  { path: '/evaluating/importAddTask', title: '新增试题导入' },
  { path: '/pevaluating_v2/testPaperLibrary', title: '试卷库查询' },
  { path: '/evaluating/machineGroupPaper', title: '智能组卷' },
  { path: '/evaluating/questionPaper', title: '编辑智能试卷' },
  { path: '/evaluating/groupPaperQuestions', title: '手动组卷' },
  { path: '/evaluating/questionPaperDetail', title: '生成试卷' },
  { path: '/evaluating/paperBaseInfo', title: '新建试卷' },
  { path: '/evaluating/createPaperStructure', title: '创建试卷结构' },
  { path: '/evaluating/paperQuestionsInfo', title: '填写试题信息' },
  { path: '/evaluating/waySpecificationTable', title: '填写双向细目表' },
  { path: '/evaluating/paperPreview', title: '预览试卷' },
  { path: '/evaluating/paperList', title: '创建试卷列表' },
  { path: '/prepareLessons/resource/public-resource', title: '云资源查询' },
  { path: '/prepareLessons/resource/school-resource', title: '校本资源查询' },
  { path: '/prepareLessons/resource/class-resource', title: '班本资源' },
  { path: '/prepareLessons/resource/my-resource-list', title: '我的资源' },
  { path: '/prepareLessons/resource/audit-setting', title: '资源审核设置' },
  { path: '/prepareLessons/resource/audit-resource', title: '资源审核列表' },
  {
    path: '/prepareLessons/resource/public-resource/detail',
    title: '资源详情'
  },
  { path: '/presourcesv3/resource', title: '资源管理列表' },
  { path: '/presourcesv3/add_course', title: '创建课程' },
  { path: '/presourcesv3/course_tree', title: '管理课程章节' },
  { path: '/presourcesv3/add_resource', title: '创建资源' },
  { path: '/presourcesv3/classify', title: '资源分类管理' },
  { path: '/presourcesv3/audit', title: '资源审核管理' },
  { path: '/portal/space', title: '工作台首页' },
  { path: '/portal/space/thirdOrgPage', title: '综合服务平台' },
  { path: '/SmartLife/consume', title: '智慧生活' },
  { path: '/Overview', title: '学校概况' },
  { path: '/SmartLibrary', title: '智慧图书馆' },
  { path: '/SmartEducationBusiness', title: '智慧德育' },
  { path: '/SmartTeaching', title: '智慧教学' },
  { path: '/EduQuality', title: '办学质量' },

  { path: '/attendance/attendanceconfirm/home', title: '学生出勤确认汇总' },
  {
    path: '/attendance/studentAttendance/attendancestatics/attenschinout',
    title: '学生进出校考勤统计'
  },
  {
    path: '/attendance/studentAttendance/attendancestatics/attenschbus',
    title: '学生校车打卡统计'
  },
  { path: '/attendance/attendancesetting/setting', title: '学生考勤设置' },
  {
    path: '/oa/oa_manage/safety_report_fill/approval_list',
    title: '安全上报-审批设置'
  },
  { path: '/res/schmgr_respublic/list', title: '公开资源' },
  { path: '/res/schmgr_resschool/list', title: '学校资源' },
  { path: '/res/schmgr_resmy/release', title: '上传资源' },
  { path: '/res/schmgr_resmy/releaseList', title: '我上传的资源' },
  { path: '/res/schmgr_resmy/collectList', title: '我收藏的资源' },
  {
    path: '/dorm/dormitoryrating/ScoringRecord/ScoringRecordNewScore',
    title: '新建宿舍评分'
  },
  {
    path: '/dorm/dormitoryrating/EvaluationStatistics',
    title: '宿舍评价学生累计分'
  },
  { path: '/dorm/dormitoryrating/Summary', title: '评分汇总违纪名单' },

  { path: '/train/appraise/sch_list', title: '素质课程服务评价' },
  { path: '/ptrainv3/meal/list/launch', title: '发起停餐' },

  {
    path: '/device/qrcode/qrTea/teaGeneration/teaImport',
    title: '教职工二维码'
  },
  {
    path: '/device/qrcode/qrTea/qr_operation_log',
    title: '教职工二维码操作日志'
  },
  { path: '/datacenter/headmaster/manage', title: '智慧管理' },
  { path: '/datacenter/headmaster/safety', title: '智慧安全' },
  { path: '/datacenter/headmaster/domitory', title: '智慧宿舍' },
  { path: '/pteaching/class_server', title: '中小学智慧教学平台' },
  { path: '/pteaching/subject', title: '中小学智慧教学平台' },
  { path: '/pteaching/class_scene', title: '中小学智慧教学平台' },
  { path: '/pteaching/course', title: '中小学智慧教学平台' },
  { path: '/pteaching/course/detail', title: '中小学智慧教学平台' },
  { path: '/pteaching/subject', title: '中小学智慧教学平台' },
  { path: '/pteaching/subject/subject_detail', title: '中小学智慧教学平台' },
  { path: '/pteaching/subject_class_scene', title: '中小学智慧教学平台' },
  /** 2023-2-28 新增路由 */
  { path: '/poverallevaluation/manager_report/list', title: '综合报告' },
  { path: '/business/notes/book', title: '笔记本' },
  { path: '/business/notes/list', title: '笔记列表' },
  {
    path: '/courseatten/course_arrangement/normalTask/new_course/2/new_arrange',
    title: '新建排课'
  },
  { path: '/dorm/checksleep/TeacherStatistics', title: '查寝统计' },
  { path: '/dorm/dormitoryIterate/teacherStats', title: '宿舍考勤统计' },
  { path: '/jxhd/noti_teacher/noti/list', title: '通知列表' },
  { path: '/jxhd/noti_teacher/noti/my', title: '我发布的' },
  { path: '/album/grow/photoEdit', title: '查看相册' },
  { path: '/jxhd/teacher_album/album_list', title: '相册列表' },
  { path: '/jxhd/teacher_album/review_album', title: '待审核列表' },
  { path: '/jxhd/teacher_album/review_list/review_setting', title: '设置' },
  { path: '/jxhd/teacher_teach/homework', title: '班级作业' },
  { path: '/jxhd/teacher_scores/list', title: '班级成绩' },
  { path: '/res/schmgr_respublic/list', title: '查看资源' },
  { path: '/res/resdetail', title: '查看资源' },
  { path: '/res/schmgr_resschool/list', title: '查看资源' },
  { path: '/res/schmgr_resmy/auditList', title: '查看资源' },
  { path: '/res/respublic/list', title: '公开资源' },
  { path: '/res/resschool/list', title: '学校资源' },
  { path: '/res/resmy/releaseList', title: '我的资源' },
  { path: '/res/resmy/release', title: '上传资源' },
  { path: '/pteaching/course/detail', title: '查看课程' },
  { path: '/pteaching/course_server', title: '课后服务' },
  { path: '/pteaching/class_scene', title: '上课中' },
  { path: '/pteaching/lessons_detail', title: '备课详情' },
  { path: '/pteaching/course', title: '课程管理' },

  { path: '/attendance/attendance_center/list', title: '课班考勤' },
  { path: '/courseatten/scheduling_course/arrange/tab', title: '继续排课' },
  { path: '/attendance/attendance_center/student/list', title: '学生考勤' },
  { path: '/attendance/attendance_center/teacher/list', title: '教师考勤' },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/add',
    title: '选课-新增课程'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/multiadd',
    title: '选课-新增课程'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/declareadd',
    title: '选课-新增申报类'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/edit',
    title: '选课-编辑课程'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/clzstasticsdetail',
    title: '选课-统计详情'
  },
  {
    path: '/oa/asset_manage/asset_discovery/start_audit',
    title: '资产盘点-盘点详情'
  },
  { path: '/oa/asset_manage/asset_discovery', title: '资产管理-资产盘点' },
  { path: '/oa/asset_manage/audit_setting', title: '资产管理-审批设置' },
  { path: '/oa/asset_manage/warehouse_setting', title: '资产管理-仓库记录' },
  {
    path: '/oa/asset_manage/warehousing/export_qrcode_check',
    title: '库存记录-二维码导出记录'
  },
  {
    path: '/oa/asset_manage/warehousing/import_check',
    title: '库存记录-导入查询'
  },
  {
    path: '/oa/asset_manage/warehousing/operate_record',
    title: '库存记录-操作记录'
  },
  {
    path: '/oa/asset_manage/warehousing/stuff_add',
    title: '资产管理-添加物品'
  },
  { path: '/oa/asset_manage/warehousing', title: '资产管理-库存记录' },
  {
    path: '/oa/asset_manage/warehousing/use_record/',
    title: '库存查询-使用记录'
  },
  { path: '/oa/asset_manage/stock_check', title: '资产管理-库存查询' },
  { path: '/oa/oa_prize_mgr/approvalsetting', title: '奖项管理-审批设置' },
  { path: '/oa/oa_prize_mgr/approvallist', title: '奖项管理-审批查询' },
  { path: '/oa/oa_prize_mgr/list', title: '奖项列表-学校奖项' },
  { path: '/oa/oa_prize_mgr/list', title: '奖项列表-教师奖项' },
  { path: '/oa/oa_prize_mgr/list', title: '奖项列表-学生奖项' },
  { path: '/jxhd/notiCustomize/list/add/sms', title: '发布短信' },
  { path: '/jxhd/notiCustomize/list/sms', title: '短信列表' },
  { path: '/jxhd/notiCustomize/list/add/bulletin', title: '发布公告' },
  { path: '/jxhd/notiCustomize/list/bulletin', title: '公告' },
  { path: '/oa/work_statement_teacher/draft_report_list', title: '草稿箱' },
  { path: '/oa/work_statement_teacher/receive_report_list', title: '收件箱' },
  { path: '/oa/work_statement_teacher/send_report_list', title: '发件箱' },
  { path: '/oa/work_statement_admin/work_report_set', title: '报告设置' },
  { path: '/oa/work_statement_admin/work_report_list', title: '报告管理' },
  {
    path: '/oa/oa_manage_teacher/dispatch_manage/receiving_list',
    title: '我的收文'
  },
  {
    path: '/oa/oa_manage_teacher/dispatch_manage/myPostList',
    title: '我的发文'
  },
  {
    path: '/oa/oa_manage_teacher/dispatch_manage/send_my_super',
    title: '发给我的-外部发文'
  },
  {
    path: '/oa/oa_manage_teacher/dispatch_manage/send_mylist',
    title: '发给我的-内部发文'
  },
  {
    path: '/oa/oa_manage/receiving_manage/permission_setting',
    title: '收文-权限设置'
  },
  { path: '/oa/oa_manage/receiving_manage/applyfile', title: '收文-收文归档' },
  {
    path: '/oa/oa_manage/receiving_manage/type_setting',
    title: '收文-类型设置'
  },
  {
    path: '/oa/oa_manage/receiving_manage/approval_list',
    title: '收文-审批设置'
  },
  { path: '/oa/oa_manage/receiving_manage/applylist', title: '收文-申请记录' },
  {
    path: '/oa/oa_manage/dispatch_manage/type_setting',
    title: '发文-类型设置'
  },
  {
    path: '/oa/oa_manage/dispatch_manage/approval_list',
    title: '发文-审批设置'
  },
  { path: '/oa/oa_manage/dispatch_manage/applylist', title: '发文-申请记录' },
  { path: '/oa/approval/agenda/approval_list', title: '议题管理' },
  { path: '/oa/agenda/list', title: '议题管理' },
  { path: '/oa/agenda/list', title: '议题列表' },
  { path: '/oa/safetyreport/reportRecord/log', title: '上报记录-操作日志' },
  { path: '/oa/safetyreport/reportRecord', title: '上报记录' },
  { path: '/oa/safetyreport/datatable', title: '数据表管理' },
  {
    path: '/basemgr/usermgr/departure_list/export_record',
    title: '离职档案-导出记录'
  },
  { path: '/basemgr/usermgr/staff_grouping/detail', title: '职员详情' },
  { path: '/basemgr/usermgr/jobmgr_list/jobmgr_modify', title: '编辑职位' },
  { path: '/basemgr/usermgr/usermgr_list/bulk_record', title: '用户-操作日志' },
  { path: '/basemgr/teaching/structure/school_upgrade', title: '教学架构升级' },
  {
    path: '/finm/schoolcard/schoolcard_list/exportRecords',
    title: '校园卡-导出记录'
  },
  { path: '/finm/schoolmanager_finm/list/import_log', title: '缴费-操作日志' },
  { path: '/attendance/schoolmonitor/list/add', title: '新增校园监控' },
  { path: '/basemodel/sch_mgr/electronic/add', title: '新增围栏' },
  {
    path: '/attendance/safety_qr_code/visitors_qr_code/appointmentModel',
    title: '访客二维码-预约模式'
  },
  {
    path: '/attendance/safety_qr_code/visitors_qr_code/registerModel',
    title: '访客二维码-登记模式'
  },
  {
    path: '/attendance/school_bus/busInformation/list/edit',
    title: '修改校车'
  },
  { path: '/attendance/school_bus/path_list/lineConfig', title: '路线配置' },
  { path: '/attendance/school_bus/path_list/add', title: '修改路线' },
  {
    path: '/attendance/school_bus/record_list/operationlogs',
    title: '校车-操作日志'
  },
  {
    path: '/attendance/school_bus/record_list/pushSettings',
    title: '校车打卡推送设置'
  },
  { path: '/attendance/school_bus/path_list/add', title: '添加路线' },
  { path: '/attendance/school_bus/busInformation/list/add', title: '新增校车' },
  { path: '/jxhd/manager_mission/subject_setting', title: '科目设置' },
  {
    path: '/courseatten/template_adjust_course/static/class',
    title: '开始调课'
  },
  { path: '/jxhd/teacher_teach/elective/elective_mycourse', title: '我的选课' },
  {
    path: '/jxhd/teacher_teach/elective/elective_mycourse/declareList',
    title: '我的选课'
  },
  { path: '/jxhd/teacher_teach/elective/elective_stastics', title: '选课统计' },
  { path: '/jxhd/teacher_teach/elective/elective_list', title: '选课列表' },
  { path: '/jxhd/print/stasticsprint', title: '选课-打印预览' },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/clzstasticsdetail',
    title: '选课-统计详情'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/edit',
    title: '选课-统计详情'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/edit',
    title: '选课-编辑课程'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/declareadd',
    title: '选课-新增申报类'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/multiadd',
    title: '选课-新增课程'
  },
  {
    path: '/jxhd/manager_mission/schoolmanager_elective/add',
    title: '选课-新增课程'
  },
  { path: '/attendance/attendance_center/teacher/list', title: '下载教师考勤' },
  { path: '/attendance/attendance_center/teacher/list', title: '教师考勤' },
  { path: '/attendance/attendance_center/student/list', title: '学生考勤' },
  { path: '/attendance/attendance_center/list', title: '课班考勤' },
  { path: '/courseatten/scheduling_course/arrange/tab', title: '排课详情' },
  { path: '/courseatten/scheduling_course/arrange/tab', title: '继续排课' },

  { path: '/wgw/culturalwall_group/entrance', title: '融合文化墙' },
  { path: '/wgw/culturalwall/list', title: '文化墙管理-发布列表' },
  { path: '/wgw/culturalwall/column_setting', title: '文化墙管理-栏目设置' },
  { path: '/wgw/culturalwall/school_setting', title: '文化墙管理-学校信息设置' }
]

export const specialTitle = [
  {
    tag: '{"tag":"accumulate"}',
    title: '宿舍评价学生累计分'
  },
  {
    tag: '{"tag":"dorm"}',
    title: '宿舍评价宿舍统计'
  },
  {
    tag: '{"tag":"class"}',
    title: '宿舍评价班级统计'
  },
  {
    tag: '{"tag":"discipline"}',
    title: '评分汇总违纪名单'
  },
  {
    tag: '{"tag":"warning"}',
    title: '评分汇总停宿预警'
  },
  {
    tag: '{"tag":"record"}',
    title: '评分汇总预停宿记录'
  },
  {
    tag: '{"tag":"pteaching"}',
    title: '中小学智慧教学平台'
  }
]
