import MenuService from "@/services/MenuService"
import common from '@/common/common'
import mconfig from '../../../mconfig/static'
export default {
  data() {
    return {
      filterModel: [
        'WEB_M_EVALUATING',
        'WEB_T_EVALUATING',
        'WEB_S_EVALUATING',
        'KECHENGZIYUAN',
        'WEB_M_EVALUATING4',
        'WEB_M_EVALUATING62',
        'WEB_T_EVALUATING3',
        'WEB_T_HOME_WORK',
        "PORTAL_TEACHER_SCORES_LIST2"
      ]
    }
  },
  computed: {
    menuList() {
      let userMenuList = this.$store.state.layout.userMenuList

      let data = MenuService.getMenuDataList(userMenuList)

      return this.iconCompensation(data)
    }
  },
  methods: {
    isIncludes(a, {menuUrl, menuId}) {
      if (!menuUrl) return false
      const routeMenuId = this.$route.query.menuId
      // 如果路由有menuId参数，需要与 menuId 匹配，如果没有，按照路由path来查找对应menuUrl的菜单
      if(routeMenuId) return +routeMenuId === menuId
      return a === menuUrl || a.indexOf(menuUrl) >= 0 || MenuService.isTargetMenu(menuUrl,a)
    },
    menuUrlToObj(menuUrl = this.$route.path) {
      // 菜单最多5级
      let result = {
        link: "",
        menuKey: []
      }
      if (menuUrl === '/error') {
        return result
      }
      for (let o = 0; o < this.menuList.length; o++) {
        const one = this.menuList[o]
        result.menuKey[0] = o

        if (one.funcType !== 1) continue
        if (this.isIncludes(menuUrl, one)) {
          result.link = menuUrl
          return result
        }
        if (!one.menuDtos) continue

        for (let t = 0; t < one.menuDtos.length; t++) {
          const two = one.menuDtos[t]

          if (two.funcType !== 1) continue
          if (this.isIncludes(menuUrl, two)) {
            result.menuKey.push(t)
            result.link = menuUrl
            return result
          }
          if (!two.menuDtos) continue

          for (let th = 0; th < two.menuDtos.length; th++) {
            const three = two.menuDtos[th];

            if (three.funcType !== 1) continue
            if (this.isIncludes(menuUrl, three)) {
              result.menuKey = result.menuKey.concat([t, th])
              result.link = menuUrl
              return result

            }
            if (!three.menuDtos) continue

            for (let f = 0; f < three.menuDtos.length; f++) {
              const four = three.menuDtos[f];

              if (four.funcType !== 1) continue
              if (this.isIncludes(menuUrl, four)) {
                result.menuKey = result.menuKey.concat([t, th, f])
                result.link = menuUrl
                return result
              }
              if (!four.menuDtos) continue

              for (let index = 0; index < four.menuDtos.length; index++) {
                const five = four.menuDtos[index];

                if (five.funcType !== 1) continue
                if (this.isIncludes(menuUrl, five)) {
                  result.menuKey = result.menuKey.concat([t, th, f, index])
                  result.link = menuUrl
                  return result
                }
              }
            }
          }
        }
      }

      if (!result.link) result.menuKey = []
      return result
    },
    openLink(item) {
      if (item.skipType === 2) {
        // 课程中心
        if (item.skipTag == 'COURSE_RESOURSE_CENTERS') {
          let { origin } = window.location
          window.open(
            `${origin}/ssportal/courseCenter/courseSetToken?url=${item.menuUrl}`,
            '_blank'
          )
          return
        }

        window.open(item.menuUrl, "_blank")
        return false
      }
      return true
    },
    getDefaultMenuUrl(menu) {
      if (!menu) return ''

      if (!this.openLink(menu)) return ''

      if (menu.menuUrl && (!menu.menuDtos || !menu.menuDtos[0] || menu.menuDtos[0].funcType !== 1)) {
        return menu
      }

      return this.getDefaultMenuUrl(menu.menuDtos[0])
    },
    redirectUrl(menu) {
      let {menuUrl, menuId} = menu || {};
      if (!menuUrl) return
      if (!this.openLink(menu)) return ''
      this.$router.push({
        path: this.interceptUrl(menuUrl, menu.menuCode), 
        query:{menuId}
      })
    },
    getChildUserId(userInfo) {
      return userInfo && userInfo.child ? userInfo.child.childId : null
    },
    interceptUrl(path, menuCode) {
      const userInfo = this.$store.state.user

      let tradeCode = ['TRADE_T_EVALUATING', 'TRADE_M_EVALUATING']
      const [, key] = path.split('/')
      let urlParams
      if (userInfo.user) {
        urlParams = common.jsonToHttpQuery({
          account: userInfo.user.account,
          jzuserId: userInfo.user.userId,
          jztoken: userInfo.user.token,
          schoolId: userInfo.school.schoolId,
          userType: userInfo.userType,
          roleId: userInfo.userType,
          userName: userInfo.user.userName,
          childId: this.getChildUserId(userInfo),
          path,
        })
      }
      if (this.filterModel.includes(menuCode)) {
        let { origin } = window.location
        if(menuCode == 'PORTAL_TEACHER_SCORES_LIST2'){
          origin = mconfig.staticsDomain;
        }
        // 中山环境跨环境使用
        if(['YJT_ZS'].includes(mconfig.dest)){
          origin = 'https://web.etonedu.cn'
        }
        window.open(
          `${origin}/static/pstudy/#/evaluating/login?${urlParams}`,
          'aistudy'
        )
        return
      }
      if (tradeCode.includes(menuCode)) {
        return `/evaluating/login?${urlParams}`
      }
      return path
    },
    iconCompensation(data) {
      // 循环查找空的icon，补充
      let dictionaries = {
        家校互动: "menu-icon-jxgt",
        托管学堂: "menu-icon-train",
        班级教务: "menu-icon-jwgl",
        智慧操场: "menu-icon-playground",
        健康中心: "menu-icon-health",
        校园办公: "menu-icon-oa",
        校园安全: "menu-icon-safety",
        云课堂: "menu-icon-res",
        德育评价: "menu-icon-zhpj",
        AI学堂: "menu-icon-pcxx",
        工作台: "menu-icon-yyzx",
        宿舍管理: "menu-icon-ssgl",
        校长驾驶舱: "menu-icon-xzjsc",
      }
      data.forEach((item, index) => {
        if (item.icon == "") {
          item.icon = dictionaries[item.menuName]
        }
      })
      return data
    },
  },
}
