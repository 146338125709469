<template>
  <div class="app-wrapper" :class="{hideSidebar:!sidebar.opened}">
    <Sidebar class="sidebar-container"></Sidebar>
    <div class="main-container">
      <Navbar></Navbar>
      <SubMenuComponent></SubMenuComponent>
      <div class="main-content">
        <AppMain></AppMain>
      </div>
    </div>
  </div>
</template>
<script>
import LayoutMixin from '@/mixins/LayoutMixin';
import Sidebar from 'components/skin02/Sidebar'
import Navbar from 'components/skin02/Navbar'
import SubMenuComponent from 'components/skin02/SubMenuComponent'
import AppMain from 'components/skin02/AppMain'
export default {
    components: {
        Sidebar,
        Navbar,
        SubMenuComponent,
        AppMain
    },
    mixins:[LayoutMixin],
    computed: {
        sidebar(){
            return this.$store.state.app.sidebar
        }
    }
}
</script>

<style lang="less" scoped>
//   @import "src/styles/mixin.scss";

    // @include clearfix;
  .app-wrapper {
    /* position: relative; */
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    &.hideSidebar {
      .sidebar-container {
        width: 64px;
        overflow: inherit;
      }
      .main-container {
        margin-left: 64px;
      }
    }
    .sidebar-container {
      transition: width 0.28s ease-out;
      width: 160px;
      height: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 110;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none
      }
    }
    .main-container {
      min-height: 100%;
      transition: margin-left 0.28s ease-out;
      margin-left: 160px;
    }
  }

  .main-content {
    /* background: #f8f8f8; */
    // min-height: 610px;
    /* overflow: hidden; */
  }

  .main-content:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: #f8f8f8;
    z-index: -1;
  }
</style>

