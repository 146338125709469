<template>
  <div class="container_topmenu">
    <div class="container_topmenu_role">
      <div class="container_topmenu_logo">
        <img src="../../assets/images/topmenu/icon_logo_tea.png" alt="" />
        <div class="platform">中小学智慧教学平台</div>
      </div>
      <span @click="mouseEnter" id="headerImg">
        <img
          v-if="user && user.avatarUrl"
          :src="user.avatarUrl"
          alt=""
          class="useravatrl"
        />
        <div class="useravatrl" v-else>
          {{ user.userName.substr(-2, 2) }}
        </div>
      </span>
      <div class="user_info">
        <div class="user_info_name">{{ user.userName }}</div>
        <div class="user_info_role">{{ userTypeFormat(userType, school) }}</div>
      </div>
      <div class="schooname">
        {{ school.schoolName }}
      </div>
    </div>

    <div
      id="swithRole"
      class="swithRole"
      :style="isShow ? 'display:block' : 'display:none'"
    >
      <RoleChange @roleChange="roleChange"> </RoleChange>
    </div>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from "@/mixins/SwitchSchoolAndRoleMixin";
import RoleChange from "./components/RoleChange.vue";
import { mapState } from "vuex";
export default {
  mixins: [SwitchSchoolAndRoleMixin],
  components: { RoleChange },
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    ...mapState({ theme: (state) => state.theme.theme || {} }),
    headerStyle() {
      if (this.theme.menuBgUrl) {
        return `background-image: url(${this.theme.menuBgUrl})`;
      }
      return "background-color: #4b87ff";
    },
    textColor() {
      return this.theme.menuTextColor || "#fff";
    },
    themeColor() {
      // 获取原型上的theme
      return this.theme.themeColor;
    },
  },
  mounted() {
    console.log(this.theme, "themeColor===.");
  },
  methods: {
    mouseEnter(el) {
      this.isShow = true;
      document.addEventListener("click", (e) => this.clickOutSide(e));
    },
    roleChange() {
      this.isShow = false;
    },
    clickOutSide(e) {
      let btn = document.getElementById("headerImg");
      let box = document.getElementById("swithRole");
      // 判断鼠标点击到触发按钮和弹出框外的区域
      if (btn && box && !box.contains(e.target) && !btn.contains(e.target)) {
        this.isShow = false;
      }
    },
    // mouseLeave(el) {
    //   this.isShow = false;
    // },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/teachPaltf.less";

.swithRole {
  position: absolute;
  top: 72px;
  left: 209px;
  z-index: 999;
}

.container_topmenu {
  &_logo {
    padding: 12px 20px;

    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }

    .platform {
      color: #252526;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding-left: 3px;
    }
  }

  .useravatrl {
    width: 56px;
    height: 56px;
    box-shadow: 0px 4px 24px rgba(45, 85, 235, 0.04),
      0px 0px 16px rgba(45, 85, 235, 0.04), 0px 0px 4px rgba(45, 85, 235, 0.04);
    border-radius: 50%;
    text-align: center;
    line-height: 56px;
    margin: 16px auto;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    background: #2d55eb;
    display: block;
    transition: ease 0.3s;
    border: 1px solid rgb(244, 246, 252);
  }
  .useravatrl:hover {
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 16px rgba(45, 121, 235, 0.3);
    // width: 55px;
    // height: 55px;
  }

  .user_info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    &_name {
      color: #252526;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      margin-right: 10px;
      height: 20px;
    }

    &_role {
      background-color: @mainBgColor;
      border-radius: @mainRadius;
      color: @mainColor;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
    }
  }

  .schooname {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    color: #575859;
    text-align: center;
    margin-top: 6px;
  }
}
</style>