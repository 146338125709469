<!--
 * @Author: chenhanxiong
 * @Date: 2022-06-17 00:04:21
 * @LastEditors: chenhanxiong
 * @LastEditTime: 2022-07-26 11:24:27
 * @FilePath: \ws-production\web\src\layout\LayoutWeb.vue
 * @Description: 头部注释
-->
<template>
  <section class="el-container wrapper is-vertical">
    <div class="top-container">
      <TopHeader />
      <TopMenu />
    </div>
    <section
      v-if="loadEnd"
      class="el-container"
    >
      <aside class="el-aside menu resize">
        <LeftMenu />
      </aside>
      <div
        class="el-main content resize"
        id="right-content"
      >
        <router-view></router-view>
        <div id="v3-container"></div>
      </div>
    </section>
  </section>
</template>
<script>
import LayoutMixin from "@/mixins/LayoutMixin";
import TopHeader from "components/skin01/TopHeader";
import TopMenu from "components/skin01/TopMenu";
import LeftMenu from "components/skin01/LeftMenu";

export default {
  components: {
    TopHeader,
    TopMenu,
    LeftMenu,
  },
  mixins: [LayoutMixin],
};
</script>

<style type="text/css" scoped>
.top-container {
  display: flex;
  flex-wrap: wrap;
}
.content {
  background: #f8f8f8;
  padding: 0;
}
.wrapper {
  height: 100%;
  min-width: 1276px;
}
.menu.resize {
  /* height:83.5vh; */
  /* height:calc(100vh - 116px); */
  overflow-y: auto;
  overflow-x: hidden;
}
.resize {
  /* height:83.5vh; */
  height: calc(100vh - 116px);
  overflow-y: auto;
  overflow-x: hidden;
}
.el-aside {
  width: initial;
}
</style>
