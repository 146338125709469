/*
 * @Author: liuzhihao
 * @Date: 2021-08-28 14:10:32
 * @LastEditors: chenhuiling
 * @LastEditTime: 2023-07-31 18:01:00
 * @FilePath: \edumgrd:\yjt-projects\web\src\main.js
 * @Description: 头部注释
 */
__webpack_public_path__ = '//' + gweb.staticsDomain + '/statics/web/'
import Vue from 'vue'
import store from './store/index'

import router from './router'
/*import FastClick from 'fastclick';*/
import App from './App'
import { EduInstall } from 'edu-ui-saas'
//
import Lang from './locale/index'
import GlobalPlugin from './plugins/GlobalPlugin'

import './utils/loadsub.js'
// 注册所有svg图标
import './components'

import { i18n } from './i18n/index'
/* eslint-disable no-new */

/*vue-socket.io*/
Vue.use(EduInstall)
Vue.use(Lang);
/*工程全局变量*/
Vue.use(GlobalPlugin)
/*element */
import ElementUI from 'element-ui'
import ElementLocale from 'element-ui/lib/locale'
// import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/css/base.css'
import '@/assets/css/lang-en.css'
import '@/assets/css/element-self.css'
import '@/assets/css/teachPaltf.css'
// 公共仓库
import "@edumgr/assets/css/common.scss";

import themeColor from '@/directives/themeColor'
Vue.directive('themecolor', themeColor)

Vue.use(ElementUI)

import ElUpload from '@/components/base/ElUpload.vue'

Vue.component(ElUpload.name,ElUpload)
// console.log(ElementUI.version,"<<<<element ui version");

/*end element*/
import config from './config.js'
/*公开插件路径*/
window.PUBLIC_PLUGINS_PATH = config.PUBLIC_PLUGINS_PATH
/*服务端路径*/
window.ctx = config.API_EDU_ROOT
/*Ueditor 编辑器路径*/
window.UEDITOR_HOME_URL = config.API_EDU_ROOT + '/static/ueditor/'

// ElementLocale.i18n((key, value) => i18n.t(key, value))
// import 'edu-ui/dist/static/css/dist.css';
/* socketio服务写在main下，防止组件获取不到this.$socket*/
// import VueSocketio from 'vue-socket.io'
// import socketClient from 'socket.io-client'
//
//
// Vue.use(new VueSocketio({
//   debug: false,
//   connection: socketClient(config.API_EDU_ROOT, { path: config.SOCKET_PATH + '/socket/socket.io' })
// }))
// 设置 标题
//document.title = to.meta.title || '';

/*end*/
var vm = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: {
    App,
  },
})
