<!--
 * @Author: liuzhihao
 * @Date: 2021-11-12 21:58:28
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-12-11 21:40:37
 * @FilePath: /web/src/layout/Layout.vue
 * @Description: 头部注释
-->
<template>
  <div :is="layout" />
</template>

<script>
import LayoutWeb from './LayoutWeb.vue'
import LayoutWebNanning from './LayoutWebNanning.vue'
import LayoutMiddle from './LayoutMiddle.vue'
import LayoutV3 from './LayoutV3.vue'
import LayoutMain from './LayoutMain.vue'
import LayoutSpace from './LayoutSpace/index.vue'
import LayoutSpace2 from './LayoutSpace2/index.vue'
import LayoutRegion from './LayoutRegion'
import { pfvLayoutMap } from '../../config/pfv'
import LayouTeachPlatform from './LayouTeachPlatform.vue' // 中小学智慧教学平台
import LayoutGroupSchool from './LayoutGroupSchool' // 集团校
import LayoutClsEval from './LayoutClsEval' // 课堂评价-windows客户端
import { getQueryString } from '@/utils/utils'


export default {
  components: {
    LayoutWeb,
    LayoutWebNanning,
    LayoutMiddle,
    LayoutV3,
    LayoutMain,
    LayoutSpace,
    LayoutSpace2,
    LayouTeachPlatform,
    LayoutGroupSchool,
    LayoutRegion,
    LayoutClsEval
  },
  data() {
    return {}
  },
  computed: {
    domainConfig() {
      return this.$store.state.app.domainConfig
    },
    skinCode() {
      const domainConfig = this.domainConfig
      if (domainConfig && domainConfig.idxTemp) {
        return domainConfig.idxTemp.code
      } else {
        return ''
      }
    },
    pfv() {
      const domainConfig = this.domainConfig
      // 地址中携带的pfv参数(当前只有课堂评价-客户端在用)
      const searchPfv = getQueryString('pfv')
      console.log('searchPfv layout======',searchPfv)
      return searchPfv || (domainConfig && domainConfig.pfv) || ''
    },
    hasNoLayout() {
      // 是否内嵌在新平台的工作台
      const isSpace = this.$store.state.user.loginMode === 3006 || 3007
      // 是否内嵌在 iframe 中
      const isInIframe = window.self !== window.top
      return isSpace && isInIframe
    },
    isDashboard() {
      let { name: routeName, meta, query } = this.$route
      return (
        // 校门看板
        routeName === 'DashboardLeaveSchool' ||
        routeName === 'DashboardEnterSchool' ||
        routeName === 'DashboardBillboard' ||
        meta.isFillScreen === 1 // meta.isFillScreen=1页面全屏
      )
    },
    layout() {
      if (this.hasNoLayout) return 'LayoutMain'
      if (this.isDashboard) return 'LayoutMain'
      // 判断是否是登录区域平台
      const platStr = localStorage.getItem('plat')
      if(platStr === 'region') return 'LayoutRegion'

      const skinCode = this.skinCode
      let pfv = this.pfv

      if (!pfv) {
        // 若未直接配置平台版本，根据登录页的 skinCode默认设置平台版本
        if (!skinCode) {
          pfv = 'web'
        } else if (skinCode === 'middle') {
          pfv = 'middle'
        } else if (skinCode === 'sass' || skinCode === 'saas') {
          pfv = 'saas'
        } else if (skinCode === 'web' || skinCode === 'CLOUD_SIMPLE') {
          pfv = 'web'
        }
      }
      if (!pfv) return 'LayoutWeb'
      // 按平台版本显示对应布局
      return pfvLayoutMap[pfv] || 'LayoutWeb'
    }
  },
  mounted(){
    console.log('layout=============',this.layout,this.$route)
  }
}
</script>
