<template>
    <div class="tabbar" v-if="menuHistoryList&&menuHistoryList.length">
        <div class="tabbar-content">
        <template v-for="(menu,index) in menuHistoryList">
            <el-tag :key="index" class="menu-bar" :type="(curMenu&&curMenu.menuId==menu.menuId)?'primary':'info'" closable @close="handleClose(index)" @click="handleMenuClick(menu)">{{menu.menuName}}</el-tag>
        </template>
        </div>
        <!-- <el-tag class="menu-bar" type="info" closable>通知</el-tag>
        <el-tag class="menu-bar" type="info" closable>详情</el-tag> -->
    </div>
</template>
<script>
import MenuMixin from '@/mixins/MenuMixin';
export default {
    computed:{
        menuHistoryList(){
            return this.$store.state.layout.menuHistoryList;
        },
        curMenu(){
			let curMenuList=this.curMenuList;
			if(curMenuList&&curMenuList.length){
                let len=curMenuList.length;
				return curMenuList[len-1];
			}
        },
        curMenuList(){
            return this.$store.state.layout.curMenuList;
        },
    },
    mixins:[MenuMixin],
    methods:{
        handleClose(index){
            this.$store.commit("REMOVE_MENU_HISTORY",index);
        },
        handleMenuClick(menu){
            this.handleMenuRedirect(menu,1);
        }
    }
}
</script>
<style lang="less" scoped>
    .tabbar{
        padding:5px ;
        box-sizing: border-box;
        overflow: hidden;
        background:#fff;
        width: 1380px;
        margin:0 auto 10px;
        .tabbar-content{
            white-space:nowrap;
            .menu-bar{
                cursor: pointer;
                margin-right: 7px;
            }
        }
        
    }
</style>