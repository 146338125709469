<template>
  <i class="menu-icon" :class="icon"></i>
</template>
<script type="text/javascript">
export default {
  props: ["icon"],
};
</script>
<style type="text/css" scoped>
.menu-icon {
  width: 26px;
  height: 26px;
  display: inline-block;
  background-repeat: no-repeat;
  margin-right: 5px;
  background-size: 100% 100%;
}
.menu-icon-kpi {
  background-image: url("~@/assets/images/middleicons/menu-icon-kpi.png");
}
.menu-icon-jxgt {
  background-image: url("~@/assets/images/middleicons/menu-icon-notice-message.png");
}
.menu-icon-orgs {
  background-image: url("~@/assets/images/middleicons/menu-icon-orgs.png");
}
.menu-icon-stumgr {
  background-image: url("~@/assets/images/middleicons/menu-icon-stumgr.png");
}
.menu-icon-enrollmgr {
  background-image: url("~@/assets/images/middleicons/menu-icon-enrollmgr.png");
}
/* 校园安全 */
.menu-icon-xyaq,
.menu-icon-safety {
  background-image: url("~@/assets/images/middleicons/menu-icon-safety.png");
}
.menu-icon-usermgr {
  background-image: url("~@/assets/images/middleicons/menu-icon-usermgr.png");
}
.menu-icon-messagemgr {
  background-image: url("~@/assets/images/middleicons/menu-icon-messagemgr.png");
}
/* 财务管理 */
.menu-icon-finm {
  background-image: url("~@/assets/images/middleicons/menu-icon-bill.png");
}
.menu-icon-authority {
  background-image: url("~@/assets/images/middleicons/menu-icon-authority.png");
}
.menu-icon-home {
  background-image: url("~@/assets/images/middleicons/menu-icon-home-new.png");
}
.menu-icon-attendance {
  background-image: url("~@/assets/images/middleicons/menu-icon-attendance.png");
}
.menu-icon-recipe {
  background-image: url("~@/assets/images/middleicons/menu-icon-recipe.png");
}
.menu-icon-jwgl {
  background-image: url("~@/assets/images/middleicons/menu-icon-teach.png");
}
.menu-icon-picture {
  background-image: url("~@/assets/images/middleicons/menu-icon-picture.png");
}
.menu-icon-wgw {
  background-image: url("~@/assets/images/middleicons/menu-icon-wgw.png");
}
.menu-icon-repair {
  background-image: url("~@/assets/images/middleicons/menu-icon-repair.png");
}
.menu-icon-oaassets {
  background-image: url("~@/assets/images/middleicons/menu-icon-oaassets.png");
}
.menu-icon-messmanage {
  background-image: url("~@/assets/images/middleicons/menu-icon-messmanage.png");
}
.menu-icon-schoolcard {
  background-image: url("~@/assets/images/middleicons/menu-icon-schoolcard.png");
}
.menu-icon-teacher-home {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-home.png");
}
.menu-icon-teacher-message {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-message.png");
}
.menu-icon-teacher-phone {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-phone.png");
}
.menu-icon-teacher-evaluate {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-evaluate.png");
}
.menu-icon-teacher-attendance {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-attendance.png");
}
.menu-icon-teacher-classteach {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-classteach.png");
}
.menu-icon-teacher-nutrition {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-nutrition.png");
}
.menu-icon-teacher-picture {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-picture.png");
}
.menu-icon-teacher-messmanage {
  background-image: url("~@/assets/images/middleicons/teacher/menu-icon-teacher-messmanage.png");
}
.menu-icon-student-daily {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-daily.png");
}
.menu-icon-student-exam {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-exam.png");
}
.menu-icon-student-schedule {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-schedule.png");
}
.menu-icon-pcxx {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-childdaily.png");
}
.menu-icon-student-childhomework {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-childhomework.png");
}
.menu-icon-student-message {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-message.png");
}
.menu-icon-student-index {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-index.png");
}
.menu-icon-student-addressbook {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-addressbook.png");
}
.menu-icon-student-recipe {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-recipe.png");
}
.menu-icon-student-homework {
  background-image: url("~@/assets/images/middleicons/student/menu-icon-student-homework.png");
}
.menu-icon-education-index {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-index.png");
}
.menu-icon-education-message {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-message.png");
}
/* 学校管理 */
.menu-icon-xxgl {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-schoolmgr.png");
}
.menu-icon-education-schoolmgr {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-schoolmgr.png");
}
.menu-icon-userimport {
  background-image: url("~@/assets/images/middleicons/menu-icon-userimport.png");
}
.menu-icon-ssgl {
  background-image: url("~@/assets/images/topmenu/schoolmgr/ssgl.png");
}
/* 校长驾驶舱 */
.menu-icon-xzjsc {
  background-image: url("~@/assets/images/middleicons/xzjsc.png");
}
.menu-icon-res {
  background-image: url("~@/assets/images/middleicons/menu-icon-res.png");
}
.menu-icon-oa {
  background-image: url("~@/assets/images/middleicons/menu-icon-officemgr.png");
}
.menu-icon-oashop {
  background-image: url("~@/assets/images/middleicons/menu-icon-oashop.png");
}
.menu-icon-oaprize {
  background-image: url("~@/assets/images/middleicons/menu-icon-oaprize.png");
}
/* 智能排课 */
.menu-icon-choose-ranging {
  background-image: url("~@/assets/images/middleicons/menu-icon-zbkb.png");
}
/* 上课应用 应用中心 */
.menu-icon-yyzx {
  background-image: url("~@/assets/images/middleicons/menu-icon-zbkb.png");
}
.menu-icon-xjgl {
  background-image: url("~@/assets/images/middleicons/menu-icon-xjgl.png");
}
/* 智慧操场 */
.menu-icon-health {
  background-image: url("~@/assets/images/topmenu/schoolmgr/health.png");
}
.menu-icon-xsfw {
  background-image: url("~@/assets/images/middleicons/menu-icon-xsfw.png");
}
.menu-icon-ssgl {
  background-image: url("~@/assets/images/topmenu/schoolmgr/ssgl.png");
}
/*主题活动 */
.menu-icon-ggw-ta {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-answergl.png");
}
.menu-icon-education-livektgl {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-livektgl.png");
}
.menu-icon-education-answergl {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-answergl.png");
}
.menu-icon-education-schoolfx {
  background-image: url("~@/assets/images/middleicons/education/menu-icon-education-schoolfx.png");
}
.menu_icon_wage {
  background-image: url("~@/assets/images/middleicons/gongzitaio.png");
}
.menu_icon_fund {
  background-image: url("~@/assets/images/middleicons/menu-icon-jingfeiguanli.png");
}
.menu-icon-classcardmgr {
  background-image: url("~@/assets/images/middleicons/menu-icon-classcardmgr-new.png");
}
.menu-icon-staff-atten {
  background-image: url("~@/assets/images/middleicons/menu-icon-staff-atten.png");
}
.menu-icon-train {
  background-image: url("~@/assets/images/middleicons/menu-icon-recipe.png");
}
.menu-icon-zhpj {
  background-image: url("~@/assets/images/middleicons/menu-icon-pevaluate.png");
}
</style>
