<template>
  <section class="el-container wrapper is-vertical">
    <div class="top-container">
      <TopHeader />
      <TopMenu />
    </div>
    <section
      v-if="loadEnd"
      class="el-container"
    >
      <aside class="el-aside menu resize">
        <LeftMenu />
      </aside>
      <div class="el-main content resize" id="right-content">
        <router-view></router-view>
      </div>
    </section>
  </section>
</template>
<script>
import LayoutMixin from '@/mixins/LayoutMixin';
import TopHeader from 'components/nanning/TopHeader'
import TopMenu from 'components/nanning/TopMenu'
import LeftMenu from 'components/nanning/LeftMenu'
export default {
    components: {
        TopHeader,
        TopMenu,
        LeftMenu
    },
    mixins:[LayoutMixin]
}
</script>

<style type="text/css" scoped>
	.content{
		background: #f8f8f8;
		padding:0;
	}
	.wrapper{
		height: 100%;
    min-width: 1276px;
	}
	.resize{
		/* height:83.5vh; */
		height:calc(100vh - 116px);
		overflow-y:auto;
		overflow-x:hidden;
	}
</style>

