/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-13 17:50:12
 * @Last Modified by:   caiyf
 * @Last Modified time: 2023-02-13 17:50:12
 * @Description: Description
 */

<template>
  <div class="icon-container">
    <img v-if="isImgUrl"
         :src="iconClass" />
    <div v-else
         class="top-menu-icon"
         :class="iconClass"></div>
  </div>
</template>
<script>
export default {
  props: ['iconClass'],
  computed: {
    isImgUrl() {
      return this.iconClass && this.iconClass.indexOf('http') !== -1
    }
  }
}
</script>

<style scoped>
.icon-container {
  display: inline-block;
  margin-right: 5px;
}
.top-menu-icon {
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 24px;
  background-size: 100% auto;
}
img {
  width:24px;
  height: 24px;
}
</style>

