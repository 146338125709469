<!--
 * @Author: jiangfei
 * @Date: 2022-12-07 11:04:21
 * @LastEditors: jiangfei
 * @LastEditTime: 2022-07-26 11:24:27
 * @FilePath: \ws-production\web\src\layout\LayouTeachPlatform.vue
 * @Description: 中小学智慧教学平台
-->
<template>
    <section class="el-container wrapper is-vertical">
      <div class="top-container">
        <!-- <TopHeader />
        <TopMenu /> -->
      </div>
      <section
        v-if="loadEnd"
        class="el-container"
      >
        <aside class="el-aside menu resize mside">
          <LeftMenuTopVue/>
          <LeftMenu />
        </aside>
        <div
          class="el-main content resize"
          id="right-content"
          v-if="spaceLoadEnd"
        >
          <router-view></router-view>
          <div id="v3-container"></div>
        </div>
      </section>
    </section>
  </template>
  <script>
  import LayoutMixin from "@/mixins/LayoutMixin";
  import TopHeader from "components/skin01/TopHeader";
  import TopMenu from "components/skin01/TopMenu";
  import LeftMenu from "./LayoutTeach/LeftMenu";
  import LeftMenuTopVue from "./LayoutTeach/LeftMenuTop.vue";
  export default {
    components: {
      TopHeader,
      TopMenu,
      LeftMenu,
      LeftMenuTopVue
    },
    mixins: [LayoutMixin],
  };
  </script>
  
  <style type="text/css" scoped>
  .top-container {
    display: flex;
    flex-wrap: wrap;
  }
  .content {
    background: #f8f8f8;
    padding: 0;
  }
  .wrapper {
    height: 100%;
    min-width: 1276px;
  }
  .menu.resize {
    /* height:83.5vh; */
    /* height:calc(100vh - 116px); */
    overflow-y: auto;
    overflow-x: hidden;
  }
  .resize {
    /* height:83.5vh; */
    /* height: calc(100vh - 116px); */
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .el-aside {
    width: initial;
  }
  .mside{
    background: url("../assets/images/topmenu/leftMenu_bg.png") no-repeat ;
    background-size: 100% 100%;
    background-color: #F3F6FD;
  }
  </style>
  