/*
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2022-10-18 11:50:09
 * @FilePath: /web/src/store/modules/theme.js
 * @Description: 简介
 */
import Vue from 'vue'

const theme = {
  state: {
    theme: {}
  },
  mutations: {
    CHANGE_THEME: (state, payload = {}) => {
        const theme = {...Vue.prototype.themes, ...payload}
        Vue.prototype.themes = theme
        state.theme = theme
    }
  },
  actions: {
  }
}

export default theme
