<template>
  <div class="org-picker">
    <el-dropdown popper-class="org-picker-dropdown" @command="changeSchool">
      <div class="org-box">
        <el-avatar
          :size="32"
          shape="square"
          :src="school.slogo"
          :style="{ backgroundColor: themeColor }"
        >
          <span class="empty-logo">
            {{ school.schoolName && school.schoolName.slice(0, 1) }}
          </span>
        </el-avatar>
        <span class="org-title">
          <span class="g-ellipsis">
            {{ school.schoolName }}
          </span>
          <i class="el-icon-caret-bottom"></i>
        </span>
      </div>
      <template slot="dropdown">
        <el-dropdown-menu class="layout-region_school-list">
          <el-input
            v-model="keyword"
            class="org-search"
            prefix-icon="el-icon-search"
            placeholder="搜索内容"
          />
          <el-dropdown-item
            v-for="item in filterSchoolList"
            :key="item.schoolId"
            class="org-item"
            :class="{ 'is-active': school.schoolId === item.schoolId }"
            :style="{
              color: school.schoolId === item.schoolId ? themeColor : '',
            }"
            :command="{ school: item }"
          >
            {{ item.schoolName }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin'
export default {
  data() {
    return {
      keyword: '',
      org: {},
    }
  },
  mixins: [SwitchSchoolAndRoleMixin],
  computed: {
    school() {
      return this.$store.state.user.school || {}
    },
    schoolList() {
      return this.$store.state.user.schoolList || []
    },
    themeColor() {
      const theme = this.$store.state.theme
      return theme && theme.theme && theme.theme.themeColor
    },
    filterSchoolList() {
      if (this.keyword) {
        return this.schoolList.filter((item) =>
          item.schoolName.includes(this.keyword)
        )
      }
      return this.schoolList
    }
  },
}
</script>

<style scoped lang="scss">
/deep/.el-dropdown-menu {
  padding: 20px;
}
.org-picker {
  //   display: flex;
  //   gap: 8px;
  //   align-items: center;
  //   max-width: 360px;
  padding: 0 20px;
}

.org-box {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
}

/deep/.el-avatar {
  border-radius: 8px;
}

.empty-logo {
  width: 100%;
  font-size: 18px;
  height: 100%;
  line-height: 32px;
}

.org-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #1c2c49;
  font-weight: 500;
  > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
<style lang="less">
.org-picker-dropdown {
  border-radius: 4px;
  border: none !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}
.layout-region_school-list {
  width: 320px;
  position: relative;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  &.el-dropdown-menu {
    margin: 24px 0 0 !important;
    position: relative;
    padding: 8px !important;
  }
  .org-search {
    position: sticky;
    height: 48px;
    top: 0;
    display: flex;
    align-items: center;
    > input {
      line-height: 48px;
    }
    &::before {
      // 白色块遮住滚动内容
      display: block;
      content: '';
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      height: 12px;
      background-color: #fff;
    }
  }
  .org-item {
    padding: 12px 16px;
    line-height: 24px;
    color: #1c2c49;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  .org-item.is-active {
    font-weight: 600;
  }

  .org-item:hover {
    font-weight: 600;
    // color: #1c2c49 !important;
    background: #f5f7fa !important;
  }
}
</style>
