import { postByUrl } from '@/api/common'
import SchoolLogo from '@/components/SchoolLogo'
import LoginService from '@/services/LoginService'
import { Message, MessageBox } from 'element-ui'
import schoolJson from '@/datas/school.json'
export default {
  components: {
    SchoolLogo,
  },
  data: function () {
    return {
      dialogVisiable: false,

      switchFlag: false,
      switchBtn: false,
    }
  },
  watch: {
    school (newVal) {
      if (!newVal) {
        return
      }
      let isRemove = schoolJson[newVal.schoolId]
      let ts = new Date().getTime(),
        deadline = new Date('2021/01/29 23:00').getTime() //new Date("2021/01/29 23:00").getTime();
      if (ts > deadline && isRemove) {
        let switchBtn = false,
          schoolList = this.schoolList
        if (schoolList && schoolList.length > 1) {
          let sch = schoolList.find((it) => {
            return !!schoolJson[it.schoolId]
          })
          if (sch) {
            switchBtn = true
          }
        }
        this.switchBtn = switchBtn
        this.switchFlag = true
      } else {
        this.switchFlag = false
      }
    },
  },
  computed: {
    school () {
      return this.$store.state.user.school
    },
    schoolList () {
      return this.$store.state.user.schoolList
    },
    user () {
      return this.$store.state.user.user
    },
    userType () {
      return this.$store.state.user.userType
    },
    child () {
      return this.$store.state.user.child
    },
    stuClass () {
      return this.$store.state.user.stuClass
    },
    stuClassList () {
      return this.$store.state.user.stuClassList
    },
    domainConfig () {
      return this.$store.state.app.domainConfig
    },
    roleTypeInfos () {
      let school = this.school
      if (!school) {
        return null
      }
      let typeList = [32, 8, 4, 256, 64, 16];
      let parentRoleDetails = school.parentRoleDetails
      let roleTypes = school.roleTypes
      let result = []

      typeList.forEach(item => {
        if (roleTypes.includes(item)) {
          result.push({ userType: item })
        }
      })

      if (roleTypes.indexOf(1) != -1 && this.stuClassList) {
        for (let i = 0; i < this.stuClassList.length; i++) {
          let stuClass = this.stuClassList[i]
          stuClass.userType = 1
          result.push(stuClass)
        }
      }

      if (parentRoleDetails && parentRoleDetails.length) {
        for (let i = 0; i < parentRoleDetails.length; i++) {
          let child = parentRoleDetails[i]
          child.userType = 2
          result.push(child)
        }
      }
      return result
    },
  },
  methods: {
    changeSchool ({ school, more }) {
      /*切换学校*/
      if (more) {
        this.openDialog()
      } else if (school) {
        this.changeSchoolAndRole(school.schoolId)
      }
    },
    changeRole ({ school, role, op, callback }) {
      if (op == 'setting') {
        this.toBaseInfo()
        return
      } else if (op == 'exit') {
        this.toLogout()
        return
      }
      sessionStorage.clear()
      /*切换角色*/
      this.changeSchoolAndRole(school.schoolId, role.userType, role, callback)
    },
    toBaseInfo () {
      this.$router.push({ path: '/basemgr/basic/info' })
    },
    toLogout() {
      // 登出回调，目前只有课堂评价模板有用到
      let { beforeLogout } = this.$listeners
      LoginService.logout(beforeLogout)
    },
    openDialog () {
      this.dialogVisiable = true
    },
    changeSchoolAndRole (schoolId, userType, role, callback) {
      //切换成中文的模式
      if (schoolId != '110133') {
        localStorage.setItem("locale", "zh");
        this.$i18n.locale = localStorage.getItem("locale");
      }

      // 切换学校或者角色
      this.dialogVisiable = false;
      this.$store.dispatch('setSpaceLoadEnd', false);
      this.$store
        .dispatch('getLoginData', {
          route: this.$route,
          router: this.$router,
          schoolId,
          role,
          domainConfig: this.domainConfig,
        })
        .then(async (json) => {
          if (schoolId) {
            const homeMenuUrl = this.$store.state.layout.homeMenuUrl
            console.log('homeMenuUrl', homeMenuUrl)
            if (!homeMenuUrl) {
              this.$router.push({ path: '/index' })
              return
            }

            const isSpace = homeMenuUrl.indexOf('space2') !== -1
            if (isSpace) {
              !this.$store.state.user.portalUser ?
                await this.$store.dispatch('getPortalUser') : await this.$store.dispatch('switchPortalRole')
            }


            this.$nextTick(() => {
              if (homeMenuUrl === this.$route.fullPath && (this.userType <= 2)) {
                location.reload()
              } else {
                const curMenuList = this.$store.state.layout.curMenuList || []
                //跳转空页面立即返回解决切换后跳转同一个页面不刷新问题
                if (curMenuList.length > 0 && curMenuList[curMenuList.length - 1].menuUrl === homeMenuUrl) {
                  this.$router.replace({ name: 'black_middle_page', query: { path: homeMenuUrl } })
                } else {
                  this.$router.push({ path: homeMenuUrl })
                  this.$store.commit('SET_CUR_MENUS', this.$route)
                }

              }
              this.$store.dispatch('setSpaceLoadEnd', true);
            })
          }
          // 切换后清除信息
          this.$store.commit('CLEAR_MENU_HISTORY')
          callback && callback()
        })
    },
    userTypeFormat: function (ut, school) {
      if (ut == 4 && school && school.stype === 0) {
        return this.$t('role.worker')
      }
      var userTypeObj = {
        1: this.$t('overData.student'),
        2: this.$t('overData.parent'),
        4: this.$t('overData.J_teacher'),
        8: this.$t('overData.admin'),
        16: this.$t('role.RegionalAdministrator'),
      }
      return userTypeObj[ut] || this.$t('overData.admin')
    },
    clsRoleFormat (familyRole, isJwh) {
      if (isJwh == 1) {
        return this.$t('role.parentCommittee')
      } else {
        return this.$t('overData.parent')
      }
    }
  }
}

