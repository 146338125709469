//引入相关文件
import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/modules/app'
import user from '@/store/modules/user'
import newUser from '@/store/modules/newUser'
import sensifunc from '@/store/modules/sensifunc'
import layout from '@/store/modules/layout'
import theme from '@/store/modules/theme'

Vue.use(Vuex);
//定义初始化变量
const state = {
	spaceMsgCenterDrawer: false, // 大平台我的空间消息中心抽屉显隐
	spaceThemeDialog: false, // 大平台我的空间主题弹窗显隐
	msgCountData: {}, // 消息数量数据
	isRefreshMsgCount: false, // 大平台我的空间 - 是否刷新消息数量(LayooutSpace头部 - 消息图标badge)
	isSpaceSetting: false, // 大平台我的空间 - 空间布局是否是编辑设置状态
}
//定义动作
const mutations = {
	// 设置大平台我的空间消息中心抽屉显隐
	SET_SPACE_MSG_CENTER_DRAWER(state, bool) {
		state.spaceMsgCenterDrawer = bool
	},
	SET_SPACE_THEME_DIALOG(state, bool) {
		state.spaceThemeDialog = bool
	},
	SET_REFRESH_MSG_COUNT(state, bool) {
		state.isRefreshMsgCount = bool
	},
	SET_MSG_COUNT_DATA(state, data) {
		state.msgCountData = data
	},
	SET_SPACE_SETTING(state, bool) {
		state.isSpaceSetting = bool
	}
}
export default new Vuex.Store({
	state,
	mutations,
	modules: {
		app,
		user,
		newUser,
		sensifunc,
		layout,
		theme
	}
})