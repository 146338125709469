var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{staticClass:"role"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.changeUserRole}},[_c('div',{staticClass:"el-dropdown-link"},[_c('div',{staticClass:"user-picker"},[_c('el-avatar',{style:({ backgroundColor: _vm.themeColor }),attrs:{"src":_vm.user.avatarUrl}},[_c('span',{staticClass:"empty-avatar"},[_vm._v(_vm._s(_vm.user.userName && _vm.user.userName.slice(0, 1)))])]),_vm._v(" "),_c('div',[_vm._v("\n          "+_vm._s(_vm.user.userName)+"\n          "),(!_vm.isStaff)?_c('p',{staticClass:"user-role-tag",style:({
              color: _vm.themeColor,
              borderColor: _vm.themeColor,
            })},[_vm._v("\n            管理员\n          ")]):_vm._e()]),_vm._v(" "),_c('i',{staticClass:"el-icon-caret-bottom icon-arrow"})],1)]),_vm._v(" "),_c('el-dropdown-menu',{staticClass:"switch-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.hasTwoRoles)?_c('el-dropdown-item',{attrs:{"command":{
          school: _vm.school,
          role: _vm.roleTypeInfos.find((item) => item.userType !== _vm.userType),
        }}},[_vm._v("\n        切换"+_vm._s(_vm.isStaff ? '管理员' : '职员')+"\n      ")]):_vm._e(),_vm._v(" "),_c('el-dropdown-item',{attrs:{"divided":_vm.hasTwoRoles,"command":{ op: 'setting' }}},[_c('div',[_vm._v("个人设置")])]),_vm._v(" "),_c('el-dropdown-item',{attrs:{"divided":"","command":{ op: 'exit' }}},[_c('div',[_vm._v("退出")])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }