<!--
 * @Author: jiangfei
 * @Date: 2022-12-07 11:04:21
 * @LastEditors: chenhuiling
 * @LastEditTime: 2023-09-08 14:31:53
 * @FilePath: \web\src\layout\LayoutClsEval.vue
 * @Description: 课堂评价-windows客户端
-->
<template>
  <section class="el-container wrapper is-vertical">
    <div class="top-container">
      <TopHeader :intro="intro" @beforeLogout="handleBeforeLogout" v-drag-winform>
        <template #logo>
          <img :src="icon_logo" alt="" class="logo">
        </template>
        <template #right-suffix>
          <div class="suffix-btns">
            <div class="logout-btn" @click="handleLogout">
              <img :src="icon_logout" alt="">
              退出
            </div>
            <div class="img-btn" @click="handleClick('setMinimized')">
              <img :src="icon_minimize" alt="">
            </div>
            <div class="img-btn" @click="handleClick('setMaximized')">
              <img :src="icon_maximize" alt="">
            </div>
            <!-- loginOut 0 返回登录界面  1 关闭程序 -->
            <div class="img-btn" @click="handleClick('loginOut', 1)">
              <img :src="icon_close" alt="">
            </div>
          </div>
        </template>
      </TopHeader>
    </div>
    <!-- getLoginData请求成功后loadEnd为true -->
    <section
      v-if="loadEnd"
      class="el-container container-style"
    >
      <div
        class="el-main content"
        id="right-content"
        v-if="spaceLoadEnd"
      >
        <router-view></router-view>
        <div id="v3-container"></div>
      </div>
    </section>
  </section>
</template>
<script>
  import LayoutMixin from "@/mixins/LayoutMixin";
  import TopHeader from "components/skin01/TopHeader";
  import icon_logo from "@/assets/images/clsEval/logo@2x.png"
  import icon_close from "@/assets/images/clsEval/icon_close@2x.png"
  import icon_maximize from "@/assets/images/clsEval/icon_full_page@2x.png"
  import icon_logout from "@/assets/images/clsEval/icon_logout@2x.png"
  import icon_minimize from "@/assets/images/clsEval/icon_minimize@2x.png"
  import LoginService from '@/services/LoginService'

  export default {
    components: {
      TopHeader,
    },
    mixins: [LayoutMixin],
    data(){
      return {
        icon_logo,
        icon_close,
        icon_maximize,
        icon_logout,
        icon_minimize,
        intro:'宜校园 - 班级助手'
      }
    },
    methods: {
      /**
       * @description: 调用windows客户端方法：window.WFBound
       * @param {String} methodName window.WFBound内定义的方法
       * @param {type} args window.WFBound内定义的方法的入参
       */
      excuteClientMethod(methodName, args) {
        console.log('excuteClientMethod====', methodName, args)
        if (!methodName) return
        if (window.hasOwnProperty('WFBound')) {
          console.log('WFBound 已暴露在window下')
          const _WFBound = window.WFBound
          if (_WFBound.hasOwnProperty(methodName)) {
            if (args || args === 0) {
              if (Array.isArray(args) && args.length) {
                _WFBound[methodName](...args)
              } else {
                _WFBound[methodName](args)
              }
            } else {
              _WFBound[methodName]()
            }
          } else {
            console.log(`err: can't find ${methodName} in WFBound`)
          }
        } else {
          console.log(`err: can't find WFBound in window`)
        }
      },
      /**
      * @description: 点击按钮
      * @param {String} methodName window.WFBound内定义的方法
      */
      handleClick(methodName,args) {
        console.log('handleClick:methodName====',methodName,args)
        this.excuteClientMethod(methodName,args)
      },
      handleBeforeLogout(type = 0){
        this.excuteClientMethod('loginOut', type)
      },
      // 退出登录，返回登录页
      handleLogout(){
        LoginService.logout(this.handleBeforeLogout)
      }
    },
      directives: {
    // 监听web网页拖拽，调用客户端winform方法，实现客户端窗口拖拽效果
    dragWinform: {
      //  el当前元素 binding加参数 通过vnode获取vue实例
      inserted: (el, binding, vnode) => {
        let _this = vnode.context
        let moveFlag
        el.onmousedown = function(e) {
          // 阻止事件冒泡
          e.stopPropagation()

          moveFlag = true
          let x = e.clientX - this.offsetLeft
          let y = e.clientY - this.offsetTop
          // console.log('onmousedown=====', x, y)
          _this.excuteClientMethod('htmMouseDown', [x, y])

          // el.onmousemove = function(e) {
          //   if (moveFlag) {
          //     let l = e.clientX - x
          //     let t = e.clientY - y
          //     _this.excuteClientMethod('htmMouseMove', [l, t])
          //     console.log('onmousemove=====', l, t)
          //   }
          // }
          el.onmouseup = function(e) {
            moveFlag = false
            _this.excuteClientMethod('htmMouseUp')
            // console.log('onmouseup=====')
          }
        }
        document.onmouseup = function(e) {
        // 清除事件
          moveFlag = false
          _this.excuteClientMethod('htmMouseUp')
        }
      }}
  },
  };
</script>
  
<style lang="scss" scoped >
  .content {
    background: #f8f8f8;
    border-radius: 8px;
    overflow: hidden;
  }
  .wrapper {
    height: 100%;
    min-width: 1276px;
  }
  .menu.resize {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .resize {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .el-aside {
    width: initial;
  }
  .mside {
    background: url("../assets/images/topmenu/leftMenu_bg.png") no-repeat;
    background-size: 100% 100%;
    background-color: #f3f6fd;
  }

</style>
<style scoped lang="scss">
  // 组件样式重写
  $bgColor:#F3F6FD;
  $color:252526;

  .logo {
    width: 32px;
    height: 32px;
  }
  .custom-title {
    color: $color;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
  .custom-title-box {
    position: relative;
    padding-right: 24px;
    box-sizing: border-box;
    &:after {
      content:'';
      width: 1px;
      height: 12px;
      display: inline-block;
      position: absolute;
      background: #D5D6D9;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .container-style {
    padding: 0 16px;
    box-sizing: border-box;
    background: $bgColor;
  }
  .top-container {
    display: flex;
    flex-wrap: wrap;
    .top-header {
      background: $bgColor;
      height: 48px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /deep/ &-tip {
        line-height: 24px;
        align-items: center;
        justify-content: space-between;
        color: $color;
        font-size: 14px;
        font-weight: 600;
      }
      /deep/.role-infos {
        .school-name {
          @extend .custom-title-box;
          .school-title {
            @extend .custom-title;
            
          }
        }
  
        .role-container {
          .el-dropdown {
            @extend .custom-title-box;
            .role-title {
              @extend .custom-title;
            }
          }
          
        }

        .suffix-btns {
          margin-left: 24px;
          float: left;
          line-height: 38px;
          display: flex;
          align-items: center;
          .logout-btn {
            margin-right: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
          .img-btn {
            width: 46px;
            height: 40px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      
    }
  }



</style>
  