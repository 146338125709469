<!--
 * @Author: chenhanxiong
 * @Date: 2023-10-19 17:55:09
 * @LastEditors: liuzhihao
 * @LastEditTime: 2024-05-22 14:30:23
 * @FilePath: \web\src\components\base\ElUpload.vue
 * @Description: 图片上传
-->
<template>
  <el-custom-upload 
    ref="upload" 
    v-bind="$attrs" 
    :http-request="handleHttpRequest" 
    :file-list="customFileList"
    :on-change="handleChange"
    action="string">
    <template v-for="slot in Object.entries(this.$slots)" :slot="slot[0]">
      <slot :name="slot[0]"></slot>
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </el-custom-upload>
</template>

<script>
import { uploadFile } from '@/utils/cosUploadfile'
import { Upload, Message } from 'element-ui';

export default {
  name: 'el-upload',
  props: {
    'on-success': {
      type: Function,
      default: () => { }
    },
    'on-change': {
      type: Function,
      default: () => { }
    },
    'http-request': {
      type: Function,
      default: () => { }
    },
    'on-progress': {
      type: Function,
      default: () => { }
    },
    'on-error': {
      type: Function,
      default: () => { }
    },
    fileList: {
      type: Array,
      default: () => ([])
    },
    // 额外上传参数
    addRequestParams: {
      type: [Object, null],
      default() {
        return null
      },
    },
  },
  components: {
    'el-custom-upload': Upload
  },
  mixins: [],
  data() {
    return {
      customFileList:this.fileList
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    school() {
      return this.user.school
    }
  },
  destroyed() { },
  watch: {
    fileList(list){
      if(list){
        this.customFileList = list
      }
    }
  },
  methods: {
    /**
     * @des 根据path不同指定模块
     */
    getBizModuleCode() {
      let bizModuleCode = null
      let { path } = this.$route
      let routeToCode = {
        'train':['/train','/ptrainv3']
      }
      for (let code in routeToCode) {
        let isExist = routeToCode[code].find(x => path.startsWith(x))
        if (isExist) {
          bizModuleCode = code;
          break;
        }
      }
      return bizModuleCode;
    },
    getResponseFileFormat(res, file) {
      return {
        'fileUrl': res.data.finalUrl,
        'file_url': res.data.finalUrl,
        'fileSize': file.size,
        'file_size': file.size,
      }
    },
    /**
     * @description 处理其他模块按新文件中心走自定义上传方式，列如视频，但还有一种情况未覆盖就是
     * 自定义上传走旧文件中心,多选
     */
    handleHttpRequest(fileParams) {
      let funName = this.httpRequest.name
      if (funName.indexOf('default') < 0) {
        this.httpRequest(fileParams)
        return
      }
      let { file } = fileParams;
      let bizModuleCode = this.getBizModuleCode();
      let options = {
        extension: 'cos-pub',
        userId: this.user.userId,
        areaCode: this.school.schoolId,
        userName: this.user.userName,
        userType: this.user.userType,
      }
      if (bizModuleCode) {
        options.bizModuleCode = bizModuleCode
      }
      if (this.addRequestParams) {
        Object.assign(options, this.addRequestParams);
      }
      // 重命名 - 物联方 设备部分不支持中文路径，先重命名为时间戳，下载时另行调用方法命名下载
      let resultFileName = new Date().getTime()
      let reg = /\.[^\.]+$/
      let exts = reg.exec(file.name)
      if (exts.length > 0) {
        // 补充后缀名
        resultFileName += exts[0]
      }
      const renameFile = new File([file], resultFileName, {type: file.type});
      uploadFile({
        file: renameFile,
        onFileFinish: (res) => {
          if (res.code == 200 && (res.data || {}).finalUrl) {
            let response = this.getResponseFileFormat(res, file)
            let fileObject = {
              url: res.data.finalUrl,
              name: file.name,
              size: file.size,
              raw: file,
              uid: file.uid,
              type: file.type,
              status:"success",
              response
            }
            this.customFileList.find((x,index) => {
              if (x.uid === file.uid) {
                this.$set(this.customFileList,index,fileObject)
              }
            })
            this.$nextTick(()=>{
              this.$emit("update:FileList", this.fileList)
              this.onChange(fileObject, this.customFileList)
              this.onSuccess(response, fileObject,this.customFileList)
              // 内部状态更新
              fileParams.onSuccess(response, fileObject,this.customFileList)
            })
          } else {
            Message.error({ message: '上传失败，请刷新重试' })
          }
        },
        onProgress: (res) => {
          let event = {
            percent: res.percent * 100,
            status:"uploading"
          }
          // 自定义进度
          this.onProgress(event,file,this.customFileList)
          // 内部进度
          fileParams.onProgress(event,file,this.customFileList)
        },
        onError: (res) => {
          let resmsg = res.resmsg || res.text || '上传失败，请刷新重试';
          Message.error({ message: resmsg })
          fileParams.onError(resmsg,file,this.customFileList)
          this.$nextTick(()=>{
            this.onError(resmsg,file,this.customFileList);
          })
        },
        options,
      })
    },
    handleChange(file, fileList) {
      this.customFileList = fileList;
      this.onChange(file, fileList)
    },
    submit() {
      this.$refs.upload.submit();
    },
    clearFiles() {
      this.$refs.upload.clearFiles();
    },
    abort() {
      this.$refs.upload.abort();
    }
  }
}
</script>
<style lang='scss' scoped></style>