var setSessionStorage=function(key,obj){
	if(obj){
		sessionStorage.setItem(key, JSON.stringify(obj)); 	
	}else{
		sessionStorage.removeItem(key);
	}
};
var getSessionStorage=function(key){
	var str=sessionStorage.getItem(key); 
	if(str){
		return JSON.parse(str);
	}else{
		return null;
	}	
};

var setLocalStorage=function(key,obj){
	if(obj){
		localStorage.setItem(key, JSON.stringify(obj));
	}else{
		localStorage.removeItem(key);
	} 	
};
var getLocalStorage=function(key){
	var str=localStorage.getItem(key); 	
	if(str){
		return JSON.parse(str);
	}else{
		return null;
	}
};

var jsonToHttpQuery= function(json) {  
	if (!json) {  
		return '';  
	}  
	var tmps = [];  
	for (var key in json) {  
		tmps.push(key + '=' + encodeURIComponent(json[key]));  
	}  
	return tmps.join('&');  
}  

var isEmpty=function(obj){
	if(obj===null||obj===undefined){
		return true;
	}
	if(obj instanceof Array&&obj.length===0){
		return true;
	}
	if(obj===''){
		return true;
	}
	return false;
}
export default {
	setSessionStorage,
	getSessionStorage,
	setLocalStorage,
	getLocalStorage,
	jsonToHttpQuery,
	isEmpty
}