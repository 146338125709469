/*
 * @Author: liuzhihao
 * @Date: 2020-08-12 10:33:56
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-10-18 23:44:23
 * @FilePath: \static\theme\Theme3\index.js
 * @Description: 头部注释
 */
// // 主题样式
// import './index.css'
// // icon样式
// import './iconCss/index.css'
// // 导入全局样式
// import './app/index.css'
var themeColor = "#388DF7"

export default {
  themeId: 3,
  iconType: 3,
  themeColor: themeColor,
  // 蓝色"#388DF7|#388DF7" 
  //themeMixin: themeColor + "|" + "#386ADD",
  statisticsColor: {
    // 公假
    sabbaticals: themeColor,
    // 病假
    sickLeave: "#EA5008",
    // 事假
    casualLeave: "#F5C95E",
    // 打卡
    clockIn: themeColor,
    // 需打卡
    needClockIn: "#16D2AE",
  }
}