<template>
  <div @click='handleToggle' class="screenfull-btn">
    <img v-if="isFullscreen" :src="ScreenfullState2">
    <img v-else :src="ScreenfullState1">
  </div>
</template>

<script>
import screenfull from 'screenfull'
import ScreenfullState1 from '@/assets/images/screenfull-state1.png';
import ScreenfullState2 from '@/assets/images/screenfull-state2.png';
export default {
  name: 'hamburger',
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    },
    fill: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      isFullscreen: false,
      ScreenfullState1,
      ScreenfullState2
    }
  },
  mounted(){
    let This=this;
    screenfull.onchange(function(){
      This.isFullscreen=!This.isFullscreen;
    });
  },
  methods: {
    handleToggle() {
      if (!screenfull.enabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle();
    }
  }
}
</script>

<style scoped>
.screenfull-btn{
  width: 18px;
  font-size: 14px;
  cursor: pointer;
}
.screenfull-btn img{
  width: 100%;
}
</style>
