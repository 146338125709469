import {postByCode} from '@/api/common'
/*
* 敏感功能Store
* actions：
*   getSensifuncStatebyCode----根据funcCode获取验证数据
*   例：this.$store.dispatch('getSensifuncStatebyCode', {funcCode: funcCode,}).then()
* */
const sensifunc = {
  state: {},
  mutations: {
    SET_FUNC_DATA(state, data) {
      state[data.funcCode] = data.data;
    },
    SET_FUNC_RESTYPE(state,data) {
      state[data.funcCode].resType = data.resType
    },
    CLEAR_FUNC_DATA(state,data) {
      state[data.funcCode] = null
    },
    CLEAR_FUNC_ALL_DATA(state) {
      Object.keys(state).forEach(item=>{
        state[item] = null
      })
    }
  },
  actions: {
    getSensifuncStatebyCode({commit, state,rootState}, params) {
      // const { commit, dispatch, state, rootState } = store
      // if(state[params.funcCode]&&state[params.funcCode].usefulLife==2){
      //   commit('SET_FUNC_RESTYPE', {
      //     funcCode:params.funcCode,
      //     resType:2
      //   })
      // }
      if(state[params.funcCode]&&state[params.funcCode].resType==0){
        //如果已有该功能数据且不用验证，则返回
        return
      }
      var postData = {
        funcCode: params.funcCode,
        mobile: rootState.user.user.account,
        platform: 1,
        schoolId: "W_SCHOOLID",
        userId: 'W_USERID'
      }
      return new Promise((resolve,reject)=>{
        postByCode('I_SERVER_SENSIFUNC_QUERY_VALIDATE', postData).then((json) => {
          commit('SET_FUNC_DATA', {
            funcCode:params.funcCode,
            data:json.data
          })
          //获取数据并写入store后resolve
          resolve()
        })
      })
    }
  },
  getters: {
  }
}

export default sensifunc;
