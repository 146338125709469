import config from '../config.js'
import { downloadFile } from '../utils/downloadFile'

import TablePaginationContainer from '@/components/global/TablePaginationContainer'
import PageAddContainer from '@/components/global/PageAddContainer'
import PageTableContainer from '@/components/global/PageTableContainer'
import PageContainer from '@/components/global/PageContainer'
import Breadcrumb from '@/components/global/Breadcrumb'
import LoadingTips from '@/components/global/LoadingTips'
import PaginationWrap from '@/components/global/PaginationWrap'
import FilterContainer from '@/components/global/FilterContainer'
import TableOpContainer from '@/components/global/TableOpContainer'
import TipsMsg from '@/components/global/TipsMsg'
import TinymceEditor from '@/components/global/TinymceEditor'
import {
  postByCode,
  postByUrl,
  postUrl,
  getByUrl,
  postByCodeWithHandle,
  openPostByCode,
} from '@/api/common'

/***
 * @Description 处理文件下载问题 -Element.prototype.appendChild 跟qiankun会冲突
 */
const f = document.body.appendChild.bind(document.body)
document.body.appendChild = function (dom) {
  const elemetNode = 1
  let { nodeType, nodeName, src } = arguments[0]
  let isCos = src && src.indexOf('https://co') > -1
  if (nodeType === elemetNode && nodeName === 'IFRAME' && isCos) {
    return downloadFile(arguments[0].src)
  } else {
    return f(dom)
  }
}
export function verifyRbac(rbacCode, rbacType){
  console.log(this, 'web-this')
  let curMenuList = this.$store.state.layout.curMenuList || []
  let curMenu = curMenuList[curMenuList.length - 1]
  // 无菜单
  if (!curMenuList || !curMenuList.length) {
    return false
  }
  if (this.$store.state.user.user.userType >= 8 && rbacType != 4) {
    return true
  }

  // 旧权限兼容全部开放
  if (curMenu && curMenu.menuType == 8) {
    return true
  }

  //  非老师用户，匹配老师功能，直接无权限
  if (rbacType == 4 && this.$store.state.user.user.userType != rbacType) {
    return false
  }

  let flag = false
  let isRbac = false
  for (let i = 0; i < curMenuList.length; i++) {
    let m = curMenuList[i]
    let rbacInfoDtos = m.rbacInfoDtos

    // 没有配置权限则不受控制
    if (!rbacInfoDtos || !rbacInfoDtos.length) {
      continue
    }
    isRbac = true
    // 处于启用状态的当前查询权限
    let curRbacCode = rbacInfoDtos.find((item) => {
      return item.itemCode == rbacCode
    })
    if (!curRbacCode) {
      isRbac = false
    }
    if (
      curRbacCode &&
      (curRbacCode.isCheck == 1 || curRbacCode.state == 2)
    ) {
      flag = true
      break
    }
  }
  // 没有配置权限则不受控制
  if (!isRbac && rbacType == 4) flag = true
  return flag
}

export default {
  install: function (Vue, options) {
    Vue.prototype.ctx = config.API_EDU_ROOT
    Vue.prototype.publicPath = config.PUBLIC_PATH
    Vue.prototype.mobilePortalPath = config.MOBILE_PORTAL_PATH
    Vue.prototype.mmobilePortalPath = config.M_MOBILE_PORTAL_PATH
    Vue.prototype.BIZ_DOMAIN = config.BIZ_DOMAIN
    Vue.prototype.WISDOM_PINXUEW = config.WISDOM_PINXUEW

    Vue.prototype.postByCode = postByCode
    Vue.prototype.openPostByCode = openPostByCode
    Vue.prototype.$postByCode = postByCodeWithHandle
    Vue.prototype.postByUrl = postByUrl
    Vue.prototype.postUrl = postUrl
    Vue.prototype.getByUrl = getByUrl

    Vue.prototype.tableConsts = {
      timeWidth: 160, //时间列宽
      mobileWidth: 120, //
      indexWidth: 50, //序号列宽
      userNameWidth: 100, //人名列宽
      loadingText: '拼命加载中...', //表格加载动画文字
    }
    Vue.prototype.dataPatterns = {
      mobile: /^1[3456789]\d{9}$/,
      phone: /^(1[3-9]\d{9}|\d{3,4}[\s|-][1-9]\d{6,7})$/,
      email: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
      url: /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/,
      password:
        /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]{6,20}$/,
      w: /^\w+$/, //标识符只能由字母/数字/下划线组成
      number: /^[1-9]*[1-9][0-9]*$/, //正整数
      numberAndEn: /^[A-Za-z0-9]+$/, //数字和字母
      numberAndPart: /^[1-9]\d*(\.\d+)?$/, //大于0的数字，包括小数
      userName: /^[\u4e00-\u9fa5_a-zA-Z0-9_]{2,15}$/ /*姓名*/,
      sno: /^.{2,20}$/ /*学生学号*/,
      identityNum:
        /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
    }
    Vue.prototype.dataPatternsText = {
      password: '字母，数字和符号的两种组合，6-20位',
    }

    /*引入全局组件*/
    /*表格分页*/
    Vue.component('TablePaginationContainer', TablePaginationContainer)
    /*新增页面*/
    Vue.component('PageAddContainer', PageAddContainer)
    /*表格页面*/
    Vue.component('PageTableContainer', PageTableContainer)
    /*其他页面*/
    Vue.component('PageContainer', PageContainer)
    /*搜索框*/
    Vue.component('FilterContainer', FilterContainer)
    /*表格操作列*/
    Vue.component('TableOpContainer', TableOpContainer)
    /*面包屑*/
    Vue.component('Breadcrumb', Breadcrumb)
    /*表格分页*/
    Vue.component('PaginationWrap', PaginationWrap)
    /*提示信息*/
    Vue.component('TipsMsg', TipsMsg)

    Vue.component('LoadingTips', LoadingTips)
    /** 富文本编辑器 */
    Vue.component('TinymceEditor', TinymceEditor)

    /*
		判断菜单权限,修改为实例的全局方法
		add by huzhen 2019-04-12 为子项目提供方法，主项目都需实现这个方法
		*/
    Vue.prototype.hasAuthority = function (authorityCode) {
      let curMenuList = this.$store.state.layout.curMenuList
      if (!curMenuList || !curMenuList.length) {
        return false
      }
      let flag = false
      for (let i = 0; i < curMenuList.length; i++) {
        let m = curMenuList[i]
        let menuFuncDtos = m.menuFuncDtos
        if (!menuFuncDtos || !menuFuncDtos.length) {
          continue
        }
        let funcCodes = menuFuncDtos.map((item) => {
          return item.funcCode
        })
        if (funcCodes.indexOf(authorityCode) !== -1) {
          flag = true
          break
        }
      }
      return flag
    }

    /*
		新 判断菜单权限,修改为实例的全局方法 管理员功能默认老师没配置则无
		rbacType:4 老师功能默认老师没配置则无
		*/
    Vue.prototype.verifyRbac = verifyRbac
  }
}

