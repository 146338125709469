module.exports = {
  dest: 'JZ_PRODUCT',
  dest_webserver: 'JZ_PRODUCT',
  /***
   * 日志目录
   */
  // 本地 无需配置 logPath:"../../logs",
  /*
  静态资源路径---企业号端
  cdn:statictest.woniukid.cn
  */
  staticsDomain: '//static.woniukid.cn', //'//localhost:8081',//"statictest.woniukid.cn",
  /**
   * 企业号部署地址
   */
  wxcodeDomain: 'm.woniukid.cn', //"csm.52ess.cn",

  vueDistPath: '../../wxeduserver/vue-dist/',
  //埋点数据地址
  sensorsDomain: 'https://dmp.etonedu.cn',
  /**
   * 移动端部署地址
   */
  mWxcodeDomain: "m.woniukid.cn",
  tencentyunConfig: {
    bucket: 'erc-1251444123',
    region: 'ap-guangzhou',
    secretId: 'AKIDe7QSEl23nHTP4SalkUyDCvObZHjvxjGX',
    secretKey: '2pMcbJkunQntiOr53mFTR9bnjQr2xc1A',
    allowPrefix: 'txoss/'
  }
}
