/*
 * @Author: liuzhihao
 * @Date: 2021-06-18 17:10:30
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-09-21 15:10:51
 */

import loadScript from 'load-script'
import { isMicroApp, startQiankun } from './qiankun'
import router from '../router'
import Layout from '@/layout/Layout'

/** 主项目 **/
// 业务线接入处理
const handle = async (to, from, next) => {
  let path = to.path || ''
  let [, serviceName] = path.split('/')
  let config = serviceLineConfig
  let cfg = config[serviceName]

  // qiankun子应用
  if (isMicroApp(serviceName)) {
    router.addRoute({
      path: `/${serviceName}/*`,
      component: Layout
    })
    startQiankun()
    return Promise.resolve(true)
  }

  //非业务线路由
  return new Promise((resolve, reject) => {
    if (!cfg) {
      resolve(false)
    }
    if (cfg.loadnum) {
      cfg.loadnum = cfg.loadnum + 1
    } else {
      cfg.loadnum = 1
    }
    if (cfg.loadnum > 3) {
      resolve(false)
    }
    if (!cfg) {
      resolve(false)
    }
    if (cfg.err) {
      // 报错，异常处理
      resolve(false)
    }
    // 该业务线已经加载,则返回处理次数
    // if(cfg.loaded===true) {
    //   resolve(true);
    // }
    for (var i = 0; i < cfg.src.length; i++) {
      var loadedFn = (function(i) {
        return function(err) {
          if (err) {
            cfg.err = err
            resolve(false)
            return
          }

          if (i == cfg.src.length - 1) {
            cfg.loaded = true
            resolve(true)
          }
        }
      })(i)
      let url = cfg.src[i]
      if (url.indexOf('//') === -1) {
        url = '//' + gweb.staticsDomain + '' + url
      }
      loadScript(url, { async: false }, loadedFn)
    }
  })
}
export default handle
