<template>
<div>
	<div  class="submenu-container">
		<!-- <el-menu mode="horizontal"  :default-active="secondCheckMenuIndex" class="submenu-box">
  			<el-menu-item class="submenu-item" @click="handleClick(menu)" :key="menu.id" :index="'submenu_'+index" v-for="(menu,index) in curMenu.childList">{{menu.menu_name}}</el-menu-item>
  		</el-menu> -->
        <template v-if="secondCurMenu&&secondCurMenu.menuDtos">
            <ul class="el-menu submenu-box el-menu--horizontal">
                <!-- :class="{'is-active':secondCurMenu.menuId==menu.menuId}"  -->
                <li 
                    v-for="(menu,index) in secondCurMenu.menuDtos" 
                    :class="{'is-active':lastCurMenu.menuId==menu.menuId}"
                    :key="index" 
                    class="el-menu-item submenu-item " @click="handleMenuRedirect(menu)">
                    {{menu.menuName}}
                </li>
            </ul>
            <xycloud-redirect v-model="redirectNum"></xycloud-redirect>
        </template>
        <template v-else-if="lastCurMenu">
            <ul class="el-menu submenu-box el-menu--horizontal">
                <li :class="{'is-active':true}"
                    class="el-menu-item submenu-item " @click="handleMenuRedirect(lastCurMenu)">
                    {{lastCurMenu.menuName}}
                </li>
            </ul>
        </template>
	</div>
</div>
</template>
<script type="text/javascript">
    import {mapState} from 'vuex';
    import XycloudRedirect from './sub/XycloudRedirect';
    import MenuMixin from '@/mixins/MenuMixin';
	export default{
        components:{
            XycloudRedirect
        },
        data(){
            return {
                redirectNum:0
            }
        },
        mixins:[MenuMixin],
        watch:{
         
        },
		computed:{
            secondCurMenu(){
                let curMenuList=this.curMenuList;
                
                if(curMenuList&&curMenuList.length>=3){
                    let len=curMenuList.length;
                    return curMenuList[len-2];
                }
            },
            parentCurMenu(){
                let curMenuList=this.curMenuList;
                if(curMenuList&&curMenuList.length>=2){
                    let len=curMenuList.length;
                    return curMenuList[len-2];
                }
            },
            curMenuList(){
                return this.$store.state.layout.curMenuList;
            },
            lastCurMenu(){
                let curMenuList=this.curMenuList;
                if(!curMenuList||curMenuList.length===0){
                    return;
                }
                let len=curMenuList.length;
                return curMenuList[len-1];
            }
			// authMenus(){
            //   let curMenuList=this.$store.state.layout.curMenuList;
            //   return curMenuList;
            // },
            // secondCurMenu(){
            //     let authMenus=this.authMenus;
            // 	if(!authMenus||authMenus.length<=1){
            // 		return;
            //     }
            //     return authMenus[1];
            // },
            // curMenu(){
            //     // 一级菜单 --选中
            // 	let path=this.$route.path;
            // 	let authMenus=this.authMenus;
            // 	if(!authMenus){
            // 		return;
            // 	}
            // 	// let cur=authMenus.find((item)=>{
            // 	// 	let index=path.indexOf(item.menu_url_state);
            // 	// 	if(index===0){
            // 	// 		return true;
            // 	// 	}
            // 	// 	return false;
            // 	// });
            // 	return authMenus[0];
            // }
		},
        methods:{
            handleClick(menu){
                if(menu.menu_code=='SCHOOLMANAGER_XYCLOUD_REDIRECT'){
                    this.redirectNum++;
                    return;
                }
                this.$router.push({path:menu.menu_url_state});
            }
        }
	}
</script>
<style type="text/css" lang="less" scoped >
    .submenu-container{
        padding-top: 5px;
        padding-left: 30px;
        border-bottom: solid 1px #e6e6e6;
        overflow: hidden;
        background: #fff;
        box-sizing: border-box;
        .el-menu.el-menu--horizontal{
            border-bottom:none;
        }
    }
    .submenu-box{
        border-bottom: none;
    }
    .el-menu-item.submenu-item{
        line-height: 34px;
        height: 34px;
        color: #333;
    }
    .el-menu-item.submenu-item:hover{
        color: #409EFF;
    }

    .submenu-box.el-menu .el-menu-item.submenu-item.is-active{
        color:#409EFF;
    }

    /*中小版*/
    .domain-middle .el-menu-item.submenu-item:hover{
        color: #14cf82;
    }

    .domain-middle .submenu-box.el-menu .el-menu-item.submenu-item.is-active{
        color:#14cf82;
    }
    .domain-middle .el-menu--horizontal > .el-menu-item.is-active,
    .domain-middle .el-menu--horizontal > .el-submenu.is-active .el-submenu__title{
        border-bottom: 2px solid #14cf82;
    }
</style>
