/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-06 14:37:51
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-02-06 15:45:45
 * @Description: 侧边二级菜单
 */

<template>
  <div v-if="siderBarMenu">
    <div v-for="menu of siderBarMenu"
         :key="menu.menuId">
      <el-submenu v-if="menu.menuDtos && menu.menuDtos.length > 0"
                  :index="menu.menuIndexKey">
        <template slot="title">
          <span>
            <MenuIcon v-if="menu.level === 2" :iconClass="menu.icon || 'el-icon-folder-opened'"
                      class="siderBar_icon" />{{ menu.menuName }}</span>
        </template>

        <my-submenu :sider-bar-menu="menu.menuDtos"></my-submenu>
      </el-submenu>

      <el-menu-item v-else
                    :index="menu.menuIndexKey"
                    @click="handleMenuRedirect(menu)">
        <span slot="title">
          <MenuIcon v-if="menu.level === 2" :iconClass="menu.icon || 'el-icon-menu'"
                    class="siderBar_icon" />{{ menu.menuName }}</span>
      </el-menu-item>
    </div>
  </div>
</template>

<script>
import MenuIcon from './MenuIcon'
import MenuMixin from "@/mixins/MenuMixin";
export default {
  name: 'my-submenu',
  props: {
    siderBarMenu: {
      type: Array,
      require: true
    },
  },
  components: {
    MenuIcon
  },
  mixins: [MenuMixin],
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
}
</script>
<style lang='scss' scoped>

</style>