<template>
	<div class="tips-msg " :class="{'warning':type=='warning'}">
		<div class="tips-label" v-if="title">{{title}}：</div>
		<div class="tips-content" v-html="resultContent" >
		</div>
		<div><slot></slot></div>
	</div>
</template>
<style type="text/css" scoped >
	.tips-msg{
		display: table-row;
		font-size: 12px;
		color: #333;
		line-height: 1.6;
	}
	.tips-msg.warning{
		color: #eb6100;
	}
	.tips-label{
		display: table-cell;
	}
	.tips-content{
		display: table-cell;
	}
</style>
<script type="text/javascript">
	export default{
		props:{
			title:{},
			content:{},
			datas:{},
			type:{}
		},
		computed:{
			resultContent(){
				var content=this.content;
				var datas=this.datas;
				if(!datas){
					return content;
				}
				for(var item in datas){
					content=content.replace("{{"+item+"}}",datas[item]);
				}
				return content;
			}
		}

	}
</script>