import { log } from "util";
import store from '@/store/index'
/*
菜单逻辑处理
add by huzhen 2018-08-29
*/

/*
初始化菜单数据，递归
level 层级设置 从1开始 依次往下级增加 
menuIndexKey  用于menu组件的index和key
*/
function initMenuData (menuDtos, level, parentMenu) {
	if (!menuDtos || !menuDtos.length) return menuDtos;

	if (!level) level = 1

	let resultMenuDtos = menuDtos.map((menu) => {
		menu.level = level;
		if (parentMenu && parentMenu.menuIndexKey) {
			menu.menuIndexKey = parentMenu.menuIndexKey + "#" + String(menu.menuId);
		} else {
			menu.menuIndexKey = String(menu.menuId);
		}
		/*存在子节点 则递归设置*/
		let subMenuDtos = menu.menuDtos;
		if (subMenuDtos && subMenuDtos.length) {
			menu.menuDtos = initMenuData(subMenuDtos, level + 1, menu);
		}
		return menu;
	});
	return resultMenuDtos;
}
/*
查找路由对应的菜单列表数据,递归
add by huzhen 2018-08-29
*/
function findLeafTargetMenu (menuDtos, routePath) {
	let menuDatas = menuDtos;//getMenuDataList(menuDtos);
	if (!menuDatas || !menuDatas.length) return null;

	for (let i = 0; i < menuDatas.length; i++) {
		let menu = menuDatas[i];

		let menuUrl = menu.menuUrl;
		let menuDtos = menu.menuDtos;
		/*递归查找节点 节点为菜单 并且 如果当前节点相等  则返回目标数据，如果不是则继续查找它的子节点，找到则返回*/
		if (menu.funcType == 1 && isTargetMenu(menuUrl, routePath)) {
			return menu;
		} else if (menuDtos && menuDtos.length > 0) {
			let resultMenu = findLeafTargetMenu(menuDtos, routePath);
			if (resultMenu) {
				return resultMenu;
			}
		}
	};
}
/*
查找所有选中的节点，从第一层级节点开始依次返回
add by huzhen 2018-08-29
*/
function findCurMenuList (menuDtos, routePath) {
	/*找出根节点*/
	let leafTargetMenu = findLeafTargetMenu(menuDtos, routePath);
	if (!leafTargetMenu) {
		// 未查找到匹配的子节点，则根据录入的路径去匹配
		return findFirstLevelCurMenu(menuDtos, routePath);
	}
	let level = leafTargetMenu.level;
	if (level <= 1) return [leafTargetMenu];

	let curMenuList = [];
	let menuIndexKey = leafTargetMenu.menuIndexKey;

	let indexArr = menuIndexKey.split("#");
	for (let curLevel = 1; curLevel <= level; curLevel++) {
		let menuList = null;
		if (curLevel == 1) {
			menuList = menuDtos;
		} else {
			menuList = curMenuList[curLevel - 1 - 1].menuDtos;
		}
		/*计算出当前节点的 indexKey */
		let curIndexArr = indexArr.filter((item, index) => {
			return index < curLevel;
		});
		let targetMenu = getTargetMenuByIndexKey(menuList, curIndexArr.join("#"));

		curMenuList.push(targetMenu);
	}
	return curMenuList;
}
/**
 *查找第一层节点匹配的路径 
 */
function findFirstLevelCurMenu (menuDtos, routePath) {
	if (!menuDtos || !menuDtos.length) return null
	let curMenu = menuDtos.find((menu) => {
		let menuUrl = menu.menuUrl;
		return menuUrl && menuUrl.length > 3 && routePath.indexOf(menuUrl) === 0;
	});
	if (curMenu) {
		return [curMenu];
	} else {
		return null
	}
}
/*
查找菜单列表中 menuIndexKey相等的菜单
*/
function getTargetMenuByIndexKey (menuList, indexKey) {
	if (!menuList || !menuList.length) {
		return menuList;
	}
	let cur = null;
	for (let i = 0; i < menuList.length; i++) {
		let m = menuList[i];
		if (m.menuIndexKey == indexKey) {
			cur = m;
			break;
		}
	}
	return cur;
}
/**
 * 查找目标菜单的第一个可跳转的节点
 * 点击菜单 查找它下级的子菜单
 * @param {*} menu 
 */
function getFirstJumpMenu (menu) {
	let skipTag = menu.skipTag;
	if (skipTag) {
		return menu;
		// let skipParamArr=skipTag.split("|");
		// if(skipParamArr&&skipParamArr[0]=="redirect"){
		// 	/*跳转模式*/
		// 	this.forwardNum++;
		// 	this.skipParamArr=skipParamArr;
		// 	//跳转
		// 	MenuRedirectService.forwardRedirect(skipParamArr);
		// 	return;
		// }
	}
	let subMenuList = getMenuDataList(menu.menuDtos);
	if (subMenuList && subMenuList.length > 0) {
		// 查找二级节点
		let firstMenu = subMenuList[0];
		// 存在三级菜单节点 则取三级节点数据
		let thrMenuList = getMenuDataList(firstMenu.menuDtos);
		if (thrMenuList && thrMenuList.length > 0) {
			firstMenu = thrMenuList[0];
		}
		return firstMenu;
		//skipType 跳转类型：1：当前窗口打开  2：新窗口打开
		// if(firstMenu.skipType===2){
		// 	window.open("#"+firstMenu.menuUrl, '_blank');//新窗口打开
		// }else{
		// 	this.$router.push({path:firstMenu.menuUrl});
		// }
	} else {
		return menu;
	}
}
/**
 * @description 根据 menuCode 查找对应菜单
 */
const  findMenuCode = (menu,menuCode)=>{
	let curMenuCode = null;
	let findRecursion = (dto) => {
		for (let x = 0; x < dto.length; x++) {
			if (dto[x].menuCode === menuCode) {
				curMenuCode = dto[x]
				break;
			} else {
				if(dto[x].menuDtos)findRecursion(dto[x].menuDtos) 
			}
		}
		return curMenuCode;
	}
	return findRecursion(menu)
}


export default {
	findCurMenuList,
	findLeafTargetMenu,
	findMenuCode,
	initMenuData,
	getMenuDataList,
	getBtnDataList,
	getFirstJumpMenu,
	getNodeDataList,
	getInfoDataList,
	isTargetMenu
}

function getMenuDataList (menuList) {
	//查询所有是菜单的数据，包括虚拟节点---
	return getDataList(menuList, 1);
}

function getBtnDataList (menuList) {
	//查询所有按钮数据
	return getDataList(menuList, 2);
}

function getNodeDataList(menuList) {
	//查询所有虚拟节点
	return getDataList(menuList, 3);
}

function getInfoDataList(menuList) {
	//查询所有虚拟节点
	return getDataList(menuList, 4);
}

function getHashPath (url) {
	if (url && url.indexOf("#") == 0) {
		url = url.substring(1);
	}
	return url;
}

function getDataList (menuList, mode) {
	let resultList = [];
	if (menuList && menuList.length) {
		for (let i = 0; i < menuList.length; i++) {
			let obj = menuList[i];
			if (obj.funcType == mode) {
				resultList.push(obj);
			} else if (mode == -1 && obj.funcType == 0) {
				resultList.push(obj);
			}
		}
	}
	return resultList;
}


function getMenuUrl (menu) {
	let url = null;
	let menuState = menu.menu_url_state;
	if (menuState && menuState.indexOf("#") == 0) {
		url = menuState.substr(1);
	}
	return url;
}
/*
当前路由跟查询出来的菜单的数据中路由是否一致
modify by huzhen 2019-04-20
*/
function isTargetMenu (ourl1, ourl2) {
	//判断是否是这个菜单，两个路径对应的菜单是否一致//
	let url1 = getHashPath(ourl1);
	let url2 = getHashPath(ourl2);
	if (!url1 || !url2) {
		return false;
	}
	if (url1 == url2) {
		return true;
	}

	let reg = /^(\/[\w_]+\/[\w_]+\/[\w_]+)\/?.*/;
	let pathArr = url1.split("/");
	let pfv = (store.state.app.domainConfig || {}).pfv || 'web';
	if (pathArr && pathArr.length > 1 && ["index", "pevaluate", "wgw",].indexOf(pathArr[1]) != -1 || pfv == "wisdomPlatform") {
		/*这些子模块路由,匹配规则需取两级路由判断*/
		reg = /^(\/[\w_]+\/[\w_]+)\/?.*/;
	}

	let pathRegArr1 = reg.exec(url1);
	if (!pathRegArr1 || pathRegArr1.length < 2) {
		return false;
	}
	let pathRegArr2 = reg.exec(url2);
	if (!pathRegArr2 || pathRegArr2.length < 2) {
		return false;
	}
	if (pathRegArr1[1] == pathRegArr2[1]) {
		return true;
	}
	return false;
}
