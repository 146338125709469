<template>
  <section class="el-container wrapper is-vertical">
    <div class="top-container">
      <TopHeader />
      <TopMenu />
      <TabBar></TabBar>
    </div>
    <!-- <section
      v-if="loadEnd"
      class="el-container"
    >
      <div class="el-main content resize" id="right-content">
        <div style="max-width:1276px;margin:0 auto;">
            <router-view></router-view>
        </div>
      </div>
    </section> -->
       <!-- style="max-width:1276px;margin:0 auto;" -->
    <section
      v-if="loadEnd"
      class="el-container"
      style="width:1380px;margin:0 auto;"
    >
      <aside class="el-aside menu resize">
        <LeftMenu />
      </aside>
      <div class="el-main content" id="right-content">
        <div>
            <router-view></router-view>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import LayoutMixin from '@/mixins/LayoutMixin';
import TopHeader from 'components/skin_v3/TopHeader'
import TopMenu from 'components/skin_v3/TopMenu'
import LeftMenu from 'components/skin_v3/LeftMenu'
import TabBar from 'components/skin_v3/TabBar'
export default {
    components: {
        TopHeader,
        TopMenu,
        LeftMenu,
        TabBar
    },
    mixins:[LayoutMixin]
}
</script>

<style type="text/css" scoped>
	.content{
		background: #f8f8f8;
		padding:0;
        
	}
	.wrapper{
    min-height: 100%;
    background: #f8f8f8;
	}
	.resize{
		/* height:83.5vh; */
		height:calc(100vh - 116px);
		overflow-y:auto;
		overflow-x:hidden;
	}
</style>

