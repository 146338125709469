/*
 * @Author: chenhuiling
 * @Date: 2022-06-10 18:45:39
 * @LastEditors: chenhuiling
 * @LastEditTime: 2023-10-17 13:02:25
 * @FilePath: \phealthd:\yjt-projects\web\src\utils\utils.js
 * @Description: 头部注释
 */
const CryptoJS = require('crypto-js')
const CRYPTOJSKEY = 'JsNmKzx^zJ#QDdMh'
//解密方法
exports.Decrypt = function(word, key, iv) {
  key = key || CRYPTOJSKEY
  iv = iv || CRYPTOJSKEY

  key = CryptoJS.enc.Utf8.parse(key)
  iv = CryptoJS.enc.Utf8.parse(iv)

  let base64 = CryptoJS.enc.Base64.parse(word)

  let src = CryptoJS.enc.Base64.stringify(base64)

  // 解密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
  let decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

//加密方法
exports.Encrypt = function(word, key, iv) {
  key = key || CRYPTOJSKEY
  iv = iv || CRYPTOJSKEY

  key = CryptoJS.enc.Utf8.parse(key)
  iv = CryptoJS.enc.Utf8.parse(iv)

  let srcs = CryptoJS.enc.Utf8.parse(word)
  // 加密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  //返回base64
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}
// 获取地址栏参数
exports.getQueryString =function(name) { 
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
	var r = window.location.search.substr(1).match(reg); 
	if (r != null) return unescape(r[2]); return null; 
}