/*
 * @Author: liuzhihao
 * @Date: 2021-06-18 17:10:30
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-09-20 18:46:57
 * @FilePath: /web/src/mixins/LayoutMixin.js
 * @Description: 头部注释
 */
import { getCurMenuList } from "../router/menu.js"
import store from '@/store/index'
export default {
  components: {},
  data() {
    return {
      loadEnd: false,
    }
  },
  computed: {
    domainConfig() {
      return this.$store.state.app.domainConfig
    },
    curMenuList() {
      return this.$store.state.layout.curMenuList
    },
    homeMenuUrl() {
      return this.$store.state.layout.homeMenuUrl
    },
    spaceLoadEnd() {
      return this.$store.state.layout.spaceLoadEnd
    },
  },
  watch: {
    $route() {
      this.$store.commit('SET_CUR_MENUS', this.$route)
    }
  },
  mounted() {
    this.$store
      .dispatch('getLoginData', {
        route: this.$route,
        router: this.$router,
        domainConfig: this.domainConfig,
      })
      .then(() => {
        this.$nextTick(() => {
          let pfv = (store.state.app.domainConfig || {}).pfv || 'web';
          this.loadEnd = true
          this.$store.commit('SET_CUR_MENUS', this.$route)
          this.setTabBar()
          if (this.homeMenuUrl && this.homeMenuUrl.indexOf('/space2') !== -1) {
            this.$store.dispatch('getPortalUser')
          }

          // 课程资源中心解决同时打开多个页面 角色切换 匹配默认路由
          if (pfv == "wisdomPlatform") {
            getCurMenuList(this.$route, (error) => {
              if (error) {
                this.$router.push({ path: this.homeMenuUrl })
              }
            })
          }
        })
      })
  },
  methods: {
    setTabBar() {
      let curMenuList = this.curMenuList
      if (curMenuList && curMenuList.length > 0) {
        let len = curMenuList.length
        this.$store.commit('SAVE_MENU_HISTORY', curMenuList[len - 1])
      }
    }
  },
}
