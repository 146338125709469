<!--
 * @Author: liuzhihao
 * @Date: 2022-10-19 17:04:17
 * @LastEditors: liuzhihao
 * @LastEditTime: 2023-02-15 10:05:44
 * @FilePath: \web\src\layout\LayoutSpace2\components\HeaderSubMenu.vue
 * @Description: 头部注释
-->
<template>
  <div class="navMenu"
       style="background: #4b87ff">
    <el-dropdown class="navMenu_left flex-middle"
                 placement="bottom-start"
                 @command="commandHomeMenu">
      <div class="el-dropdown-link"
           @click="$router.push('/space2')">
        <i class="el-icon-s-home el-icon--left"></i>首页{{ menuName }}
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="menu of menuList"
                          :key="menu.menuId"
                          :command="menu"
                          class="flex-middle">
          <MenuIcon :iconClass="menu.icon" />{{ menu.menuName }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-menu v-if="subMenuList.length"
             class="navMenu_menu"
             :default-active="activeIndex"
             mode="horizontal"
             text-color="#fff"
             active-text-color="#FAFBFF"
             background-color="#4B87FF"
             @select="handleSelect">
      <el-menu-item v-for="(item, index) of subMenuList"
                    :key="item.menuId"
                    :index="index + ''">{{ item.menuName }}</el-menu-item>
    </el-menu>

    <el-dropdown
      class="navMenu_left flex-middle"
      trigger="click"
      :disabled="!(schoolList && schoolList.length)"
      @command="changeSchool"
    >
      <div class="el-dropdown-link flex-middle">
        <img
          v-if="school && school.slogo"
          class="logo"
          :src="school.slogo"
          alt=""
        />
        {{ school && school.schoolName }}
        <i
          v-if="schoolList && schoolList.length > 1"
          class="el-icon-arrow-down el-icon--right"
        ></i>
        <template v-if="schoolList && schoolList.length > 1">
          <el-dropdown-menu
            slot="dropdown"
            style="max-height: 480px; overflow: auto"
          >
            <el-dropdown-item
              v-for="(school, i) of schoolList"
              :key="school.schoolId"
              :divided="i !== 0"
              :command="{ school: school }"
              >{{ school.schoolName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </div>
    </el-dropdown>

    <!-- 切换角色 -->
    <RoleChange />
  </div>
</template>

<script>
import RoleChange from './RoleChange'
import SpaceMenuMixin from '@/mixins/SpaceMenuMixin'
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin';
import MenuIcon from '../MenuIcon'

export default {
  components: { RoleChange, MenuIcon },
  mixins: [SpaceMenuMixin,SwitchSchoolAndRoleMixin],
  props: {
    menuName: {
      type: String,
      require: true
    },
    activeIndex: {
      type: String,
      require: true
    },
    subMenuList: {
      type: Array,
      require: true
    }
  },
  methods: {
    handleSelect(menuIndex) {
      const menuUrl = this.getDefaultMenuUrl(this.subMenuList[menuIndex])
      if (menuUrl) this.redirectUrl(menuUrl)
    },
    commandHomeMenu(menu) {
      if (menu.menuUrl) {
        this.$router.push(menu.menuUrl)
        return
      }

      const menuUrl = this.getDefaultMenuUrl(menu)
      if (menuUrl) this.redirectUrl(menuUrl)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../index.css');
.navMenu_left {
  padding-left: 20px;
}
.navMenu_left .logo {
  width: 30px;
  margin-right: 10px;
}
</style>
