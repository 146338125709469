/*
 * @Author: liuzhihao
 * @Date: 2021-06-18 17:17:35
 * @LastEditors: chenhuiling
 * @LastEditTime: 2023-06-28 17:11:49
 * @FilePath: \pbasemgrd:\yjt-projects\web\src\api\common.js
 * @Description: 头部注释
 */
import fetch from '@/utils/fetch'
import requestPlatform from '@/utils/requestPlatform'

import { Encrypt,Decrypt } from '@/utils/utils'
import { Message } from 'element-ui'
let mconfig = require('../../../mconfig/static')

// 开发/测试环境取消加密
let isEncrypt = () => {
  let dest = mconfig.dest;
  let destList = ['LOCAL', 'YJT_DEV', 'YJT_TEST', 'JZ_TEST']
  return !destList.includes(dest)
}

let hasEncryptFlag = (url) => {
  // 排课系统算法接口url（本地代理地址+实际url地址）
  let arrangeScheduleDomain = [
    'https://course.etonedu.cn/schedule/',
    'https://course.etonedu.cn/test-schedule/', // 排课优化2.0算法接口 (废弃？
    'https://course.etonedu.cn/dev-schedule/', // 排课线上开发环境算法接口
    'https://course.etonedu.cn/adjustschedule/',
    'https://course.etonedu.cn/test-adjustschedule/',
    'https://course.etonedu.cn/moveschedule/',
    'https://course.etonedu.cn/moveadjustschedule/',
    'https://course.etonedu.cn/shanxiCourseStart/', // 陕西课后服务排课算法接口
    'https://course.etonedu.cn/shanxiCourseStartTest/', // 陕西课后服务排课算法接口(开发+测试)
    'http://khfw.snedu.com/shanxiCourse/shanxiCourseStart/', // 陕西课后服务排课算法接口
    'https://khfw.snedu.com/shanxiCourse/shanxiCourseStart/', // 陕西课后服务排课算法接口
    '//jjjc.zxxs.moe.edu.cn/sxkhfw/shanxiCourse/shanxiCourseStart/', // 陕西课后服务排课算法接口（旧 
    'https://test-web.430tgxt.com/shanxiCourse/shanxiCourseStart/', // 陕西430压测

  ]
  let arrangeSchedule = [
    'arrange',
    'ajust',
    'moveArrange',
    'moveAjust',
    'sx_arrange',
  ]
  let filterUrl = ['login']
  filterUrl = filterUrl.concat(arrangeSchedule)
  let isArrangrScheduleDomain = arrangeScheduleDomain.findIndex((i) => {
    return i == url
  })
  if (isArrangrScheduleDomain > -1) {
    // 如果是排课算法,不需要加密
    return false
  } else {
    let [, splitUrl] = url.split('/')
    return !filterUrl.includes(splitUrl)
  }
}

export function wxSign(params) {
  return fetch({
    url: '/common/wxSign',
    method: 'post',
    data: params,
  })
}
export function postByCode(code, params) {
  params = params || {}
  params.CODE = code
  if (isEncrypt()) {
    params = { params: Encrypt(JSON.stringify(params)) }
  }

  return fetch({
    url: '/common/queryRemoteData?CODE=' + code,
    method: 'post',
    data: params,
  })
}

export function openPostByCode(code, params) {
  params = params || {}
  params.CODE = code
  if (isEncrypt()) {
    params = { params: Encrypt(JSON.stringify(params)) }
  }

  return fetch({
    url: '/common/openPostByCode?CODE=' + code,
    method: 'post',
    data: params,
  }).then(res=>{
    if(!(res && !res.data)){
      return res
    }
    let decryptRes = res;
    try {
      decryptRes =  JSON.parse( Decrypt(res));
    } catch (e) {}
    return decryptRes
  })
}

/** 统一处理错误的 postByCode 请求 */
export function postByCodeWithHandle(code, params) {
  return new Promise((resolve, reject) => {
    postByCode(code, params).then(res => {
      if (!res.status) {
        const isProd = process.env.NODE_ENV === 'production'
        // 生产环境报错不显示请求code
        const errMsg = (isProd ? '' : code + ': ') + (res.resmsg || '请求错误')
        Message({
          message: errMsg,
          type: 'error',
          duration: 5 * 1000
        })
        reject(res)
      }
      resolve(res.data)
    }).catch(err => {
      console.log('postByCodeWithHandle => ' + err)
      reject('postByCodeWithHandle => ' + err)
    })
  })
}
// 添加axios实例配置，例如timeout，progressFunc
export function postByCodeExtraConfig(code, params, extraconfig) {
  params = params || {}
  params.CODE = code
  if (isEncrypt()) {
    params = { params: Encrypt(JSON.stringify(params)) }
  }
  return fetch({
    url: '/common/queryRemoteData?CODE=' + code,
    method: 'post',
    data: params,
    ...extraconfig,
  })
}
export function postByUrl(url, params) {
  params = params || {}
  if (hasEncryptFlag(url)) {
    params = { params: Encrypt(JSON.stringify(params)) }
  }
  return fetch({
    url: url,
    method: 'post',
    data: params,
  }) /*.catch(()=>{
        console.log("errr");
        console.log(arguments);
        return;
    })*/
}
export function getByUrl(url, params) {
  params = params || {}
  return fetch({
    url: url,
    method: 'get',
    params: params,
  }) /*.catch(()=>{
        console.log("errr");
        console.log(arguments);
        return;
    })*/
}

export function getChlidCare() {
  return fetch({
    url: '/chlidcare/getChlidCare',
    method: 'get',
  })
}

export function sendSmsCode(params) {
  params = params || {}
  return fetch({
    url: '/school/getSmsCode',
    method: 'post',
    data: params,
  })
}

export function checkSmsCode(params) {
  params = params || {}
  return fetch({
    url: '/school/checkSmsCode',
    method: 'post',
    data: params,
  })
}
export function getUploaderUrl() {
  return fetch({
    url: '/common/getUploaderUrl',
    method: 'get',
  })
}

export function post(params) {
  params = params || {}
  return fetch({
    url: '/common/url',
    method: 'post',
    data: params,
  })
}

//postByUrl去除算法校验调用方法
export function postUrl(url, data) {
  return fetch({
    url,
    method: 'post',
    data,
  })
}

export function postByPortal(url, data = {}) {
  return requestPlatform({
    url,
    method: 'post',
    data: { data },
  })
}
