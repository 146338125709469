<!--
 * @Author: longtuxin
 * @LastEditors: liuzhihao
 * @LastEditTime: 2023-05-30 15:58:21
 * @FilePath: \web\src\components\global\TinymceEditor\index.vue
 * @Description: 富文本编辑器
-->
<template>
  <div
    class="tinymce-container"
    :style="{ width: isNaN(+width) ? width : width + 'px' }"
  >
    <tinymce-editor
      ref="tinymceEditor"
      :id="tinymceId"
      :value="content"
      :init="init"
      :witdh="width"
      :height="height"
      :resize="resize"
      :disabled="disabled"
      @input="onInput"
    />
    <p
      v-if="wordlimit"
      class="statusbar"
      :style="{ color: wordcount > wordlimit ? 'red' : '#666' }"
    >
      {{ wordcountTips }}
    </p>
  </div>
</template>

<script>
import tinymce from "@static/libs/tinymce/tinymce";
import TinymceEditor from "@static/libs/tinymce/@tinymce/tinymce-vue";
// 引入配置信息
import defaultConfig from "@static/libs/tinymce/config";
import axios from "axios";

export default {
  name: "Editor",
  components: { TinymceEditor },
  model: {
    prop: "content",
    event: "onInput",
  },
  props: {
    id: {
      type: String,
      default: function () {
        // 这个id一定要写，否则会出现莫名其妙的问题。
        return "tinymce-" + Date.now() + Math.floor(Math.random() * 1000);
      },
    },
    // 内容
    content: {
      type: String,
      default: "",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 宽度
    width: {
      type: [String, Number],
      default: "100%",
    },
    // 高度
    height: {
      type: [String, Number],
      default: 450,
    },
    // 是否允许拖动
    resize: {
      type: [String, Boolean],
      default: true,
    },
    // 菜单栏
    menubar: {
      type: String,
      default: "",
    },
    // 工具栏
    toolbar: {
      type: String,
      default: "",
    },
    // 字数限制
    wordlimit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      wordcount: 0,
      tinymceId: this.id,
      init: Object.assign(defaultConfig, {
        // 组件值覆盖默认配置
        width: this.width,
        height: this.height,
        resize: this.resize,
        statusbar: true,
        menubar: this.menubar || defaultConfig.menubar,
        toolbar: this.toolbar || defaultConfig.toolbar,
        ax_wordlimit_num: this.wordlimit || defaultConfig.ax_wordlimit_num,
        setup: this.editorSetup,
        ax_wordlimit_callback: (editor, txt, num) => {
          this.wordcount = txt.length;
          const isLt = this.wordcount <= this.wordlimit;
          this.$emit("wordcount", isLt, txt, num);
        },
        // 自定义图片上传
        images_upload_handler: this.imagesUpload
          ? this.imagesUpload
          : this.handleImageUpload,
      }),
    };
  },
  computed: {
    uploadUrl() {
      return this.$store.state.user.uploadUrl || "";
    },
    wordcountTips() {
      const isLt = this.wordcount <= this.wordlimit;
      const tips = this.wordcount + " / " + this.wordlimit;
      return isLt ? tips : "字数超出限制 " + tips;
    },
  },
  mounted() {
    setTimeout(() => {
      // 初始化时回显字数
      this.getWordcount();
    }, 500);
  },
  methods: {
    /**
     * 解决与element UI弹窗的冲突：
     * 在编辑器初始化阶段向整个编辑器添加Mousemove监听事件，通过获取根节点最高层数赋给编辑器和弹窗层；
     * 编辑区阻止事件冒泡，所以会出现当鼠标停留在编辑区中，无法显示菜单。
     * 这种选择弹层方式，需要在tinymce/themes/silver/theme.min.js中的弹窗class名（tox-tinymce-aux）处加上实例ID，如下图中的 d.id；
     * 监听多个事件是为了尽可能规避，无法显示菜单和弹窗的问题；
     */
    editorSetup(editor) {
      function overlay(aux) {
        return function (e) {
          let index = 1;
          document.body.childNodes.forEach(function (element) {
            if (element.style && element.style.zIndex > index) {
              index = parseInt(element.style.zIndex);
            }
          });
          if ((parseFloat(aux.style.zIndex) || 0) <= index) {
            e.target.style.zIndex = aux.style.zIndex = index + 1;
          }
        };
      }
      editor.on("init", function (e) {
        const aux = document.querySelector("." + e.target.id);
        e.target.editorContainer.addEventListener(
          "mouseenter",
          overlay(aux),
          false
        );
        e.target.editorContainer.addEventListener(
          "mousedown",
          overlay(aux),
          false
        );
      });
    },
    async handleImageUpload(blobInfo, success, failure) {
      const maxSize = 2;
      const blob = blobInfo.blob();
      if (blob.size / 1024 / 1024 > maxSize) {
        failure("图片大小不能超过" + maxSize + "MB");
        return;
      }
      await this.$store.dispatch("getUploadUrl"); //获取服务器文件上传地址

      const formData = new FormData();
      formData.append("file", blob, blobInfo.fileName);

      this.uploadImage(formData)
        .then((res) => {
          success(res.data.fileUrl);
        })
        .catch(() => {
          failure("文件上传失败，请重试");
        });
    },
    uploadImage(formdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: this.uploadUrl,
          method: "post",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          if (res.status) {
            resolve(res);
          } else {
            reject("上传失败");
          }
        });
      });
    },
    onInput(content) {
      this.getWordcount();
      this.$emit("onInput", content);
    },
    // 获取字数，兼容粘贴时没有及时更新字数 TODO: 输入时频繁获取，待优化
    async getWordcount() {
      if (!this.$refs.tinymceEditor) return;
      const wordcount = this.$refs.tinymceEditor.editor.plugins.wordcount;
      wordcount && (this.wordcount = wordcount.body.getCharacterCount());
    },
  },
};
</script>
<style scoped>
.tinymce-container {
  font-size: 14px;
  position: relative;
}
.statusbar {
  position: absolute;
  bottom: 1px;
  font-size: 12px;
  text-align: right;
  width: calc(100% - 20px);
  line-height: 16px;
  right: 16px;
  background: #fff;
  z-index: 9999;
}
</style>