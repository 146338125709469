/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-04-07 13:53:03
 * @Last Modified by: caiyf
 * @Last Modified time: 2023-06-26 19:26:08
 * @Description: 学校列表
 */

<template>
  <div class="school_list">
    <el-dropdown
      class="school_list_box"
      :disabled="!(schoolList && schoolList.length > 1)"
      @command="changeSchool"
    >
      <div
        class="el-dropdown-link flex-middle school-select"
        :class="{ type: type == 2 }"
        :style="{ color: _textColor, 'font-size': textSize || '20px' }"
      >
        <SchoolLogo
          :slogo="(school && school.slogo) || schoolLogoIcon"
          class="logo"
        />
        <span class="school_name">{{ school && school.schoolName }}</span>
        <svg-icon
          v-if="schoolList && schoolList.length > 1"
          class="school-select__icon"
          :style="{ color: _textColor }"
          icon-class="arrow-down"
        />
        <el-dropdown-menu
            slot="dropdown"
            style="max-height: 480px; overflow: auto; padding: 6px 0 !important"
          >
            <el-dropdown-item
              v-for="(school, i) of showSchoolList"
              :key="school.schoolId"
              :divided="i !== 0"
              :command="{ school: school }"
              >
                <div style="display: flex; align-items: center">
                  <SchoolLogo :slogo="school.slogo" style="width: 30px; height: 30px; display: flex" />
                  <span style="margin-left: 12px">
                    {{ school.schoolName }}
                  </span>
                </div>
              </el-dropdown-item
            >
            <el-dropdown-item
              v-if="schoolList && schoolList.length > 6"
              divided
              :style="{
                color: '#2d55eb',
                'font-style': 'italic'
              }"
              :command="{ more: true }"
              >更多学校...</el-dropdown-item
            >
          </el-dropdown-menu>
      </div>
    </el-dropdown>
    <el-dialog
      :visible.sync="dialogVisiable"
      title="选择要切换的学校"
      class="school_list_dialog"
      width="960px"
      @close="dialogVisiable = false"
    >
      <div class="search">
        <el-input
          v-model="schoolName" 
          placeholder="搜索学校" 
          class="search_name"
          suffix-icon="el-icon-search"
          clearable
        ></el-input>
      </div>
      <ul class="list">
        <li
          v-for="school, i of searchSchoolList"
          :key="i"
          class="list_item"
          @click="changeSchool({ school })"
        >
          <SchoolLogo :slogo="school.slogo" class="list_item_logo" />
          <span class="list_item_name">{{ school.schoolName }}</span>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin';
import SchoolLogo from '@/components/skin02/sub/SchoolLogo'
export default {
  props: {
    type: {
      type: [Number, String],
      default: 1
    },
    textColor: String,
    textSize: String
  },
  mixins: [SwitchSchoolAndRoleMixin],
  components: {
    SchoolLogo
  },
  data() {
    return {
      schoolName: ''
    };
  },
  mounted(){
    
  },
  computed: {
    theme() {
      return this.$store.state.theme.theme || {}
    },
    _textColor() {
      return this.textColor || this.theme.menuTextColor || '#252526'
    },
    // 下拉显示的学校列表 - 超多六个显示更多选项
    showSchoolList() {
      let schoolList = this.schoolList || [];
      if (schoolList.length <= 6) return schoolList
      return schoolList.slice(0, 6) 
    },
    // 弹窗里边的学校列表列表
    searchSchoolList() {
      let schoolList = this.schoolList
      if (!schoolList || !this.schoolName) return schoolList
      return schoolList.filter(sch => {
        return sch.schoolName.indexOf(this.schoolName) !== -1
      })
    },

    schoolLogoIcon() {
      return require('@/assets/images/school_logo.png')
    }
  }
};
</script>
<style lang='less' scoped>
.school_list {
  width: 100%;
  height: 100%;
  padding-left: 24px;
  &_box {
    width: 100%;
    height: 100%;
    line-height: 64px;
    .logo {
      display: flex;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 10px;
    }
    .el-dropdown-link {
      &.type {
        padding-right: 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
      }
      .school_name {
        max-width: calc(100% - 74px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .school-select__icon {
      font-size: 12px;
      margin-left: 8px;
      transition: all 0.1s ease-in-out;
    }
    &:hover {
      .school-select__icon {
        transform: rotate(180deg);
      }
    }
  }
  &_dialog {
    /deep/ .el-dialog {
      overflow: hidden;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }
    /deep/ .el-dialog__header {
      height: 56px;
      box-sizing: border-box;
      padding: 16px 24px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #252526;
    }
    /deep/ .el-dialog__body {
      padding: 0 24px 24px;
      max-height: 376px;
      overflow-y: auto;
    }
    /deep/ .el-dialog__close {
      color: #898A8C;
    }
    .list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: -24px;
      &_item {
        &:hover {
          .list_item_name {
            color: #2D55EB;
          }
          border: 1px solid #2D55EB;
          background: #EAEEFE;
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04), 0px 0px 16px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.04);
          cursor: pointer;
        }
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 16px;
        background: #FFFFFF;
        border: 1px solid #EBECF0;
        border-radius: 12px;
        width: calc(33.33% - 24px);
        height: 72px;
        margin: 24px 0 0 24px;
        &_logo {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          flex-shrink: 0;
        }
        &_name {
          flex: 1;
          margin-left: 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .search {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 24px;
      &_name {
        width: 288px;
      }
    }
  }
}
</style>