import { propsColumns, eventColumns } from '../consts'
export default {
  componentFunctionDesc: '通过传递配置项来实现搜索表单，搭配baseTable使用，路径：edumgr/components/base/BaseQueryForm',
  props: {
    columns: propsColumns,
    list: [
      { name: 'form', desc: '搜索表单配置项 - 配置选项，具体见下表form', type: 'Array' },
      { name: 'formOption', desc: 'el-form配置项', type: 'Object', default: JSON.stringify({
        size: 'small'
      }) }
    ]
  },
  form: {
    columns: eventColumns,
    list: [
      { name: 'type', desc: '目前支持的类型input、select、cascader、date' },
      { name: 'model', desc: '表单组件双向绑定的属性' },
      { name: 'label', desc: '对应el-form-item的label属性' },
      { name: 'props', desc: '表单的配置项，对应elementUI表单的配置项，其中label和value字段可设置选择器的指定选项的值为选项对象的某个属性值' },
      { name: 'options', desc: '选择器和级联选择器的列表' },
      { name: 'defaultValue', desc: '默认值' }
    ]
  },
  events: {
    columns: eventColumns,
    list: [
      { name: 'change', desc: '搜索回调（除了input之外，值改变则立即触发change事件），input需回车触发' }
    ]
  },
  slot: {
    columns: eventColumns,
    list: [
      { name: 'btn', desc: '作用域插槽，值为整个表单数据' }
    ]
  }
}
