<template>
  <div class="navMenu" ref="menu">
    <SchoolList class="schoolList"></SchoolList>

    <div style="flex: 1">
      <!-- 使用v-show，避免条件变化时会有一瞬间两个元素同时存在 -->
      <div class="menu-empty-item" v-show="!menuList.length">
        {{ loadEnd ? '菜单为空' : '加载中...' }}
      </div>
      <el-menu
        v-show="menuList.length"
        class="navMenu_menu"
        ref="menuContainer"
        :default-active="activeIndex"
        mode="horizontal"
        text-color="#303133"
        :active-text-color="themeColor"
        background-color="#fff"
        collapse
        @select="handleSelect"
      >
        <span
          v-for="(item, index) of menuList.slice(0, showMenuNum)"
          :key="item.menuId"
        >
          <!-- 多级菜单 -->
          <el-submenu
            v-if="item.menuDtos && item.menuDtos.length"
            class="menu_submenu"
            popper-class="layout-space_submenu"
            :index="index + ''"
          >
            <template slot="title">
              {{ item.menuName }}
            </template>
            <el-menu-item
              v-for="(subMenu, i) of item.menuDtos"
              :key="subMenu.menuId"
              :index="`${index}-${i}`"
              >{{ subMenu.menuName }}</el-menu-item
            >
          </el-submenu>

          <!-- 单级菜单 -->
          <el-menu-item
            v-else
            :index="index+''"
            :route="{ path: '/web/view' }"
          >
            {{ item.menuName }}
          </el-menu-item>
        </span>

        <!-- 超出showMenuNum个菜单用 - 更多 - 显示 -->
        <el-submenu
          v-if="menuList.length > showMenuNum"
          class="menu_submenu"
          popper-class="layout-space_submenu"
          index="collapse"
        >
          <template slot="title">更多</template>
          <span
            v-for="(item, idx1) in menuList.slice(showMenuNum)"
            :key="item.menuId"
          >
            <el-menu-item
              v-if="!item.menuDtos"
              :index="`${idx1 + showMenuNum}`"
              :route="{ path: '/web/view' }"
            >
              {{ item.menuName }}
            </el-menu-item>
            <el-submenu
              v-else
              class="menu_submenu"
              popper-class="layout-space_submenu"
              :index="`${idx1 + showMenuNum}`"
            >
              <template slot="title">
                {{ item.menuName }}
              </template>
              <el-menu-item
                v-for="(subMenu, idx2) of item.menuDtos"
                :key="subMenu.menuId"
                :index="`${idx1 + showMenuNum}-${idx2}`"
                >{{ subMenu.menuName }}</el-menu-item
              >
            </el-submenu>
          </span>
        </el-submenu>
      </el-menu>
    </div>

    <div ref="rightCont">
      <!-- 切换角色 -->
      <RoleChange />
    </div>
  </div>
</template>

<script>
import RoleChange from './RoleChange'
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin'
import SpaceMenuMixin from '@/mixins/SpaceMenuMixin'
import { mapState } from 'vuex'
import SchoolList from './SchoolList'

export default {
  components: { RoleChange, SchoolList },
  mixins: [SwitchSchoolAndRoleMixin, SpaceMenuMixin],
  props: {
    activeIndex: {
      type: String,
      require: true,
    },
    // 加载结束
    loadEnd: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      rightContWidth: 0, // 右边换肤、切换角色模块宽度
      showMenuNum: 5,
      observer: null, // 监听器
    }
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme.theme || {},
    }),
    textColor() {
      return this.theme.menuTextColor || '#fff'
    },
    themeColor() {
      return this.theme.themeColor
    },
    school() {
      return this.$store.state.user.school || {}
    },
  },
  watch: {
    menuList(val) {
      if (!val || !val.length) return
      // 菜单变化，更新菜单显示数量
      this.$nextTick(() => {
        if (!this.observer) {
          this.onResize()
        }
        this.updateShowNum()
      })
    },
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    handleSelect(key) {
      const indexArr = key.split('-').map((it) => {
        return parseInt(it)
      })

      const [menuIndex, subMenuIndex] = indexArr

      if (this.menuList[menuIndex].menuUrl) {
        this.redirectUrl(this.menuList[menuIndex])
        return
      }

      const subMenuList = this.menuList[menuIndex].menuDtos
      const menu =
        subMenuList && this.getDefaultMenuUrl(subMenuList[subMenuIndex])
      this.redirectUrl(menu)
    },

    // 获取菜单的宽度
    updateShowNum(width) {
      if (!width) {
        const containerDom = this.$refs.menuContainer && this.$refs.menuContainer.$el
        width = containerDom.offsetWidth
        if (!width) return
      }
      let count = 0
      for (let menu of this.menuList) {
        // 40是每个菜单的左右内边距和、菜单名每个字20px
        width = width - 40 - (menu.menuName ? menu.menuName.length * 20 : 0)
        // 80是更多项的宽度（包含左右内边距），如果是最后一个则判断宽度大于0既是不用放入更多项里边
        if (width > 80 || (width > 0 && this.menuList.length === count + 1)) {
          count++
        } else {
          break
        }
      }
      this.showMenuNum = count
    },
    onResize() {
      const containerDom = this.$refs.menuContainer && this.$refs.menuContainer.$el
      if (!containerDom) return
      // 监听容器宽度，修改表单每行数量
      this.observer = new ResizeObserver((entries) => {
        // TODO: 防抖
        const rowWidth = entries[0].contentRect.width
        this.updateShowNum(rowWidth)
      })
      this.observer.observe(containerDom)
    },
  },
}
</script>

<style lang="less" scoped>
@import url('../menu.less');
@nav-text-size: 20px;
.navMenu {
  position: relative;
  padding: 0 20px 0 0;
}
.navMenu_menu {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .menu-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}

.navMenu_right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: @nav-height;
}

.schoolList {
  max-width: 280px;
  min-width: 200px;
}

.menu-empty-item {
  height: @nav-height;
  line-height: @nav-height;
  font-size: @nav-text-size;
  color: #303133;
}
/deep/ .navMenu_menu {
  &.el-menu {
    // 菜单背景色改为透明
    background-color: transparent !important;
    font-size: @nav-text-size !important;
  }
  .el-menu-item {
    // 菜单背景色改为透明
    background-color: transparent !important;
    height: @nav-height;
    line-height: @nav-height;
    font-size: @nav-text-size;
    &.is-active {
      font-weight: 600;
    }
    &:not(.is-active) {
      color: #303133;
    }
    &:not(.is-disabled):hover {
      color: #2d55eb;
      font-weight: 600;
    }
  }
  .el-submenu .el-submenu__title {
    font-size: @nav-text-size !important;
    // 菜单元素背景色改为透明
    background-color: transparent !important;
    height: @nav-height;
    line-height: @nav-height;
    padding: 0 20px;
  }
  // 悬停时菜单样式
  .el-submenu.is-opened .el-submenu__title {
    background: rgba(255, 255, 255, 0.25) !important;
    color: #2d55eb !important;
    font-weight: 600;
  }
  // /deep/.el-menu--horizontal .el-icon-arrow-down {
  //   display: none;
  // }
}
</style>

<style lang="less">
.layout-space_submenu {
  .el-menu--popup-bottom-start {
    /* 下拉菜单距离顶部距离 */
    margin-top: 16px !important;
  }
  .el-menu {
    border-radius: 8px;
  }
  .el-menu-item,
  .el-submenu__title {
    font-size: 16px;
    height: 44px !important;
    line-height: 44px !important;
    border-radius: 4px;
  }
  .el-menu-item:not(.is-disabled):hover {
    background: #f3f4f5 !important;
  }
}
</style>
