<!--
 * @Author: liuzhihao
 * @Date: 2022-04-21 17:57:49
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-10-24 11:00:24
 * @FilePath: \web\src\components\skin01\sub\TopMenuIcon.vue
 * @Description: 头部注释
-->
<template>
  <div>
    <img v-if="isImgUrl"
         :src="iconClass" />
    <div v-else
         class="top-menu-icon"
         :class="iconClass"></div>
  </div>
</template>
<script>
export default {
  props: ['iconClass'],
  computed: {
    isImgUrl() {
      return this.iconClass && this.iconClass.indexOf('http') !== -1
    }
  }
}
</script>

<style scoped>
.top-menu-icon {
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
</style>

