import { propsColumns } from '../consts'
export default {
  componentFunctionDesc: '节流按钮，通过对el-button进行二次封装，在规定时间内或者在click时间结束前再次点击按钮无效',
  props: {
    columns: propsColumns,
    list: [
      { name: 'time', desc: '节流时间（在该时间内再次点击按钮无效）', type: 'Number String', default: '300ms' },
      { name: 'other', desc: '其他参数参考el-button' }
    ]
  }
}
