<template>
  <!-- 切换角色 -->
  <div v-if="user" class="role">
    <img
      v-if="user && user.avatarUrl"
      :src="user.avatarUrl"
      alt=""
      class="role_headerImg"
    />
    <div class="useravatrl_role" v-else>
      {{ user.userName.substr(-2, 2) }}
    </div>
    <div class="el-dropdown-link">
      <span v-if="userType === 2 && child" class="role_title">
        {{ child.childName }}
        <small
          >（{{ child.className }}）{{
            child.familyRole | clsRole(child.isJwh)
          }}</small
        >
      </span>

      <span v-else-if="userType === 1 && stuClass" class="role_title">
        <span class="roleusername"> {{ user.userName }}</span>
        <small>（{{ stuClass.className }}）</small>
      </span>

      <span v-else class="role_title">
        <span class="roleusername">{{ user.userName }}</span>
        <small>{{ userType | userType(school) }}</small>
      </span>
      <!-- <svg-icon style="font-size: 10px" :style="{ color: textColor }" icon-class="arrow-down" /> -->
    </div>
    <div class="roleswitcha">
      <el-dropdown
        class="navMenu_left flex-middle"
        trigger="hover" 
        :disabled="!(schoolList && schoolList.length)"
        @command="setSchool"
      >
        <div class="el-dropdown-link flex-middle">
          {{ school && school.schoolName }}
          <!-- <i v-if="schoolList && schoolList.length > 1" class="el-icon-arrow-down el-icon--right"></i> -->
          <img
            src="../../../assets/images/Vector.png"
            alt=""
            v-if="schoolList && schoolList.length > 1"
            class="el-icon-arrow-down el-icon--right"
          />
          <template v-if="schoolList && schoolList.length > 1">
            <el-dropdown-menu
              slot="dropdown"
              style="max-height: 480px; overflow: auto; z-index: 30000"
              class="school_menu dropdown-menu-style"
            >
              <el-dropdown-item
                v-for="(school, i) of schoolList"
                :key="school.schoolId"
                :divided="i !== 0"
                :command="{ school: school }"
                >{{ school.schoolName }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </div>
      </el-dropdown>
    </div>
    <div class="menu_bottom">
      <div class="menu_bottom_fir" v-if="roleTypeInfos.length > 1">
        <el-dropdown trigger="hover"  @command="changeUserRole">
          <div class="el-dropdown-link menu_bottom_switch">
            <img
              src="../../../assets/images/repeat.png"
              alt=""
              class="firdishide"
            />
            <img
              src="../../../assets/images/repeat_b.png"
              alt=""
              class="firhide"
            />
            <span>切换角色</span>
          </div>
          <el-dropdown-menu class="switch-dropdown dropdown-menu-style" slot="dropdown">
            <el-dropdown-item
              v-for="(role, index) in roleTypeInfos"
              :key="'role_' + index"
              :divided="index > 0"
              :command="{ school, role }"
            >
              <a href="javascript:;">
                <span v-if="role.userType !== 2 && role.userType !== 1">
                  {{ role.userType | userType(school) }}</span
                >

                <span v-else-if="role.userType === 2">
                  {{ role.childName }}
                  <small
                    >（{{ role.className }}）&nbsp;{{
                      role.familyRole | clsRole(role.isJwh)
                    }}</small
                  >
                </span>

                <span v-else-if="role.userType === 1">
                  <small>{{ role.className }}</small>
                </span>
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="menu_bottom_second" @click="changeRole({ op: 'exit' })">
        <img src="../../../assets/images/logout.png" alt="" class="disHide" />
        <img src="../../../assets/images/logoutr.png" alt="" class="Hide" />
        <span>退出登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from "@/mixins/SwitchSchoolAndRoleMixin";
import CONST_USER from "@/const/user";
import { mapState } from "vuex";

export default {
  components: {},
  mixins: [SwitchSchoolAndRoleMixin],
  data() {
    return {};
  },
  computed: {
    ...mapState({ theme: (state) => state.theme.theme || {} }),
    textColor() {
      return this.theme.menuTextColor || "#fff";
    },
    curRoleTypeInfos() {
      let curRoleArr = [8, 4]; // 过滤除了管理员/老师身份的角色
      if (this.roleTypeInfos instanceof Array) {
        return this.roleTypeInfos.filter((role) => {
          return curRoleArr.includes(role.userType);
        });
      } else {
        return [];
      }
    },
  },
  filters: {
    userType: function (ut, school) {
      if (ut == 4 && school && school.stype === 0) {
        return "职员";
      }
      return CONST_USER.userType[ut] || "管理员";
    },
    clsRole(familyRole, isJwh) {
      if (isJwh == 1) {
        return "家委";
      } else {
        return "家长";
      }
    },
  },
  methods: {
    setSchool(params) {
      this.$emit("roleChange");
      this.changeSchool(params);
    },

    changeUserRole(params) {
      // 修改角色成功后，触发change事件
      this.$emit("roleChange");
      this.changeRole({
        ...params,
        callback: () => {
          this.$emit("change");
        },
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
/deep/ .el-dropdown-menu__item--divided {
  border-top: none !important;
  margin: 0;
}

.role:hover {
  display: block;
}

.roleswitcha:hover {
  .role {
    display: block !important;
  }
}

.menu_bottom {
  border-top: 1px solid #f0f1f5;
  height: 62px;
  display: flex;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
  padding: 0 16px;
  justify-content: space-around;

  &_fir {
    flex: 1;

    .firhide {
      display: none;
    }
  }

  &_fir:hover {
    color: #2d55eb;

    .firhide {
      display: inline-block;
    }

    .firdishide {
      display: none;
    }
  }

  &_second {
    cursor: pointer;
  }

  &_second:hover {
    color: #f52f3e;

    .disHide {
      display: none;
    }

    .Hide {
      display: inline-block;
    }
  }

  .Hide {
    display: none;
  }
}

.navMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
}

.navMenu_left {
  min-width: 190px;
  margin-top: 8px;
  color: #575859;
  font-size: 16px;
}

.navMenu_left .el-dropdown-link {
  font-size: 18px;
  cursor: pointer;
  color: #575859;
  padding: 0;
}

.navMenu_menu {
  border: none !important;
  flex: 1;
  display: flex;
}

.flex-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navMenu_left {
  padding-bottom: 48px;
}

.navMenu_left .logo {
  width: 30px;
  margin-right: 10px;
}

.navMenu_menu {
  /* max-width: calc(100vw - 440px); */
  height: 100%;
  overflow: auto;
}

.el-icon--right {
  margin-left: 7px;
}

.navMenu_menu::-webkit-scrollbar {
  display: none;
}

.navMenu_menu .menu-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.navMenu_menu .navMenu_menu_item,
.navMenu_menu .menu_submenu /deep/ .el-submenu__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  line-height: normal;
}

/deep/.el-menu--horizontal > span > .el-menu-item {
  border-bottom: none !important;
  font-size: 16px !important;
}

/deep/.el-menu--horizontal > .el-menu-item {
  border-bottom: none !important;
  font-size: 16px !important;
}

/deep/.el-submenu .el-submenu__title {
  font-size: 16px !important;
}

/deep/.el-menu--horizontal .el-icon-arrow-down {
  display: none;
}

.role {
  min-width: 320px;
  background-color: #fff;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.04),
    0px 0px 24px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  background-image: url(~@/assets/images/switch-bg.png);
  z-index: 999;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 10px;
}

.role_headerImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin: 48px 110px 32px 110px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.04),
    0px 0px 24px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #ffffff;
}

.role_title small {
  margin-left: 8px;
  width: 48px;
  height: 20px;
  background: rgba(45, 85, 235, 0.1);
  border-radius: 4px;
  line-height: 20px;
  color: #2d55eb;
}

.role_title {
  display: flex;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.roleusername {
  font-weight: 600;
  font-size: 24px;
  color: #252526;
}

.switch-dropdown a {
  color: #252526;
}

.el-dropdown-link {
  cursor: pointer;
  white-space: nowrap;
}

.menu_bottom_switch:hover {
  color: #2d55eb;
}

.el-dropdown {
  display: block;
}

.switch-dropdown {
  text-align: center;
  font-size: 16px;
  color: #252526;
  background: #ffffff;
  border-radius: 8px;
  z-index: 30000 !important;
  left: 244px !important;
}

.school_menu {
  position: absolute !important;
  top: 329px !important;
  left: 252px !important;
  padding: 0 !important;
  border-radius: 8px;
  height: 320px;
}

/deep/ .el-dropdown-menu {
  padding: 0 !important;
}

.el-dropdown-menu__item {
  line-height: 53px;
}
</style>
<style lang='less'>
  /deep/ .el-dropdown-menu {
  padding: 0 !important;
  }

  .el-dropdown-menu {
    padding: 0 !important;
  }
  // 自定义dropdown-menu样式类名
  .dropdown-menu-style {
    .popper__arrow,
    .popper__arrow::after {
      border: none !important;
    }
  }

  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #f6f7f9;
    color: #2d55eb;
  }

  .el-dropdown-menu__item--divided {
    border-top: 1px solid #fff;
  }

  .el-dropdown-menu__item--divided:before {
    height: 0;
  }

  .useravatrl_role {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0px 4px 24px rgba(45, 85, 235, 0.04),
      0px 0px 16px rgba(45, 85, 235, 0.04), 0px 0px 4px rgba(45, 85, 235, 0.04);
    color: #ffffff;
    background: #2d55eb;
    text-align: center;
    line-height: 100px;
    font-weight: 600;
    font-size: 24px;
    display: inline-block;
    margin: 48px 110px 32px 110px;
    border: 1px solid #ffffff;
  }

</style>

