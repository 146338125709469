/*
 * @Author: chenhuiling
 * @Date: 2021-10-22 16:56:17
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-02-28 16:19:53
 * @FilePath: \web\src\i18n\index.js
 * @Description: 头部注释
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
// import zh from './lang/zh-CN'

// let zhCN = {}
// zh().then(r => {
//     console.log('r-------->', r)
//     zhCN = r
// }).catch(err => {
//     console.log('err-------->', err)
// })

const translate = async () => {
    return await store.dispatch('getTranslateTrain')
}
import zh_h from './lang/zh-CN';
import en_h from './lang/en';
Vue.use(VueI18n)
Vue.prototype.$loadLanguageAsync = translate;
let i18nObj = {
    locale: localStorage.getItem('locale') || 'zh',
    messages: {
        zh: {},
        en: {}
    }
}
// 中文配置
zh_h().then(obj=>{
    i18nObj.messages.zh = obj || {}
})
// 英文配置
en_h().then(obj=>{
    i18nObj.messages.en = obj || {}
})

const i18n = new VueI18n(i18nObj)
console.log('i18nObj',i18nObj)
export {
    i18n
}
