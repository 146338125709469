<template>
	<div>
		<div class="school-role-box">
			<div class="school-container"
			     v-if="school">
				<div class="school-name"
				     v-if="schoolList&&schoolList.length==1">
					<span class="school-title f-toe">{{school.schoolName}}</span>
				</div>
				<el-dropdown v-else
				             placement="top-start"
				             @command="changeSchool">
					<!-- 切换学校 -->
					<div class="el-dropdown-link school-name"
					     :class="{'sel-block':schoolList&&schoolList.length>1}">
						<span class="school-title f-toe">{{school.schoolName}}</span>
						<i class="el-icon-caret-bottom"></i>
					</div>
					<el-dropdown-menu class="switch-dropdown"
					                  slot="dropdown">
						<el-dropdown-item :divided="index>0?true:false"
						                  :command="{school:sch}"
						                  :class="{'is-active':sch.schoolId==school.schoolId}"
						                  v-for="(sch,index) in schoolList"
						                  v-if="index<8"
						                  :key="'schoolsel_'+sch.schoolId">
							<a class="f-toe"
							   href="javascript:;">
								<school-logo class="slogo"
								             :slogo="sch.slogo"></school-logo>{{sch.schoolName}}</a>
						</el-dropdown-item>
						<el-dropdown-item divided
						                  :command="{more:1}"
						                  v-if="schoolList&&schoolList.length>8">
							<a href="javascript:;"
							   class="more-school">更多学校...</a>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

			<div class="role-container">
				<div class="role-name "
				     v-if="roleTypeInfos&&roleTypeInfos.length<=1">
					<span class="role-title f-toe"
					      v-if="userType==2&&child">{{child.childName}}
						<small>（{{child.className}}）{{ clsRoleForamt(child.familyRole, child.isJwh) }}</small>
					</span>
					<span class="role-title f-toe"
					      v-else-if="userType==1&&stuClass">{{stuClass.className}}</span>
					<span class="role-title f-toe"
					      v-else>{{userType|userType}}</span>
				</div>
				<el-dropdown v-else
				             placement="top-start"
				             @command="changeRole">
					<!-- 切换角色 -->
					<div class="el-dropdown-link role-name sel-block">
						<span class="role-title f-toe"
						      v-if="userType==2&&child">{{child.childName}}
							<small>（{{child.className}}）{{ clsRoleForamt(child.familyRole, child.isJwh) }}</small>
						</span>
						<span class="role-title f-toe"
						      v-else-if="userType==1&&stuClass">{{stuClass.className}}</span>
						<span class="role-title f-toe"
						      v-else>{{userType|userType}}</span>
						<i class="el-icon-caret-bottom"></i>
					</div>
					<el-dropdown-menu class="switch-dropdown"
					                  slot="dropdown">
						<el-dropdown-item :command="{school:school,role:role}"
						                  v-for="(role,index) in roleTypeInfos"
						                  :key="'role_'+index">
							<a href="javascript:;"
							   class="f-toe">
								<span v-if="role.userType!=2&&role.userType!=1">{{role.userType|userType}}</span>
								<span v-else-if="role.userType==2">{{role.childName}}
									<small>（{{role.className}}）&nbsp;{{ clsRoleForamt(child.familyRole, child.isJwh) }}</small>
								</span>
								<span v-else-if="role.userType==1">
									<small>{{role.className}}</small>
								</span>
							</a>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<el-dialog title="选择要切换的学校"
		           class="center-dialog"
		           width="829px"
		           :visible.sync="dialogVisiable">
			<ul class="switch-school-list">
				<li v-for="sch in schoolList"
				    :key="'schoolsel_'+sch.schoolId">
					<a href="javascript:;"
					   :title="sch.schoolName"
					   class="f-toe"
					   @click="changeSchoolAndRole(sch.schoolId)">
						<school-logo class="slogo"
						             :slogo="sch.slogo"></school-logo>
						{{sch.schoolName}}
					</a>
				</li>
			</ul>
			<div slot="footer"></div>
		</el-dialog>
	</div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import { postByUrl } from '@/api/common'
import SchoolLogo from './SchoolLogo'
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin'
export default {
  components: {
    SchoolLogo
  },
  data: function() {
    return {
      dialogVisiable: false
    }
  },
  mixins: [SwitchSchoolAndRoleMixin],
  computed: {
    school() {
      return this.$store.state.user.school
    },
    schoolList() {
      return this.$store.state.user.schoolList
    },
    user() {
      return this.$store.state.user.user
    },
    userType() {
      return this.$store.state.user.userType
    },
    child() {
      return this.$store.state.user.child
    },
    domainConfig() {
      return this.$store.state.app.domainConfig
    },
    roleTypeInfos() {
      let school = this.school
      if (!school) {
        return null
      }
      let typeList = [32,8,4,256,64,16];
      let parentRoleDetails = school.parentRoleDetails
      let roleTypes = school.roleTypes
      let result = []

      typeList.forEach(item=>{
        if(roleTypes.includes(item)) {
          result.push({ userType: item })
        }
      })

      if (roleTypes.indexOf(1) != -1 && this.stuClassList) {
        for (let i = 0; i < this.stuClassList.length; i++) {
          let stuClass = this.stuClassList[i]
          stuClass.userType = 1
          result.push(stuClass)
        }
      }

      if (parentRoleDetails && parentRoleDetails.length) {
        for (let i = 0; i < parentRoleDetails.length; i++) {
          let child = parentRoleDetails[i]
          child.userType = 2
          result.push(child)
        }
      }
      return result
    }
  },
  mounted: function() {
    //this.getLoginInfo();
  },
  methods: {
    // changeSchool({school,more}){
    // 	/*切换学校*/
    // 	if(more){
    // 		this.openDialog();
    // 	}else if(school){
    // 		this.changeSchoolAndRole(school.schoolId);
    // 	}
    // },
    // changeRole({school,role}){
    // 	/*切换角色*/
    // 	this.changeSchoolAndRole(school.schoolId,role.userType,role);
    // },
    openDialog() {
      this.dialogVisiable = true
    },
    // changeSchoolAndRole(schoolId,userType,role){
    // 	this.dialogVisiable=false;
    // 	//this.$emit("change",schoolId,userType,role)
    // 	this.$store.dispatch("getLoginData",{route:this.$route,router:this.$router,schoolId,role,domainConfig:this.domainConfig});
    // 	//this.getLoginInfo(schoolId,role);
    // },
    getLoginInfo: function(schoolId, role) {
      var params = {}
      if (schoolId) {
        params.schoolId = schoolId
      }
      if (role) {
        params.userType = role.userType
      }
      if (role && role.userType == 2) {
        /*家长切换孩子*/
        params.child = role
      }
      if (role && role.userType == 1) {
        /*学生切换班级*/
        params.stuClass = role
      }
      let domainConfig = this.domainConfig
      if (domainConfig) {
        params.roleTypes = domainConfig.fitRoles
        params.phases = domainConfig.fitPhases
        params.dmid = domainConfig.id
        params.domain = domainConfig.domain
      }
      

      this.loading = true
      postByUrl('/login/getLoginInfo', params).then(json => {
        this.loading = false
        this.setData(json, params, schoolId)
      })
    },
    setData: function(json, params, schoolId) {
      if (json.status == false && !json.data) {
        if (
          json.rescode == 500 ||
          json.rescode == '40003' ||
          json.rescode == '50001'
        ) {
          this.$router.push({ path: '/login' })
          this.$dialog.error(json.resmsg)
        } else if (!schoolId) {
          this.$router.push({ path: '/login' })
        } else {
          this.$dialog.error(json.resmsg)
        }
        return
      }
      this.$store.dispatch('setAuthMenus', json.data.menuList)
      this.$store.dispatch('setLoginInfo', json)
      if (schoolId) {
        this.$router.push({ path: '/index' })
      }
    }
  }
}
</script>
<style type="text/css" scoped >
.school-role-box {
  height: 50px;
  padding-top: 1px;
  box-sizing: border-box;
  line-height: 49px;
}
.switch-dropdown .el-dropdown-menu__item {
  font-weight: bold;
  color: #666;
}
.el-dropdown-menu__item:hover a {
  color: #409eff;
}
.el-dropdown-menu__item.is-active a {
  color: #409eff;
}
/*学校*/
.school-container {
  display: inline-block;
}
.school-name {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
/*角色切换*/
.role-container {
  margin-left: 15px;
  display: inline-block;
}
.role-name {
  color: #e4ff00;
  font-size: 18px;
  font-weight: bold;
}
.role-name i {
  color: #fff;
}
.sel-block {
  cursor: pointer;
}
/*更多学校 切换*/
.more-school {
  color: #fa5555;
  font-style: italic;
}
.switch-school-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}
.switch-school-list li {
  width: 33.3%;
  float: left;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
}
.switch-school-list li a {
  border: 1px solid #ececec;
  padding: 7px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  width: 100%;
  line-height: 28px;
  color: #333;
}
.switch-school-list li a:hover {
  background: #ecf5ff;
  color: #409eff;
}
.slogo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  float: left;
}
</style>
