<!--
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-12-12 13:36:14
 * @FilePath: /web/src/layout/LayoutRegion/components/SiderBar.vue
 * @Description: 简介
-->
<template>
  <!-- 目前只允许四级菜单 -->
  <el-menu v-if="siderBarMenu.length > 0"
           class="el-menu-vertical"
           :default-active="activeSideMenu || defaultActiveMenu"
           :default-openeds="defaultOpeneds"
           background-color="#fff"
           text-color="#000"
           unique-opened>
    <div v-for="(menu, index) of siderBarMenu"
         :key="menu.menuId">
      <el-submenu v-if="menu.menuDtos && menu.menuDtos.length > 0"
                  :index="index + ''">
        <template slot="title">
          <span>
            <MenuIcon :iconClass="menu.icon || 'el-icon-folder-opened'"
                      class="siderBar_icon" />{{ menu.menuName }}</span>
        </template>

        <div v-for="(item, i) of menu.menuDtos"
             :key="item.menuId">
          <el-submenu v-if="item.menuDtos && menu.menuDtos.length > 0"
                      :index="`${index}-${i}`">
            <template slot="title">
              <span>
                <MenuIcon :iconClass="item.icon || 'el-icon-folder-opened'"
                          class="siderBar_icon" />{{ item.menuName }}</span>
            </template>
            <el-menu-item v-for="(m, n) of item.menuDtos"
                          :key="m.menuId"
                          :index="`${index}-${i}-${n}`"
                          @click="handleMenuRedirect(m)">{{ m.menuName }}</el-menu-item>
          </el-submenu>

          <el-menu-item v-else
                        :index="`${index}-${i}`"
                        @click="handleMenuRedirect(item)">{{ item.menuName }}</el-menu-item>
        </div>
      </el-submenu>

      <el-menu-item v-else
                    :index="index + ''"
                    @click="handleMenuRedirect(menu)">
        <span slot="title">
          <MenuIcon :iconClass="menu.icon || 'el-icon-menu'"
                    class="siderBar_icon" />{{ menu.menuName }}</span>
      </el-menu-item>
    </div>
  </el-menu>
</template>

<script>
import MenuIcon from '../MenuIcon'
import MenuMixin from "@/mixins/MenuMixin";

export default {
  mixins:[ MenuMixin ],
  components: { MenuIcon },
  data() {
    return {
      defaultOpeneds: null
    }
  },
  props: {
    siderBarMenu: {
      type: Array,
      require: true
    },
    activeSideMenu: {
      type: String,
      require: true
    }
  },
  computed: {
    curMenuList() {
      return this.$store.state.layout.curMenuList
    },
    defaultActiveMenu() {
      let curMenuList = this.curMenuList
      if (!curMenuList) {
        return null
      }
      let len = curMenuList.length
      if (len) {
        this.index = curMenuList[len - 1].menuIndexKey
        return curMenuList[len - 1].menuIndexKey
      }
      return null
    }
  }
}
</script>

<style scoped>
.el-menu-vertical {
  height: calc(100vh - 80px);
}
</style>

<style>
.el-submenu .el-menu-item {
  min-width: auto !important;
}
</style>
