/*
 * @Author: chenhanxiong
 * @Date: 2022-03-30 09:54:29
 * @LastEditors: longtuxin
 * @LastEditTime: 2024-01-24 14:37:27
 * @FilePath: /web/src/utils/qiankun.js
 * @Description: 头部注释
 */

import { registerMicroApps, start } from 'qiankun'
import globalStore from '../store/index'
import { Loading, Message } from 'element-ui'
import LoginService from '@/services/LoginService'
import { authTokenKey } from './auth'

let isProd = process.env.NODE_ENV == 'production'

// 子应用地址匹配规则
const getActiveRule = (rule) => (location) => location.hash.startsWith(rule)

let loading = null
let microApps = []
/** 新架构子应用列表 */
let newApps = [
  { name: 'pclassanalysis', host: '4000' },
  { name: 'pregionalv3', host: '8088' },
  { name: 'pcampus', host: '7068' },
  { name: 'puserv3', host: '9527' },
]

/** 注册qiankun */
export async function registerQiankun() {
  microApps = getAppsConfig()
  registerMicroApps(microApps, {
    beforeLoad: [
      async () => {
        if (loading) {
          // 先清空loading
          loading.close()
          loading = null
        }
        loading = Loading.service({
          text: '正在加载子应用...',
        })
      },
    ],
    beforeMount: [
      async () => {
        if (loading) {
          loading.close()
          loading = null
        }
      },
    ],
    afterUnmount: [],
  })
}

/** 获取所有子应用配置 */
export function getAppsConfig() {
  // 传递给新架构子应用的属性
  const newAppProps = {
    userInfo: globalStore.state.newUser,
    tokenKey: authTokenKey,
    logout: LoginService.logout,
  }
  // 传递给saas架构子应用的属性
  const saasAppProps = { userInfo: globalStore.state.user }
  
  let appsConfig = newApps.map(({ name, host, appType }) => {
    return {
      name,
      entry: isProd ? `/statics/${name}/index.html` : `//localhost:${host}`,
      container: '#v3-container',
      activeRule: getActiveRule(`#/${name}`), // 子应用触发规则（路径）
      props: appType === 'saas' ? saasAppProps : newAppProps,
    }
  })
  if (!serviceLineConfig) {
    return appsConfig
  }
  for (let appName in serviceLineConfig) {
    let service = serviceLineConfig[appName]
    if (!service || !service.src) {
      return
    }
    let { src } = serviceLineConfig[appName]
    // 子应用地址域名
    let devStaic = src[0].substr(0, 21)
    let appver = appName.slice(-2).toLowerCase()

    if ('v3' == appver) {
      appsConfig.push({
        name: appName,
        entry: isProd ? `/statics/${appName}/index.html` : devStaic,
        container: '#v3-container',
        activeRule: getActiveRule(`#/${appName}`), // 子应用触发规则（路径）
        props: saasAppProps,
      })
    }
  }
  return appsConfig
}

/** 判断是否为qiankun子应用 */
export function isMicroApp(name) {
  return microApps.some((item) => item.name === name)
}

/** 启动乾坤 */
export function startQiankun() {
  if (window.__QIANKUN_STARTED__) return
  loading = Loading.service({
    text: '正在加载子应用...',
  })
  startCalle()
  setTimeout(() => {
    // 8 秒后还没有加载完成
    if (loading) {
      loading.close()
      loading = null
      Message.error('子应用加载超时，请刷新重试！')
    }
  }, 8000)
}

/** 轮询直到页面加载完毕 */
function startCalle() {
  let timer = setTimeout(() => {
    let isElemet = document.getElementById('v3-container')
    if (isElemet) {
      clearTimeout(timer)
      timer = null
      start({})
      window.__QIANKUN_STARTED__ = true
    } else {
      startCalle()
    }
  }, 300)
}
