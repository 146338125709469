export const eventColumns = [
  { prop: 'name', label: '参数' },
  { prop: 'desc', label: '说明' }
]

export const propsColumns = [
  ...eventColumns,
  { prop: 'type', label: '类型' },
  { prop: 'default', label: '默认值' }
]
export const methodColumns = [
  ...eventColumns
]
