<template>
	<div class="top-menu" >
		<div class="school-info" v-if="school" :title="school.schoolName" @click="toHome">
			<school-logo class="school-logo" :slogo="school.slogo"></school-logo>
			<div class="school-name">{{school.schoolName}}</div>
		</div>
		<ul class="web-menu-list">
			<template v-for="(item,index) in userMenuList" >
				<li class="item" 
					:class="{'cur':isCurMenu(item)}"
					@mouseenter="handleMenuMouseenter(item.menuId)"
					@mouseleave="handleMenuMouseleave(item.menuId)"
					:key="index" 
					v-if="index<16" @click="handleMenuRedirect(item)">
					<div class="menu-name">{{item.menuName}}</div>
				</li>
			</template>
		</ul>
		<SetSubjectDialog :visible.sync="setSubjectVisible" :noSubjectClassList="noSubjectClassList" @success="execRedirect(curJumpMenu)"></SetSubjectDialog>
	</div>
</template>
<script>
import TopMenuIcon from '@/components/skin01/sub/TopMenuIcon.vue';
import SchoolLogo from '@/components/SchoolLogo';
import MenuMixin from '@/mixins/MenuMixin';
import ThemeMixin from '@/mixins/ThemeMixin';
import MenuService from '@/services/MenuService';

import SetSubjectDialog from '@/components/base/SetSubjectDialog.vue';
export default {
	components:{
		TopMenuIcon,
		SchoolLogo,
		SetSubjectDialog
	},
	mixins:[MenuMixin,ThemeMixin],
	computed:{
		userMenuList(){
			let userMenuList=this.$store.state.layout.userMenuList;
			return MenuService.getMenuDataList(userMenuList);
		},
		curMenuList(){
			return this.$store.state.layout.curMenuList;
		},
		curMenu(){
			let curMenuList=this.curMenuList;
			if(curMenuList&&curMenuList.length){
				return curMenuList[0];
			}
		},
		school(){
			return this.$store.state.user.school;
		},
		homeMenuUrl(){
			return this.$store.state.layout.homeMenuUrl;
		},
		homeMenu(){
			return this.$store.state.layout.homeMenu;
		}
	},
	methods:{
		toHome(){
			this.$router.push({path:this.homeMenuUrl});
		},
		isCurMenu(item){
			let curMenu=this.curMenu;
			return curMenu&&curMenu.menuId==item.menuId;
		},
		handleMenuMouseenter(id){
			// if(this.curMenu){
			// 	this.curMenu.menuId = id;
			// }
			// event.target.style.backgroundColor=this.color2;
		},
		handleMenuMouseleave(){
            // this.curMenu.menuId = null;
			// event.target.style.backgroundColor="";
		}
	}
}
</script>

<style type="text/css" scoped >
	.top-menu{
		/* height:78px; */
		position: relative;
		display: flex;
		height:60px;
		background:linear-gradient(135deg,rgba(164,43,51,1) 0%,rgba(202,63,41,1) 100%);
	}
	.school-info{
		color:#fff;
		/* float: left; */
		/* margin:15px 0 0 15px; */
		margin-left: 15px;
		font-weight: bold;
		font-size: 16px;
		max-width: 290px;
		min-width: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		/* position: absolute; */
	}
	.school-logo{
		width: 40px;
		height: 40px;
		vertical-align: middle;
		margin-right: 10px;
		flex-shrink:0;
	}
	.school-name{
		flex-shrink: 10;
		max-height: 48px;
		overflow: hidden;
	}
	/* 菜单 */
	.web-menu-list{
		flex-grow: 1;
		overflow: hidden;
		margin-left: 40px;
		display: flex;
	}
	.web-menu-list li{
		padding:13px 10px 13px;
		color:#fff;
		flex-grow: 1;
		width: 106px;
		min-width: 82px;
    	max-width: 128px;
		box-sizing: border-box;
		text-align: center;
		font-size: 15px;
		cursor: pointer;
		/* border-right:1px solid; */
		transition:all 0.3s;
	}
	.web-menu-list li:hover{
		transform: scale(1.1);
		font-weight: bold;
	}
	.web-menu-list li.cur:hover{
		transform: none;
		font-weight: normal;
	}
	.web-menu-list li:last-child{
		border-right: none;
	}
	.web-menu-list li .img-box{
		height: 38px;
		width: 38px;
		margin:0 auto;
	}
	.web-menu-list li .menu-name{
		line-height: 33px;
	}

	.web-menu-list li.cur .menu-name{
		background:#98100B;
		border-radius: 6px;		
	}

</style>