/*
 * @Author: chenhanxiong
 * @Date: 2023-10-31 17:53:00
 * @LastEditors: chenhanxiong
 * @LastEditTime: 2024-05-11 14:52:18
 * @FilePath: \web\src\utils\downloadFile.js
 * @Description: 文件下载
 */
import { postUrl } from '../api/common'
import { Message, MessageBox } from 'element-ui'
/**
 * @Description 当fileName为空的时候，直接取url的文件名称
 */
const getFilenameFromUrl = (url) => {
  const pathname = new URL(url).pathname
  const index = pathname.lastIndexOf('/')
  return index !== -1 ? pathname.substring(index + 1) : pathname
}

/**
 * @Description 处理 fileName不含文件后缀，直接取url的文件名称
 */
const disposeSuffix = (fileName, fileUrl) => {
  let resultFileName = fileName
  let reg = /\.[^\.]+$/
  /* 判断是否含有文件后缀 */
  let result = reg.test(fileName)
  if (result != true) {
    /* 不含有文件后缀 则加入fileUrl中的文件后缀 */
    let exts = reg.exec(fileUrl)
    if (exts.length > 0) {
      resultFileName += exts[0]
    }
  }
  return resultFileName
}
/**
 * @Description 处理 fn=&fn= 出现重复的
 */
const disposeRepetFnName = (name) => {
  if (!name) {
    return
  }
  let fileName = decodeURIComponent(name)
  if (fileName.indexOf('fn') > -1) {
    return fileName.split('&fn=')[0]
  }
  return fileName
}

/**
 * @Description 处理 fileName 长度
 */
const disposeFileNameLen = (name) => {
  let fileName = decodeURIComponent(name)
  if (fileName && fileName.length > 60) {
    fileName = fileName.substring(0, 60)
  }
  return fileName
}

const downloadBrowserFile = (fileUrl, fileName, isFlag) => {
  let userAgent = navigator.userAgent.toUpperCase();
  // if (userAgent.indexOf("FIREFOX") != -1 || isFlag) {
    // 火狐
    let aLink = document.createElement("A");
    aLink.href = fileUrl;
    aLink.download = decodeURI(fileName);
    aLink.style.display = "none";
    document.body.appendChild(aLink);
    aLink.click();
  // } else {
  //   // 其他
  //   let elemIF = document.createElement("iframe");
  //   elemIF.src = fileUrl;
  //   elemIF.style.display = "none";
  //   document.body.appendChild(elemIF);
    
  //   elemIF.onload = () => {
  //     document.body.removeChild(elemIF);
  //     elemIF = null;
  //   };
  // }
}

/** 针对新的文件中心下载重命名操作 */
const downloadRenameFile = (fileUrl, fileName) => {
  postUrl('/file/fileDownload', {
    arguments: { "filename": fileName },
    fileSrc: fileUrl
  }).then((res) => { 
    let { data } = res;
    if (!data || !data.downloadUrl) {
      downloadBrowserFile(fileUrl, fileName)
      return;
    }
    downloadBrowserFile(data.downloadUrl, fileName)
  })
}


export function downloadFile(fileUrl) {
  if (!fileUrl) {
    Message({
      message: "fileUrl不能为空",
      type: 'error',
    })
    return;
  }
  let fileName = null
  let isRename = fileUrl.indexOf('?fn=') > -1;
  if (fileUrl.indexOf('?') > -1) {
    let [url, name] = fileUrl.split('?fn=')
    fileUrl = url
    fileName = disposeRepetFnName(name)
  }
  if (!fileName) {
    fileName = getFilenameFromUrl(fileUrl)
  }
  fileName = disposeFileNameLen(fileName)
  fileName = disposeSuffix(fileName, fileUrl)
  
  
  if (isRename) { 
    downloadRenameFile(fileUrl, fileName)
  } else {
    downloadBrowserFile(fileUrl, fileName)
  }

}
