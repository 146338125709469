<!--
 * @Author: longtuxin
 * @LastEditors: liuzhihao
 * @LastEditTime: 2023-06-28 20:56:23
 * @FilePath: \web\src\layout\LayoutSpace\components\MsgAndBg.vue
 * @Description: 简介
-->
<template>
  <div class="msg">
    <el-dropdown @command="settingCommand">
      <div class="icon_box">
        <svg-icon icon-class="setting" class="icon" :style="{ color: menuTextColor }" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="1" v-if="isStuOrTeacher">空间设置</el-dropdown-item>
        <el-dropdown-item :command="2">换肤</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-badge :value="count" :class="{ 'hide_badge': !count }" v-if="!isStu">
      <div class="icon_box" style="margin-left:24px" @click="handleMsg">
        <svg-icon icon-class="msg" class="icon" :style="{ color: menuTextColor }" />
      </div>
    </el-badge>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    textColor: String
  },
  computed: {
    ...mapState({ theme: state => state.theme.theme || {} }),
    userType() {
      return this.$store.state.user.userType;
    },
    // 是否是学生或者是老师
    isStuOrTeacher() {
      return [1, 4].includes(this.userType)
    },
    // 是否是学生
    isStu() {
      return [1].includes(this.userType)
    },
    menuTextColor() {
      return this.textColor || this.theme.menuTextColor
    },
    hideMsg(){
      return !this.count || this.isStu
    }
  },
  methods: {
    // 显示背景图弹窗
    HandlePifu() {
      this.$store.commit("SET_SPACE_THEME_DIALOG", true);
    },
    settingCommand(item) {
      console.log(item);
      if (item == 2) {
        this.HandlePifu();
      } else {
        this.$store.commit("SET_SPACE_SETTING", true);
      }
    },
    // 显示消息中心抽屉
    handleMsg() {
      this.$store.commit("SET_SPACE_MSG_CENTER_DRAWER", true);
    },
  },
};
</script>
<style scoped>
.hide_badge ::v-deep .el-badge__content {
  display: none;
}

.msg {
  height: 100%;
  min-width: 72px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.icon_box {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_box .icon {
  width: 16px;
  height: 16px;
}

.icon_box:hover {
  background: rgba(0, 0, 0, 0.04);
}

::v-deep .el-dropdown {
  color: #fff !important;
}
</style>