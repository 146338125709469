import { Message, MessageBox } from 'element-ui';
import commonUtil from '@/common/common';

import { getByUrl, postByUrl, postByPortal } from '@/api/common';
import { setToken, removeToken } from '@/utils/auth';
import { getQueryString } from '@/utils/utils'

const user = {
  state: {
    // 登陆状态
    status: '',
    code: '',
    uploadUrlJson: null,
    uploadUrl: '',
    name: '',
    avatar: '',
    roles: [],

    school: null,
    schoolList: null,
    classList: null,
    user: null,
    userId: null,
    userType: null,
    child: null,
    stuClass: null,
    // 新增字段 家长操作学生身份，学生 家长可使用同样处理
    recordUserType: null,
    recordUserId: null,
    recordStuClass: null,
    recordStuClassList: null,

    fullscreenLoading: false,
    loginMode: null,

    portalUser: null,
    portalToken: null,
    portalOrganize: null,
    portalRole: null
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_LOGIN_MODE: (state, loginMode) => {
      state.loginMode = loginMode
    },
    SET_TOKEN: (state, token) => {
      if (token) {
        state.token = token
        setToken(token)
      } else {
        removeToken()
      }
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_SCHOOL: (state, school) => {
      state.school = school
    },
    SET_SCHOOLLIST: (state, schoolList) => {
      state.schoolList = schoolList
    },
    SET_UPLOAD_URL_JSON(state, uploadUrlJson) {
      state.uploadUrlJson = uploadUrlJson
      let uploadUrl = uploadUrlJson.upload_url
      if (typeof uploadUrl == "string") {
        state.uploadUrl = uploadUrl
      }
    },
    SET_CLASSLIST(state, classList) {
      state.classList = classList
    },
    SET_USER: (state, user) => {
      state.user = user
      if (user) {
        state.userId = user.userId
      } else {
        state.userId = null
      }
    },
    SET_USERTYPE: (state, userType) => {
      state.userType = userType
    },
    SET_CHILD: (state, child) => {
      state.child = child
    },
    SET_STUCLASS: (state, stuClass) => {
      state.stuClass = stuClass
    },
    SET_STUCLASSLIST: (state, stuClassList) => {
      state.stuClassList = stuClassList
    },
    SET_OPUSER: (state, { userType, user, child, stuClass, school, stuClassList }) => {
      // console.log("----stuClassList:",stuClassList,"userType:",userType,"user",user);
      let recordUserType = null,
        recordUserId = null,
        recordStuClass = null,
        recordStuClassList = null;
      if (userType == 2) {
        // 家长身份处理
        recordUserType = 1;
        recordUserId = child.childId;
        recordStuClass = child;
        let parentRoleDetails = school.parentRoleDetails;
        if (parentRoleDetails && parentRoleDetails.length) {
          recordStuClassList = parentRoleDetails.filter((it) => {
            return it.childId === child.childId;
          });
        }
      } else {
        // 其他身份处理--学生及其他
        recordUserType = userType;
        recordUserId = user.userId;
        recordStuClass = stuClass;
        recordStuClassList = stuClassList;
      }
      // 设置回state
      state.recordUserId = recordUserId;
      state.recordUserType = recordUserType;
      state.recordStuClass = recordStuClass;
      state.recordStuClassList = recordStuClassList;
    },
    SET_PORTAL_USER: (state, portalUser = {}) => {
      state.portalUser = portalUser
      localStorage.setItem('user', JSON.stringify(portalUser))
      sessionStorage.setItem('freeLogin', 1)
    },
    SET_PORTAL_ORG(state, org) {
      localStorage.setItem('organization', JSON.stringify(org))
      state.portalOrganize = org
    },
    SET_PORTAL_ROLE(state, role) {
      localStorage.setItem('role', JSON.stringify(role))
      state.portalRole = role
    },
    SET_PORTAL_TOKEN(state, token) {
      localStorage.setItem('formonz-token', token)
      state.portalToken = token
    }
  },

  actions: {
    getLoginData({ commit, dispatch, state }, { route, router, schoolId, role, domainConfig }) {
      // 地址中携带的pfv参数(当前只有课堂评价-客户端在用)
      const searchPfv = getQueryString('pfv')
      console.log('searchPfv store======',searchPfv)
      /*初次加载用户信息及菜单数据*/
      var params = {};
      if (schoolId) {
        params.schoolId = schoolId;
      }
      if (role) {
        params.userType = role.userType;
      }
      if (role && role.userType == 2) {
        /*家长切换孩子*/
        params.child = role;
      }
      if (role && role.userType == 1) {
        /*学生切换班级*/
        params.stuClass = role;
      }
      params.typeCode = 'web'
      // params.typeCode = 'wisdomPlatform' //中小学智慧教学平台测试
      if (domainConfig) {
        params.roleTypes = domainConfig.fitRoles;
        params.phases = domainConfig.fitPhases;
        params.dmid = domainConfig.id;
        params.domain = domainConfig.domain;
        params.typeCode = searchPfv || domainConfig.pfv || 'web';
      }
      if (searchPfv === 'clsEval') {
        params.roleTypes = [4] // 课堂评价限制只有老师角色
      }
      return new Promise((resolve, reject) => {
        state.fullscreenLoading = true;
        function toLoginPage() {
          if (process.env.NODE_ENV === "development") {
            router.push({ path: '/login' })
          } else {
            router.push({ path: '/prod_login' })
          }
        }
        postByUrl("/login/getLoginData", params).then((json) => {
          state.fullscreenLoading = false;
          if (json.status == false || commonUtil.isEmpty(json.data)) {
            if (json.rescode == 500 || json.rescode == "40003" || json.rescode == 403 || json.rescode == "50001") {
              MessageBox.confirm(json.resmsg, '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'warning'
              }).then(() => {
                toLoginPage()
              }).catch(() => {
                toLoginPage()
              });
              return
            }
            if (json.resmsg) {
              MessageBox.confirm(json.resmsg, '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'warning'
              }).then(() => {
                toLoginPage()
              }).catch(() => {
                toLoginPage()
              });
              return
            }
            if (!schoolId) {
              MessageBox.confirm(json.resmsg, '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'warning'
              }).then(() => {
                toLoginPage()
              }).catch(() => {
                toLoginPage()
              });
              return
            }
          }
          

          //设置菜单数据
          dispatch("setLoginInfo", json);
          dispatch("newUser/setLoginData", json);
          //user store 中设置用户登录信息
          dispatch("setMenuInfo", json);
          resolve(json);

        }).catch((e) => {
          state.fullscreenLoading = false;
        });
      });
    },
    setLoginInfo({ commit }, json) {
      // commit('CLEAR_USER_DETAIL_INFOS',{});
      const { school, schoolList, user, userType, child, stuClass, classList, stuClassList, loginMode } = json;
      commit('SET_SCHOOL', school);
      commit('SET_SCHOOLLIST', schoolList);
      commit('SET_USER', user);
      commit('SET_TOKEN', user.token);
      commit('SET_STATUS', 'login');
      commit('SET_USERTYPE', userType);
      commit('SET_CHILD', child);
      commit('SET_STUCLASS', stuClass);
      commit('SET_STUCLASSLIST', stuClassList);
      commit('SET_CLASSLIST', classList);
      commit('SET_LOGIN_MODE', loginMode);

      commit("SET_OPUSER", { school, schoolList, user, userType, child, stuClass, classList, stuClassList });
      /*"W_AREACODE","W_USERID","W_USERNAME","W_ACCOUNT",
      "W_MOBILE","W_USERTYPE","W_TOKEN","W_SCHOOLID",
      "W_STU_USERID","W_STU_CLASSID","W_SCHOOLNAME"
      */
      let headers = {
        schoolId: school.schoolId,
        schoolName: school.schoolName,
        userType
      }
      if (stuClass) {
        headers.stuClassId = stuClass.classId;
      }
      if (child) {
        headers.stuUserId = child.userId;
      }
      let headersQuery = commonUtil.jsonToHttpQuery(headers);
      commonUtil.setSessionStorage("headers", { auths: headersQuery });
    },
    getUploadUrl({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        if (state.getUploadUrlLoading) {
          resolve({});
          return;
        }
        if (state.uploadUrl && params && params.biz_type && state.uploadUrl.indexOf("biz_type=" + params.biz_type) !== -1
          && state.uploadUrlJson.schoolId && state.uploadUrlJson.schoolId == params.schoolId) {
          resolve(state.uploadUrlJson);
          return;
        }
        state.getUploadUrlLoading = true;
        state.uploadUrl = null;
        params = params || {};
        getByUrl("/common/getUploaderUrl", params).then(json => {
          state.getUploadUrlLoading = false;
          resolve(json);
          json.schoolId = params.schoolId;
          if (json.ret_code == 0) {
            commit('SET_UPLOAD_URL_JSON', json);
          } else {
            Message.error({ message: "获取上传地址失败，请刷新重试" });
          }
        });
      });
    },
    getUserDetailInfo({ commit, state }) {
      // console.log("classList");
      if (state.classList) {
        return;
      }
      let params = {};
      postByUrl("/login/getUserDetailInfo", params).then(json => {
        if (json.rescode == 10000) {
          commit('SET_CLASSLIST', json.data.classList);
        }
      });
    },
    set_jobmgr_modify_flag({ commit, state }, jobmgr_modify_flag) {
      commit('SET_JOBMGR_MODIFY_FLAG', jobmgr_modify_flag);
    },
    set_attendance_modify_flag({ commit, state }, attendance_modify_flag) {
      commit('SET_ATTENDANCE_MODIFY_FLAG', attendance_modify_flag);
    },
    set_album_modify_flag({ commit, state }, album_modify_flag) {
      commit('SET_ALBUM_MODIFY_FLAG', album_modify_flag);
    },
    getPortalUser({ commit, state }) {
      return new Promise((resolve, reject) => {
        postByPortal('/auth/free/login', {
          userId: state.userId,
          origin: 1,
          schoolId: state.school.schoolId,
          roleId: state.userType
        }).then(portalRes => {
          commit('SET_PORTAL_USER', portalRes)
          commit('SET_PORTAL_ORG', portalRes.organizes[0])
          commit('SET_PORTAL_ROLE', portalRes.organizes[0].roles[0])
          commit('SET_STATUS', 'login')
          resolve(portalRes)
        }).catch(() => {
          reject('获取平台登录失败')
        })
      })
    },
    switchPortalRole({ commit, state }) {
      commit('SET_STATUS', 'reload')
      return new Promise((resolve, reject) => {
        const { school, userId, userType } = state
        postByPortal('/auth/message/switch', { userId, schoolId: school.schoolId, roleId: userType }).then(orgRes => {
          commit('SET_PORTAL_ORG', orgRes)
          commit('SET_PORTAL_ROLE', orgRes.roles[0])
          commit('SET_STATUS', 'login')
          resolve(orgRes)
        }).catch(() => {
          reject('新平台切换用户失败')
        })
      })
    }
  }
}

function isEqualPath(path1, path2) {
  return path1 === path2;
}
function getValidMenuPath(fullPath) {
  let pathArr = fullPath.split("/");
  if (pathArr.length > 3) {
    return "/" + pathArr[1] + "/" + pathArr[2];
  } else {
    return fullPath;
  }
}
export default user
