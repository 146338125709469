import axios from "axios";
import { MessageBox, Message } from "element-ui";
import _pickBy from "lodash/pickBy";
import _isNumber from "lodash/isNumber";
import _isEmpty from "lodash/isEmpty";
import LoginService from '@/services/LoginService';
const tokenKey = 'formonz-token'
import store from '../store'

// 创建axios实例
const service = axios.create({
  baseURL: '/api/schoolmind/portal',
  timeout: 5000
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const { portalToken, portalOrganize, portalRole } = store.state.user

    if (portalToken && portalOrganize && portalRole) {
      const state = {
        organizeCode: portalOrganize.code,
        roleAlias: portalRole.alias
      }

      let param = config.data.data
      if (!_isEmpty(param) && !config.headers.isNoFilterEmptyParam && !(param instanceof Array)) {
        // 从 data 对象中去掉无效值（筛选出 有值 或者 为0 的属性）
        param = _pickBy(param, (c) => !!c || _isNumber(c))
      }

      config.data = {
        data: param || {},
        state
      }
      config.headers[tokenKey] = portalToken
    }
    return config
  },
  (error) => {
    // 处理请求异常
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === 200 || (res.rescode && res.rescode === '10000')) {

      const token = response.headers[tokenKey]
      store.commit('SET_PORTAL_TOKEN', token)
      return res.data;

    } else if (res.code === 400004) {
      // 重新登录
      MessageBox.confirm('登录已失效，可以取消继续留在该页面，或者重新登录', '登录失效', {
        confirmButtonText: '重新登录',
        type: 'warning',
        showCancelButton: true
      }).then(() => {
        LoginService.logout();
      })
      return Promise.reject(new Error(res.text || "Error"));
    } else {
      Message({
        message: res.text || "Error",
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(new Error(res.text || "Error"));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
