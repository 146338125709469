module.exports = {
  dest: "JZ_PRODUCT",
  dest_webserver: "JZ_PRODUCT",
	/***
	 * 日志目录
	 */
  // 生产日志路径 无需配置 
  logPath: "../../logs",
	/*
	静态资源路径---企业号端
	cdn:static.woniukid.cn
	*/
  staticsDomain: "static.woniukid.cn",
	/***
	 * RBAC-接口系统路径api调用路径
	 */
  API_RBAC_PATH: "172.16.0.38/rbac",
	/**
	 * 企业号部署地址
	 */
  wxcodeDomain: "m.woniukid.cn",
  wxcodeProtocol: "https",//移动端访问协议
  stWxcodeDomain: "m.woniukid.cn",
	/**
	 * 移动端部署地址
	 */
  mWxcodeDomain: "m.woniukid.cn",

  // 运营端部署地址
  bizDomain: "mgr.woniukid.cn",
  pc_ctx: '/ssportal',
  //pc端平台地址
  pcDomain: "web.woniukid.cn",
  pcProtocol: "https",

	/* 
	本地配置true,线上配置true，配置为true后http路径不能使用cookie，无法登录
	等保漏洞修改：Web 应用程序通过 SSL 发送不安全的 cookie,向所有敏感的 cookie 添加“Secure”属性 
	add by huzhen 2019-12-18
	*/
  secure: false,
	/**
	 * 
	 */
  vueDistPath: "../../wxeduserver/vue-dist/",

	/* 
	SOCKET服务的地址，host代表域名，pathname代表路径。独立部署的版本
	现使用于 loginPage项目 PC端
	add by huzhen 2019-04-13
	*/
  SOCKET_HOST: "web.woniukid.cn",
  SOCKET_PATHNAME: "ssportal",

  //文件服务器配置
  FILEUPLOADCONFIG: {
    app_code: "prodjzeduweb",
    password: "WwyVr8FdiNIC6JA4lFzObk314hqBcwzK"
  },
  ssportal: {
    redissettings: {
      "host": "172.16.0.17",
      "port": 13697,
      "pass": "j2pKg$pvlO7C",
      "db": 3
    }
  },
  wxeduserver: {
    redissettings: {
      "host": "172.16.0.17",
      "port": 13697,
      "pass": "j2pKg$pvlO7C",
      "db": 4
    }
  },
  biz: {
    redissettings: {
      "host": "172.16.0.17",
      "port": 13697,
      "pass": "j2pKg$pvlO7C",
      "db": 6
    }
  },
  webserver: {
    redissettings: {
      "host": "172.16.0.17",
      "port": 13697,
      "pass": "j2pKg$pvlO7C",
      "db": 8
    }
  },
  // 内部访问token
  innerTokens: {
    "login_app_key": "x6Yp6DEU4qJN5XpSdQ11qX6uej0nNLO7Y3jRUo0WNjkeRzDU",//wxeduserver
    "default_sys_token": "DefHT4BPXFpzSLdrJtl8Kd4MFLVjH5FWcNkLci6cjpVtuyXv",//wxeduserver
    "sys_opera_token": "Ops3BY94supIOJ1PTuuZmgaVBNZjuYlKlXVDnsJL8isaNmMW",//ssportal wxeduserver
    /*短信验证码接口I_CORE_SMSCODE  key*/
    "sms_access_key": "748a17b9-f791-11e4-8d8a-0071cc9b9dd2",//wxeduserver 固定值
    "busi_token_key": "qFxIvmkRt9yGtZFy6mLcQ306aqtCfThxXcLE6WaKh2HaMwO5",//wxeduserver
    /*缴费bill*/
    "school_bill_key": "T8PXxyWeieGhPXT9BNyXVBppAqwoOqs3GX1on4lPNuKRJnuL",//wxeduserver
    "school_bill_token": "F6SOI8wGrrMYQiWV4n5QrXxKq4IadaYrkhV546TjMst1RIPQ",//wxeduserver
    "data_board_key": "RedJcMTRQjLuSUwOAud22893NWs7a62gXnQ8hVQWscgmi9VW",//wxeduserver
    "sms_send_key": "IJGy9eDwNv5UEf1DagEq0RzDpHJgNAgD",//biz
    "monitor_preview_appkey": "8wfFukc0GHB3ztnrmyGsA3g2ipXjRt9XbwiPcN9OPD27MyX9",//biz ssportal
    "thr_bind_key": "15EZbJSIgbsBckRw9Eb7eUbYzCL1tgoBD6yjcwxqaYJfHc8F",//test==product wxeduserver
    "ww_user_key": "BKui4xGwRU5rPvJ5HfkgxfJpoQTiCHfgmRg8tZnugFF03NqO",//wxeduserver
    "wwcm_user_key": "wx19MRkinAXmTJRDb4hoGYbyUrZslmAePZW8Suyh3qDv4ViQ",//wxeduserver
    "eoa_inner_token": "BTgomBJWMGLFtqpeqn62SjIQIfMkuRAZF3eTsKCzU9QWTa5g",//wxeduserver
    "tycc_space_secret": "Al2AJsrXNTsSh5bY6Th2CX5Dgg64Xzvn4d9W2vyRI8ah9NE7",//wxeduserver 云盘
    "atten_token": "vg09PZMdMKYOFUtYfHCsIkvPzA9m7ik5SBP8NFdSWczNSYgm",//wxeduserver
  },
  SP_INFO: {
    jzsp: {
      1: 'https://k.woniukid.cn',
      2: 'https://pm.woniukid.cn',
      4: 'https://web.woniukid.cn'
    },
    stjysp: {
      1: 'https://k.woniukid.cn',
      2: 'https://pm.woniukid.cn',
      4: 'https://web.woniukid.cn'
    }
  },
  // 默认服务号appid 分享 上传图片 鉴权等使用
  default_mp_appid: "wx330672998fb2236f",
  //云商通签约地址 add by huzhen 2020-03-22--后台配置，暂废弃
  yunst_sign_contact: "http://116.228.64.55:6900/yungateway/member/signContract.html",
  //云商通配置
  ALLINPAY: {
    payTypeCode: "WECHAT_PUBLIC"
  },
  //使用的企业号 服务号 小程序等信息 add by huzhen 2020-03-28
  appInfos: {
    "aistudy": { appid: "ww7bf148ae21f9a064", appCode: "QEbUKTYPA3mpSNxJvFyO" },//ai学堂 ww7bf148ae21f9a064 生产宜教通的服务商
  },//wxapp_secrets app 微信公众号secret add by huzhen 2020-04-06 
  wxapp_secrets: {
    "wxf9b0ca0e92a8daa5": "c372a5d9a2eae11fff4f4fe79d690b82",//宜脸通 小程序
    "wxda4a62665557462c": "a841b57fba146aef0095f47f6dd462e4",//慧谷宜脸通 小程序
    "wx0de5c02e09ce782a": "7185cc98c976ad7e679cb18f90ad03e8",
    "wx285e2f4cdbf4d73f": "854a0f17e204c33f2847723073426db6",//翼校通宜脸通  小程序
    "wx1da4579386f897f1": "c66c40450e51561ef5e6d3db06fa67a5",//中缘宜脸通
    "wx53f7bd717ed0dc65": "ccf9488716bc33dd8560ba109bff984e", //新疆宜脸通  
  },
  ssportal_login_time: 20 * 60,
  // 是否使用本地配置的接口地址，true 或 false
  localInterfaceSwitch: false,
  // 路径path上下文
  webserver_ctx: "",
  ssportal_ctx: "/ssportal",

  //华附班牌对应的学校id---21966测试加州小学  华南师大附中50656
  hfbp_schoolid: 50656,
  // 德育评价mevaluate2版本跳转-根据配置的wxcid wwb499c238fd9220ac-华南师大附中-华南师范大学附属中学
  mevaluate2Wxcids: ["wwb499c238fd9220ac"],
  //升级公告开关
  isUpgradeTxt: true,
  //登录code
  login_code: 'e7feaebcdc5244619efe394446b51da9',
  login_mode: 1001, //1001  自营  3010 融合
  // 单点登录状态
  login_single_status: false,
  tencentyunConfig: {
    bucket: 'erc-1251444123',
    region: 'ap-guangzhou',
    secretId: 'AKIDe7QSEl23nHTP4SalkUyDCvObZHjvxjGX',
    secretKey: '2pMcbJkunQntiOr53mFTR9bnjQr2xc1A',
    allowPrefix: 'txoss/'
  },
  // 文件中心上传
  fileCosConfig: {
    secretId: 'q8mdmjwdikxkrh2ow942sb8ajpov5tftup89',
    secretKey: 'px0s990gu7umrdkfpwx20ptcdqvw7xz2',
  }

  
}
