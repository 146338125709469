<template>
  <Building />

</template>

<script>
import Building from '@/components/base/Building'

export default {
  components: { Building },

  data() {
    return {}
  }
}
</script>
