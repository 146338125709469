
import {postByUrl} from '@/api/common';
import {MessageBox} from 'element-ui';

var menuRedirectLoading=false;
var forwardRedirect=function(skipParamArr){
    if(menuRedirectLoading===true){
        return;
    }
    menuRedirectLoading=true;
    var newWindow = window.open("about:blank") ;
    let appcode=skipParamArr[1];
    let urtype=skipParamArr[2];
    let params={
        "appcode":appcode,
        "urtype":urtype,
    };
    postByUrl("/other/forwardRedirect",params).then((json)=>{
        menuRedirectLoading=false;
        let data=json.data;
        if(json.status===false){
            newWindow.close();
            MessageBox.alert(json.resmsg);
            return;
        }
        if(!newWindow){
            MessageBox.alert("您的浏览器拦截了新弹出的窗口，请在浏览器右上角设置允许弹出窗口");
            return;
        }
        if(!data){
            newWindow.close();
            MessageBox.alert("跳转路径为空！");
        }else{
            newWindow.location.href=json.data;
        }
    });
}


export default{
    forwardRedirect
}