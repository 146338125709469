<template>
	<div class="edu-top-box" mode="horizontal">
		<hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="!sidebar.opened"></hamburger>
		<switch-school-and-role class=""
      ></switch-school-and-role>
		<div class="edu-top-right">
			<el-dropdown class="avatar-container" placement="top-start" @command="onDropdownCommand">
				<div class="el-dropdown-link avatar-wrapper " >
					<el-avatar icon="el-icon-user-solid" class="user-avatar" size="medium" v-if="user" :src="user.avatarUrl"></el-avatar>
					<div class="f-toe user-name" v-if="user">{{user.userName}}</div>
					<i class="el-icon-caret-bottom"></i>
				</div>
				<el-dropdown-menu class="user-dropdown" slot="dropdown" >
					<el-dropdown-item :command="{op:'setting'}" >
						<div class="">个人设置</div>
					</el-dropdown-item>
					<el-dropdown-item divided :command="{op:'exit'}" >
						<div class="">退出</div>
					</el-dropdown-item>
						<!-- <el-dropdown-item>
							<router-link :to="{path:'/usersetting/basicinfo'}">个人设置</router-link>
						</el-dropdown-item>
					<el-dropdown-item divided><span @click="logout" style="display:block;">退出登录</span></el-dropdown-item> -->
				</el-dropdown-menu>
			</el-dropdown>
			<screenfull class='screenfull'></screenfull>
		</div>
	</div>
</template>

<script>
import { mapGetters,mapState } from 'vuex'
import Hamburger from './sub/Hamburger'
import Screenfull from './sub/Screenfull'
import SwitchSchoolAndRole from './sub/SwitchSchoolAndRole';
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin';
export default {
  components: {
    Hamburger,
    Screenfull,
    SwitchSchoolAndRole,
  },
  data() {
    return {
    }
  },
  mixins:[SwitchSchoolAndRoleMixin],
  computed: {
    sidebar(){
      return this.$store.state.app.sidebar
    },
    user(){
      return  this.$store.state.user.user
    },
    userType(){
      return this.$store.state.user.userType
    }
  },
  methods: {
	onDropdownCommand({op}){
		if(op=="setting"){
			this.toBaseInfo();
			return;
		}else if(op=="exit"){
			this.toLogout();
		}
	},
	toggleSideBar() {
		this.$store.commit('ToggleSideBar')
	}
  }
}
</script>

<style rel="stylesheet/scss" lang="less" scoped >
	.edu-top-box{
		background: #666;
		color: #fff;
		font-size: 0;
		position: relative;
	}
	.edu-top-box {
			border-radius: 0px !important;
			.hamburger-container {
					line-height: 58px;
					height: 49px;
					float: left;
					padding: 0 10px;
			}
			.errLog-container {
					display: inline-block;
					position: absolute;
					right: 150px;
			}
			.screenfull {
					position: absolute;
					right: 15px;
					top: 13px;
					color: red;
			}
			.avatar-container {
					height: 50px;
					line-height: 50px;
					display: inline-block;
					/* position: absolute;
					right: 35px; */
					margin-left: 25px;
					margin-right: 35px;
					.avatar-wrapper {
							cursor: pointer;
							position: relative;
							.user-avatar {
									// width: 40px;
									// height: 40px;
									// border-radius: 100%;
									float: left;
									// font-size: 0;
									margin-top: 6px;
							}
							.user-name{
								color: #fff;
								padding-left: 12px;
								max-width: 68px;
								font-size: 16px;
								float: left;
							}
							.el-icon-caret-bottom {
									position: absolute;
									right: -20px;
									top: 16px;
									font-size: 16px;
									color: #fff;
							}
					}
			}
	}
	.edu-top-right{
	    width: 220px;
	    position: absolute;
	    right: 0;
	    top: 0;
	    bottom: 0;
	    background: #333;
	}
	/* 中小版 */
	.domain-middle .edu-top-box{
		background: #14cf82;
	}
	.domain-middle .edu-top-right{
		background: #2bbb61;
	}
</style>



