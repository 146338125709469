<template>
  <div
    class="main-wrap"
    :class="'domain-'+(code||'sass') + ` ${themeCss ? themeCss:''} lang-${lang}`"
    v-loading.fullscreen.lock="loading||fullscreenLoading"
  >
    <router-view v-if="loadingFinish===true"></router-view>
    <div id="globalDialog"></div>
    <!-- 开发工具 -->
    <DevTool v-if="isDev" />
  </div>
</template>

<script >
import { postByCode } from "@/api/common";
// 导入主题
import themes from "@/theme/index";
import Vue from "vue";
import DevTool from "./components/DevTool/index.vue";
export default {
  name: "app",
  components: { DevTool },
  data() {
    return {
      loading: false,
      loadingFinish: false,
      themeCss: null,
    };
  },
  computed: {
    domainConfig() {
      let domainConfig = this.$store.state.app.domainConfig;
      return domainConfig;
    },
    code() {
      let domainConfig = this.domainConfig;
      if (!domainConfig) {
        return "";
      }
      let code = domainConfig.idxTemp.code;
      /*if(code=="ty"){
          code="middle";
        }*/
      return domainConfig.pfv || code;
    },
    fullscreenLoading() {
      return this.$store.state.user.fullscreenLoading;
    },
    loginStatus() {
      //登陆状态
      return this.$store.state.user.status;
    },
    // 为了新增语言样式
    lang() {
      return localStorage.getItem("locale") || "";
    },
    // 是否为开发环境
    isDev() {
      return process.env.NODE_ENV === "development";
    },

    schoolId() {
      let school = this.$store.state.user.school
      return school && school.schoolId
    }
  },
  watch: {
    loginStatus(newVal) {
      if (newVal == "NOAUTH") {
        this.$router.push({ path: "/login" });
      }
    },
  },
  mounted() {
    this.getDomainConfig();
  },
  methods: {
    getParams(domainConfig, key) {
      if (
        domainConfig &&
        domainConfig.idxTemp &&
        domainConfig.idxTemp.elements
      ) {
        let elements = domainConfig.idxTemp.elements;
        if (elements && elements.length) {
          // 查找颜色值
          let targetElement = elements.find((el) => {
            return el.code == key;
          });
          if (targetElement) {
            return targetElement.content;
          }
        }
      }
    },
    getDomainConfig() {
      /*获取该域名的配置信息*/
      let routeName = this.$route.name;
      var promise = null;

      this.loading = true;
      if (routeName === "previewPage") {
        promise = postByCode("I_JXHD_LOOK_PREVIEW", {
          code: this.$route.params.code,
        });
      } else {
        let path = location.pathname;
        let paths = path.split("/");
        if (paths.length > 1 && paths[1]) {
          path = "/" + paths[1] + "/";
        }
        promise = postByCode("I_JXHD_GET_DOMAIN_CONFIG", {
          domain: location.host + path,
        });
      }
      promise
        .then((json) => {
          this.loading = false;
          this.loadingFinish = true;
          if (!json.status) {
            return;
          }
          if (routeName === "previewPage" && !json.data) {
            this.$dialog.error("预览信息错误");
            return;
          }
          this.data = json.data;
          this.$store.commit("SET_APP_DOMAIN_CONFIG", json.data);

          let title = "WeSaaS智慧教育公共服务平台";
          if (this.data && this.data.title) {
            title = this.data.title;
          }
          this.$store.commit("SET_TITLE", title);
          // document.title = title;

          // 注册对应主题
          let themeId = this.getParams(this.data, "themeId");
          this.themeCss = themeId || "Theme3";
          Vue.use(themes, { themeId });
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>

<style rel="stylesheet/less" type="text/css"  lang="less">
/* // @import "./assets/css/base.scss";
  // @import "./assets/css/transition.css";
   .main-wrap { position: relative; max-width: 750px; min-width: 320px; margin: 0 auto; width: 100%; min-height: 100%; overflow: hidden;}
   */
.main-wrap {
  height: 100%;
  min-width: 1024px;
}
.main-wrap.domain-web,
.main-wrap.domain-CLOUD_SIMPLE,
.main-wrap.domain-middle,
.main-wrap.domain-sass {
  overflow-x: auto;
  /deep/.home-container {
    padding: 0 15px;
  }
}
</style>
