/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-01-18 17:11:09
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-03-31 14:27:10
 * @Description: 公用子组件描述文档
 */

<template>
  <el-container>
    <el-aside width="300px">
      <ul class="aside y_scroll">
        <li
          v-for="item, i of leftMenu"
          :key="i"
          class="aside_item"
          :class="{ 'aside_item_cur': i === +leftCurIndex }"
          @click="handleAsideClick(item, i)">{{ item }}</li>
      </ul>
    </el-aside>
    <el-main class="y_scroll">
      <div v-if="description[selItem]" class="p24">
        <div
          v-for="val, key, i in description[selItem]"
          :key="key"
          :class="{ mt40: i > 0 }">
          <div v-if="key === 'template'"
               class="prism">
            <span>示例代码：</span>
            <div class="y_scroll_editor">
              <template v-if="isUnfold">
                <PrismEditor 
                  v-model="val.template"
                  class="my-editor mt16"
                  :highlight="highlighter"
                  readonly
                  line-numbers />
              </template>
              <div class="y_scroll_editor_btn" @click="handleAccordion">
                {{ isUnfold ? '隐藏代码' : '显示代码' }}
              </div>
            </div>
          </div>
          <template v-else>
            <template v-if="key === 'componentFunctionDesc'">
              组件描述：{{ val }}
            </template>
            <template v-else>
              <p class="tips">
                {{ key }}
              </p>
              <el-table
                :data="val.list">
                <el-table-column
                  v-for="item, j of val.columns"
                  :key="j"
                  :prop="item.prop"
                  :label="item.label" />
              </el-table>
            </template>
          </template>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import common from './common'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css'
export default {
  name: '',
  components: {
    PrismEditor
  },
  props: {},
  data() {
    return {
      description: {
        ...common
      }, // 各组件配置项说明
      selItem: '',
      leftCurIndex: 0,
      isUnfold: false // 是否展开示例代码
    }
  },
  computed: {},
  created() {
    const curIndex = this.$route.query.curIndex || 0
    this.leftMenu = Object.keys(this.description)
    this.selItem = this.leftMenu[curIndex]
    this.leftCurIndex = curIndex
  },
  methods: {
    // 左侧菜单点击
    handleAsideClick(item, i) {
      this.selItem = item
      this.leftCurIndex = i
      this.$router.push({
        path: this.$route.path,
        query: {
          curIndex: i
        }
      })
    },

    highlighter(code) {
      return highlight(code, languages.js)
    },

    handleAccordion() {
      this.isUnfold = !this.isUnfold
    }
  }
}
</script>
<style lang='less' scoped>
.aside {
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  border-right: 1px solid #ddd;
  cursor: pointer;
  &_item {
    height: 40px;
    font-size: 16px;
    &_cur {
      font-weight: bold;
      color: #409eff;
    }
  }
}

.p24 {
  padding: 24px;
}

.my-editor {
  background: #333;
  color: #f92672;
  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism {
  margin-bottom: 30px;
}

.y_scroll {
  height: 100vh;
  overflow-y: auto;
  &_editor {
    position: relative;
    &_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      background: #fafafa;
      font-size: 16px;
      color: #aaa;
      cursor: pointer;
      &:hover {
        color: #2d55eb;
      }
    }
  }
}

.mt40 {
  margin-top: 40px;
}

.tips {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

</style>
