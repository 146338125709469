/*
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2022-09-29 13:48:36
 * @FilePath: /web/src/components/DevTool/IconPicker/svg-icons.js
 * @Description: 获取assets/icons/svg文件下的所有svg图标
 */
const req = require.context('@static/svgIcons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys()

const re = /\.\/(.*)\.svg/

const svgIcons = requireAll(req).map(i => {
  return i.match(re)[1]
})

export default svgIcons
