<template>
  <div class="person_portrayal">
    <div class="bg_lighting"></div>
    <!-- <div class="user_info">
      <div class="user_info-item">
        <div class="user_info-icon">
          <img class="user_bg" src="@/assets/images/personPortrayal/user_bg.png">
          <img class="user_avatar" src="@/assets/images/personPortrayal/user_avatar.png">
        </div>
        <div class="user_info-dec">
          <span class="dec">姓名</span>
          <span class="real">庞祥</span>
        </div>
      </div>
      <div class="user_info-item">
        <div class="user_info-icon">
          <img class="user_bg" src="@/assets/images/personPortrayal/user_bg.png">
          <img class="user_avatar" src="@/assets/images/personPortrayal/user_avatar.png">
        </div>
        <div class="user_info-dec">
          <span class="dec">所在班级</span>
          <span class="real">高二(3)班</span>
        </div>
      </div>
    </div> -->
    
    <div class="main_cont">
      <!-- <img src="@/assets/images/personPortrayal/avatar.gif" alt="123"> -->
    </div>

  </div>
</template>

<script>
//import 导⼊⽂件 第三⽅插件js > json⽂件 > ⼯具js >组件
export default {
  name: '',
  props: {},
  components: {},
  mixins: [],
  data() {
    return {};
  },
  computed: {},
  created() {},
  //⽣命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //⽣命周期 - 销毁完成
  destroyed() {},
  watch: {},
  methods: {
    /**
     * @description:
     * @param {type}
     */
  },
};
</script>
<style lang='less' scoped>
//@import url()> @mixin> value
  .person_portrayal {
    position: relative;
    width: 100%;
    height: 100%;
    // background-color: #040809;
  }

  // .bg_lighting {
  //   max-width: 1299px;
  //   height: 84px;
  //   margin: 0 auto;
  //   // background: url('../assets/images/personPortrayal/bg_lighting.png') no-repeat;
  //   // background-size: 100% 100%;
  // }

  .main_cont {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('/statics/static/demo/main_cont.png');
    background-size: 100% 100%;
  }

  /* .user_info {
    margin-left: 100px;
    width: 704px;
    height: 704px;
    background: #007373;
    &-item {
      display: inline-block;  
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 76px;
      border-radius: 73px;
      border: 1px solid #3DFFFF;
      padding: 8px 22px 8px 8px;
    }
    &-dec {
      // display: flex;
      // flex-direction: column;

      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #3DFFFF;
      margin-top: 3px;
      .real {
        font-size: 24px;
        line-height: 34px;
        color: #fff;
      }
    }
    &-icon {
      position: relative;
      width: 60px;
      height: 60px;
      margin-right: 11.7px;
      .user_bg {
        width: 100%;
        height: 100%;
      }
      .user_avatar {
        position: absolute;
        width: 24px;
        height: 24px;
        left: calc(50% - 24px/2);
        top: calc(50% - 24px/2);
      }
    }
  } */

</style>