<template>
	<div class="top-menu" :style="{'background-color':color1}" ref="menuReference">
		<ul class="web-menu-list">
			<template v-for="(item,index) in userMenuList" >
				<li class="item" 
					:key="index"
					:class="{'cur':(!clickMenu&&isCurMenu(item)),'click-menu':isClickMenu(item)}"
					v-if="index<16" @click="handleV3MenuClick(item)">
					<div class="menu-name " :style="{'border-color':color1}">{{item.menuName}}</div>
				</li>
			</template>
		</ul>
		<transition name="fade-in-linear">
		<div class="v3-submenu-popup" v-show="v3SubmenuVisible" ref="v3SubmenuPopup">
			<ul class="submenu-content">
				<li class="submenu-item" v-for="sm in subMenuList" :key="sm.id" @click="handleV3SubMenuClick(sm)">
					<TopMenuIcon class="img-box" :iconClass="sm.icon">
					</TopMenuIcon>
					<div class="submenu-name">{{sm.menuName}}</div>
				</li>
			</ul>
		</div>
		</transition>
	</div>
</template>
<script>
import TopMenuIcon from './sub/TopMenuIcon.vue';

import MenuMixin from '@/mixins/MenuMixin';
import ThemeMixin from '@/mixins/ThemeMixin';
import MenuService from '@/services/MenuService';

import { on, off } from '@/utils/dom';
export default {
	components:{
		TopMenuIcon
	},
	mixins:[MenuMixin,ThemeMixin],
	data(){
		return {
			v3SubmenuVisible:false,
			clickMenu:null,

			subMenuList:null,

		}
	},
	mounted(){
		on(document, 'click', this.handleDocumentClick);
	},
	computed:{
		userMenuList(){
			let userMenuList=this.$store.state.layout.userMenuList;
			return MenuService.getMenuDataList(userMenuList);
		},
		curMenuList(){
			return this.$store.state.layout.curMenuList;
		},
		curMenu(){
			let curMenuList=this.curMenuList;
			if(curMenuList&&curMenuList.length){
				return curMenuList[0];
			}
		},
		school(){
			return this.$store.state.user.school;
		},
		homeMenuUrl(){
			return this.$store.state.layout.homeMenuUrl;
		},
		homeMenu(){
			return this.$store.state.layout.homeMenu;
		}
	},
	methods:{
		handleDocumentClick(e) {
			let reference = this.$refs.menuReference;
			const popper =  this.$refs.v3SubmenuPopup;
			if (!this.$el ||
				!reference ||
				this.$el.contains(e.target) ||
				reference.contains(e.target) ||
				!popper ||
				popper.contains(e.target)) return;
			this.closePopup();
		},
		toHome(){
			// this.handleMenuRedirect(this.homeMenu);
			this.$router.push({path:this.homeMenuUrl});
		},
		isCurMenu(menu){
			let curMenu=this.curMenu;
			return curMenu&&curMenu.menuId==menu.menuId;
		},
		isClickMenu(menu){
			let clickMenu=this.clickMenu;
			return clickMenu&&clickMenu.menuId==menu.menuId;
		},
		handleV3MenuClick(menu){
			let subList=menu.menuDtos;
			let subMenuList=MenuService.getMenuDataList(subList);
			if(subMenuList&&subMenuList.length>0){
				this.v3SubmenuVisible=true;
				this.clickMenu=menu;
				this.subMenuList=subMenuList;
			}else{
				this.closePopup();
				this.handleMenuRedirect(menu);
			}
		},
		handleV3SubMenuClick(menu){
			this.closePopup();
			this.handleMenuRedirect(menu);
		},
		closePopup(){
			// this.subMenuList=null;
			this.clickMenu=null;
			this.v3SubmenuVisible=false;
		}
	},
	destroyed(){
		off(document, 'click', this.handleDocumentClick);
	}
}
</script>

<style type="text/css" scoped >
	.top-menu{
		/* height:48px; */
	}
	
	/* 菜单 */
	.web-menu-list{
		overflow: hidden;
		/* margin-left: 260px; */
		max-width: 1380px;
		margin:0 auto;
		display: flex;
		/* height: 48px; */
	}
	.web-menu-list li{
		padding:9px 5px 5px;
		color:#fff;
		min-width: 85px;
		max-width: 140px;
		box-sizing: border-box;
		text-align: center;
		font-size: 15px;
		cursor: pointer;
		/* border-right:1px solid; */
		transition:all 0.3s;
		flex-grow: 1;
	}
	.web-menu-list li:hover,.web-menu-list li.cur,.web-menu-list li.click-menu{
		transform: scale(1.05);
		font-weight: bold;
	}
	.web-menu-list li:hover .menu-name,
	.web-menu-list li.cur .menu-name,
	.click-menu .menu-name{
		border-color:#fff!important;
	} 
	
	/* .web-menu-list li.cur:hover{
		transform: none;
		font-weight: normal;
	} */
	.web-menu-list li:last-child{
		border-right: none;
	}
	.web-menu-list li .img-box{
		height: 38px;
		width: 38px;
		margin:0 auto;
	}
	.web-menu-list li .menu-name{
		margin-top: 3px;
		padding:0 0px 8px 0px;
		display: inline-block;
		border-bottom:3px solid;
	}
	
	

	/* 菜单弹出层 */
	.v3-submenu-popup{
		position: absolute;
		left: 0;
		width: 100%;
		background:#2073BF;
		padding:40px 0 20px;
		z-index: 11;
	}
	.submenu-content{
		max-width: 1380px;
		margin:0 auto;
		padding:0 20px;
		box-sizing: border-box;
		overflow: hidden;
		color:#fff;
	}
	.submenu-item{
		float: left;
		text-align: center;
		/* padding:10px; */
		cursor: pointer;
		margin-right: 20px;
		width: 84px;
		height: 105px;
    	margin-bottom: 15px;
	}
	.submenu-name{
		margin-top:5px;
	}
	

</style>