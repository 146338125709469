export default {
  computed: {
    domainConfig() {
      return this.$store.state.app.domainConfig
    },
    theme() {
      // const { loginMode } = this.$store.state.user
      let domainConfig = this.domainConfig;
      if (domainConfig && domainConfig.idxTemp && domainConfig.idxTemp.elements) {
        let elements = domainConfig.idxTemp.elements;
        if (elements && elements.length) {
          // 查找颜色值
          let targetElement = elements.find((el) => {
            return el.code == "theme";
          });
          if (targetElement) {
            return targetElement.content;
          }
        }
      }
      // 设置主题色
      return '#388DF7|#386ADD|#2A364F|#FFFFFF|#7F99BC|#F3F6FD|#2D55EB|#252526';
    },
    color1() {
      return this.getColor(0)
    },
    color2() {
      return this.getColor(1)
    },
    color3() {
      return this.getColor(2)
    },
    color4() {
      return this.getColor(3)
    },
    color5() {
      return this.getColor(4)
    },
    color6(){
      return this.getColor(5)
    },
    color7(){
      return this.getColor(6)
    },
    color8(){
      return this.getColor(7)
    },
  },
  methods: {
    getColor(index) {
      let theme = this.theme;
      let arr = theme.split("|");
      if (arr.length > 1) {
        return arr[index];
      }
    }
  },
  mounted() {
    localStorage.setItem('domainThemeColor', this.theme)
  }
}
