<!--
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2024-01-24 14:14:27
 * @FilePath: /web/src/layout/LayoutSpace/index.vue
 * @Description: 大平台布局(新)
-->
<template>
  <el-container v-if="!isInIframe">
    <el-header style="margin-left: -20px;height: 64px;">
      <first-level-menu v-if="!subMenuList.length" :active-index="activeIndex" />
      <HeaderSubMenu
        v-else
        :sub-menu-list="subMenuList"
        :menu-name="menuName"
        :active-index="subMennActiveIndex"
        class="HeaderSubMenu"
      />
    </el-header>
    <el-container>
      <el-aside v-if="siderBarMenu && siderBarMenu.length" width="200px">
        <SiderBar :sider-bar-menu="siderBarMenu" :active-side-menu="activeSideMenu" />
      </el-aside>

      <el-main id="right-content" :class="$route.meta.pageGreybg === 1 ? 'greybg-content' : 'right-content'">
        <!-- loadEnd 请求完成用户数据后，再加载子应用 -->
        <router-view v-if="spaceLoadEnd && loadEnd" />
        <div v-if="loadEnd" id="v3-container"></div>
      </el-main>
    </el-container>
  </el-container>

  <!-- 如果是内嵌，不显示菜单布局 -->
  <div v-else>
    <div v-if="spaceLoadEnd && loadEnd" class="el-main content resize">
      <router-view />
    </div>
    <div v-if="loadEnd" id="v3-container"></div>
  </div>
</template>
<script>
import FirstLevelMenu from './components/FirstLevelMenu.vue'
import HeaderSubMenu from './components/HeaderSubMenu'
import SiderBar from './components/SiderBar'
import LayoutMixin from '@/mixins/LayoutMixin'
import SpaceMenuMixin from '@/mixins/SpaceMenuMixin'
import MenuService from '@/services/MenuService'

export default {
  mixins: [LayoutMixin, SpaceMenuMixin],
  components: {
    FirstLevelMenu,
    HeaderSubMenu,
    SiderBar
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.getMenuListAndSiderBar()
      }
    },
    menuList: {
      handler(val) {
        if (!val.length) return
        this.getMenuListAndSiderBar()
      },
      immediate: true
    }
  },
  data() {
    return {
      activeIndex: '0',
      subMenuList: [],
      menuName: '',
      subMennActiveIndex: '',
      siderBarMenu: [],
      activeSideMenu: '0-0'
    }
  },
  computed: {
    isInIframe() {
      return window.self !== window.top
    }
  },
  methods: {
    initData() {
      this.activeIndex = '0'
      this.subMenuList = []
      this.menuName = ''
      this.subMennActiveIndex = ''
      this.siderBarMenu = []
      this.activeSideMenu = '0-0'
    },
    getMenuenuDtosList(list) {
      list = JSON.parse(JSON.stringify(list || []))
      list.forEach(item => {
        item.menuDtos = MenuService.getMenuDataList(item.menuDtos || [])
        // 有下级则继续递归
        if (item.menuDtos.length) {
          item.menuDtos = this.getMenuenuDtosList(item.menuDtos)
        } else {
          item.menuDtos = null
        }
      })
      return list
    },
    getMenuListAndSiderBar() {
      const { menuKey } = this.menuUrlToObj()
      let curMenu = this.menuList[menuKey[0]]
      // 递归筛选出菜单
      let menuenuDtos = this.getMenuenuDtosList((curMenu || []).menuDtos)
      // 无菜单或错误页，初始化菜单数据
      if (!menuKey.length || !curMenu) return
      this.initData()

      if (!menuenuDtos) return

      this.menuName = ` /  ${curMenu.menuName}`
      this.subMennActiveIndex = menuKey[1] + ''
      this.subMenuList = menuenuDtos

      if ((menuKey[1] || menuKey[1] === 0) && menuenuDtos[menuKey[1]].menuDtos) {
        this.siderBarMenu = menuenuDtos[menuKey[1]].menuDtos
        const activeSideMenu = menuKey.slice(2, menuKey.length)
        this.activeSideMenu = activeSideMenu.join('-')
      }
    }
  }
}
</script>

<style type="text/css" scoped>
.content {
  background: #f8f8f8;
  padding: 0;
}
.el-header {
  padding-right: 0;
}
.wrapper {
  height: 100%;
  min-width: 1276px;
}
.menu.resize {
  /* height:83.5vh; */
  /* height:calc(100vh - 116px); */
  overflow-y: auto;
  overflow-x: hidden;
}
.right-content {
  height: calc(100vh - 64px);
  background-color: #f6f7f9;
  overflow-y: auto;
  /* padding: 24px !important; */
}
.greybg-content {
  background-color: #f6f7f9;
}
.HeaderSubMenu {
  height: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
}
</style>
