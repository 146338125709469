/*
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2022-10-14 14:30:41
 * @FilePath: /web/src/components/index.js
 * @Description: 注册公共组件
 */
import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon'

Vue.component('svg-icon', SvgIcon)

// 将static仓库的svg图标注册到全局
const req = require.context('@static/svgIcons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
