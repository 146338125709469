<template>
  <div
    v-if="iconClass"
    class="top-menu-icon"
    :class="iconClass"
  />
  <div
    v-else
    class="top-menu-icon menu-icon-ggw-ta"
  />
</template>
<script>
export default {
  props: {
    iconClass: {
      type: String,
      default: 'menu-icon-resreleaselist'
    }
  }
}
</script>

<style scoped lang="less">
    //带目录放 /assets/images/v3icons
    /**
    @jxList 代表图标名称如  /assets/images/v3icons/album.png 菜单录入类名未 menu-icon-album
    第二个参数代表目录名称
    */
    @jxList:album,activity,albumaudit,albumset,book,homework,noti,scores,vote,wgw,wgwhome,daily,kemu,kebiao,growupalbum;
    .menu-icon-loop-folder(@jxList,'jxhd');
    @basemodelList:msgcount,msglist,msgstatis,userstatis,rescenter;
    .menu-icon-loop-folder(@basemodelList,'basemodel');
    @oaList:fund,office,asset,report,purchase,information,wage,prize,openactivity,zidingyiform,dashiji,outapply;
    .menu-icon-loop-folder(@oaList,'oa');
    @fangkeList:fangke;
    .menu-icon-loop-folder(@fangkeList,'fangke');
    @zsList:zsgl;
    .menu-icon-loop-folder(@zsList,'zsgl');
    @carList:carmanage;
    .menu-icon-loop-folder(@carList,'car');
    @runningList:sunnyrunning,richangtest,ticefenxi,ticemgr,tiyitest,tiwenjiankang,shenggaotizhon,chenjianmgr;
    .menu-icon-loop-folder(@runningList,'running');
    @scheduleList:schedule,adjust,atten,check,manage,rest,elective;
    .menu-icon-loop-folder(@scheduleList,'schedule');
    @finmList:finm,schoolcard;
    .menu-icon-loop-folder(@finmList,'finm');
    @dormList:dormmgr,sickleavemgr,askleaverecord,position;
    .menu-icon-loop-folder(@dormList,'dorm');
    @bancardList:bancard,bancardwash,shipu;
    .menu-icon-loop-folder(@bancardList,'bancard');
    @evaluateList:evaluate;
    .menu-icon-loop-folder(@evaluateList,'evaluate');
    @evaluatingList: mine, report, syspaper, schpaper, schquestion, grouppaper,
    machinegrouppaper, knowledgepoint, device, book, homework, workbook;
    .menu-icon-loop-folder(@evaluatingList, "evaluate");
    @attenList:studatten,staffatten;
    .menu-icon-loop-folder(@attenList,'atten');
    @basemgrList:studentstatus,schoolcalendar,basemgr,oragizemgr,usermgr,authoritymgr,studentaccount;
    .menu-icon-loop-folder(@basemgrList,'basemgr');
    @resList:respublic,resschool,resclass,resrelease,resreleaselist,rescollectlist,resaudit,resauditsetting;
    .menu-icon-loop-folder(@resList,'res');
    //  @zsList:album,activity;
    // .menu-icon-loop(@zsList);

    //  @kqList:album,activity;
    // .menu-icon-loop(@kqList);

    // @jxList:album,activity;
    // .menu-icon-loop(@jxList);

    // @aiList:album,activity;
    // .menu-icon-loop(@aiList);

    // @aiList:album,activity;
    // .menu-icon-loop(@aiList);

    // @aiList:album,activity;
    // .menu-icon-loop(@aiList);

    // 循环生成菜单图标类名
    .menu-icon-loop-folder(@list,@folder,@i:1,@val:extract(@list,@i)) when (length(@list)>=@i){
        .top-menu-icon.menu-icon-@{val}{
            background-image:url('~@/assets/images/v3icons/@{folder}/@{val}.png');
        }
        .menu-icon-loop-folder(@list,@folder,(@i+1));
    }
    .menu-icon-loop(@list,@folder,@i:1,@val:extract(@list,@i)) when (length(@list)>=@i){
        .top-menu-icon.menu-icon-@{val}{
            background-image:url('~@/assets/images/v3icons/@{val}.png');
        }
        .menu-icon-loop(@list,@folder,(@i+1));
    }

    //下面是less官方文档给的循环实例，其实实际上这就是递归调用
    // .loop(@counter) when (@counter > 0) {
    //     .loop((@counter - 1));
    //     width: (10px + @counter);
    // }

    .top-menu-icon{
        background-size: 100% auto;
        font-size: 28px;
        margin-bottom: 5px;
        width: 56px;
        height: 56px;
        line-height: 56px;
        margin: 0 auto;
        background-image:url('~@/assets/images/v3icons/res/resreleaselist.png');
    }

</style>
