/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-01-31 17:50:23
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-03-31 14:28:58
 * @Description: 新增、修改表单弹窗
 */

import { propsColumns, eventColumns } from '../consts'
export default {
  componentFunctionDesc: '新增、修改表单弹窗, 路径: edumgr/components/base/BaseTable',
  props: {
    columns: propsColumns,
    list: [
      { name: 'data', desc: '双向绑定数据源', type: 'Object' },
      { name: 'option', desc: 'form表单配置项详细信息见下表option', type: 'Object' },
      { name: 'title', desc: '弹窗标题', type: 'String' },
      { name: 'visible', desc: '弹窗显隐(使用 :visible.sync="变量")', type: 'Boolean', default: 'false' },
      { name: 'dialogWidth', desc: '弹窗宽度', type: 'String', default: '480px' },
      { name: 'isEdit', desc: '是否是编辑状态', type: 'Boolean', default: 'false' },
      { name: 'showEditBtn', desc: '编辑状态下是否显示删除按钮', type: 'Boolean', default: 'false' },
      { name: 'dialogOption', desc: 'el-dialog - 配置项', type: 'Object' }
    ]
  },
  option: {
    columns: propsColumns,
    list: [
      { name: 'form', desc: '同el-form配置项', type: 'Object' },
      { name: 'row', desc: '同el-row配置项', type: 'Object' },
      { name: 'columns', desc: 'columns配置见下表说明', type: 'Array' }
    ]
  },
  columns: {
    columns: propsColumns,
    list: [
      { name: 'col', desc: '同el-col配置项', type: 'Object' },
      { name: 'formItem', desc: '同el-form-item配置项', type: 'Object' },
      { name: 'opt', desc: '复选框、单选框、下拉选择框、级联选择框 -- 配置label - value的别名', type: 'Object' },
      { name: 'options', desc: '复选框、单选框、下拉选择框、级联选择框 -- 数据源', type: 'Array' },
      { name: 'props', desc: '同其他基础表单的配置项', type: 'Object' }
    ]
  },
  events: {
    columns: eventColumns,
    list: [
      { name: 'submit', desc: '表单提交事件（如果设置了规则则通过验证时才会触发）function(data) {data为表单数据}' },
      { name: 'remove', desc: '表单删除事件function(data) {data为表单数据}' }
    ]
  }
}
