/*
 * @Author: liuzhihao
 * @Date: 2021-12-24 18:38:43
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-10-09 11:45:37
 * @FilePath: /web/src/utils/fetch.js
 * @Description: 头部注释
 */
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
// let store={};
import config from '../config';
import Vue from 'vue';
import LoginService from '@/services/LoginService';
import { getToken } from "@/utils/auth";
import md5 from 'js-md5';
import { getAuthToken, setAuthToken } from './auth';
var uuid = require("uuid");
// 创建axios实例
const service = axios.create({
  baseURL: config.API_EDU_ROOT, // api的base_url
  // timeout: 30000                  // 请求超时时间
  timeout: 3 * 60 * 1000
})
export const AUTH_TOKEN_KEY = 'authorization' // 区域平台认证token

function loginErrorTips() {
  MessageBox.confirm('登录已失效，可以取消继续留在该页面，或者重新登录', '登录失效', {
    confirmButtonText: '重新登录',
    type: 'warning',
    showCancelButton: true
  }).then(() => {
    LoginService.logout();
    // store.dispatch('FedLogOut');
    // return response.data;
  })
}
// request拦截器---加请求鉴权参数
service.interceptors.request.use(config => {
  let token = getToken() || "";
  if (token) {
    config.headers["x-token"] = token;
  }
  // 携带区域平台认证token
  config.headers[AUTH_TOKEN_KEY] = getAuthToken()

  let uuidVal = uuid.v4();
  if (uuidVal) {
    uuidVal = uuidVal.replace(/-/g,"")
    config.headers["traceId"] = uuidVal;
  }

  let data = config.data;
  if (data) {
    // data参数加密摘要
    let jsonStr = JSON.stringify(data) + "" + token;

    let hash = md5(jsonStr);
    config.headers["dhash"] = hash;
  }
  let params = config.params;
  if (params) {
    // params参数加密摘要
    let jsonStr = JSON.stringify(params) + "" + token;
    let hash = md5(jsonStr);
    config.headers["phash"] = hash;
  }

  let timestamp = new Date().getTime() + "" + parseInt(Math.random() * 1000);
  let tshash = md5(timestamp + "" + gweb.wxcodeDomain);
  config.headers["ts"] = timestamp + ";" + tshash;

  // let { user } = store.state.user;
  // if(user){
  //   config.headers["Authorization"] = user.authorization;
  // }

  return config;
}, error => {
  Promise.reject(error)
});

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
    * code为非20000是抛错 可结合自己业务进行修改
    */
    const res = response.data;
    // console.log("response:",response);
    if (response.status === 404) {
      Message({
        message: res.resmsg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject('error')

    } else if (response.data.rescode == 403) {
      loginErrorTips();
      return Promise.reject('error');
    } else if (response.data.rescode == 429) {
      return Promise.reject('访问次数过多，请稍后重试！');
    } else {
      // 保存区域平台认证token
      const authToken = response.headers[AUTH_TOKEN_KEY]
      if (authToken) setAuthToken(authToken)
      else if (response.config.url.includes('/login/getLoginData')) {
        // 如果getLoginData的token为空，清空token
        setAuthToken('')
      }
      
      return response.data
    }
  },
  error => {
    console.log('err:' + error)// for debug
    /*错误处理---登录失效处理流程*/
    // loginErrorTips();
    return Promise.reject(error)
  }
)

export default service
