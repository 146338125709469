/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-24 14:15:16
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-02-24 14:18:37
 * @Description: 空布局
 */

<template>
  <div class='wrap'>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name:'',
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {}
}
</script>
<style scoped>
.wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>
