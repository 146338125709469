<template>
  <section class="el-container wrapper is-vertical">
    <section v-if="loadEnd"
             class="el-container">
      <!-- <aside class="el-aside menu resize">
        <LeftMenu />
      </aside> -->
      <div id="right-content"
           class="el-main content resize">
        <router-view />
      </div>
    </section>
  </section>
</template>
<script>
import LayoutMixin from '@/mixins/LayoutMixin'
// import LeftMenu from 'components/skin01/LeftMenu'

export default {
  // components: {
  //   LeftMenu
  // },
  mixins: [LayoutMixin]
}
</script>

<style type="text/css" scoped>
.content {
  background: #f8f8f8;
  padding: 0;
}
.wrapper {
  height: 100%;
  min-width: 1276px;
}
.menu.resize {
  /* height:83.5vh; */
  /* height:calc(100vh - 116px); */
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
