<template>
  <!-- 切换角色 -->
  <div v-if="user" class="role">
    <img
      v-if="user && user.avatarUrl"
      :src="user.avatarUrl"
      alt=""
      class="role_headerImg"
    />

    <el-dropdown trigger="click" @command="changeRole">
      <div class="el-dropdown-link">
        <span v-if="userType === 2 && child" class="role_title">
          {{ child.childName }}
          <small
            >（{{ child.className }}）{{
              child.familyRole | clsRole(child.isJwh)
            }}</small
          >
        </span>

        <span v-else-if="userType === 1 && stuClass" class="role_title">
          {{ user.userName }}
          <small>（{{ stuClass.className }}）</small>
        </span>

        <span v-else class="role_title">
          <span>{{ user.userName }}</span>
          <small>{{ userType | userType(school) }}</small>
        </span>

        <i class="el-icon-arrow-down" style="color:#fff"></i>
      </div>

      <el-dropdown-menu class="switch-dropdown" slot="dropdown">
        <el-dropdown-item
          v-for="(role, index) in roleTypeInfos"
          :key="'role_' + index"
          :divided="index > 0"
          :command="{ school, role }"
        >
          <a href="javascript:;">
            <span v-if="role.userType !== 2 && role.userType !== 1">
              {{ role.userType | userType(school) }}</span
            >

            <span v-else-if="role.userType === 2">
              {{ role.childName }}
              <small
                >（{{ role.className }}）&nbsp;{{
                  role.familyRole | clsRole(role.isJwh)
                }}</small
              >
            </span>

            <span v-else-if="role.userType === 1">
              <small>{{ role.className }}</small>
            </span>
          </a>
        </el-dropdown-item>
        <!-- <el-dropdown-item divided
                          :command="{ op:'setting'}">
          <div>个人设置</div>
        </el-dropdown-item> -->
        <el-dropdown-item divided :command="{ op: 'exit' }">
          <div>退出</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from "@/mixins/SwitchSchoolAndRoleMixin"
import CONST_USER from "@/const/user"

export default {
  components: {},
  mixins: [SwitchSchoolAndRoleMixin],
  data() {
    return {}
  },
  filters: {
    userType: function (ut, school) {
      if (ut == 4 && school && school.stype === 0) {
        return "职员"
      }
      return CONST_USER.userType[ut] || "管理员"
    },
    clsRole(familyRole, isJwh) {
      if (isJwh == 1) {
        return "家委"
      } else {
        return "家长"
      }
    },
  },
}
</script>

<style scoped>
.role {
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.role_headerImg {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-right: 10px;
}

.role_title {
  color: #fff;
}
.role_title small {
  padding: 5px;
  margin-left: 15px;
}
.switch-dropdown a {
  color: #595959;
}
.el-dropdown-link {
  cursor: pointer;
}
</style>
