<template>
    <div class="sidebar-container" :class="{'hide-sidebar':sidebar.opened===false}">
        <div class="sign-container"  v-if="school">
            <img class="logo" v-if="school.slogo" :src="school.slogo" @error="imgError"/>
            <img v-else class="logo" :src="defaultLogo" >
        </div>
        <el-menu v-if="authMenus"
            class="edu-el-menu"
            :class="[skinCode]"
            mode="vertical"
            :default-active="defaultActiveMenu"
            :default-openeds="getOpensMenu()"
            :collapse="sidebar.opened==false"
        >
            <!-- 功能类型：1菜单，2按钮，3虚拟节点，4面板 -->
            <template v-for="(item) in authMenus">
                <template v-if="item.funcType==1">
                    <!-- 左侧菜单带二级节点 -->
                        <!-- v-if="item.menuCode=='SCHOOLMGR_EMPOWER'" -->
                    <el-submenu class="edu-el-submenu" 
                        
                        :data-key="item.menuIndexKey" 
                        :key="item.menuIndexKey" :index="item.menuIndexKey" 
                        v-if="hasChildMenu(item)" 
                        >
                        <template slot="title">
                        <span :is="menuIcon" :icon="item.icon"></span>
                        <span>{{item.menuName}}</span>
                        </template>
                        <el-menu-item v-for="sub in item.menuDtos" :data-key="sub.menuIndexKey" :key="sub.menuIndexKey" :index="sub.menuIndexKey" @click="handleMenuRedirect(sub)">
                            <span  slot="title"><i class="web-left-menu-icon" :class="getIcon(sub.icon,'folder')"></i>{{sub.menuName}}</span>
                        </el-menu-item>
                    </el-submenu>
                    <!-- 左侧只展示一级菜单 -->
                    <el-menu-item v-else :data-key="item.menuIndexKey" :key="item.menuIndexKey" :index="item.menuIndexKey" @click="handleMenuRedirect(item)">
                        <span :is="menuIcon" :icon="item.icon"></span>
                        <span  slot="title">{{item.menuName}}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>

        <xycloud-redirect v-model="redirectNum"></xycloud-redirect>
        <!-- <forward-redirect v-model="forwardNum" :skipParamArr="skipParamArr"></forward-redirect> -->
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import MenuIconSass from './sub/MiconSkinSass';
    import MenuIconMiddle from './sub/MiconSkinMiddle';
    import defaultLogo from '@/assets/images/school-logo.png';

    import XycloudRedirect from './sub/XycloudRedirect';
    // import ForwardRedirect from '@skin/components/busi/ForwardRedirect';

    import MenuService from '@/services/MenuService';
    import MenuRedirectService from '@/services/MenuRedirectService';
    import MenuMixin from '@/mixins/MenuMixin';
    export default {
        name: 'EoaSidebar',
        props: {
            query: {
                type: Object,
                default: null
            }
        },
        data(){
            return {
                defaultLogo:defaultLogo,
                redirectNum:0,

                /*forward redirect 跳转*/
                forwardNum:0,
                skipParamArr:null
            }
        },
        mixins:[MenuMixin],
        components:{
            MenuIconSass,
            MenuIconMiddle,
            XycloudRedirect,
            // ForwardRedirect
        },
        watch:{

        },
        computed:{
            school(){
                return this.$store.state.user.school
            },
            firstCheckMenuIndex(){
                return this.$store.state.user.firstCheckMenuIndex
            },
            domainConfig(){
                return this.$store.state.app.domainConfig
            },
            sidebar(){
              return this.$store.state.app.sidebar;
            },
            topMenuIndex(){
              return this.$store.state.user.topMenuIndex;
            },
            authMenus(){
              let authMenus=this.$store.state.layout.userMenuList;
              return authMenus;
            },
            curMenuList(){
                return this.$store.state.layout.curMenuList;
            },
            skinCode(){
                let domainConfig=this.domainConfig;
                if(domainConfig&&domainConfig.idxTemp){
                    return domainConfig.idxTemp.code;
                }
            },
            menuIcon(){
                let domainConfig=this.domainConfig;
                let iconComponent="MenuIconSass";
                if(domainConfig&&domainConfig.idxTemp.code=="middle"){
                    iconComponent="MenuIconMiddle"
                }
                return iconComponent;
            },
            defaultActiveMenu(){
                let curMenuList=this.curMenuList;
                if(!curMenuList){
                    return null;
                }
                let len=curMenuList.length;
                if(len>=3){
                    return curMenuList[len-2].menuIndexKey;
                }else if(len>0){
                    return curMenuList[len-1].menuIndexKey;
                }
                return null;
            }
        },
        mounted(){

        },
        methods: {
            getIcon(icon,type){
            if(!icon){
                if(type=="folder"){
                    return "fa fa-folder";
                }
                return "fa fa-file-text-o";
            }
            return icon;
        },
            getMenuList(menuDataList){
                return MenuService.getMenuDataList(menuDataList);
            },
            hasChildMenu(menu){
                //是否存在下级菜单，去除其他类型的数据
                let menuList=this.getMenuList(menu.menuDtos);
                if(menuList&&menuList.length>0){
                    return true;
                }
                return false;
            },
            getOpensMenu(){
                let authMenus=this.authMenus;
                let url=this.$route.fullPath;
                let curMenuList=this.curMenuList;

                if(!curMenuList||!curMenuList.length){
                    return [];
                }

                let curMenuKeyIndexs= curMenuList.map((menu)=>{
                    return menu.menuIndexKey;
                });

                return curMenuKeyIndexs;
            },
            imgError(){
                this.school.slogo=this.defaultLogo;
            },
            getActiveMenuUrl(){
                let fullPath=this.$route.fullPath;

                let arr=fullPath.split("/");
                let len=arr.length;
                if(arr[len-1]===""){
                    arr.splice(len-1);
                }
                if(len>3){
                    arr.splice(3);
                }
                return arr.join("/");

            },
            handleClick(menu){
                if(menu.menuCode=='SCHOOLMANAGER_XYCLOUD_REDIRECT'||menu.menuCode=='TEACHER_XYCLOUD'){
                    this.redirectNum++;
                    return;
                }
                let skipTag=menu.skipTag;
                if(skipTag){
                    let skipParamArr=skipTag.split("|");
                    if(skipParamArr&&skipParamArr[0]=="redirect"){
                        /*跳转模式*/
                        this.forwardNum++;
                        this.skipParamArr=skipParamArr;
                        //跳转
                        MenuRedirectService.forwardRedirect(skipParamArr);
                        return;
                    }
                }
                let subMenuList=MenuService.getMenuDataList(menu.menuDtos);
                if(subMenuList&&subMenuList.length>0){
                    let firstMenu=subMenuList[0];
                    //skipType 跳转类型：1：当前窗口打开  2：新窗口打开
                    if(firstMenu.skipType===2){
                        window.open("#"+firstMenu.menuUrl, '_blank');//新窗口打开
                    }else{
                        this.$router.push({path:firstMenu.menuUrl});
                    }
                }else{
                    this.$message.error("菜单错误，请联系管理员");
                }
                /*this.$router.push({path:menu.menu_url_state});*/
            }
        }
    }
</script>

<style  lang="less" scoped>
    .sidebar-container{
        background: #00b5c2;
    }
    .edu-el-menu.sass{
        min-height: 100%;
        padding-bottom: 100px;
        padding-bottom: 100px;
        background: #00b5c2;
        border-right: none;
        /deep/.el-menu-item{
            color:#fff;
            i{
                margin-right: 5px;
                color:#ddd;
            }
        }
        /deep/.el-submenu__title{
            color:#fff;
        }
        /deep/.el-icon-arrow-down{
            color:#fff;
        }
        /deep/.el-menu-item.is-active,.el-menu-item:hover{
            color: #fff;
            background: #00909b;
            box-sizing: border-box;
        }
        /deep/.el-submenu__title:hover{
            color: #fff;
            background: #00909b;
            box-sizing: border-box;
        }
        /deep/.el-menu--inline{
            background: #00b5c2;
        }
    }
    // 绿色版
    .edu-el-menu.middle{
        background: #fff;
        /deep/.el-menu-item{
            i{
                margin-right: 5px;
                color:#999;
            }
        }
        /deep/.el-menu-item.is-active,.el-menu-item:hover{
            color: #14CF82;
            background: #f0f1f6;
            box-sizing: border-box;
            i{
                color: #14CF82;
            }
        }
        /deep/.el-menu-item.is-active:before{
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 3px;
            left: 0;
            background:  #2bbb61;
        }

    }

    .sign-container{
        background: #00a1ac;
        padding: 15px 15px 14px;
        text-align: center;
    }
    .sign-container .logo{
        max-width: 150px;
        max-height: 50px;
        min-height: 50px;
    }
    .sidebar-container.hide-sidebar .sign-container{
        padding: 10px 5px;
    }
    .sidebar-container.hide-sidebar .sign-container .logo{
        width: 50px;
        height: 50px;
    }

    .domain-middle .sidebar-container{
        background: #fff;
    }

    /* middle版本--中小版 */
    .domain-middle .sidebar-container{
        background: #fff;
        border-right: 1px solid #ececec;
    }
    .domain-middle .sign-container{
        background: #fff;
    }
    // .domain-middle .edu-el-menu{
    //     background: #fff;
    // }
    // .domain-middle .edu-el-menu .el-menu-item{
    //     color: #333;
    // }
    // .domain-middle .edu-el-menu .el-menu-item:focus,
    // .domain-middle .edu-el-menu .el-menu-item:hover,
    // .domain-middle .edu-el-menu .el-menu-item.is-active{
    //     color: #14CF82;
    //     background: #f0f1f6;
    //     box-sizing: border-box;
    // }
    // .domain-middle .edu-el-menu .el-menu-item{
    //     position: relative;
    // }
    // .domain-middle .edu-el-menu .el-menu-item.is-active:before{
    //     content: " ";
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     width: 3px;
    //     left: 0;
    //     background:  #2bbb61;
    // }
    /*
    .menu-text{
      font-size: 12px;
    } */
</style>


