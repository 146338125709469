<!--
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2022-10-31 10:14:20
 * @FilePath: /web/src/components/DevTool/IconPicker/index.vue
 * @Description: 简介
-->
<template>
  <div>
    <p>
      说明：将需要使用的svg图标文件放到static仓库的svgIcons文件夹中，在web仓库执行
      yarn svgo
      命令压缩图标并去色，然后在当前面板点击对应图标复制，最后在任意仓库中粘贴即可生效
    </p>
    <p>
      注意：如果在static仓库添加了新图标，更新到线上环境时，需重新打包web仓库(./rebuild_web.sh)才能注册svg图标成功
    </p>
    <el-input
      placeholder="搜索图标"
      style="display: block"
      prefix-icon="el-icon-search"
      v-model="searchIcon"
    >
    </el-input>
    <div class="grid">
      <div
        v-for="item of icons"
        :key="item"
        @click="handleClipboard(generateIconCode(item), $event)"
      >
        <el-tooltip placement="top">
          <div slot="content">
            {{ generateIconCode(item) }}
          </div>
          <div class="icon-item">
            <svg-icon :icon-class="item" class-name="disabled" />
            <span>{{ item }}</span>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import clipboard from "@/utils/clipboard";
import svgIcons from "./svg-icons";

export default {
  name: "Icons",
  data() {
    return {
      svgIcons,
      searchIcon: "",
    };
  },
  computed: {
    icons() {
      return [...this.svgIcons].filter((item) =>
        item.includes(this.searchIcon)
      );
    },
  },
  methods: {
    generateIconCode(symbol) {
      return `<svg-icon icon-class="${symbol}" />`;
    },
    handleClipboard(text, event) {
      clipboard(text, event);
    },
  },
};
</script>

<style scoped>
.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(96px, 0fr));
}

.icon-item {
  height: 80px;
  margin-top: 12px;
  text-align: center;
  width: 96px;
  float: left;
  font-size: 30px;
  color: #24292e;
  cursor: pointer;
}

span {
  display: block;
  font-size: 16px;
  margin-top: 10px;
}

.disabled {
  pointer-events: none;
}
</style>
