<template>
  <div>
    <el-dropdown v-if="schoolList && schoolList.length > 1" @command="changeSchool">
      <span class="school_list">
        <school-logo class="school-logo" :slogo="school.slogo"></school-logo>
        <div class="school-name">{{ schoolName }}</div>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" class="group_school_list">
        <el-dropdown-item
          v-for="school, i of schoolList"
          :key="i"
          class="school_item"
          :command="{school}"
        >
          <school-logo class="school_item_logo" :slogo="school.slogo"></school-logo>
          <div class="school_item_name">{{ school.schoolName }}</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="school-info" v-else :title="schoolName">
      <school-logo class="school-logo" :slogo="school.slogo"></school-logo>
      <div class="school-name">{{ schoolName }}</div>
    </div>
  </div>
</template>

<script>
import SchoolLogo from '@/components/SchoolLogo';
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin';
export default {
  components: {
    SchoolLogo
  },
  mixins: [SwitchSchoolAndRoleMixin],
  data() {
    return {};
  },
  computed: {
    // 用户
    user() {
      return this.$store.state.user
    },
    // 学校
    school() {
      return this.$store.state.user.school;
    },
    // 学校名称
    schoolName() {
      return this.school && this.school.schoolName || ''
    },
    // 学校列表
    schoolList() {
      return this.user && this.user.schoolList
    }
  },
  created() {},
  methods: {
    handleCommand(school) {

    }
  }
}
</script>
<style scoped lang="less">
.group_school_list {
  max-height: 350px;
  overflow-y: auto;
}

.school_list,
.school-info {
  display: flex;
  align-items: center;
  color: #fff;
}

.school_item {
  display: flex;
  align-items: center;
  &_logo {
    display: flex;
    width: 16px;
    height: 16px;
  }
  &_name {
    margin-left: 8px;
    font-size: 16px;
  }
}

.school-logo {
  width: 44px;
  height: 44px;
}

.school-name {
  margin-left: 8px;
  font-weight: 600;
  font-size: 24px;
}
</style>