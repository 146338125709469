/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-02 14:18:36
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-03-28 16:59:19
 * @Description: 集团校除首页外其他页面的布局
 */

<template>
  <div class="wrap">
    <el-container class="group_header">
      <!-- 集团名称 -->
      <school-info></school-info>
      <!-- 菜单列表 -->
      <first-level-menu @change="handleFirstLevelMenuChange"></first-level-menu>
      <!-- 账户信息、角色 -->
      <ChangeRoleAndUserManage></ChangeRoleAndUserManage>
    </el-container>
    <el-container style="height: calc(100vh - 64px); background-color: #f6f7f9">
      <el-aside v-if="curMenu && curMenu.menuDtos" width="200px" class="wrap_aside">
        <SiderBar
          :sider-bar-menu="curMenu.menuDtos"
          :active-side-menu="activeSideMenu"
        />
      </el-aside>
      <el-main class="wrap_main">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import SiderBar from './components/SiderBar'
import SchoolInfo from './components/SchoolInfo'
import TopMenuIcon from './components/TopMenuIcon';
// import ChangeRole from './components/ChangeRole'
import ChangeRoleAndUserManage from './components/ChangeRoleAndUserManage'
import ThemeMixin from '@/mixins/ThemeMixin';
import FirstLevelMenu from './components/FirstLevelMenu'
export default {
  components: {
    SchoolInfo,
    // ChangeRole,
    ChangeRoleAndUserManage,
    TopMenuIcon,
    SiderBar,
    FirstLevelMenu
  },
  mixins: [ThemeMixin],
  data() {
    return {
      activeSideMenu: '', // 左侧菜单 - 当前激活菜单
    };
  },
  computed: {
    curMenuList() {
      return this.$store.state.layout.curMenuList;
    },
    // 当前菜单
    curMenu() {
      let curMenuList = this.curMenuList;
      if (curMenuList && curMenuList.length) {
        return curMenuList[0];
      }
    }
  },
  methods: {
    handleFirstLevelMenuChange(key) {
      this.activeSideMenu = key
    }

  }
}
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  &_main {
    // margin: 24px;
    background: #f6f7f9;
  }
  &_aside {
    height: 100%;
    border-right: 1px solid #f0f1f5;
  }
  /deep/ .el-menu {
    border: none;
  }
}

.group_header {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1A58E8;
  // background: #fff;
  box-shadow: 0px 2px 8px rgba(29, 35, 41, 0.05);
  color: #fff;
  box-sizing: border-box;
}

.school-info {
  display: flex;
  align-items: center;
}

.school-logo {
  width: 32px;
  height: 32px;
}

.school-name {
  margin-left: 8px;
  font-weight: 600;
  font-size: 24px;
}
</style>