/*
 * @Author: longtuxin
 * @LastEditors: liuzhihao
 * @LastEditTime: 2023-12-14 17:10:29
 * @FilePath: \web\config\pfv.js
 * @Description: 简介
 */
// 大平台平台版本
const spacePfvs = ['webportal', 'newportal', 'zjportal']

const saasSpacePfvs = ['space','qhf']

/** 不同pfv版本对应的布局组件 */
const pfvLayoutMap = {
  web: 'LayoutWeb',
  wisdomPlatform: 'LayouTeachPlatform',
  groupSchool: 'LayoutGroupSchool',
  middle: 'LayoutMiddle',
  saas: 'LayoutMiddle',
  v3: 'LayoutV3',
  V3: 'LayoutV3',
  nanning: 'LayoutWebNanning',
  webportal: 'LayoutSpace2',
  newportal: 'LayoutSpace2',
  zjportal: 'LayoutSpace2',
  space: 'LayoutSpace',
  qhf: 'LayoutSpace',
  regional: 'LayoutRegional',
  clsEval: 'LayoutClsEval' // 课堂评价-windows客户端
}

export { spacePfvs,saasSpacePfvs, pfvLayoutMap }