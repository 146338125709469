<template>
	<el-dialog
        width="1000px" 
        center
        :visible.sync="dialogVisible"
        title="完善任教科目"
        >
			<div class="top-desc">请完善您的任教科目</div>
			<el-collapse v-model="activeNames" >
                <template v-for="(cls,index) in classList">
                <el-collapse-item :title="cls.className" :name="cls.classId+''" :key="index" class="cls-box">
                   <el-checkbox-group v-model="cls.subjects">
                       <template v-for="(subject,index) in schoolSubjects">
                            <el-checkbox :label="subject" :key="index" :value="subject.id">{{subject.name}}</el-checkbox>
                       </template>
                    </el-checkbox-group>
                </el-collapse-item>
                </template>
			</el-collapse>
			<div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </div>
    </el-dialog>
</template>
<script type="text/javascript">
	import {postByCode} from '@/api/common';
	export default{
		props:{
            visible:{},
            noSubjectClassList:{}
		},
		data:function(){
			return {
				dialogVisible:false,
                loading:false,
                
                classList:null,
                schoolSubjects:null,
                activeNames:[]
				
			}
		},
		components:{
			
		},
		watch:{
			dialogVisible(){
                this.$emit("update:visible",this.dialogVisible);
            },
            visible(){
                this.dialogVisible=this.visible;
                if(this.dialogVisible===true){
                    this.querySchoolSubject();
                    this.classList=this.noSubjectClassList.map((cls)=>{
                        this.$set(cls,"subjects",[]);
                        return cls;
                    });
                }
            }
        },
		methods:{
            handleSubmit(){
                let classList=this.classList;

                let notSelSubjectsClass=null;
                for(let i=0;i<classList.length;i++){
                    let cls=classList[i];
                    
                    let subjects=cls.subjects;
                    if(subjects.length==0){
                        notSelSubjectsClass=cls;
                        break;
                    }
                }
                if(notSelSubjectsClass){
                    this.$message.error("请选择"+notSelSubjectsClass.className+"任教科目");
                    return;
                }

                let result=classList.map(el => {
                    return {
                        userId:"W_USERID",
                        schoolId:"W_SCHOOLID",
                        classId:el.classId,
                        subjectIds:el.subjects.map((s)=>{return s.id;})
                    }
                });
                let code="I_BDM_BDM_TEACHING_SUBJECT_SAVE_USER_TEACHING_CLZ";
                let params={
                    userTeachings:result
                }
                postByCode(code,params).then((json)=>{
                    if(json.status===false){
                        this.$message.error(json.resmsg)
                        return;
                    }
                    this.$message.success("设置成功");
                    this.toNext();
                });
            },
            querySchoolSubject(){
                let code="I_REST_ADAPTER_SUBJECT_QUERYSUBJECT";
                let params={
                    schoolId:"W_SCHOOLID"
                }
                postByCode(code,params).then((json)=>{
                    if(json.status===false){
                        this.$vux.alert.show({
                            content:json.resmsg
                        });
                        return;
                    }

                    this.schoolSubjects=json.data.list;
                });
            },
            toNext(){
                this.dialogVisible=false;
                this.$emit("success",1);
            },
			close(cls){
				this.dialogVisible=false;
			},
        }
    }
</script>
<style lang="less" scoped >
    .top-desc{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .cls-box{
        padding-left:15px;
        border-left: 1px solid #ececec;
        border-right: 1px solid #ececec;
        /deep/.el-collapse-item__header{
            font-size: 18px;
        }
    }
</style>