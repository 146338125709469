/*
 * @Author: liuzhihao
 * @Date: 2020-09-15 09:31:16
 * @LastEditors: Zhongnanwei
 * @LastEditTime: 2021-12-29 13:58:38
 * @FilePath: \web\src\locale\normal.js
 * @Description: 头部注释
 */
var serviceTel = "4008804333";
var company = "宜教通";
var productTitle = "智慧校园";
var ueditorWordLimit = 7000;

export default {
	company: company,
	productTitle: productTitle,
	ueditorWordLimit: ueditorWordLimit,
	tips: {
		uploadingAtt: "文件上传中，请耐心等待上传完成",
		uploadingImg: "图片上传中，请耐心等待"
	},
	homework: {
		timeTip: "截止时间不能小于当前时间"
	},
	campus: {
		timeTip: "截止日期不能小于当前日期"
	},
	office: {
		emptyText: "系统暂未开通此功能，如需开通请联系" + company + "客服" + serviceTel,
		groupSettingTips: "1、可拖动整个类别条进行排序 2、鼠标移动到类别条，可进行名称编辑或类别删除"
	},
	authority: {
		tips: `您可以将校级应用的管理权限赋予给普通老师，以便让老师辅助管理相应的校级业务。不影响老师原有的班级权限管理。
			<br/>为了安全起见，每个校级应用的权限人员不超过{{num}}个。`
	},
	xycloud: {
		tips: `（1）云盘一但关联到班级就会消耗一个云盘。云盘用户数满后，将无法再加入新的使用用户；<br/>
			（2）进行班主任的调整后，需在云盘配置里重新进行云盘管理权限的移交，该班主任方可管理该班云盘`
	}
}