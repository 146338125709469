/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-02 14:22:13
 * @Last Modified by: caiyf
 * @Last Modified time: 2023-02-24 15:35:30
 * @Description: 集团校布局 通过路由中的meta对象中的groupSchool属性判断每个页面所对应的头部
 * $route.meta.groupSchool: index：首页工作台头部、other：除首页外其他页面的头部、fusion：融合文化头部、single：单校头部
 */

<template>
  <div class='container' v-if="user">
    <component :is="layout"></component>
  </div>
</template>
<script>
import IndexLayout from './components/IndexLayout'
import OtherLayout from './components/OtherLayout'
import BlankLayout from './components/BlankLayout'
// import FusionLayout from './components/FusionLayout'
// import SingleLayout from './components/SingleLayout'
import LayoutMixin from "@/mixins/LayoutMixin";
export default {
  components: {
    IndexLayout,
    OtherLayout,
    BlankLayout
    // FusionLayout,
    // SingleLayout
  },
  mixins: [LayoutMixin],
  data() {
    return {
      
    };
  },
  computed: {
    layout() {
      // groupSchool匹配顶级菜单需要选择哪个 index工作台、other跟saas其他一级路由类似、blank空布局（自由发挥）
      let route = this.$route
      let metched = route.matched
      // 存放该路由及父级路由的groupSchool
      let groupSchoolArr = []
      // 判断是否有匹配的父级路由
      if (metched && metched.length) {
        metched.forEach(v => {
          let index = route.path.indexOf(v.path)
          // 匹配到父级路由后则判断路由meta配置里是否有groupSchool字段，有则添加进数组
          if (index === 0) {
            const meta = v.meta || {}
            meta.groupSchool && groupSchoolArr.unshift(meta.groupSchool)
          }
        })
      }
      // 优先使用自身的groupSchool(存在动态params参数时匹配不上，需先拿自身的)，没有则往上级找，全部没找到时默认使用OtherLayout布局
      let val = (route.meta || {}).groupSchool
      val || (val = groupSchoolArr.find(v => v))
      if (!val) return 'OtherLayout'
      return {
        index: 'IndexLayout',
        other: 'OtherLayout',
        blank: 'BlankLayout'
        // fusion: 'FusionLayout',
        // single: 'SingleLayout'
      }[val]
    },
    user() {
      return this.$store.state.user.user
    }
  },
  methods: {}
}
</script>
<style lang='less' scoped>
.container {
  width: 100%;
  height: 100%;
  min-width: 1024px;
  overflow-x: auto;
}
</style>