<!--
 * @Author: TianGuiLan
 * @Date: 2023-04-06 16:26:04
 * @LastEditors: TianGuiLan
 * @LastEditTime: 2023-04-06 22:52:22
 * @Description: 头部注释
-->
<template>
  <div class=""></div>
</template>

<script>
//import 导⼊⽂件 第三⽅插件js > json⽂件 > ⼯具js >组件
export default {
  name: '',
  props: {},
  components: {},
  mixins: [],
  data() {
    return {}
  },
  computed: {},
  created() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$route.query.path) {
        vm.$router.replace({ path: vm.$route.query.path })
      } else {
        vm.$router.go(-1)
      }
    })
  },
  //⽣命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //⽣命周期 - 销毁完成
  destroyed() {},
  watch: {},
  methods: {
    /**
     * @description:
     * @param {type}
     */
  }
}
</script>
<style scoped></style>
