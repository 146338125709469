/*
 * @Author: longtuxin
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-10-18 23:43:48
 * @FilePath: \static\theme\ThemePortal\index.js
 * @Description: 大平台主题
 */
// // 主题样式
// import './index.css'
// // icon样式
// import './iconCss/index.css'
// // 导入全局样式
// import './app/index.css'

var themeColor = '#2D55EB'

export default {
  themeId: 'portal',
  iconType: 2,
  themeColor,
  menuBgUrl: require('../../images/theme/menuBg/1.png'),
  menuTextColor: '#252526',
  statisticsColor: {
    // 公假
    sabbaticals: themeColor,
    // 病假
    sickLeave: "#EA5008",
    // 事假
    casualLeave: "#F5C95E",
    // 打卡
    clockIn: themeColor,
    // 需打卡
    needClockIn: "#16D2AE",
  }
}