import MenuService from '@/services/MenuService';
import router from '@/router'
import appStore from "./app.js";
import { spacePfvs,saasSpacePfvs } from '../../../config/pfv';

// import Cookies from 'js-cookie'
const layout = {
  state: {
    // 菜单
    userMenuList: null,
    curMenuList: null,
    homeMenu: null,
    homeMenuUrl: null,

    menuHistoryList: [],
    spaceLoadEnd: true,
    menuCode: ''
    // sidebar: {
    //   opened: !+Cookies.get('sidebarStatus')
    // },
  },
  mutations: {
    // TOGGLE_SIDEBAR: state => {
    //   if (state.sidebar.opened) {
    //     Cookies.set('sidebarStatus', 1)
    //   } else {
    //     Cookies.set('sidebarStatus', 0)
    //   }
    //   state.sidebar.opened = !state.sidebar.opened
    // },
    SET_MENU_DATA: (state, {json, domainConfig}) => {
      state.menuCode = json.data && json.data.menuCode;
      let menuDtos = json.data && json.data.menuDtos;
      if (!menuDtos) {
        state.homeMenuUrl = null
        state.homeMenu = null
        state.userMenuList = null
        return
      }

      let empowerMenuDtos = json.data.empowerMenuDtos;
      if (empowerMenuDtos && empowerMenuDtos) {
        let empowerFuncList = [];
        empowerMenuDtos.forEach(element => {
          empowerFuncList.push(...element.menuDtos);
        });
        /*校级管理授权*/
        menuDtos.push({
          funcType: 1,
          icon: "menu-icon-xjgl",
          menuCode: "SCHOOLMGR_EMPOWER",
          menuDtos: empowerFuncList,
          menuId: "SCHOOLMGR_EMPOWER",
          menuName: "校级管理",
          menuType: 8,
          menuUrl: "",
          sortNum: 1,
        });
      }
      //进行初始化处理
      state.userMenuList = MenuService.initMenuData(menuDtos);
      let menus = MenuService.getMenuDataList(state.userMenuList);
      // 设置首页菜单信息
      // 如果是大平台，首页设为 /space
      // if (domainConfig && domainConfig.pfv && spacePfvs.includes(domainConfig.pfv)) {
      //   state.homeMenuUrl = "/space2/m_home"
      //   return
      // }
      // if (domainConfig && domainConfig.pfv && saasSpacePfvs.includes(domainConfig.pfv)) {
      //   state.homeMenuUrl = "/space/m_home"
      //   return
      // }
      let homeMenu = MenuService.getFirstJumpMenu(menus[0]);
      state.homeMenu = homeMenu;
      console.log('homeMenu',homeMenu)
      if (state.homeMenu) {
        state.homeMenuUrl = homeMenu.menuUrl;
      }
    },
    SET_CUR_MENUS(state, route) {
      let curMenuList = MenuService.findCurMenuList(state.userMenuList, route.fullPath);
      state.curMenuList = curMenuList;
      // if (curMenuList) {
      //   state.curMenuList = curMenuList;
      // } else {
      //   // 找不到菜单时,无权限打开,跳转页面
      //   router.push({ path: "/error" })
      // }
    },
    // save_menu_rediret
    SAVE_MENU_HISTORY(state, menu) {
      if (!menu || !menu.menuName) {
        return;
      }
      let historyList = state.menuHistoryList;
      let index = historyList.findIndex((it) => {
        return it.menuId === menu.menuId;
      });
      if (index !== -1) {
        state.menuHistoryList.splice(index, 1);
      }
      if (state.menuHistoryList.length > 10) {
        state.menuHistoryList.splice(0, 1);
      }
      state.menuHistoryList.push(menu);
    },
    REMOVE_MENU_HISTORY(state, index) {
      state.menuHistoryList.splice(index, 1);
    },
    CLEAR_MENU_HISTORY(state, index) {
      if (state.menuHistoryList && state.menuHistoryList.length > 0) {
        let len = state.menuHistoryList.length;
        state.menuHistoryList.splice(0, len);
      }
    },
    SET_SPACE_LOADEND(state, data) {
      state.spaceLoadEnd = !!data;
    },
  },
  actions: {
    setMenuInfo({ commit, rootState }, json) {
      commit("SET_MENU_DATA", {json, domainConfig: rootState.app.domainConfig});
    },
    setSpaceLoadEnd({ commit, rootState }, data) {
      commit("SET_SPACE_LOADEND", data);
    },
  }
}

export default layout
