import store from '../store';
import layout from '../store/modules/layout.js';
import { postByUrl } from '@/api/common';


const filterUrl = ['/error', '/index', '/login'];

const getCharCount = (str, char) => {
  let regex = new RegExp(char, 'g');
  let result = str.match(regex);
  let count = !result ? 0 : result.length;
  return count;
}
/**
 * 解决tabs切换的 降低匹配要求 /超过等于2级，去掉最后一级全匹配
 */
const lowMatching = (path, str, count) => {
  let arr = str.split("/");
  let len = arr.length;
  arr[len - 1] = '';

  let shortPath = arr.join("/");
  let hasFlag = count > 2 && path.includes(shortPath);
  return hasFlag;
}



/**
 * menuUrl存在且当前路径包含跳转路径且至少是两层起 /attendance/attendanceStatica/
 * 还是会存在一些路径并没有在运营平台里录入的，例如tabs的切换
 */
export const getCurMenuList = async (to, next) => {
  let path = to.path;
  let { userMenuList } = layout.state;

  if (filterUrl.includes(path)) {
    next();
    return;
  }
  if (!userMenuList) {
    const json = await postByUrl("/login/queryMenuList", { typeCode: "web" });
    store.dispatch("setMenuInfo", json);
    userMenuList = json.data.menuDtos;
  }
  let findMenu = (menu) => {
    if (!menu) {
      return;
    }
    let hasMenuPath = menu.find(x => {
      let count = getCharCount(x.menuUrl, '/')
      let hasLowMatching = lowMatching(path, x.menuUrl, count);
      //console.log(x.menuUrl, x.menuName, path, path.includes(x.menuUrl), count > 1, hasLowMatching)
      if (x.menuUrl && (path.includes(x.menuUrl) || hasLowMatching) && count > 1) {
        return x.menuUrl
      } else {
        return findMenu(x.menuDtos);
      }
    });
    return hasMenuPath

  }
  let hasMenu = findMenu(userMenuList);
  if (hasMenu) {
    next();
  } else {
    next('/error')
  }
}