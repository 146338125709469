import { render, staticRenderFns } from "./PageTableContainer.vue?vue&type=template&id=00c44328&scoped=true&"
var script = {}
import style0 from "./PageTableContainer.vue?vue&type=style&index=0&id=00c44328&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c44328",
  null
  
)

export default component.exports