
const getColor = function () {
  let theme = localStorage.getItem('domainThemeColor')
  let arr = theme ? theme.split('|') : ''
  if (arr.length > 0) {
    return arr[0]
  }
  return '#388DF7'
}

const bindThemeColor = (el, bingding) => {
  let style = el.getAttribute('style') || ''
  style += ';--themeColor:' + getColor()
  // style += ';color:' + getColor()
  el.setAttribute('style', style);
}

export default {
  inserted (el, binding) {
    bindThemeColor(el, binding);
  },
  update (el, binding) {
    bindThemeColor(el, binding);
  }
}