import MenuService from '@/services/MenuService'
import MenuRedirectService from '@/services/MenuRedirectService'
import common from '@/common/common'
import mconfig from '../../../mconfig/static'
import { postByCode } from '@/api/common'
export default {
  data() {
    return {
      setSubjectVisible: false,
      noSubjectClassList: null,
      curJumpMenu: null,
      filterModel: [
        'WEB_M_EVALUATING',
        'WEB_T_EVALUATING',
        'WEB_S_EVALUATING',
        'KECHENGZIYUAN',
        'WEB_M_EVALUATING4',
        'WEB_M_EVALUATING62',
        'WEB_T_EVALUATING3',
        'WEB_T_HOME_WORK',
        "PORTAL_TEACHER_SCORES_LIST2"
      ]
    }
  },
  computed: {
    lang() {
      return localStorage.getItem('locale') || 'zh'
    },
    schoolId() {
      let school = this.$store.state.user.school
      return school && school.schoolId
    }
  },
  methods: {
    getTranslateMenuName(menu) {
      if (!menu.menuAlias) return menu.menuName
      const menuAlias = JSON.parse(menu.menuAlias)
      const alias = menuAlias.find((m) => m.language === this.lang)
      if (!alias) return menu.menuName
      return alias.aliasName
    },
    async validExistSubject(menu) {
      // 是否通过校验，老师身份 所有班级都有科目，有返回true,没有返回false
      if (menu.menuType !== 4) {
        return true
      }
      let code = 'I_BDM_BDM_TEACHING_SUBJECT_CHECK_TEA_NO_SUB'
      let params = {
        schoolId: 'W_SCHOOLID',
        userId: 'W_USERID',
      }
      let json = await postByCode(code, params)
      let data = json.data
      this.noSubjectClassList = data
      if (data && data.length > 0) {
        return false
      }
      return true
    },
    async handleMenuRedirect(menu, notRefresh) {
      if (!menu) {
        this.$router.push({ path: '/error' })
        console.log('error')
        return
      }
      console.log(menu, 'menu====>')
      // 菜单跳转
      let jumpMenu = MenuService.getFirstJumpMenu(menu)
      if (!notRefresh) {
        this.$store.commit('SAVE_MENU_HISTORY', jumpMenu)
      }
      if (!jumpMenu) {
        this.$message.error('菜单错误')
        return
      }
      let skipTag = jumpMenu.skipTag
      if (skipTag === 'validExistSubject') {
        //设置科目弹框
        let result = await this.validExistSubject(jumpMenu)
        if (result === false) {
          this.curJumpMenu = jumpMenu
          this.setSubjectVisible = true
          return
        }
      }
      this.execRedirect(jumpMenu)
    },
    execRedirect(jumpMenu) {
      if (!jumpMenu) this.$message.error('菜单数据错误')
      const skipTag = jumpMenu.skipTag
      if (skipTag) {
        const skipParamArr = skipTag.split('|')
        if (skipParamArr && skipParamArr[0] == 'redirect') {
          MenuRedirectService.forwardRedirect(skipParamArr)
          return
        }
      }

      let jumpUrl = jumpMenu.menuUrl
      if (jumpMenu.menuCode == 'WEB_M_HEADMASTER') {
        jumpUrl = origin + '/ssportal/datacenter/#/datacenter/headmaster/manage'
      }

      // 路径中需要携带哈希值（/ssportal/datacenter/#/datacenter/new_headmaster）
      if (jumpMenu.skipTag === 'NEED_CARRY_HASH') {
        let { origin } = window.location
        jumpUrl = origin + jumpUrl
      }
      
      // 集团校 - 跳转融合文化墙
      if (jumpMenu.skipTag === 'GROUP_SCHOOL_CONVERGENCE_CULTURE') {
        let { origin } = window.location
        jumpUrl = origin + `/ssportal/popen/?schoolId=${this.schoolId}#/cultural/home`
      }

      //skipType 跳转类型：1：当前窗口打开  2：新窗口打开
      if (/^https?:/.test(jumpUrl)) {
        //课程资源中心的业务逻辑
        if (jumpMenu.skipTag) {
          if (jumpMenu.skipTag == 'COURSE_RESOURSE_CENTERS') {
            let { origin } = window.location
            window.open(
              `${origin}/ssportal/courseCenter/courseSetToken?url=${jumpUrl}`,
              '_blank'
            )
            return
          }
        }

        jumpMenu.skipType === 2
          ? window.open(jumpUrl, '_blank')
          : (location.href = jumpUrl)
        return
      }

      if (jumpMenu.skipType === 2) {
        window.open('#' + jumpUrl, '_blank') //新窗口打开
        return
      }

      if (jumpUrl) {
        this.$router.push({
          path: this.interceptUrl(jumpUrl, jumpMenu.menuCode),
        }).then(()=>{
          this.$store.dispatch('setSpaceLoadEnd', false);
          this.$nextTick(()=>{
            this.$store.dispatch('setSpaceLoadEnd', true);
          })
        })
      }
    },
    getChildUserId(userInfo) {
      return userInfo && userInfo.child ? userInfo.child.childId : null
    },
    interceptUrl(path, menuCode) {
      const userInfo = this.$store.state.user

      let tradeCode = ['TRADE_T_EVALUATING', 'TRADE_M_EVALUATING']
      const [, key] = path.split('/')
      let urlParams
      if (userInfo.user) {
        urlParams = common.jsonToHttpQuery({
          account: userInfo.user.account,
          jzuserId: userInfo.user.userId,
          jztoken: userInfo.user.token,
          schoolId: userInfo.school.schoolId,
          userType: userInfo.userType,
          roleId: userInfo.userType,
          userName: userInfo.user.userName,
          childId: this.getChildUserId(userInfo),
          path,
        })
      }
      if (this.filterModel.includes(menuCode)) {
        let { origin } = window.location;
        if(menuCode == 'PORTAL_TEACHER_SCORES_LIST2'){
          origin = mconfig.staticsDomain;
        }
        // 中山环境跨环境使用
        if(['YJT_ZS'].includes(mconfig.dest)){
          origin = 'https://web.etonedu.cn'
        }
        window.open(
          `${origin}/static/pstudy/#/evaluating/login?${urlParams}`,
          'aistudy'
        )
        return
      }
      if (tradeCode.includes(menuCode)) {
        return `/evaluating/login?${urlParams}`
      }
      return path
    },
  },
}
