import { propsColumns, eventColumns, methodColumns } from '../consts'
export default {
  componentFunctionDesc: '对el-table进行二次封装，通过传递配置项的形式达到更快速的开发',
  template: {
    template: handleRender()
  },
  props: {
    columns: propsColumns,
    list: [
      { name: 'data', desc: '表格数据源', type: 'Array' },
      { name: 'columns', desc: '表格列的配置项', type: 'Array' },
      { name: 'total', desc: '表格总条数', type: 'Number', default: 0 },
      { name: 'customEmpty', desc: '是否自定义表格无数据时的内容', type: 'Boolean', default: 'false' },
      { name: 'showPage', desc: '是否显示分页', type: 'Boolean', default: 'true' },
      { name: 'pageLayout', desc: '分页的样式，详细参考 el-pagination 参数', type: 'String', default: 'total, sizes, prev, pager, next' },
      { name: 'isCheckbox', desc: '是否显示复选框', type: 'Boolean', default: 'false' },
      { name: 'isRadio', desc: '是否显示单选框', type: 'Boolean', default: 'false' },
      { name: 'selectData', desc: '设置了复选框或者单选框时，初始化默认选中的值', type: 'Array', default: [] },
      { name: 'needMountedHandlePage', desc: '是否在初次加载调用 handlePage 方法', type: 'Boolean', default: 'true' }
    ]
  },
  columns: {
    columns: [
      ...eventColumns
    ],
    list: [
      { name: 'isSlot', desc: '是否是插槽' },
      { name: 'slotName', desc: '插槽名称，作用域插槽，使用#slotName="{ data: { row } }"，row为当前行数据' },
      { name: 'type', desc: '值为action时操作列的按钮组搭配list属性一起使用' },
      { name: 'list', desc: `[ { text: '按钮名称', cb: Function(row, index){ '点击按钮时的回调', 返回两个参数，row: '当前行信息', index: '当前行索引' }, des: '有该配置项时有气泡提示，(该项为提示语)', isHide: 'Boolean - 是否隐藏该按钮'}]` },
      { name: 'other', desc: '其他属性则对应el-table-column' }
    ]
  },
  events: {
    columns: eventColumns,
    list: [
      { name: 'handleSelectionChange', desc: '设置了单选或复选，选中值改变时触发' },
      { name: 'selectable', desc: '设置了单选或复选，参数是个回调函数传入true不禁用，传入false为禁用' },
      { name: 'handlePage', desc: '页码或者条数切换时触发，返回一个对象{ page: 1, size: 10 }，初始化随即触发' }
    ]
  },
  methods: {
    columns: methodColumns,
    list: [
      { name: 'clearSelection', desc: '清除选中的值' }
    ]
  }
}

function handleRender() {
  return `
  <template>
    <base-table
      ref="baseTable"
      :data="tableData"
      :columns="columns"
      :total="total"
      :is-header="false"
      @handlePage="handlePage">
    </base-table>
  </template>
  <script>
    export default {
      data() {
        return {
          tableData: [
            {
              id: 1,
              memberSn: 213454,
              memberName: '张三'
            },
            {
              id: 2,
              memberSn: 213454,
              memberName: '李四'
            }
          ],
          columns: [
            {
              type: 'index',
              label: '序号',
              width: 60
            },
            {
              prop: 'memberSn',
              label: '学号',
              width: 140
            },
            {
              prop: 'memberName',
              label: '学生姓名',
              width: 120
            },
            {
              isSlot: true,
              type: 'action',
              label: '操作',
              width: 200,
              list: [
                { text: '修改', cb: this.edit },
                { text: '删除', des: '确定要删除?', cb: this.remove }
              ]
            }
          ],
          total: 0
        }
      },
      methods: {
        handlePage(page) {
          console.log(page);
        },

        edit(data) {},

        remove(data) {}
      }
    }
  </script>
  `
}
