/*
 * @Author: longtuxin
 * @Date: 2021-12-07 16:51:01
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-09-15 17:19:11
 * @FilePath: /web/src/utils/auth.js
 * @Description: 头部注释
 */
import Cookies from 'js-cookie'

const TokenKey = 'ssportal_token'
export const authTokenKey = 'auth_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getAuthToken() {
  return Cookies.get(authTokenKey)
}

export function setAuthToken(token) {
  return Cookies.set(authTokenKey, token)
}

export function removeAuthToken() {
  return Cookies.remove(authTokenKey)
}
