/*
 * @Author: liuzhihao
 * @Date: 2020-08-12 10:33:56
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-10-18 23:45:09
 * @FilePath: \static\theme\Theme1\index.js
 * @Description: 头部注释
 */
// // 主题样式
// import './index.css'
// // icon样式
// import './iconCss/index.css'
// // 导入全局样式
// import './app/index.css'
var themeColor = "#6696e8"
export default {
    themeId:1,
    iconType: 1,
    themeColor:themeColor,
    themeMixin: themeColor + "|" + "#558AE3",
    statisticsColor:{
        // 公假
        sabbaticals:"#5adb71",
        // 病假
        sickLeave:"#e89968",
        // 事假
        casualLeave:"#6898e9",
        // 打卡
        clockIn:"#3398DB",
        // 需打卡
        needClockIn:"#e99a68",
    }
}
