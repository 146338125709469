/*
 * @Author: longtuxin
 * @Date: 2024-01-23 12:40:53
 * @LastEditors: longtuxin
 * @LastEditTime: 2024-01-24 11:16:41
 * @FilePath: /web/src/store/modules/newUser.js
 * @Description: 新架构用户结构
 */
const NEW_ORG_TYPE = {
  edu: 1, // 教育局
  school: 4, // 学校
  group: 5, // 集团
  agency: 6, // 机构
}
/** saas组织类型fVersion转新架构组织类型 */
const fVersionToOrgTypeMap = {
  2: NEW_ORG_TYPE.school, // 中小
  4: NEW_ORG_TYPE.school, // 高职
  8: NEW_ORG_TYPE.edu, // 机构/局端
  16: NEW_ORG_TYPE.group, // 集团校
}
export default {
  namespaced: true,
  state: {
    organize: {}, // 当前组织数据
    member: {} // 用户数据
  },
  mutations: {
    SET_ORGANIZE(state, school) {
      const { fVersion, edbType, schoolName, schoolId, slogo, roleTypes } = school || {}
      let organizeType;
      if (fVersion == 8 && edbType == 6) {
        // 如果是机构
        organizeType = NEW_ORG_TYPE.agency;
      } else {
        // 其他组织类型
        organizeType = fVersionToOrgTypeMap[fVersion];
      }
      state.organize = {
        organizeTitle: schoolName,
        organizeId: schoolId,
        organizeType,
        organizeLogo: slogo,
        organizeCode: null,
        roleAlias: roleTypes,
      }
    },
    SET_MEMBER(state, data) {
      const { user = {}, school = {} } = data || {};
      let member = {}
      if (Array.isArray(school.memberSchoolRoleDtoList)) {
        const res = school.memberSchoolRoleDtoList.find(item => item.roleType === user.userType)
        res && (member = res)
      }
      // saas用户转新架构用户member
      state.member = {
        organizeId: school.schoolId,
        organizeTitle: school.schoolName,
        userId: user.userId,
        memberName: user.userName,
        memberId: member.memberId,
        mobile: user.mobile,
        roleAlias: user.userType,
      }
    }
  },
  actions: {
    // 保存新架构登录用户数据
    setLoginData({ commit }, data) {
      commit('SET_MEMBER', data)
      commit('SET_ORGANIZE', data && data.school)
    }
  }
}