<template>
  <div class="page" v-loading="isPageLoading" v-if="school">
    <iframe 
            :src="iframeUrl"
            frameborder="0"
            v-loading.fullscreen.lock="isLoading"></iframe>
    <div class="shroud" v-if="isPageLoading"></div>
  </div>
</template>

<script>
import CommonJs from '@/common/common.js'
import { postByCode } from '@/api/common'
let mconfig = require('../../../mconfig/static')
export default {
  data() {
    return {
      isLoading: true,
      isHchledu: false,
      iframeUrl: "",
      isPageLoading: false,
    }
  },
  mounted(){
    this.getHref();
    this.fecthLoginData();
  },
  watch: {
    '$route.query.link': {
      handler(val) {
        if (!val) return
        this.fecthLoginData()
      }
    },
    '$route.path':{
      handler(val) {
        if (!val) return
        // this.iframeUrl = '';
        this.getHref();
      }
    },
    school(val){
      if(val){
        this.fecthLoginData();
      }
    }
  },
  computed: {
    link() {
      return this.$route.path.replace('/web', '')
    },
    user(){
      return this.$store.state.user.user
    },
    school(){
      return this.$store.state.user.school
    },
    isDev() {
      let dest = mconfig.dest
      let destList = ['LOCAL', 'YJT_DEV', 'YJT_TEST', 'JZ_TEST']
      return destList.includes(dest)
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      if(vm.school){
        vm.fecthLoginData()
      }
    })
  },
  methods: {
    async getHref() {
      const { origin } = location

      let url = `${origin}/ssportal/#${this.link}`
      // 新平台内嵌链接
      if (this.link.indexOf('portal') >= 0) {
        url = `${origin}/portal/#${this.link}`
      }
      // ai学堂内嵌链接
      if (this.link.indexOf('/aiSchool') >= 0) {
        url = origin + this.formatAiLink()
      }

      // 内嵌外部链接
      if (this.link.indexOf('/iframeUrl/') >= 0){
        url = window.atob(this.link.replace('/iframeUrl/', ''))
      }

      // 体育东学校演示内嵌页面，演示结束后删除
      if(this.link.startsWith('/hchledu')){
        this.isHchledu = true
        const newLink = this.link.replace('/hchledu','')
        const module = newLink.split('/')[1]
        const hash = newLink.replace(`/${module}`,'')

        if(module == 'index'){
          // 控制台首页，单点登录
          url = await this.getHchleduUrl();
          // 两秒后再显示，期间显示loading，防止第三方登录页闪现影响观感
          this.isPageLoading = true;
          setTimeout(()=>{
            this.isPageLoading = false;
          },2000)
        } else {
          url = 'https://v5.hchledu.com/' + module + '/#' + hash;
        }
      } else {
        this.isHchledu = false
      }
      this.iframeUrl = url;
      // return url
    },
    // 健康体育页面单点登录
    async getHchleduUrl(){
      let code = 'I_SI_APPLICATION_FORWARD';
      let params = {
        token: 'W_TOKEN',
        agentCode: "SykD8f6lfpWj8vsnfBjr",
        agentType: 2,
        schoolId: "W_SCHOOLID",
        userId: "W_USERID",
        userName: "W_USERNAME",
        account:"W_ACCOUNT",
        userTypeList: this.user.roleTypes,
      }
      if(!this.isDev){
        params.agentCode = 'aW0D5nmYhUNtWFTjRNtv'
      }
      return await postByCode(code,params).then(res=>{
        return res.data || "";
      })
    },
    async fecthLoginData() {
      if(!this.school){
        return
      }
      // 由于新平台前端保证state的一致性,需请求
      if (this.link.indexOf('/portal/') >= 0) {
        try {
          await this.$store.dispatch('getPortalUser')
        } catch (error) {
          console.log("🚀 ~ fecthLoginData ~ error", error)
        }
      }
      this.isLoading = false
    },
    getChildUserId(userInfo) {
      return userInfo && userInfo.child ? userInfo.child.childId : null
    },
    formatAiLink() {
      const userInfo = this.$store.state.user
      let urlParams = ''
      if (userInfo.user) {
        urlParams = CommonJs.jsonToHttpQuery({
          account: userInfo.user.account,
          jzuserId: userInfo.user.userId,
          jztoken: userInfo.user.token,
          schoolId: userInfo.school.schoolId,
          userType: userInfo.userType,
          roleId: userInfo.userType,
          userName: userInfo.user.userName,
          childId: this.getChildUserId(userInfo),
          path: this.link.replace('/aiSchool', '')
        })
      }
      return `/static/pstudy/#/evaluating/login?${urlParams}`
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
  }
  .shroud{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 300;
    background: #fff;
  }
}

</style>
