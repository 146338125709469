/*
 * @Author: liuzhihao
 * @Date: 2022-02-26 22:02:32
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-04-19 16:52:00
 * @FilePath: \web\src\i18n\lang\en.js
 * @Description: 头部注释
 */
import locale from 'element-ui/lib/locale/lang/en'
import { getByUrl } from '@/api/common'
import config from '@/config';
const h = {
    ...locale,
}
const getLang = async ()=>{
    let url = `${ location.origin }/statics/static/i18n/en.json?v=${ config.v }`;
    let lang = await getByUrl(url) || {};
    return Object.assign({},h,lang)
}
export default getLang;

