/*
 * @Author: liuzhihao
 * @Date: 2022-03-14 10:00:41
 * @LastEditors: liuzhihao
 * @LastEditTime: 2022-04-19 16:51:46
 * @FilePath: \web\src\config.js
 * @Description: 头部注释
 */
/**
 * Created by  on 2016/4/1.
 */
let packageJson = require('../package.json');
let v = null;
try {
	v = require('../v.json');
} catch (error) {
	
}
var WISDOM_PINXUEW = 'http://47.106.211.16'

var pathname = window.location.pathname;
var paths = pathname.split("/");
var ctxPath = "";
if (paths.length && paths[1]) {
	ctxPath = "/" + paths[1];
}
var eduPath = "//" + window.location.host + ctxPath;//config.eduDomain;

var staticsDomain = gweb.staticsDomain;
var wxcodeDomain = gweb.wxcodeDomain;
var mWxcodeDomain = gweb.mWxcodeDomain;

export default {
	/*企业号访问的路径*/
	M_MOBILE_PORTAL_PATH: "http://" + mWxcodeDomain + "/",
	MOBILE_PORTAL_PATH: "http://" + wxcodeDomain + "/",
	SOCKET_PATH: (process.env.NODE_ENV !== 'production') ? "" : "/ssportal",
	PUBLIC_PATH: (process.env.NODE_ENV !== 'production') ? "/assets/" : "//" + staticsDomain + "/statics/ssportal/static/",
	PUBLIC_PLUGINS_PATH: (process.env.NODE_ENV !== 'production') ? "/assets/js/" : "//" + staticsDomain + "/statics/ssportal/static/plugins/",
	API_EDU_ROOT: eduPath,
	BIZ_DOMAIN: gweb.bizDomain,
	WISDOM_PINXUEW: WISDOM_PINXUEW,
	v: v || packageJson.version
}
