<template>
  <div>
    <el-menu
      ref="menu"
      class="web-left-menu"
      v-if="authMenus"
      mode="vertical"
      style="width: 200px"
      :text-color="color8"
      :active-text-color="color7"
      :default-active="defaultActiveMenu"
      :default-openeds="getOpensMenu()"
      @open="open"
      :collapse="false"
    >
      <!-- :collapse="sidebar.opened==false" -->
      <!-- 功能类型：1菜单，2按钮，3虚拟节点，4面板 -->
      <template v-for="item in authMenus">
        <template v-if="item.funcType == 1">
          <!-- :class="bgcClick()" -->
          <!-- 左侧菜单带二级节点 -->
          <el-submenu
            :style="{ color: color1 }"
            class="web-left-menu-submenu"
            :class="{
              cur: parentCurMenu && parentCurMenu.menuId == item.menuId,
              mshow: dips,
            }"
            v-if="hasChildMenu(item)"
            :data-key="item.menuIndexKey"
            :key="item.menuIndexKey"
            :index="item.menuIndexKey"
          >
            <template slot="title">
              <div>
                <img :src="item.icon" alt="" class="web-left-menu-icon" />
                <span>{{ getTranslateMenuName(item) }}</span>
              </div>
            </template>
            <el-menu-item
              class="web-left-menu-item"
              v-for="sub in getMenuList(item.menuDtos)"
              :data-key="sub.menuIndexKey"
              :key="sub.menuIndexKey"
              :index="sub.menuIndexKey"
              @click="
                handleClick(sub, $event);
                handleMenuRedirect(sub);
              "
            >
              <div>
                <img
                  :src="sub.icon"
                  alt=""
                  class="web-left-menu-icon"
                  v-if="sub.icon"
                />
                <div
                  class=""
                  v-else
                  style="display: inline-block; margin-right: 14px"
                ></div>
                <span slot="title">{{ getTranslateMenuName(sub) }}</span>
              </div>
            </el-menu-item>
          </el-submenu>
          <!-- 左侧只展示一级菜单 -->
          <el-menu-item
            class="web-left-menu-single-item"
            v-else
            :class="{ cur: curMenu.menuId == item.menuId }"
            :data-key="item.menuIndexKey"
            :key="item.menuIndexKey"
            :index="item.menuIndexKey"
            @click="
              handleClick(item, $event);
              handleMenuRedirect(item);
            "
          >
            <!-- <i class="web-left-menu-icon" :class="getIcon(item.icon)"></i> -->
            <div>
              <img :src="item.icon" alt="" class="web-left-menu-icon" />
              <span slot="title">{{ getTranslateMenuName(item) }}</span>
            </div>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <div class="marginbox" v-else></div>
  </div>
</template>
<script>
import MenuMixin from "@/mixins/MenuMixin";
import ThemeMixin from "@/mixins/ThemeMixin";
import MenuService from "@/services/MenuService";
const squire = require("@/assets/images/topmenu/squire.png");
export default {
  data() {
    return {
      index: null,
      parentMenuIndex: null,
      dips: false,
      upMenuIndexKey: null,
    };
  },
  mixins: [MenuMixin, ThemeMixin],
  created() {},
  computed: {
    parentCurMenu() {
      let userMenuList = this.userMenuList;
      if (userMenuList && userMenuList.length >= 2) {
        let len = userMenuList.length;
        return userMenuList[len - 2];
      }
    },
    curMenu() {
      let userMenuList = this.userMenuList;
      if (userMenuList && userMenuList.length) {
        let len = userMenuList.length;
        return userMenuList[len - 1];
      }
    },
    topMenu() {
      let userMenuList = this.userMenuList;
      if (userMenuList && userMenuList.length) {
        return userMenuList;
      }
    },
    authMenus() {
      let topMenu = this.topMenu;
      if (topMenu) {
        return topMenu;
      }
    },
    domainConfig() {
      return this.$store.state.app.domainConfig;
    },
    // userMenuList: null,
    // userMenuList: null,
    // homeMenu: null,
    // homeMenuUrl: null,

    // menuHistoryList: [],
    // spaceLoadEnd: true,
    // menuCode: ''
    userMenuList() {
      return this.$store.state.layout.userMenuList;
    },
    curMenuList() {
      return this.$store.state.layout.curMenuList;
    },
    menuIcon() {
      let domainConfig = this.domainConfig;
      let iconComponent = "MenuIconSass";
      if (domainConfig && domainConfig.idxTemp.code == "middle") {
        iconComponent = "MenuIconMiddle";
      }
      return iconComponent;
    },
    defaultActiveMenu() {
      let curMenuList = this.curMenuList;
      console.log("curMenuList", curMenuList);
      if (!curMenuList) {
        return null;
      }
      let len = curMenuList.length;
      if (len) {
        // if (len > 1) {
        // if (curMenuList[0].menuDtos) {
        //   this.index = curMenuList[0].menuDtos[0].menuIndexKey;
        // } else {
        this.index = curMenuList[len - 1].menuIndexKey;
        // }
        console.log("this.index1", this.index);
        return this.index;
        // } else {
        //   if (curMenuList[0].menuDtos) {
        //     this.index = curMenuList[0].menuDtos[0].menuIndexKey;
        //     console.log("this.index2", this.index);
        //   } else {
        //     this.index = curMenuList[len - 1].menuIndexKey;
        //     console.log("this.index3", this.index);
        //   }

        //   console.log("this.index4", this.index);
        //   return this.index;
        // }
      }

      return null;
    },
  },
  methods: {
    open(index) {
      console.log(index, "open===>");
      this.parentMenuIndex = index;
    },
    handleClick(item, event) {
      console.log(item, item.parentMenuId, this.parentMenuIndex, "点击子菜单");
      //跳转新页面高亮选中上一次选中状态
      if (this.filterModel.includes(item.menuCode) || item.skipType == 2) {
        this.$refs["menu"].activeIndex = this.upMenuIndexKey;
        return;
      }

      if (item.parentMenuId == this.parentMenuIndex) {
        this.dips = true;
        this.$nextTick(() => {
          this.$refs["menu"].open(this.parentMenuIndex);
          this.upMenuIndexKey = this.$refs["menu"].activeIndex;
        });
      } else {
        this.dips = false;
      }
    },
    selectmenu(index, item) {
      this.open(index); // 第一次进入页面，页面所在父菜单栏高亮
      this.handleClick(item);
    },
    getMenuList(menuDataList) {
      return MenuService.getMenuDataList(menuDataList);
    },
    hasChildMenu(menu) {
      //是否存在下级菜单，去除其他类型的数据
      let menuList = this.getMenuList(menu.menuDtos);
      if (menuList && menuList.length > 0) {
        return true;
      }
      return false;
    },
    getIcon(icon, type) {
      if (!icon) {
        if (type == "folder") {
          return "fa fa-folder";
        }
        return "fa fa-file-text-o";
      }
      return icon;
    },
    getOpensMenu() {
      let authMenus = this.authMenus;
      let url = this.$route.fullPath;
      let userMenuList = this.userMenuList;
      console.log(
        userMenuList,
        authMenus,
        url,
        this.userMenuList,
        "userMenuList===>"
      );
      if (
        !userMenuList ||
        !userMenuList.length ||
        userMenuList[0].menuDtos == null
      ) {
        return [];
      }

      let curMenuKeyIndexs = userMenuList.map((menu) => {
        return menu.menuIndexKey;
      });
      console.log(curMenuKeyIndexs, "authMenus===>");

      return curMenuKeyIndexs;
    },
  },
  watch: {
    userMenuList(newName, oldName) {
      if (newName) {
        if (
          !this.userMenuList ||
          !this.userMenuList[0] ||
          this.userMenuList[0].menuDtos == null
        ) {
          return;
        }
        this.selectmenu(
          this.userMenuList[0].menuIndexKey,
          this.userMenuList[0].menuDtos[0]
        );
      }
    },
  },
  mounted() {
    if (
      !this.userMenuList ||
      !this.userMenuList[0] ||
      this.userMenuList[0].menuDtos == null
    ) {
      return;
    }
    this.selectmenu(
      this.userMenuList[0].menuIndexKey,
      this.userMenuList[0].menuDtos[0]
    );
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/css/teachPaltf.less";
.marginbox {
  width: 1px;
  padding-left: 210px;
}
.mshow {
  background: #fff !important;
  -webkit-mask: url(~@/assets/images/topmenu/squire.png);
  mask: url(~@/assets/images/topmenu/squire.png);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center top;
  margin-top: -16px;
}

.web-left-menu {
  height: calc(100vh -204) px;
  background-color: transparent;
  border: none !important;
  margin-top: 24px;
}

.web-left-menu-submenu {
  margin-top: -16px;
}

.web-left-menu-submenu.cur /deep/ .el-menu {
  // background-color: #0a0 !important;
}

.web-left-menu-item.el-menu-item {
  // padding-left: 50px !important;
  font-size: 16px;
}

.web-left-menu-icon {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.el-menu-item {
  font-size: 16px;
}

/deep/.el-submenu__title {
  font-size: 16px !important;
}

/deep/.el-menu-item .web-left-menu-single-item {
  padding-left: 14px !important;
}

.el-menu {
  margin-left: 10px;
}

/deep/ .el-menu {
  background-color: transparent !important;
}

/deep/ .el-menu-item:hover {
  background: none !important;
  color: @mainColor !important;
}

/deep/ .el-submenu__title:hover {
  background: none !important;
  color: @mainColor !important;
}

.el-submenu .el-menu-item.is-active {
  background: #2d55eb !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  -webkit-mask: none;
  mask: none;
}

.el-menu-item.is-active {
  font-weight: 700 !important;
  background: #fff !important;
  -webkit-mask: url(~@/assets/images/topmenu/rectang.png);
  mask: url(~@/assets/images/topmenu/rectang.png);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center top;
  margin-bottom: -16px;
  height: 108px;
  line-height: 108px;
}

.el-menu-item,
.el-submenu__title {
  height: 108px !important;
  line-height: 108px !important;
}

.el-menu .el-menu--inline .el-submenu .el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
}

.el-submenu .el-menu-item {
  height: 40px !important;
  line-height: 36px !important;
  margin: 0 15px 15px 15px;
}

.el-submenu .el-menu-item:last-child {
  margin-bottom: 36px;
}

.web-left-menu-submenu .el-submenu {
  .el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
  }
}

/deep/.el-menu-item,
/deep/.el-submenu__title {
  height: 108px !important;
  line-height: 108px !important;
}

/deep/ .el-submenu__icon-arrow {
  top: 54% !important;
}
</style>
