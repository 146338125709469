<template>
    <el-menu class="web-left-menu"
             v-if="authMenus"
             mode="vertical"
             style="width:200px"
             :text-color="color5"
             :active-text-color="color4"
             :background-color="color3"
             :default-active="defaultActiveMenu"
             :default-openeds="getOpensMenu()">
        <!-- :collapse="sidebar.opened==false" -->
        <!-- 功能类型：1菜单，2按钮，3虚拟节点，4面板 -->
        <template v-for="(item) in authMenus">
            <template v-if="item.funcType==1">
                <!-- 左侧菜单带二级节点 -->
                <el-submenu :style="{'border-left-color':color2,'color':color2}"
                            class="web-left-menu-submenu"
                            :class="{'cur':parentCurMenu&&parentCurMenu.menuId==item.menuId}"
                            v-if="hasChildMenu(item)"
                            :data-key="item.menuIndexKey"
                            :key="item.menuIndexKey"
                            :index="item.menuIndexKey">
                    <template slot="title">
                        <i class="web-left-menu-icon"
                           :class="getIcon(item.icon,'folder')"></i>
                        <span>{{ getTranslateMenuName(item) }}</span>
                    </template>
                    <el-menu-item class="web-left-menu-item"
                                  v-for="sub in getMenuList(item.menuDtos)"
                                  :data-key="sub.menuIndexKey"
                                  :key="sub.menuIndexKey"
                                  :index="sub.menuIndexKey"
                                  @click="handleMenuRedirect(sub)">
                        <span slot="title">{{ getTranslateMenuName(sub) }}</span>
                    </el-menu-item>
                </el-submenu>
                <!-- 左侧只展示一级菜单 -->
                <el-menu-item class="web-left-menu-single-item"
                              v-else
                              :class="{'cur':curMenu.menuId==item.menuId}"
                              :data-key="item.menuIndexKey"
                              :key="item.menuIndexKey"
                              :index="item.menuIndexKey"
                              @click="handleMenuRedirect(item)">
                    <i class="web-left-menu-icon"
                       :class="getIcon(item.icon)"></i>
                    <span slot="title">{{ getTranslateMenuName(item)}}</span>
                </el-menu-item>
            </template>
        </template>
    </el-menu>
</template>
<script>
import MenuMixin from '@/mixins/MenuMixin'
import ThemeMixin from '@/mixins/ThemeMixin'
import MenuService from '@/services/MenuService'
export default {
  data() {
    return {
      index: null
    }
  },
  mixins: [MenuMixin, ThemeMixin],
  created() {},
  computed: {
    /**
     * @description 根据路由query的activeMenuCode=MenuCode选中左侧菜单
     */
     routerActiveLeftMenu() {
      let activeMenuCode = this.$route.query.activeMenuCode
      let [curMenuList] = this.curMenuList;
      let menu = MenuService.findMenuCode([curMenuList],activeMenuCode)
      return menu
    },
    parentCurMenu() {
      let curMenuList = this.curMenuList
      if (curMenuList && curMenuList.length >= 2) {
        let len = curMenuList.length
        return curMenuList[len - 2]
      }
    },
    curMenu() {
      let curMenuList = this.curMenuList
      if (curMenuList && curMenuList.length) {
        let len = curMenuList.length
        return curMenuList[len - 1]
      }
    },
    topMenu() {
      let curMenuList = this.curMenuList
      if (curMenuList && curMenuList.length) {
        return curMenuList[0]
      }
    },
    authMenus() {
      let topMenu = this.topMenu
      if (topMenu) {
        return topMenu.menuDtos
      }
    },
    domainConfig() {
      return this.$store.state.app.domainConfig
    },
    curMenuList() {
      return this.$store.state.layout.curMenuList
    },
    menuIcon() {
      let domainConfig = this.domainConfig
      let iconComponent = 'MenuIconSass'
      if (domainConfig && domainConfig.idxTemp.code == 'middle') {
        iconComponent = 'MenuIconMiddle'
      }
      return iconComponent
    },
    defaultActiveMenu() {
      let curMenuList = this.curMenuList
      if (!curMenuList) {
        return null
      }
      let len = curMenuList.length
      if (len) {
        if (this.routerActiveLeftMenu) return this.routerActiveLeftMenu.menuIndexKey;
        this.index = curMenuList[len - 1].menuIndexKey
        return curMenuList[len - 1].menuIndexKey
      }
      return null
    }
  },
  methods: {
    getMenuList(menuDataList) {
      return MenuService.getMenuDataList(menuDataList)
    },
    hasChildMenu(menu) {
      //是否存在下级菜单，去除其他类型的数据
      let menuList = this.getMenuList(menu.menuDtos)
      if (menuList && menuList.length > 0) {
        return true
      }
      return false
    },
    getIcon(icon, type) {
      if (!icon) {
        if (type == 'folder') {
          return 'fa fa-folder'
        }
        return 'fa fa-file-text-o'
      }
      return icon
    },
    /**
     * @description [1,2,3]拆分 [1,1#2,1#2#3]
     */
    getRouterOpensMenu() {
      let { menuIndexKey } = this.routerActiveLeftMenu
        let menuArray = menuIndexKey.split("#")
        menuArray.find((x, index) => {
          if (menuArray[index - 1]) {
            menuArray[index] = `${menuArray[index-1]}#${x}`
          } 
        })
        return menuArray
    },
    getOpensMenu() {
      let authMenus = this.authMenus
      let url = this.$route.fullPath
      let curMenuList = this.curMenuList

      if (!curMenuList || !curMenuList.length) {
        return []
      }

      let curMenuKeyIndexs = curMenuList.map(menu => {
        return menu.menuIndexKey
      })
      if (this.routerActiveLeftMenu) return this.getRouterOpensMenu()
      return curMenuKeyIndexs
    }
  }
}
</script>
<style scoped>
.web-left-menu {
  height: 100%;
}

.web-left-menu-submenu.cur /deep/ .el-menu {
  background: #f8f8f8;
}
.web-left-menu-item.el-menu-item {
  padding-left: 50px !important;
}
.web-left-menu-icon {
  margin-right: 8px;
  width: 14px;
  font-size: 16px;
  display: inline-block;
}
</style>

