<!--
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-03-29 17:51:27
 * @FilePath: /web/src/components/DevTool/index.vue
 * @Description: 开发工具面板
-->
<template>
<div>
    <div
        class="icon-picker-btn"
        circle
        @click="handleClick">开发工具</div>
    <el-dialog title="开发工具" :visible.sync="dialogVisible">
        <el-tabs>
            <el-tab-pane label="图标">
                <IconPicker />
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</div>
</template>

<script>
import IconPicker from './IconPicker/index.vue';

export default {
    components: { IconPicker },
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        handleClick() {
            this.dialogVisible = true
        }
    }
}
</script>
<style scoped>
.icon-picker-btn {
    position: fixed;
    width: 32px;
    height: 88px;
    line-height: 1.2;
    color: #fff;
    background: #409eff;
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    bottom: 120px;
    right: -26px;
    z-index: 99;
    cursor: pointer;
}
.icon-picker-btn:hover {
    right: 0;
}
:deep(.el-dialog__body) {
    padding-top: 8px
}
</style>