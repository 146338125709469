<!--
 * @Author: longtuxin
 * @LastEditors: longtuxin
 * @LastEditTime: 2023-12-30 12:14:45
 * @FilePath: /web/src/layout/LayoutRegion/index.vue
 * @Description: 大平台布局(新)
-->
<template>
  <div class="layout-region" :class="{ 'no-bg': subMenuList.length }">
    <el-container>
      <el-header class="layout-region__header" height="80">
        <MenuSubLevel
          v-if="subMenuList.length"
          :sub-menu-list="subMenuList"
          :cur-menu="curMenu"
          :active-index="subMennActiveIndex"
          @clickFirstMenu="activeIndex = String($event)"
        />
        <MenuFirstLevel
          v-else-if="!subMenuList.length"
          :active-index="activeIndex"
          :load-end="spaceLoadEnd && loadEnd"
        />
      </el-header>
      <el-container>
        <el-aside v-if="siderBarMenu && siderBarMenu.length" width="200px">
          <SiderBar
            :sider-bar-menu="siderBarMenu"
            :active-side-menu="activeSideMenu"
          />
        </el-aside>

        <el-main id="right-content" class="right-content">
          <router-view v-if="spaceLoadEnd && loadEnd" />
          <!-- 请求完登录数据后，才挂载子应用 -->
          <div v-if="loadEnd" id="v3-container"></div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import MenuFirstLevel from './components/MenuFirstLevel'
import MenuSubLevel from './components/MenuSubLevel'
import SiderBar from './components/SiderBar'
import LayoutMixin from '@/mixins/LayoutMixin'
import SpaceMenuMixin from '@/mixins/SpaceMenuMixin'
import MenuService from '@/services/MenuService'

export default {
  mixins: [LayoutMixin, SpaceMenuMixin],
  components: {
    MenuFirstLevel,
    MenuSubLevel,
    SiderBar,
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.getMenuListAndSiderBar()
      }
    },
    menuList: {
      handler(val) {
        if (!val.length) return
        this.getMenuListAndSiderBar()
      },
      immediate: true,
    },
  },
  data() {
    return {
      activeIndex: '0',
      subMenuList: [],
      curMenu: {},
      subMennActiveIndex: '',
      siderBarMenu: [],
      activeSideMenu: '0-0',
    }
  },
  computed: {
    isInIframe() {
      return window.self !== window.top
    },
  },
  mounted() {
    // 初始化主题
    this.$store.commit('CHANGE_THEME')
  },
  methods: {
    initData() {
      this.activeIndex = '0'
      this.subMenuList = []
      this.curMenu = {}
      this.subMennActiveIndex = ''
      this.siderBarMenu = []
      this.activeSideMenu = '0-0'
    },
    getMenuenuDtosList(list) {
      list = JSON.parse(JSON.stringify(list || []))
      list.forEach((item) => {
        item.menuDtos = MenuService.getMenuDataList(item.menuDtos || [])
        // 有下级则继续递归
        if (item.menuDtos.length) {
          item.menuDtos = this.getMenuenuDtosList(item.menuDtos)
        } else {
          item.menuDtos = null
        }
      })
      return list
    },
    getMenuListAndSiderBar() {
      const { menuKey } = this.menuUrlToObj()
      let curMenu = this.menuList[menuKey[0]]
      // 递归筛选出菜单
      let menuenuDtos = this.getMenuenuDtosList((curMenu || []).menuDtos)
      // 无菜单或错误页，初始化菜单数据
      if (!menuKey.length || !curMenu) return
      this.initData()

      if (!menuenuDtos) return

      this.curMenu = curMenu
      this.subMennActiveIndex = menuKey[1] + ''
      this.subMenuList = menuenuDtos

      if (
        (menuKey[1] || menuKey[1] === 0) &&
        menuenuDtos[menuKey[1]].menuDtos
      ) {
        this.siderBarMenu = menuenuDtos[menuKey[1]].menuDtos
        const activeSideMenu = menuKey.slice(2, menuKey.length)
        this.activeSideMenu = activeSideMenu.join('-')
      }
    },
  },
}
</script>

<style scoped lang="less">
@nav-height: 80px;
.layout-region {
  height: 100vh;
  background: url('/statics/static/images/pregionalv3/layout_header_bg.png?v1')
      no-repeat top left,
    #f6f8ff;
  background-size: 2580px 500px;
  &.no-bg {
    background-image: none;
  }
  &__header {
    margin-left: -20px;
  }
}
.content {
  /* background: #f8f8f8; */
  padding: 0;
}
.el-header {
  padding-right: 0;
}
.wrapper {
  height: 100%;
  min-width: 1276px;
}
.menu.resize {
  /* height:83.5vh; */
  /* height:calc(100vh - 116px); */
  overflow-y: auto;
  overflow-x: hidden;
}
.right-content {
  height: calc(100vh - @nav-height);
  /* background-color: #f6f7f9; */
  overflow-y: auto;
  /* padding: 24px !important; */
}
</style>
