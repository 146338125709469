<template>
  <div class="page">
    <img src="@/assets/images/build.gif"
         alt="" />
    <h2>正在建设中</h2>
    <div class="page_build">
      <div>宜教通智慧校园综合服务平台 </div>
      <div>以云计算、大数据、物联网、移动计算、AI机器学习技术为依托 </div>
      <div>围绕“管、教、学、测、练”展开 </div>
      <div>统筹谋划，突出核心需求 </div>
      <div>基于先进信息技术和先进的教育管理与教科研理念进行规划</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  }
}
</script>

<style scoped>
.page {
  height: calc(100vh - 60px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.page h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0;
}
.page_build {
  text-align: center;
  line-height: 2em;
}
</style>
