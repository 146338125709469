/*
 * @Author: caiyf
 * @Email: caiyifei@etonedu.cn
 * @Date: 2023-02-02 17:52:12
 * @Last Modified by: caiyifei
 * @Last Modified time: 2023-03-27 10:19:21
 * @Description: 用户管理、切换角色
 */

<template>
  <div class='flex'>
    <div class="user_manage flex" @click="handleClick">
      <img src="@/assets/images/hierarchy-square.png" class="icon" />
      <span class="ml5">用户管理</span>
    </div>
    <change-role></change-role>
  </div>
</template>

<script>
import ChangeRole from './ChangeRole'
export default {
  components: {
    ChangeRole
  },
  data() {
    return {

    };
  },
  computed: {},
  created() {},
  methods: {
    // SET_IS_SHOW_GROUP_SCHOOL_FRAMEWORK
    handleClick() {
      this.$router.push({
        name: 'group_framework_orgFramework'
      })
    }
  }
}
</script>
<style scoped lang="less">
.flex {
  display: flex;
  align-items: center;
}

.user_manage {
  padding: 5px 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  filter: drop-shadow(0px 2px 9px rgba(41, 46, 53, 0.1));
  border-radius: 51px;
  font-size: 14px;
  color: #fff;
  margin-right: 24px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
}

.user_manage .icon {
  width: 16px;
  height: 16px;
}

</style>