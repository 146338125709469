<template>
  <!-- 切换角色 -->
  <div v-if="user" class="role">
    <el-dropdown trigger="click" @command="changeUserRole">
      <div class="el-dropdown-link">
        <div class="user-picker">
          <el-avatar
            :src="user.avatarUrl"
            :style="{ backgroundColor: themeColor }"
          >
            <span class="empty-avatar">{{
              user.userName && user.userName.slice(0, 1)
            }}</span>
          </el-avatar>
          <div>
            {{ user.userName }}
            <p
              v-if="!isStaff"
              class="user-role-tag"
              :style="{
                color: themeColor,
                borderColor: themeColor,
              }"
            >
              管理员
            </p>
          </div>
          <i class="el-icon-caret-bottom icon-arrow"></i>
        </div>
      </div>

      <el-dropdown-menu class="switch-dropdown" slot="dropdown">
        <el-dropdown-item
          v-if="hasTwoRoles"
          :command="{
            school,
            role: roleTypeInfos.find((item) => item.userType !== userType),
          }"
        >
          切换{{ isStaff ? '管理员' : '职员' }}
        </el-dropdown-item>
        <el-dropdown-item :divided="hasTwoRoles" :command="{ op: 'setting' }">
          <div>个人设置</div>
        </el-dropdown-item>
        <el-dropdown-item divided :command="{ op: 'exit' }">
          <div>退出</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin'
import { mapState } from 'vuex'

export default {
  components: {},
  mixins: [SwitchSchoolAndRoleMixin],
  computed: {
    ...mapState({ theme: (state) => state.theme.theme || {} }),
    themeColor() {
      // 获取原型上的theme
      return this.theme.themeColor
    },
    isStaff() {
      return this.userType === 4
    },
    // 是否有多个角色
    hasTwoRoles() {
      return this.roleTypeInfos && this.roleTypeInfos.length > 1
    },
  },
  methods: {
    changeUserRole(params) {
      if (params.op === 'setting') {
        // 跳转区域个人信息页
        this.$router.push({ path: '/pregionalv3/user/info' })
      } else if (params.op === 'exit') {
        this.$confirm('确定要退出登录吗?', '提示', {
          type: 'warning',
        }).then(() => {
          this.onMixinChangeRole(params)
        })
      } else this.onMixinChangeRole(params)
    },
    onMixinChangeRole(params) {
      this.changeRole({
        ...params,
        callback: () => {
          // 修改角色成功后，触发change事件
          this.$emit('change')
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.el-dropdown-link {
  color: #1c2c49;
}
.user-picker {
  // padding: 24px 20px;
  display: flex;
  gap: 12px;
  font-size: 18px;
  align-items: center;
  color: #1c2c49;
  cursor: pointer;

  .empty-avatar {
    width: 100%;
    font-size: 18px;
    height: 100%;
    line-height: 40px;
  }
  .user-role-tag {
    font-size: 12px;
    line-height: 20px;
    border-radius: 12px;
    border: 1px solid;
    text-align: center;
    padding: 0 8px;
    margin-top: 4px;
    font-weight: bold;
  }
  .icon-arrow {
    font-size: 14px;
    color: #1c2c49;
  }
}
</style>
