import Cookies from 'js-cookie'

const app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus')
    },
    visitedViews: [],
    
    domainConfig:null,
    title:"",


    /*记录用户信息，菜单数据*/
    
  },
  mutations: {
    ToggleSideBar: state => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
      state.sidebar.opened = !state.sidebar.opened
    },
    ADD_VISITED_VIEWS: (state, view) => {
      if (state.visitedViews.some(v => v.path === view.path)) return
      state.visitedViews.push({ name: view.name, path: view.path })
    },
    DEL_VISITED_VIEWS: (state, view) => {
      let index
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          index = i
          break
        }
      }
      state.visitedViews.splice(index, 1)
    },

    SET_APP_DOMAIN_CONFIG:(state,domainConfig) =>{
      state.domainConfig=domainConfig;
    },

    SET_TITLE:(state,title) =>{
      state.title=title;
    }
  },
  actions: {
    // ToggleSideBar({ commit }) {
    //   commit('TOGGLE_SIDEBAR')
    // },
    addVisitedViews({ commit }, view) {
      commit('ADD_VISITED_VIEWS', view)
    },
    delVisitedViews({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_VISITED_VIEWS', view)
        resolve([...state.visitedViews])
      })
    },
  }
}

export default app
