<template>
	<div class="logo-container">
		<img class="logo" v-if="logo" :src="logo" @error="imgError"/>
        <img v-else class="logo" :src="defaultLogo" >
	</div>
</template>
<script type="text/javascript">
    import defaultLogo from '@/assets/images/school-logo.png';
	export default{
		props:{
			slogo:{}
		},
		data(){
			return {
				loadError:0,
			 	defaultLogo:defaultLogo
			}
		},
		computed:{
			logo(){
				if(this.slogo&&!this.loadError){
					return this.slogo;
				}else{
					return this.defaultLogo;
				}
			}
		},
		methods:{
			imgError(){
				this.loadError=1;
                //this.logo=this.defaultLogo;
            }
		}
	}
</script>
<style type="text/css" scoped >
	.logo-container{
		display: inline-block;
	}
	.logo-container .logo{
		width: 100%;
		height: 100%;
	}
</style>