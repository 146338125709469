<template>

  <div class="top-header">
    <div class="top-header-tip">
      <slot name="logo"></slot>
      <div>{{introText}}</div>
      <div class="icon-box"
           v-if="user&&hasGJZYW">
        <a title="评价"
           :href="`http://system.eduyun.cn/bmp-web/getSpAppDetail_index?appId=${thrAppId}&userId=${user.userCode}`"
           target="_blank"
           class="eval">
        </a>
        <a title="举报"
           :href="`http://system.eduyun.cn/bmp-web/sysAppReport/appReport?appId=${thrAppId}&userId=${user.userCode}`"
           target="_blank"
           class="report">
        </a>
      </div>
    </div>
    <div class="role-infos">
      <!-- 翻译按钮 start -->
      <div class="full"
           @click="changeLangEvent()"
           v-if="school&&school.schoolId=='110133'">
        <span class="lan">{{ language }}</span>
      </div>
      <!-- 翻译按钮 end -->
      <div v-if="school"
           class="school-container">
        <div class="school-name"
             v-if="schoolList&&schoolList.length==1">
          <span class="school-title f-toe">{{school.schoolName}}</span>
        </div>
        <el-dropdown v-else
                     placement="top-start"
                     @command="changeSchool">
          <!-- 切换学校 -->
          <div class="school-name"
               :class="{'sel-block':schoolList&&schoolList.length>1}">
            <span class="school-title f-toe">{{school.schoolName}}</span>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu class="switch-dropdown"
                            slot="dropdown">
            <template v-for="(sch,index) in schoolList">
              <el-dropdown-item :divided="index>0?true:false"
                                :command="{school:sch}"
                                :class="{'is-active'
							:sch.schoolId==school.schoolId}"
                                v-if="index<6"
                                :key="'schoolsel_'+sch.schoolId"
                                :title="sch.schoolName">
                <div class="f-toe school-info"
                     href="javascript:;">
                  <school-logo class="slogo"
                               :slogo="sch.slogo"></school-logo>{{sch.schoolName}}
                </div>
              </el-dropdown-item>
            </template>
            <el-dropdown-item divided
                              :command="{more:1}"
                              v-if="schoolList&&schoolList.length>6">
              <div href="javascript:;"
                   class="more-school">更多学校...</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="role-container">
        <el-dropdown v-if="user"
                     placement="top-start"
                     @command="changeRole">
          <!-- 切换角色 -->
          <div class="el-dropdown-link role-name sel-block">
            <span class="role-title f-toe"
                  v-if="userType==2&&child">{{child.childName}}
              <small>（{{child.className}}）{{ clsRoleFormat(child.familyRole, child.isJwh) }}</small>
            </span>
            <span class="role-title f-toe"
                  v-else-if="userType==1&&stuClass">{{user.userName}}
              <small class="extra">（{{stuClass.className}}）</small>
            </span>
            <span class="role-title f-toe"
                  v-else>
              <span v-if="!showWxName">{{user.userName}}</span>
              <span v-else>
                <ww-open-data :type="`userName`"
                              :openid="openid"
                              id="ww_open_data" />
              </span>
              <small>{{ userTypeFormat(userType, school) }}</small>
            </span>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu class="switch-dropdown"
                            slot="dropdown">
            <el-dropdown-item :command="{school:school,role:role}"
                              v-for="(role,index) in roleTypeInfos"
                              :key="'role_'+index">
              <a href="javascript:;"
                 class="f-toe">
                <span v-if="role.userType!=2&&role.userType!=1">{{ userTypeFormat(role.userType, school) }}</span>
                <span v-else-if="role.userType==2">{{role.childName}}
                  <small>（{{role.className}}）&nbsp;{{ clsRoleFormat(role.familyRole, role.isJwh) }}</small>
                </span>
                <span v-else-if="role.userType==1">
                  <small>{{role.className}}</small>
                </span>
              </a>
            </el-dropdown-item>
            <el-dropdown-item divided
                              :command="{op:'setting'}">
              <div>{{ $t('topHeader.setting') }}</div>
            </el-dropdown-item>
            <el-dropdown-item divided
                              :command="{op:'exit'}">
              <div>{{ $t('topHeader.logout') }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 插槽 -->
      <slot name="right-suffix"></slot>
    </div>

    <el-dialog title="选择要切换的学校"
               width="829px"
               :visible.sync="dialogVisiable">
      <el-input class="search-input"
                size="mini"
                placeholder="搜索学校"
                v-model.trim="searchSchoolName" />
      <ul class="switch-school-list">
        <li v-for="sch in searchSchoolList"
            :key="'schoolsel_'+sch.schoolId">
          <a href="javascript:;"
             :title="sch.schoolName"
             class="f-toe"
             @click="changeSchoolAndRole(sch.schoolId)">
            <school-logo class="slogo"
                         :slogo="sch.slogo"></school-logo>
            {{sch.schoolName}}
          </a>
        </li>
      </ul>
      <div slot="footer"></div>
    </el-dialog>
  </div>
</template>

<script>
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin'
import icon_report from '@/assets/images/icon-report.png'
import icon_report2 from '@/assets/images/icon-report2.png'
import icon_eval from '@/assets/images/icon-eval.png'
import icon_eval2 from '@/assets/images/icon-eval2.png'
import { wxSign } from '@/api/common'
export default {
  mixins: [SwitchSchoolAndRoleMixin],
  props: {
    // 头部导语
    intro: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      language: localStorage.getItem('lang') || 'zh',

      showWxName: false,
      openid: '',
      searchSchoolName: null
    }
  },
  computed: {
    // 头部导语
    introText(){
      // 默认导语：欢迎进入 xxxx！
      let defIntro = `${this.$t('topHeader.welcome')} ${this.$store.state.app.title}！`
      return this.intro ? this.intro : defIntro
    },
    changeLangEvent() {
      if (this.language == 'zh') {
        localStorage.setItem('locale', 'en')
        this.$i18n.locale = localStorage.getItem('locale')
        localStorage.setItem('lang', 'en')
        this.language = 'en'
        location.reload()
      } else if (this.language == 'en') {
        localStorage.setItem('locale', 'zh')
        this.$i18n.locale = localStorage.getItem('locale')
        localStorage.setItem('lang', 'zh')
        this.language = 'zh'
        location.reload()
      }
    },
    tdVer() {
      return this.school.tdVer
    },
    school() {
      if (
        !!this.$store.state.user.school &&
        this.$store.state.user.school.tdVer == 2
      ) {
        this.initQW()
      }
      return this.$store.state.user.school
    },
    idxTemp() {
      let data = this.domainConfig
      if (data) {
        return data.idxTemp
      }
    },
    elements() {
      let idxTemp = this.idxTemp
      if (idxTemp) {
        return idxTemp.elements
      }
    },
    thrAppId() {
      //第三方配置值 GGzMN8gp4u5CZjm6yl3hmuxu00iY3qEH 评测学习 配置在模板中  。WeSaaS ZFmeahsE5J0SuzcN0Vtg3jWHzQpWhcBx
      let templateVal = this.getTemplateElement('thrAppId')
      return templateVal || 'ZFmeahsE5J0SuzcN0Vtg3jWHzQpWhcBx'
    },
    user() {
      return this.$store.state.user.user
    },
    hasGJZYW() {
      let userMenuList = this.userMenuList
      if (userMenuList) {
        let obj = userMenuList.find(it => {
          return it.menuCode && it.menuCode.indexOf('GJZYW') !== -1
        })
        if (obj) {
          return true
        }
      }
      return false
    },
    userMenuList() {
      return this.$store.state.layout.userMenuList
    },
    searchSchoolList() {
      let schoolList = this.schoolList
      if (!schoolList || !this.searchSchoolName) {
        return schoolList
      }
      return schoolList.filter(sch => {
        return sch.schoolName.indexOf(this.searchSchoolName) !== -1
      })
    }
  },
  mounted() {
    // if(this.school.tdVer == 2 || true) {
    //   console.log('当前为行业版');
    // }
  },
  methods: {
    loadJs(url, callback) {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      if (typeof callback === 'function') {
        // ie
        if (script.readyState) {
          script.onreadystatechange = function() {
            if (
              script.readyState == 'loaded' ||
              script.readyState == 'complete'
            ) {
              script.onreadystatechange = null
              callback()
            }
          }
        } else {
          script.onload = function() {
            callback()
          }
        }
      }
      script.src = url
      document.body.appendChild(script)
    },
    initQW() {
      this.loadJs('https://res.wx.qq.com/open/js/jweixin-1.2.0.js', () => {
        this.loadJs(
          'https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js',
          () => {
            let url = window.location.href
            if (url.indexOf('#') > -1) {
              url = url.split('#')[0]
            }
            // url = window.location.host;

            wxSign({
              accType: 'wxwork',
              type: 'agent_config',
              url: url,
              schoolId: this.school.schoolId
            }).then(res => {
              let { data, resmsg, status } = res
              let _this = this
              if (!status) return this.$message.error(resmsg)
              wx.agentConfig({
                corpid: data.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: data.agentid, // 必填，企业微信的应用id （e.g. 1000247）
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature, // 必填，签名，见附录1
                jsApiList: ['selectExternalContact'], //必填
                success: function(res) {
                  _this.showWxName = true
                  WWOpenData.bind(document.getElementById('ww_open_data'))
                  _this.openid = _this.user.account
                },
                fail: function(res) {
                  // if(res.errMsg.indexOf('function not exist') > -1){
                  //     alert('版本过低请升级')
                  // }
                }
              })
            })
          }
        )
      })
    },
    getTemplateElement(code) {
      let elements = this.elements
      if (elements && elements.length > 0) {
        let logoObj = elements.find(el => {
          return el.code === code
        })
        if (logoObj) {
          return logoObj.content
        }
      }
    }
  }
}
</script>

<style type="text/css" scoped >
.top-header {
  width: 100%;
  height: 38px;
  background: #f2f2f2;
}
.top-header-tip {
  float: left;
  line-height: 38px;
  padding-left: 20px;
  font-size: 12px;
  display: flex;
}
.icon-box {
  padding-top: 7px;
  margin-left: 15px;
  box-sizing: border-box;
}
.icon-box .eval,
.icon-box .report {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-left: 10px;
}
.icon-box .eval {
  background: url(~@/assets/images/icon-eval.png);
}
.icon-box .eval:hover {
  background: url(~@/assets/images/icon-eval2.png);
}
.icon-box .report {
  background: url(~@/assets/images/icon-report.png);
}
.icon-box .report:hover {
  background: url(~@/assets/images/icon-report2.png);
}

.role-infos {
  float: right;
  padding-right: 15px;
}
.school-container {
  font-size: 0;
}
.school-name {
  font-size: 0;
}
.school-name .school-title {
  max-width: 150px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}
.school-name i {
  font-size: 14px;
  vertical-align: middle;
  margin-left: 5px;
}
.role-infos .school-container {
  float: left;
  margin-left: 15px;
  /* min-width: 100px; */
  line-height: 38px;
}
.role-infos .role-container {
  float: left;
  margin-left: 15px;
  line-height: 38px;
}
.role-title .extra {
  font-size: 14px;
  margin-left: 5px;
}
.role-title > small {
  font-size: 14px;
  margin-left: 5px;
}
/* 学校信息 */
.switch-dropdown {
}
.switch-dropdown .school-info {
  max-width: 170px;
}
.slogo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  float: left;
  /* vertical-align: middle; */
}
.switch-dropdown .more-school {
  font-style: italic;
}

/*更多学校 切换*/
.more-school {
  color: #fa5555;
  font-style: italic;
}
.switch-school-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}
.switch-school-list li {
  width: 33.3%;
  float: left;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  font-size: 0;
}
.switch-school-list li a {
  border: 1px solid #ececec;
  padding: 7px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  width: 100%;
  line-height: 28px;
  color: #333;
  font-size: 14px;
}
.switch-school-list li a:hover {
  background: #ecf5ff;
  color: #409eff;
}
.search-input {
  width: 200px;
  margin-bottom: 10px;
  position: absolute;
  margin-top: -35px;
  right: 30px;
}
.role-infos .full {
  float: left;
  /* min-width: 100px; */
  line-height: 38px;
}
.lan {
  display: inline-block;
  cursor: pointer;
  width: 22px;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background: #24292e;
}
</style>
