<!--
 * @Author: Zhongnanwei
 * @Date: 2021-07-30 16:43:22
 * @LastEditors: Zhongnanwei
 * @LastEditTime: 2021-11-11 19:25:56
 * @Description: 
-->
<template>
  <div class="breadcrumb-box">
    <span class="breadcrumb-desc">{{ $t('overData.currentLocation') }}：</span>
    <ol class="breadcrumb">
      <template v-for="(item,index) in navs">
        <li v-if="index != 0"
            class="sperator">></li>
        <li>
          <router-link v-if="item.to"
                       :to="item.to">{{item.name}}</router-link>
          <span v-else>{{item.name}}</span>
        </li>
      </template>
    </ol>
  </div>
</template>
<style type="text/css">
.breadcrumb-box {
  overflow: hidden;
  color: #999;
}
.breadcrumb-desc {
  float: left;
}
.breadcrumb {
  float: left;
  padding-left: 5px;
}
.breadcrumb .sperator {
  padding: 0 5px;
}
.breadcrumb li {
  float: left;
}
.breadcrumb li a {
  text-decoration: underline;
  color: #999;
}
</style>
<script type="text/javascript">
export default {
  props: {
    navs: {
      type: Array
    }
  }
}
</script>
