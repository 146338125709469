<template>
  <div class="navMenu" style="background: #4b87ff">
    <el-dropdown
      class="navMenu_left flex-middle"
      trigger="click"
      :disabled="!(schoolList && schoolList.length)"
      @command="changeSchool"
    >
      <div class="el-dropdown-link flex-middle">
        <img
          v-if="school && school.slogo"
          class="logo"
          :src="school.slogo"
          alt=""
        />
        {{ school && school.schoolName }}
        <i
          v-if="schoolList && schoolList.length > 1"
          class="el-icon-arrow-down el-icon--right"
        ></i>
        <template v-if="schoolList && schoolList.length > 1">
          <el-dropdown-menu
            slot="dropdown"
            style="max-height: 480px; overflow: auto"
          >
            <el-dropdown-item
              v-for="(school, i) of schoolList"
              :key="school.schoolId"
              :divided="i !== 0"
              :command="{ school: school }"
              >{{ school.schoolName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </div>
    </el-dropdown>

    <el-menu
      class="navMenu_menu"
      :default-active="activeIndex"
      mode="horizontal"
      text-color="#fff"
      active-text-color="#FAFBFF"
      background-color="#4B87FF"
      collapse
      @select="handleSelect"
    >
      <span v-for="(item, index) of menuList" :key="item.menuId">
        <el-menu-item
          v-if="!item.menuDtos"
          class="navMenu_menu_item"
          :index="index + ''"
          :route="{ path: '/web/view' }"
        >
          <MenuIcon class="menu-icon" :iconClass="item.icon" />{{
            item.menuName
          }}
        </el-menu-item>

        <el-submenu v-else class="menu_submenu" popper-class="layout-space2_submenu" :index="index + ''">
          <template slot="title">
            <MenuIcon class="menu-icon" :iconClass="item.icon" />{{
              item.menuName
            }}
          </template>
          <el-menu-item
            v-for="(subMenu, i) of item.menuDtos"
            :key="subMenu.menuId"
            :index="`${index}-${i}`"
            >{{ subMenu.menuName }}</el-menu-item
          >
        </el-submenu>
      </span>
    </el-menu>

    <!-- 切换角色 -->
    <RoleChange />
  </div>
</template>

<script>
import RoleChange from './RoleChange';
import SwitchSchoolAndRoleMixin from '@/mixins/SwitchSchoolAndRoleMixin';
import SpaceMenuMixin from '@/mixins/SpaceMenuMixin';
import MenuIcon from '../MenuIcon';
import MenuService from '@/services/MenuService';

export default {
  components: { RoleChange, MenuIcon },
  mixins: [SwitchSchoolAndRoleMixin, SpaceMenuMixin],
  props: {
    activeIndex: {
      type: String,
      require: true,
    },
  },
  methods: {
    handleSelect(key) {
      const indexArr = key.split('-').map((it) => {
        return parseInt(it);
      });

      const [menuIndex, subMenuIndex] = indexArr;

      if (this.menuList[menuIndex].menuUrl) {
        this.redirectUrl(this.menuList[menuIndex]);
        return;
      }

      const subMenuList = this.menuList[menuIndex].menuDtos;
      const menu =
        subMenuList && this.getDefaultMenuUrl(subMenuList[subMenuIndex]);
      this.redirectUrl(menu);
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../index.css');
.navMenu_left {
  padding-left: 20px;
}
.navMenu_left .logo {
  width: 30px;
  margin-right: 10px;
}
.navMenu_menu {
  /* max-width: calc(100vw - 440px); */
  height: 100%;
  overflow: auto;
}
.navMenu_menu::-webkit-scrollbar {
  display: none;
}
.navMenu_menu .menu-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.navMenu_menu .navMenu_menu_item,
.navMenu_menu .menu_submenu /deep/ .el-submenu__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  line-height: normal;
}

/deep/.el-menu--horizontal > span > .el-menu-item {
  border-bottom: none !important;
  font-size: 16px !important;
}
/deep/.el-menu--horizontal > .el-menu-item {
  border-bottom: none !important;
  font-size: 16px !important;
}
/deep/.el-submenu .el-submenu__title {
  font-size: 16px !important;
}
/deep/.el-menu--horizontal .el-icon-arrow-down {
  display: none;
}
</style>
<style>
.el-main {
  padding: 0 !important;
}
.layout-space2_submenu.el-menu--horizontal .el-menu-item.is-active {
  background-color: #4d80ef !important;
}
</style>
