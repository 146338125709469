<template>
	<div class="list-tips error" >{{resmsg}}</div>
</template>
<style type="text/css" scoped >
	.list-tips{
		margin: 30px auto;
		text-align: center;
		padding-top: 130px;
		font-size: 14px;
		color: #999;
	}
	.list-tips.error{
		background: url('../../assets/images/warning-img.png') no-repeat center top;
	}
</style>
<script type="text/javascript">
	export default{
		props:{
			resmsg:{
				type:String,
				default:"系统错误，请稍后重试（10001）"
			},
			loading:{}
		},
		computed:{
			/*type(){
				var tmpType=null;
				var json=this.json;
				if(this.loading==true||!json){
					return "";
				}
				var data=json.data;
				if(json.status==false){
					tmpType=1;
				}else if(!data||!data.list||!data.list.length){
					tmpType=2;
				}
				return tmpType;
			},*/
			
		}
	}
</script>