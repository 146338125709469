<!--
 * @Author: chenhuiling
 * @Date: 2021-10-22 16:56:17
 * @LastEditors: longtuxin
 * @LastEditTime: 2022-03-31 10:51:37
 * @FilePath: /web/src/pages/NotFoundPage.vue
 * @Description: 头部注释
-->
<template>
  <div class="error-page">
    <img src="@/assets/images/404.png" />
    <p class="desc">{{ text }}</p>
    <p class="btn-box">
      <edu-button
        type="primary"
        @click="jumpReturn"
      >{{ returnLast }}</edu-button>
      <edu-button
        type="primary"
        @click="jumpIndex"
      >{{ returnIndex }}</edu-button>
    </p>
  </div>
</template>
<style type="text/css" scoped >
.error-page {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.desc {
  font-size: 18px;
  color: #999;
  margin: 30px;
}
.btn-box .edu-btn {
  margin-right: 30px;
}
</style>
<script type="text/javascript">
import { EduButton } from "edu-ui";
export default {
  components: {
    EduButton,
  },
  data: function () {
    return {
      text: this.$t("nofound.text"),
      returnLast: this.$t("nofound.returnLast"),
      returnIndex: this.$t("nofound.returnIndex"),
    };
  },
  computed:{
    homeMenuUrl() {
      return this.$store.state.layout.homeMenuUrl
    },
  },
  mounted: function () {},
  methods: {
    jumpIndex() {
      this.$router.push({ path: this.homeMenuUrl });
    },
    jumpReturn() {
      this.$router.back();
    },
  },
};
</script>