<template>
  <div class="tips-container">
    <edu-spin class="loading-box"
              v-if="loading==true"
              loadingText="加载中..."></edu-spin>

    <error-tip v-else-if="loading!=true&&json&&json.status==false"
               :resmsg="json && json.resmsg"></error-tip>

    <no-data-tip v-else-if="nodata===true||(loading!=true&&isNoData)"
                 :resmsg="json && json.resmsg"
                 :imgUrl="json && json.imgUrl"></no-data-tip>
  </div>
</template>
<script type="text/javascript">
import { EduSpin } from 'edu-ui'
import ErrorTip from './ErrorTip'
import NoDataTip from './NoDataTip'
export default {
  components: {
    EduSpin,
    ErrorTip,
    NoDataTip
  },
  props: {
    loading: {},
    json: {},
    size: {},
    nodata: {}
  },
  computed: {
    isNoData() {
      let flag = false
      let json = this.json
      if (!json) {
        return flag
      }
      let data = json.data
      if (!data) {
        flag = true
      } else if (data instanceof Array && data.length == 0) {
        flag = true
      } else if (data.list instanceof Array && data.list.length == 0) {
        flag = true
      }
      return flag
    }
  }
}
</script>
<style type="text/css" scoped>
.tips-container {
  text-align: center;
  overflow: hidden;
}
.loading-box {
  margin-bottom: 30px;
}
</style>